import React from "react";
import { useNavigate } from "react-router-dom";

const SettingTabs = ({ currentTab }) => {
  const navigate = useNavigate();

  const onClickTab = (t) => {
    if (t === currentTab) {
      return false;
    } else {
      t ? navigate("/settings/" + t) : navigate("/settings");
    }
  };
  return (
    <div className="commonSlidePl">
      <div className="setting-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-content">
                <ul className="nav nav-pills justify-content-start pt-4" id="pills-tab" role="tablist">
                  {/* <li className="nav-item" role="presentation">
                    <div
                      className="section-back"
                      onClick={() => onClickTab(null)}
                    >
                      <a href="#" className="nav-link">
                        <i className="fa-solid fa-angle-left"></i> Settings
                      </a>
                    </div>
                  </li> */}
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => onClickTab("general")}
                  >
                    <button
                      className={`nav-link ${currentTab === "general" ? "active" : ""
                        }`}
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      General
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => onClickTab("payment")}
                  >
                    <button
                      className={`nav-link ${currentTab === "payment" ? "active" : ""
                        }`}
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Payments
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => onClickTab("tickets")}
                  >
                    <button
                      className={`nav-link ${currentTab === "tickets" ? "active" : ""
                        }`}
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Tickets
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => onClickTab("team")}
                  >
                    <button
                      className={`nav-link ${currentTab === "team" ? "active" : ""
                        }`}
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Team
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => onClickTab("accounts")}
                  >
                    {/* <button
                    className={`nav-link ${currentTab === "accounts" ? "active" : ""
                      }`}
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                  </button> */}
                    <button
                      className={`nav-link ${currentTab === "accounts" ? "active" : ""
                        }`}
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Accounts
                    </button>
                  </li>
                  {/*<li
                  className="nav-item"
                  role="presentation"
                  onClick={() => onClickTab("crm-fields")}
                >
                  <button
                    className={`nav-link ${
                      currentTab === "crm-fields" ? "active" : ""
                    }`}
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    CRM fields
                  </button>
                </li>*/}
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => onClickTab("orders")}
                  >
                    <button
                      className={`nav-link ${currentTab === "orders" ? "active" : ""
                        }`}
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Orders
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => onClickTab("shop")}
                  >
                    <button
                      className={`nav-link ${currentTab === "shop" ? "active" : ""
                        }`}
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Shop
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SettingTabs;
