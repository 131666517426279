import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { AllContext } from "../context";
import { getBusinessSubdomain } from "../shared/functions";

const PayLaterOrder = () => {
    const context = useContext(AllContext);
    const {
        allApiUrl,
        apiPostMethod,
        redirectToLogin,
        toastMessage,
    } = context;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const paylaterOrderDetail = useSelector(
        (state) => state.loginReducer.paylaterOrderDetail
    );
    
    const { currency } = useSelector((state) => state.sideMenuReducer.paymentSettingData);

    const [payLaterSuccessDetails, setPayLaterSuccessDetails] = useState(paylaterOrderDetail);
    const [userInformation, setUserInformation] = useState([]);
    const [ companySettingData, setCompanySettingData ] = useState({});
    
    useEffect(() => {
        getPaymentSettingData();
    }, []);

    async function getPaymentSettingData() {
        const subDomain = getBusinessSubdomain();
        let header = {
          //   Authorization: `Bearer ${loginUserAccessToken}`,
        };
        try {
          const response = await apiPostMethod(allApiUrl.PAYMENT_METHOD_PUBLIC, {subDomain}, header);
          if (response.status === 200) {
            setCompanySettingData(response.data.companyData);
          }
        } catch (err) {
          redirectToLogin(dispatch, navigate, err);
          // setIsLoading(false);
          toastMessage("error", err.data.message);
        }
    }

    return (
        <section className="checkOut-page">
            {
                payLaterSuccessDetails && Object.keys(payLaterSuccessDetails).length ? (
                    <div className="commonSlidePl">
                        <div className="container-fluid pe-0 overflow-hidden">
                            <div className="row h-100vh align-items-center">
                                <div className="col-lg-7">
                                    <div className="checkoutListing">
                                        <p className="agencyName">OpFlow</p>
                                        <h3>Thank you, {payLaterSuccessDetails.name}</h3>
                                        <p>
                                            We've accepted your order, and we're getting it ready. A
                                            confirmation email has been sent to{" "}
                                            <strong>{userInformation.userId?.email}</strong>.
                                        </p>
                                        <p>
                                            Go to the{" "}
                                            <a
                                                href="#"
                                                onClick={() => {
                                                    navigate("/home");
                                                }}
                                            >
                                                client portal
                                            </a>{" "}
                                            to check the status of this order and receive updates.
                                        </p>

                                        <div className="billingRow">
                                            <p>
                                                <strong>We have been created your order with unpaid invoice. Your payment is due for this order and you can pay it later.</strong>
                                            </p>
                                        </div>

                                        <div className="sectionHelp">
                                            <p>
                                                Need help? <a href={companySettingData?.contactLink ? companySettingData?.contactLink : '#'} target="_blank">Contact us</a>
                                            </p>
                                            <a
                                                className="continueBtn"
                                                href="#"
                                                onClick={() => {
                                                    navigate("/home");
                                                }}
                                            >
                                                Continue to client portal
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="summaryHead h-100vh">
                                        <div className="summaryPage">
                                            <h4>Summary</h4>
                                            {payLaterSuccessDetails &&
                                                payLaterSuccessDetails.product &&
                                                payLaterSuccessDetails.product.map((item, idx) => {
                                                    return (
                                                        <div className="d-flex" key={idx}>
                                                            <div>
                                                                <p>{item.id.name}</p>
                                                                <p>
                                                                    <span>Qty</span> {item.quantity}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <p>{currency?.symbol ? currency.symbol : '$'} {item.id.price?.toFixed(2)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            <hr />
                                            <div className="d-flex">
                                                <div>
                                                    <p>Total</p>
                                                    <p>{currency?.code ? currency.code : 'USD'}</p>
                                                </div>
                                                <div>
                                                    <div>
                                                        <p>{currency?.symbol ? currency.symbol : '$'}{payLaterSuccessDetails?.chargeAmount.toFixed(2)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : <div className="text-center"></div>
            }
        </section>
    );
};
export default PayLaterOrder;