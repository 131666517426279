import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
// import { countries } from "../../../shared/constants";
import { getCountries } from "node-countries";
import { TreeSelect } from "antd";
import { TreeNode } from "antd/lib/tree-select";
import toastMessages from "../../toastMessages";

const allCountriesData = getCountries();

const AddTaxRuleModal = ({
  isAddRuleModalModalOpen,
  handleAddRule,
  handleCancelModal,
}) => {
  const [formValues, setFormValues] = useState({
    taxValue: 0,
    taxRuleName: "",
  });

  const [provinces, setProvinces] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  function getCountriesOptions() {
    let options = allCountriesData.map((data) => {
      return { title: data.name, value: data.name };
    });
    setCountriesData(options);
  }
  const [selectedCountryOptions, setSelectedCountryOptions] = useState([]);
  const [selectedProvinceOptions, setSelectedProvinceOptions] = useState([]);
  useEffect(() => {
    getCountriesOptions();
  }, []);
  console.log("selectedProvinceOptions", selectedProvinceOptions);
  const processProvinces = (selectedCountries) => {
    let provicesData = [];
    selectedCountries.forEach((data) => {
      const findCountry = allCountriesData.find(
        (country) => country.name.toLowerCase() === data.toLowerCase()
      );
      if (findCountry.provinces) {
        provicesData = provicesData.concat(findCountry.provinces);
      }
    });
    setProvinces(provicesData);
  };

  const handleAddRuleSubmit = () => {
    const formData = {
      taxCountries: selectedCountryOptions,
      taxProvinces: selectedProvinceOptions,
      taxRuleName: formValues.taxRuleName,
      taxValue: formValues.taxValue,
    };
    if (
      formData.taxCountries.length > 0 &&
      formData.taxValue &&
      parseInt(formData.taxValue) !== 0 &&
      formData.taxRuleName !== ""
    ) {
      handleAddRule(formData);
    } else {
      toastMessages("error", "Incorrect form data");
    }
  };

  return (
    <>
      <Modal
        show={isAddRuleModalModalOpen}
        onHide={handleCancelModal}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        className="add-new-taxrule-modal add-order-modals"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Tax Rule
          </Modal.Title>
        </Modal.Header>

        <ModalBody>
          <div className="add-taxRule-form">
            <div className="form-group mb-4">
              <label>Choose Countries</label>
              <TreeSelect
                mode="multiple"
                allowClear
                style={{ width: "100%", zIndex: 5 }}
                dropdownStyle={{ zIndex: 2000 }}
                placeholder="Choose Countries"
                value={selectedCountryOptions}
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                onChange={(event, option) => {
                  setSelectedCountryOptions(event);
                  processProvinces(event);
                  setSelectedProvinceOptions([]);
                }}
                maxTagPlaceholder={(omittedValues) =>
                  `+ ${omittedValues.length}`
                }
                showArrow
                treeData={[
                  {
                    label:
                      selectedCountryOptions.length > 0 ? (
                        <span
                          onClick={() => {
                            setSelectedCountryOptions([]);
                            processProvinces([]);
                            setSelectedProvinceOptions([]);
                          }}
                          style={{
                            display: "inline-block",
                            color: "#286FBE",
                            cursor: "pointer",
                          }}
                        >
                          Unselect all
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            setSelectedCountryOptions(
                              countriesData.map((data) => data.value)
                            );
                            processProvinces(
                              countriesData.map((data) => data.value)
                            );
                            setSelectedProvinceOptions([]);
                          }}
                          style={{
                            display: "inline-block",
                            color: "#286FBE",
                            cursor: "pointer",
                          }}
                        >
                          Select all
                        </span>
                      ),
                    value: "all",
                    disableCheckbox: true,
                    disabled: true,
                  },
                  ...countriesData,
                ]}
                size="large"
                maxTagCount={2}
              />
            </div>
            <div className="form-group mb-4">
              <label>Choose Provinces</label>
              <TreeSelect
                mode="multiple"
                allowClear
                style={{ width: "100%", zIndex: 5 }}
                dropdownStyle={{ zIndex: 2000 }}
                placeholder="Choose Provinces"
                value={selectedProvinceOptions}
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                onChange={(event) => {
                  setSelectedProvinceOptions(event);
                }}
                showArrow
                size="large"
                maxTagCount={2}
              >
                {selectedProvinceOptions.length === provinces.length ? (
                  <TreeNode
                    value="unselect all"
                    title={
                      <span
                        onClick={() => {
                          setSelectedProvinceOptions([]);
                        }}
                        style={{
                          display: "inline-block",
                          color: "#286FBE",
                          cursor: "pointer",
                        }}
                      >
                        Unselect all
                      </span>
                    }
                    disableCheckbox="true"
                  ></TreeNode>
                ) : (
                  <TreeNode
                    value="select all"
                    title={
                      <span
                        onClick={() => {
                          const eventsData = provinces.map((data) => data.name);
                          setSelectedProvinceOptions(eventsData);
                        }}
                        style={{
                          display: "inline-block",
                          color: "#286FBE",
                          cursor: "pointer",
                        }}
                      >
                        Select all
                      </span>
                    }
                    disableCheckbox="true"
                  ></TreeNode>
                )}
                {provinces.map((item, index) => {
                  return (
                    <>
                      <TreeNode value={item.name} title={item.name}></TreeNode>
                    </>
                  );
                })}
              </TreeSelect>
            </div>
            <div className="form-group mb-4">
              <label className="me-3">Tax Name</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  name="taxValue"
                  value={formValues.taxRuleName}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      taxRuleName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group mb-4">
              <label className="me-3">Tax Value</label>
              <div className="input-group">
                <input
                  type="number"
                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                  className="form-control"
                  name="taxValue"
                  value={formValues.taxValue}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      taxValue: e.target.value ? parseInt(e.target.value) : "",
                    })
                  }
                />
                <div className="input-group-prepend">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            color="secondary"
            className="cancel-btn"
            onClick={handleCancelModal}
          >
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="btn btn-save"
            onClick={handleAddRuleSubmit}
          >
            Add Tax
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddTaxRuleModal;
