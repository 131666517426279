import {convertToUpperCase} from "../../../shared/functions";
import React from "react";

const TableTabList = ({tabs, activeTab, countStatus, setActiveTab}) => {
  const tempArray = [];
  tabs.map((item, idx) => {
    tempArray.push(
      <li key={idx} className="nav-item" role="presentation">
        <button
          className={activeTab.toLowerCase() === item ? 'nav-link active' : 'nav-link'}
          id="pills-overdue-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-overdue"
          type="button"
          role="tab"
          aria-controls="pills-overdue"
          aria-selected="true"
          onClick={() => {
            setActiveTab(item);
          }}
        >
          <span>{countStatus[item]}{" "}</span>{convertToUpperCase(item)}
        </button>
      </li>
    )
  });
  return tempArray;
}
export default TableTabList;
