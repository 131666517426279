const INITIAL_STATE = {
  teamsData: []
};

const teamReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TEAM_LIST":
      return { ...state, teamsData: action.data };
    default:
      return state;
  }
};

export default teamReducer;
