import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import noData from "../../../assets/img/nodata-img.svg";
import moment from "moment";
import { AllContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../pageLoader";
import LoadingOverlay from 'react-loading-overlay';
import {apiPostMethod} from "../../../api/rest";
import PaginationNewUI from "../../PaginationNewUI";
/*import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import * as _ from "lodash";*/
LoadingOverlay.propTypes = undefined;

const TableCoupons = ({searchText, isLoadingSearch, setIsLoadingSearch}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);

  const {
    allApiUrl,
    apiGetMethod,
    // apiDeleteMethod,
    toastMessage,
    redirectToLogin,
  } = context;

  /*const [couponId, setCouponId] = useState();
  const [couponCode, setCouponCode] = useState("");
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");*/
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [coupon, setCoupon] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  useEffect(() => {
    if (isLoadingSearch) {
      filterCoupon();
    }
  }, [isLoadingSearch])
  useEffect(() => {
    filterCoupon();
  }, [itemOffset, itemsPerPage]);
  function filterCoupon() {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    let data = {};
    data.page = itemOffset;
    data.limit = itemsPerPage;
    if (searchText) { data.searchText = searchText; }

    apiPostMethod(
      allApiUrl.FILTER_COUPONS,
      data,
      header
    )
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setCoupon([...res.data.coupons]);
        }
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        // redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      });
  }

  const editCoupon = async (_id) => {
    document.getElementById('searchModalCloseId').click();
    navigate("/edit-coupon/" + _id);
  };

  /*const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  function deleteCoupon(couponId) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.COUPON, couponId, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        filterCoupon();
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function deleteMultiple() {
    setIsLoading(true);
    bulkDeleteId.map((row) => {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiDeleteMethod(allApiUrl.COUPON, row, header)
        .then((res) => {
          filterCoupon();
          setIsLoading(false);
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
  }
  function checkedAll() {
    if (coupon.length === bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = coupon.map((e) => {
        return e._id;
      });
      setBulkDeleteId([...ids]);
    }
  }
  const onChangeCheckbox = (id, val) => {
    if (val) {
      bulkDeleteId.push(id);
      setBulkDeleteId([..._.uniq(bulkDeleteId)]);
    } else {
      _.remove(bulkDeleteId, (o) => o === id);
      setBulkDeleteId([...bulkDeleteId]);
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }*/

  return (
    <>
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section horizontalPadding">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h2>Coupons</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-filter">
                <PaginationNewUI
                  itemsPerPage={itemsPerPage}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  pageCount={pageCount}
                  totalRecordsCount={totalRecordsCount}
                  setItemsPerPage={setItemsPerPage}
                  mainClassName="col-lg-12"
                />
              </div>
            </div>
          </div>

          <LoadingOverlay
            styles={{
              spinner: (base) => ({
              ...base,
              width: "50px",
              "& svg circle": {
                  stroke: "#007D87",
              },
              }),
              overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 0.5)",
              }),
          }}
          active={isLoading}
          spinner
          text="Loading content..."
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="common-table clients-table mt-3 files-table rounded-1">
                  <table className="table">
                    <thead>
                    <tr>
                      {/*<th className="ps-4 w65-px">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="selectAll form-check-input"
                            id="selectAll"
                            checked={
                              coupon.length && coupon.length === bulkDeleteId.length
                            }
                            onChange={() => checkedAll()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="selectAll"
                          ></label>
                        </div>
                      </th>*/}
                      <th>Code</th>
                      <th>Description</th>
                      <th>Type</th>
                      <th>Expires</th>
                      <th>Single use</th>
                      <th>Times used</th>
                      {/*<th></th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {coupon.map((value, index) => {
                      return (
                        <tr key={index}>
                          {/*<td className="ps-4 w65-px">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={value._id}
                                checked={!!(_.includes(bulkDeleteId, value._id))}
                                onChange={(e) => {
                                  setIsSingleChecked(e.target.checked);
                                  onChangeCheckbox(
                                    value._id,
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={value._id}
                              ></label>
                            </div>
                          </td>*/}
                          <td>
                            <a onClick={() => editCoupon(value._id)}>
                              {value.couponCode}
                            </a>
                          </td>
                          <td>{value.description}</td>
                          <td>
                              <span>
                                {value.fixedAmount ? "$":"%"}
                              </span>
                          </td>
                          <td>
                            {value.expiryDate ? moment(value.expiryDate).format("MMM DD , YYYY") : ''}
                          </td>
                          <td>{value.redemption_limit_one_use_per_customer ? 'Yes':'No'}</td>
                          <td>{value.timesUsed}</td>
                          {/*<td className="text-end pe-4">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle btn-info"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a
                                    className="dropdown-item"
                                    onClick={() => editCoupon(value._id)}
                                  >
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                    onClick={() => {
                                      setCouponId(value._id);
                                      setCouponCode(value.couponCode);
                                    }}
                                  >
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>*/}
                        </tr>
                      );
                    })}
                    {coupon && coupon.length <= 0 && (
                      <tr>
                        <td colSpan={7}>
                          <div className="noData-section">
                            <img src={noData} />
                            <p>No Data Found</p>
                          </div>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
                {/*{(bulkDeleteId.length > 0 || isSingleChecked) && (
                  <div className="table-bottom-option">
                    <div className="row">
                      <div className="col-lg-3 col-md-4">
                        <div className="input-group">
                          <select
                            id="selectOption"
                            value={selectOption.target}
                            onChange={(e) => selectOption(e)}
                            className="form-control"
                          >
                            <option value="">With Selected...</option>
                            <option value="Delete">Delete</option>
                          </select>
                          <button
                            className="btn btn-apply"
                            onClick={() => {
                              if (selectedOption == "Delete") {
                                deleteMultiple();
                              }
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}*/}
              </div>
            </div>
          </LoadingOverlay>
        </div>
      )}
    </>
  );
};
export default TableCoupons;
