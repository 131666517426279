import React from "react";
import * as _ from "lodash";

const FormElementGenerate = (props) => {
  const onChangeCrmFields = (item, e, opt = null) => {
    if (opt) {
      let itemVal = item.value ? item.value: [];
      const optVal = e.target.checked ? opt.value : null;
      if (optVal) {
        itemVal.push(optVal);
      } else {
        _.remove(itemVal, o => { return (o === opt.value)});
      }
      _.uniq(itemVal);
      item.value = itemVal;
    } else {
      item.value = e.target.value;
    }
    props.setJsonData(props.jsonData);
  }
  const getMapItems = (item) => {
    return (
      <div className="col-lg-6 col-md-6" key={item.id}>
        <div className="form-group">
          {item.element === 'TextInput' && <>
            <label>{item.label}<small>{item.required ? '':' Optional'}</small></label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => onChangeCrmFields(item, e)}
              defaultValue={item.value}
            />
          </>
          }
          {item.element === 'TextArea' && <>
            <label>{item.label}<small>{item.required ? '':' Optional'}</small></label>
            <textarea
              className="form-control"
              defaultValue={item.value}
              onChange={(e) => onChangeCrmFields(item, e)}
            />
          </>
          }
          {item.element === 'Checkboxes' && <>
            <label>{item.label}<small>{item.required ? '':' Optional'}</small></label>
            {item.options.map(opt => {
              return <div key={opt.key}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={item.value && item.value.length && item.value.includes(opt.value)}
                  onChange={(e) => onChangeCrmFields(item, e, opt)}
                />&nbsp;
                <label>{opt.text}</label>
              </div>
            })}
          </>
          }
          {item.element === 'Dropdown' && <>
            <label>{item.label}<small>{item.required ? '':' Optional'}</small></label>
            <select className="form-control" defaultValue={item.value} onChange={(e) => onChangeCrmFields(item, e)}>
              {item.options.map(opt => {
                return (
                  <option key={opt.key} value={opt.value}>{opt.text}</option>
                );
              })}
            </select>
          </>
          }
        </div>
      </div>
    )
  }
  return (
    <>
      {!!(props.jsonData.filter(o => o?.alternateForm).length && props.showCardUI) &&
        <div className="commonForm mt-5 rounded-3 p-4">
          <div className="row">
            {/* <div className="col-lg-12"> */}
              {props.jsonData.map((item) => {
                if (item.alternateForm) {
                  return (
                    getMapItems(item)
                  )
                }
              })
              }
            {/* </div> */}
          </div>
        </div>
      }
      {!!(props.jsonData.filter(o => o?.alternateForm).length && !props.showCardUI) &&
        <>
          <div className="row">
          {props.jsonData.map((item) => {
            if (item.alternateForm) {
              return (
                getMapItems(item)
              )
            }
          })}
          </div>
        </>
      }
    </>
  )
}
export default FormElementGenerate;
