import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { apiPutMethod, apiGetMethod } from "../../api/rest";
import { AllContext } from "../../context";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormElementGenerate from "../../components/FormElementsEdit/FormElementGenerate";
import openIcon from "../../assets/img/icons/openSide.svg";

const EditTicket = (props) => {
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [editId, setEditId] = useState();
  const [showToEmail, setShowToEmail] = useState(false);
  const [addTickets, setAddTickets] = useState("");
  const [realtedTicket, setRelatedTicket] = useState("");
  const [clientOrder, setClientOrder] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [ticket, setTicket] = useState({
    subject: "",
    client: {},
  });
  const [jsonData, setJsonData] = useState([]);

  let { id } = useParams();
  const context = useContext(AllContext);
  const { allApiUrl, toastMessage } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { EDIT_TICKET, GET_ALL_CLIENT, GET_TICKET, CLIENT_ORDER_ID } =
    allApiUrl;
  useEffect(() => {
    getTickets();
    getAllClient();
  }, []);

  const getTickets = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(GET_TICKET + "/" + id, "", header).then((res) => {
      if (res.status === 200) {
        setTicket(res.data.details[0]);
        setJsonData(res.data.details[0].ticketsFields || []);
        setRelatedTicket(
          res.data.details[0].relatedOrder
            ? res.data.details[0].relatedOrder._id
            : ""
        );
      }
    });
  };

  useEffect(() => {
    if (ticket.client?._id) {
      getClientOrders(ticket.client._id);
    }
  }, [ticket.client?._id]);

  function getClientOrders() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(CLIENT_ORDER_ID + "/" + ticket.client._id, "", header)
      .then((res) => {
        setClientOrder(res.data.order);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  function getAllClient() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(GET_ALL_CLIENT, "", header).then((res) => {
      if (res.status === 200) {
        setAllClient(res.data.team);
      }
    });
  }

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const editTickets = () => {
    const ticketsFields = jsonData;
    const data = {
      subject: ticket.subject,
      relatedOrder: realtedTicket,
      client: ticket.client._id,
      ticketsFields,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(EDIT_TICKET + "/" + id, data, header)
      .then((res) => {
        if (res.status === 200) {
          toastMessage("success", res.data.message);
          navigate(`/view-ticket/${id}`);
        }
      })
      .catch((err) => { });
  };

  return (
    <div>
      <Sidebar
        activePage="tickets"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <div className="commonSlidePl">

        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">
                    Tickets
                    <span className="userCounter">&nbsp;</span>
                  </h2>
                  <span className="openArrowIcon" onClick={() => {
                    setToggleSidebar(!toggleSideBar)
                  }}>
                    <img src={openIcon} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="setting-form-section pt-3 horizontalPadding mt-4">
          <div className="container-fluid pl-240">
            <form>
              <div className="row">

                <div className="col-lg-9">
                  <div className="setting-form formOuter">
                    <div className="commonForm rounded-3">
                      <h3 className="fs-4 m-0 mb-4 p-3">Edit Ticket</h3>
                      <div className="px-4">


                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <label>Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={ticket.subject}
                                onChange={(e) =>
                                  setTicket((prevData) => {
                                    return { ...prevData, subject: e.target.value };
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label>
                                Related order <small>optional</small>
                              </label>
                              <select
                                className="form-control"
                                value={realtedTicket}
                                onChange={(event) => {
                                  setRelatedTicket(event.target.value);
                                }}
                              >
                                <option value="" selected>
                                  Please Select... <body></body>
                                </option>
                                {clientOrder.map((value, idx) => {
                                  return (
                                    <option key={idx} value={value._id}>
                                      {value.title}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group position-relative">
                              <label>Select Client</label>
                              <input
                                className="form-control"
                                onClick={() => {
                                  setShowToEmail(true);
                                }}
                                onBlur={() => {
                                  setTimeout(() => {
                                    setShowToEmail(false);
                                  }, 300);
                                }}
                                value={ticket?.client?.email}
                              />
                              <ul className="dataDropdown">
                                {showToEmail &&
                                  allClient &&
                                  allClient.map((client) => {
                                    if (client.userId?.email)
                                      return (
                                        <li
                                          onClick={() => {
                                            setShowToEmail(!showToEmail);
                                            setAddTickets(client.userId.email);
                                            setTicket((prevData) => {
                                              return {
                                                ...prevData,
                                                client: client.userId,
                                              };
                                            });
                                          }}
                                        >
                                          {client.userId.firstName ||
                                            client.userId.lastName ? (
                                            <>
                                              {client.userId.firstName &&
                                                client.userId.lastName ? (
                                                client.userId.firstName +
                                                " " +
                                                client.userId.lastName
                                              ) : (
                                                <>
                                                  {client.userId.firstName
                                                    ? client.userId.firstName
                                                    : client.userId.lastName}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            client.userId.email
                                          )}
                                          <br />
                                          <small>{client.userId.email}</small>
                                        </li>
                                      );
                                  })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <FormElementGenerate
                      jsonData={jsonData}
                      setJsonData={setJsonData}
                      showCardUI={true}
                    />
                    <div className="btn-section text-end">

                    </div>

                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="commonBtn-section">
                    <button type="button" className="btn btn-cancel" onClick={() => navigate(`/view-ticket/${id}`)}>Cancel</button>
                    <button
                      type="button"
                      className="btn btn-save"
                      onClick={() => {
                        editTickets(editId);
                      }}
                    >
                      Save Changes
                    </button>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditTicket;