import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";

const DeleteConfirmModal = ({ isDeleteConfirmModalOpen, handleDelete, handleCancelModal, modalContent, disabledBtn }) => {

    return (
        <Modal
            show={isDeleteConfirmModalOpen}
            onHide={handleCancelModal}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            className="add-new-taxrule-modal add-order-modals"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete Confirmation
                </Modal.Title>
            </Modal.Header>

            <ModalBody>
                <div className="add-orderstatus-form">{modalContent || 'Are you sure, you want delete this'}</div>        
            </ModalBody>    
            
            <ModalFooter>
                <Button color="secondary" className="cancel-btn" onClick={handleCancelModal}>Cancel</Button>{' '}
                <Button color="primary" className="btn btn-save" disabled={disabledBtn} onClick={() => handleDelete()}>Delete</Button>
            </ModalFooter>    
        </Modal>
    )
}

export default DeleteConfirmModal;