import axios from "axios";
export const header = {};

export const apiGetMethod = (url, params = "", headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}${url}${params}`, { headers })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiGetMethodSession = (url, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}${url}`, { headers })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiPostMethod = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}${url}`, data, { headers })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiGetMethod1 = (url, params = "", headers) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}${url}/${params}`,
      method: "GET",
      headers: { headers },
      responseType: "blob",
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiDeleteMethod = (url, param = "", headers) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}${url}/${param}`, {
        headers,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiDeleteMethodWithBody = (url, body, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}${url}`, body, {
        headers,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiPutMethod = (url, data, headers, param = "") => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}${url}/${param}`, data, {
        headers,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
