import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";

const AddEditTaskModal = ({ isModalOpen, isEditMode, task, setTask, editHandler, addTaskHandler, handleCancelModal }) => {
  return (
    <Modal
      show={isModalOpen}
      onHide={handleCancelModal}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      className="add-new-taxrule-modal add-order-modals taskModal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {`${isEditMode == true ? "Edit Task" : "Add Task"}`}
        </Modal.Title>
      </Modal.Header>
      <ModalBody>
        <form>
          <div className="form-group">
            <label>Task</label>
            <input
              type="text"
              className="form-control"
              value={task.taskName}
              onChange={(event) =>
                setTask({
                  ...task,

                  taskName: event.target.value,
                })
              }
            />
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea
              className="form-control"
              value={task.description}
              onChange={(event) =>
                setTask({
                  ...task,
                  description: event.target.value,
                })
              }
            ></textarea>
          </div>

          <div className="form-group">
            <label>Deadline</label>
            <div className="row">
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    value={task.deadline}
                    onChange={(event) =>
                      setTask({
                        ...task,
                        deadline: parseInt(event.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <select
                    className="form-control"
                    value={task.deadlineValue}
                    onChange={(event) =>
                      setTask({
                        ...task,
                        deadlineValue: event.target.value,
                      })
                    }
                  >
                    <option value="day">Days</option>
                    <option value="hour">Hours</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" className="cancel-btn" onClick={handleCancelModal}>Cancel</Button>{' '}
        <Button color="primary" className="btn btn-save" onClick={isEditMode == true ? editHandler : addTaskHandler}>Save</Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddEditTaskModal;