import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import ServiceCart from "../../serviceCart";
import { ClientModal } from "../../Modals/ClientModal";
import { AllContext } from "../../../context";
import PageLoader from "../../pageLoader";
import PaginationNewUI from "../../PaginationNewUI";
import LoadingOverlay from "react-loading-overlay";
import noData from "../../../assets/img/nodata-img.svg";
LoadingOverlay.propTypes = undefined;

const TableShop = ({
  isPage,
  searchText,
  isLoadingSearch,
  setIsLoadingSearch,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
    apiDeleteMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { currency } = useSelector(
    (state) => state.sideMenuReducer.paymentSettingData
  );
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [cartCount, setCartCount] = useState(null);
  const [couponCodeAppliedSuccessfully, setCouponCodeAppliedSuccessfully] =
    useState(false);
  const [showCart, setShowCart] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [initialLoading, setInitialLoading] = useState(true);

  let header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  useEffect(() => {
    if (isLoadingSearch) {
      getAllServices();
    }
  }, [isLoadingSearch]);
  useEffect(() => {
    getAllServices();
  }, [itemOffset, itemsPerPage]);

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const getAllServices = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let data = {};
    data.page = itemOffset;
    data.limit = itemsPerPage;
    if (searchText) {
      data.searchText = searchText;
    }

    apiPostMethod(allApiUrl.LIST_SERVICES_V2, data, header)
      .then((res) => {
        setPageCount(res.data.totalPage);
        setTotalRecordsCount(res.data.totalItems);
        setAllServices([...res.data.services]);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {
          setIsLoadingSearch(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err?.data?.message);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {
          setIsLoadingSearch(false);
        }
      });
  };
  async function deleteHandler(productId) {
    try {
      const response = await apiDeleteMethod(allApiUrl.CART, productId, header);
      if (response.status === 200) {
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      toastMessage("error", err.data.message);
    }
  }
  return (
    <div>
      {!!isPage && (
        <>
          <Sidebar
            activePage="shop"
            setToggleSidebar={setToggleSidebar}
            toggleSideBar={toggleSideBar}
          />
          <Header handleSidebar={handleSidebar} />
        </>
      )}
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div
          className={
            true
              ? "shopSection commonPadding horizontalPadding"
              : "shopSection horizontalPadding"
          }
        >
          <div className={isPage ? "commonSlidePl" : ""}>
            <div className={"container-fluid"}>
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="heading mb-3">
                    <h2 className="fs-4">Services</h2>
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-end align-items-end">
                  <PaginationNewUI
                    itemsPerPage={itemsPerPage}
                    itemOffset={itemOffset}
                    setItemOffset={setItemOffset}
                    pageCount={pageCount}
                    totalRecordsCount={totalRecordsCount}
                    setItemsPerPage={setItemsPerPage}
                    mainClassName="col-lg-7 d-flex justify-content-end"
                  />
                  <div className="col-lg-3 d-flex justify-content-end">
                    <button
                      className="btn btn-save"
                      onClick={() => {
                        navigate("/cart");
                        document.getElementById("searchModalCloseId").click();
                      }}
                    >
                      View Cart{cartCount ? " | " + cartCount : ""}
                    </button>
                  </div>
                </div>
              </div>

              <LoadingOverlay
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                      stroke: "#007D87",
                    },
                  }),
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.5)",
                  }),
                }}
                active={isLoading}
                spinner
                text="Loading content..."
              >
                <div className="row">
                  {allServices.map((service, idx) => {
                    return (
                      <ServiceCart
                        key={idx}
                        {...service}
                        currency={currency}
                        setShowCart={setShowCart}
                        setCouponCodeAppliedSuccessfully={
                          setCouponCodeAppliedSuccessfully
                        }
                      />
                    );
                  })}
                  {allServices && allServices.length <= 0 && (
                    <div className="noData-section">
                      <img src={noData} />
                      <p>No Data Found</p>
                    </div>
                  )}
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      )}

      <ClientModal
        setIsLoading={setIsLoading}
        setShowCart={setShowCart}
        showCart={showCart}
        isLoading={isLoading}
        deleteHandler={deleteHandler}
        setCartCount={setCartCount}
        currency={currency}
        couponCodeAppliedSuccessfully={couponCodeAppliedSuccessfully}
        setCouponCodeAppliedSuccessfully={setCouponCodeAppliedSuccessfully}
      />
    </div>
  );
};
export default TableShop;
