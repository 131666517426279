import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import PageLoader from "../../../components/pageLoader";
// import DummyAvatar from "../../../assets/img/dummy-avatar.jpeg";
import DummyAvatar from "../../../assets/img/avatar.png";
import noData from "../../../assets/img/nodata-img.svg";
import openIcon from "../../../assets/img/icons/openSide.svg";
import { PermissionDenied } from "../../PermissionDenied";
import { AllContext } from "../../../context";
import SettingTabs from "../tabs";
import DeleteConfirmModal from "../../DeleteConfirmModal";

const Team = () => {
  const dispatch = useDispatch();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [allRoles, setAllRoles] = useState([]);
  const [teamAccountList, setTeamAccountList] = useState([]);
  const [modalAction, setModalAction] = useState("");
  const [teamId, setTeamId] = useState("");
  const [teamName, setTeamName] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const {
    apiGetMethod,
    allApiUrl,
    apiDeleteMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { GET_ALL_ROLE, GET_ALL_TEAM, EDIT_ROLE, DELETE_TEAM_MEMBER } =
    allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  useEffect(() => { });
  const header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  useEffect(() => {
    getAllTeam();
    getAllRoles();
  }, []);
  function getAllRoles() {
    // setIsLoading(true);
    apiGetMethod(GET_ALL_ROLE, "", header)
      .then((res) => {
        if (res.status === 200) {
          let allRoles = [];
          res.data.roles.forEach((item) => {
            if (item.type === "admin") {
              allRoles.push(item);
            }
          });
          setAllRoles(allRoles);
          setInitialLoading(false);
          // setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function getAllTeam() {
    // setIsLoading(true);
    apiGetMethod(GET_ALL_TEAM, "", header)
      .then((res) => {
        if (res.status === 200) {
          setTeamAccountList(res.data.team);
          // setIsLoading(false);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function deleteRole(deleteId) {
    setIsLoading(true);
    setDisabledBtn(true)
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(EDIT_ROLE, deleteId, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        getAllRoles();
        setIsLoading(false);
        setIsDeleteConfirmModalOpen(false)
        setDisabledBtn(false)
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
        setIsDeleteConfirmModalOpen(false)
        setDisabledBtn(false)
      });
  }
  function deleteTeam(deleteId) {
    setIsLoading(true);
    setDisabledBtn(true)
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(DELETE_TEAM_MEMBER, deleteId, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        getAllTeam();
        setIsLoading(false);
        setIsDeleteConfirmModalOpen(false)
        setDisabledBtn(false)
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
        setIsDeleteConfirmModalOpen(false)
        setDisabledBtn(false)
      });
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <>

          {/*<div className="setting-tab">
            <div className="container-fluid pl-240">
              <div className="row">
                <div className="col-lg-12">
                  <div className="tabs-content">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <div
                          className="section-back"
                          onClick={() => navigate("/settings")}
                        >
                          <a href="#" className="nav-link">
                            <i class="fa-solid fa-angle-left"></i> Settings
                          </a>
                        </div>
                      </li>
                       <li
                    className="nav-item"
                    role="presentation"
                    on
                    onClick={() => navigate("/settings/general")}
                  >
                    <button
                      className="nav-link"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Company
                    </button>
                  </li>
                      <li
                        className="nav-item"
                        role="presentation"
                        onClick={() => navigate("/settings/payment")}
                      >
                        <button
                          className="nav-link "
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Payments
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          Team
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-back pl-240 mt-3">
            <a  onClick={() => {navigate('/settings')}} href="#" className="horizontalPadding">
              <i class="fa-solid fa-angle-left"></i> Settings
            </a>
          </div>*/}

          <div className="commonSlidePl">
            <div className="orders-section commonPadding dashboardTop">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-5 col-12">
                    <div className="heading position-relative">
                      <h2 className="mb-0">
                        Team Settings
                      </h2>
                      <span className="openArrowIcon" onClick={() => { setToggleSidebar(!toggleSideBar) }}>
                        <img src={openIcon} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SettingTabs currentTab="team" />
          <div className="commonSlidePl">
            <div className="setting-form-section mt-5 mb-4">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xxl-8 col-lg-8">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabIndex="0"
                      >
                        {/* Teams */}
                        <div className="setting-form">
                          <form className="rolesListSection">
                            <h3 className="mt-0 mb-4 pb-3 d-flex justify-content-between">Roles
                              <div
                                className="add-role"
                                onClick={() => {
                                  userInfo.roles.name === "admin"
                                    ? navigate("/role/role")
                                    : navigate("/permissionDenied", {
                                      state: {
                                        noPermission: false,
                                      },
                                    });
                                }}
                              >
                                <a>+ Add Role</a>
                              </div>
                            </h3>
                            <div className="table-resposnive">
                              {
                                allRoles.map((role, index) => {
                                  return (
                                    <div key={index} className="RolesData mb-3">
                                      <span>{capitalizeFirstLetter(role.name)}</span>
                                      <div className="dropdown">
                                        <button
                                          className="btn btn-secondary dropdown-toggle"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                          <li
                                            onClick={() => {
                                              userInfo.roles.name ===
                                                "admin"
                                                ? navigate(`/role/role/${role._id}`)
                                                :
                                                navigate("/permissionDenied", {
                                                  state: {
                                                    noPermission: false,
                                                  },
                                                })
                                            }
                                            }
                                          >
                                            <a
                                              className="dropdown-item"
                                            // href="#"
                                            >
                                              Edit
                                            </a>
                                          </li>
                                          {role.name !== "admin" && (
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                                onClick={() => {
                                                  // setOpen(true);
                                                  if (
                                                    userInfo.roles.name ===
                                                    "admin"
                                                  ) {
                                                    setRoleId(role._id);
                                                    setRoleName(role.name);
                                                    setModalAction("role");
                                                    setIsDeleteConfirmModalOpen(true)
                                                  } else {
                                                    toastMessage(
                                                      "error",
                                                      "You dont have permission to access this resource"
                                                    );
                                                  }
                                                }}
                                              // onClick={()=>}
                                              >
                                                Delete
                                              </a>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  )
                                })
                              }

                            </div>


                            <h3 className="mt-5 mb-4 pb-3 d-flex justify-content-between">Team accounts  <div
                              className="add-role"
                              onClick={() => navigate("/accounts/add/2")}
                            >
                              <a href="#">+ Add User</a>
                            </div></h3>
                            <div className="team-table">
                              <table className="table ">
                                <tbody>
                                  {/* no record found */}
                                  {/* <tr>
                                <td colSpan={3}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr> */}
                                  {/* no record found */}
                                  {teamAccountList.map((teamAccount, idx2) => {
                                    return (
                                      <tr key={idx2}>
                                        <td>
                                          <div className="team-detail">
                                            <div className="img">
                                              <img
                                                src={
                                                  teamAccount.userId.image &&
                                                    teamAccount.userId.image != ""
                                                    ? teamAccount.userId.image
                                                    : DummyAvatar
                                                }
                                              />
                                            </div>
                                            <div className="name">
                                              <p
                                                style={{ cursor: "pointer" }}
                                                className="userName"
                                                onClick={() => {
                                                  userInfo.roles.name === "admin"
                                                    ? navigate(
                                                      `/accounts/team/${teamAccount.userId._id}`
                                                    )
                                                    : navigate(
                                                      "/permissionDenied",
                                                      {
                                                        state: {
                                                          noPermission: false,
                                                        },
                                                      }
                                                    );
                                                }}
                                              >
                                                {teamAccount &&
                                                  teamAccount.userId &&
                                                  teamAccount.userId.firstName
                                                  ? `${capitalizeFirstLetter(
                                                    teamAccount.userId.firstName
                                                  )} ${teamAccount.userId
                                                    .lastName || ""
                                                  }`
                                                  : ""}
                                              </p>
                                              <p>
                                                {teamAccount.userId &&
                                                  teamAccount.userId.email}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="userRoles">
                                            <p
                                              onClick={() => {
                                                userInfo.roles.name === "admin"
                                                  ? navigate(
                                                    `/role/role/${teamAccount.userId?.roleId}`
                                                  )
                                                  : navigate(
                                                    "/permissionDenied",
                                                    {
                                                      state: {
                                                        noPermission: false,
                                                      },
                                                    }
                                                  );
                                              }}
                                            >
                                              <a href="#">{teamAccount.role}</a>
                                              {teamAccount.userId.accountOwner &&
                                                "Account Owner" && (
                                                  <span
                                                    style={{
                                                      pointerEvents: "none",
                                                    }}
                                                  >
                                                    Account Owner
                                                  </span>
                                                )}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="dropdown">
                                            <button
                                              className="btn btn-secondary dropdown-toggle"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </button>
                                            <ul className="dropdown-menu">
                                              <li
                                                onClick={() => {
                                                  userInfo.roles.name === "admin"
                                                    ? navigate(
                                                      `/accounts/edit/${teamAccount.userId._id}`
                                                    )
                                                    : navigate(
                                                      "/permissionDenied",
                                                      {
                                                        state: {
                                                          noPermission: false,
                                                        },
                                                      }
                                                    );
                                                }}
                                              >
                                                <a
                                                  className="dropdown-item"
                                                // href="#"
                                                >
                                                  Edit
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="#"
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    if (
                                                      userInfo.roles.name ===
                                                      "admin"
                                                    ) {
                                                      setTeamId(
                                                        teamAccount.userId._id
                                                      );
                                                      setTeamName(
                                                        teamAccount?.userId
                                                          ?.firstName
                                                          ? teamAccount?.userId
                                                            ?.firstName
                                                          : teamAccount.userId
                                                            .email
                                                      );
                                                      setModalAction("team");
                                                      setIsDeleteConfirmModalOpen(true)
                                                    } else {
                                                      toastMessage(
                                                        "error",
                                                        "You dont have permission to access this resource"
                                                      );
                                                    }
                                                  }}
                                                >
                                                  Delete
                                                </a>
                                              </li>
                                              {/* <li>
                                            <a className="dropdown-item" href="#">
                                              Sign in as user
                                            </a>
                                          </li> */}
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  {teamAccountList &&
                                    teamAccountList.length <= 0 && (
                                      <tr>
                                        <td colSpan={7}>
                                          <div className="noData-section">
                                            <img src={noData} />
                                            <p>No Data Found</p>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                </tbody>
                              </table>
                            </div>

                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Delete Modal */}

      {isDeleteConfirmModalOpen ? (
        <DeleteConfirmModal
          isDeleteConfirmModalOpen={isDeleteConfirmModalOpen}
          handleDelete={() => {
            modalAction === "role"
              ? deleteRole(roleId)
              : deleteTeam(teamId)
          }
          }
          handleCancelModal={() => { setIsDeleteConfirmModalOpen(false) }}
          modalContent={`Are you sure you want to delete this ${modalAction === "role" ? "role" : "team member"}: ${modalAction === "role" ? roleName : teamName}`}
          disabledBtn={disabledBtn}
        />
      ) : (
        ""
      )}
      {/* <div
        className="modal fade history-modal"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  {`Are you sure you want to delete this ${modalAction === "role" ? "role" : "team member"
                    }: `}
                  <span style={{ fontWeight: "bold" }}>
                    {modalAction === "role" ? roleName : teamName}
                  </span>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
                onClick={() =>
                  modalAction === "role"
                    ? deleteRole(roleId)
                    : deleteTeam(teamId)
                }
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default Team;