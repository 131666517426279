import Header from "../../widgets/header";
import Sidebar from "../../widgets/sidebar";
import { useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import { AllContext } from "../../context";
import PageLoader from "../../components/pageLoader";
import ReactDatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
const InTakeForm = () => {
  const { state } = useLocation();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [customFormData, setCustomFormData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    if (state && state.fieldInfo.length >0 && state.mode == "Add") {
      setFields(state.fieldInfo);
      setCustomFormData(state.fieldInfo);
    } else {
      getExistingProjectData();
    }
  }, []);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const { toastMessage } = context;
  const navigate = useNavigate();
  function getselectedImages(images) {
    let selectedImages = "";
    if (images && images.length)
      for (let i = 0; i < images.length; i++) {
        selectedImages += images[i].name
          ? images[i].name
          : images[i].split("/uploads/")[1];}
    return selectedImages;
  }
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const getExistingProjectData = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(allApiUrl.ORDER + "/" + state.orderId, "", header).then(
      (res) => {
        if (res.status === 200) {
          setStartDate(res.data.order.startedDate);
          setCustomFormData(res.data.order.projectData);
          setFields(res.data.order.projectData);
        }
      }
    );
  };

  function onSubmit(e) {
    setIsLoading(true);

    e.preventDefault();
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    const formData = new FormData();
    formData.append("orderId", state.orderId);
    formData.append(
      "startedDate",
      state.mode == "Edit" ? startDate : Date.now()
    );
    if (customFormData) {
      customFormData.forEach((item, index) => {
        if (
          item.value == undefined &&
          item.type !== "date" &&
          item.type !== "paragraph" &&
          item.type !== "header"
        ) {
          toastMessage("error", "Please fill all fields");
        } else {
          if (item.type == "file") {
            for (const key of Object.keys(item.value)) {
              formData.append("files", item.value[key]);
            }
          }
        }
      });
    }
    let data = [];
    customFormData.map((row) => {
      let copyData = { ...row };
      if (copyData.type == "file") {
        if (copyData.value.length > 0) {
          copyData.value = copyData.value.length;
        }
      }

      if (copyData.type == "date") {
        if (copyData.value == null) {
          copyData.value = Date.now();
        }
      }

      data.push({ ...copyData });
    });
    formData.append("formData", JSON.stringify(data));
    apiPostMethod(allApiUrl.ADD_PROJECT_DATA, formData, header)
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          navigate(-1);
          toastMessage("success", res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  return (
    <div>
      <Sidebar
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={isLoading} />
      <div className="commonSlidePl">
      <div className="setting-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-12">
              <div className="setting-form-section pt-3">
                <div className="container-fluid pl-240">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-back mb-4">
                        <a href={void(0)} onClick={() => navigate(-1)}>
                          <i className="fa-solid fa-angle-left"></i>
                          {state.mode == "Edit"
                            ? "view-order"
                            : state.mode == "Add" && state.page == "view-orders"
                            ? " View Orders"
                            : "Purchases"}
                        </a>
                      </div>
                    </div>

                    <div className="col-xxl-6 col-xl-8 mx-auto">
                      <div className="setting-form">
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabIndex="0"
                          >
                            <div className="tab-content" id="pills-tabContent">
                              <div className="intakeForm">
                                <h3>Intake form for writing service</h3>
                                <p>
                                  Clients get access to this form after buying
                                  your service. Their order will remain{" "}
                                  <span>Pending</span> until the form is filled
                                  out.
                                </p>

                                <div className="commonForm">
                                  <form onSubmit={(e) => onSubmit(e)}>
                                    {fields &&
                                      fields.map((element, index) => {
                                        const CustomTag = `${element.subtype}`;
                                        if (element.type == "header") {
                                          return (
                                            <div
                                              className="col-lg-4"
                                              key={index}
                                            >
                                              <div className="form-group">
                                                <CustomTag>
                                                  {element.label}
                                                </CustomTag>
                                              </div>
                                            </div>
                                          );
                                        } else if (element.type == "number") {
                                          return (
                                            <div
                                              className="col-lg-4"
                                              key={index}
                                            >
                                              <div className="form-group">
                                                <label>{element.label}</label>
                                                <input
                                                  required={element.required}
                                                  name="stripePublicKey"
                                                  type="number"
                                                  value={
                                                    customFormData[index]?.value
                                                  }
                                                  onKeyDown={(evt) =>
                                                    evt.key === "e" &&
                                                    evt.preventDefault()
                                                  }
                                                  onChange={(e) => {
                                                    const re = /[^0-9]/g;

                                                    setCustomFormData(
                                                      (prevData) => {
                                                        prevData[index] = {
                                                          ...prevData[index],
                                                          value:
                                                            e.target.value.replace(
                                                              re,
                                                              ""
                                                            ),
                                                        };
                                                        return [...prevData];
                                                      }
                                                    );
                                                  }}
                                                  className="form-control"
                                                />
                                              </div>
                                            </div>
                                          );
                                        } else if (
                                          element.type == "paragraph"
                                        ) {
                                          return (
                                            <div
                                              className="form-group"
                                              key={index}
                                              dangerouslySetInnerHTML={{
                                                __html: element.label,
                                              }}
                                            ></div>
                                          );
                                        } else if (element.type == "text") {
                                          return (
                                            <div
                                              className="form-group"
                                              key={index}
                                            >
                                              <label>{element.label}</label>
                                              <input
                                                required={element.required}
                                                name="stripePublicKey"
                                                type="text"
                                                className="form-control"
                                                value={
                                                  customFormData[index]?.value
                                                }
                                                onChange={(e) => {
                                                  setCustomFormData(
                                                    (prevData) => {
                                                      prevData[index] = {
                                                        ...prevData[index],
                                                        value: e.target.value,
                                                      };
                                                      return [...prevData];
                                                    }
                                                  );
                                                }}
                                              />
                                            </div>
                                            // </div>
                                          );
                                        } else if (element.type == "textarea") {
                                          return (
                                            <div
                                              className="form-group"
                                              key={index}
                                            >
                                              <label>{element.label}</label>
                                              <textarea
                                                required={element.required}
                                                name="text"
                                                className="form-control"
                                                placeholder="Text Area"
                                                value={
                                                  customFormData[index]?.value
                                                }
                                                onChange={(e) => {
                                                  setCustomFormData(
                                                    (prevData) => {
                                                      prevData[index] = {
                                                        ...prevData[index],
                                                        value: e.target.value,
                                                      };
                                                      return [...prevData];
                                                    }
                                                  );
                                                }}
                                              ></textarea>
                                            </div>
                                          );
                                        } else if (element.type == "file") {
                                          return (
                                            <div
                                              className="form-group"
                                              key={index}
                                            >
                                              <label>{element.label}</label>
                                              <div className="commonForm">
                                                <div className="Upldimages">
                                                  <input
                                                    type="file"
                                                    multiple
                                                    onChange={(e) => {
                                                      if (e) {
                                                        if (
                                                          e.target.files
                                                            .length > 0
                                                        ) {
                                                          setCustomFormData(
                                                            (prevData) => {
                                                              prevData[index] =
                                                                {
                                                                  ...prevData[
                                                                    index
                                                                  ],
                                                                  value:
                                                                    e.target
                                                                      .files,
                                                                };
                                                              return [
                                                                ...prevData,
                                                              ];
                                                            }
                                                          );
                                                        }
                                                      }
                                                    }}
                                                    accept="image/*"
                                                  />
                                                  {getselectedImages(
                                                    customFormData[index]?.value
                                                  )}
                                                  <i className="fa-solid fa-file-arrow-up"></i>
                                                  <p>
                                                    <span>Browse or</span> drag
                                                    and drop
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        } else if (element.type == "date") {
                                          return (
                                            <div
                                              className="col-lg-4"
                                              key={index}
                                            >
                                              <div className="form-group">
                                                <label>{element.label}</label>

                                                <ReactDatePicker
                                                  id="myDate"
                                                  className="form-control"
                                                  onChange={(event) => {
                                                    setCustomFormData(
                                                      (prevData) => {
                                                        prevData[index] = {
                                                          ...prevData[index],
                                                          value:
                                                            event.getTime(),
                                                        };
                                                        return [...prevData];
                                                      }
                                                    );
                                                  }}
                                                  value={moment(
                                                    customFormData[index]?.value
                                                      ? customFormData[index]
                                                          ?.value
                                                      : new Date()
                                                  ).format("YYYY-MM-DD")}
                                                  selected={
                                                    customFormData[index]?.value
                                                      ? new Date(
                                                          customFormData[
                                                            index
                                                          ]?.value
                                                        )
                                                      : new Date()
                                                  }
                                                  minDate={new Date()}
                                                />
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                    <div className="form-group mb-0">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input checkbox-label"
                                          type="checkbox"
                                          checked={isApproved}
                                          onChange={() => {
                                            setIsApproved(!isApproved);
                                          }}
                                          id="flexCheckDefault"
                                        />
                                        <label
                                          className="form-check-label mb-0"
                                          htmlFor="flexCheckDefault"
                                        >
                                          Approval and Confirmation <br />{" "}
                                          <small>
                                            I approve this content to get
                                            publish, so long as it passes the
                                            publication's guidelines
                                          </small>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="btn-section text-end">
                                      <button
                                        disabled={!isApproved}
                                        className="btn btn-save"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};
export default InTakeForm;