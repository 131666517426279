import React from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import { AllContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { useState } from "react";
import openIcon from "../../assets/img/icons/openSide.svg";
import sortIcon from "../../assets/img/icons/sortIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  formatMonthNameAndDate,
  redirectToLogin,
} from "../../shared/functions";
import PageLoader from "../../components/pageLoader";
import LoadingOverlay from "react-loading-overlay";
import * as _ from "lodash";
import PaginationNewUI from "../../components/PaginationNewUI";
LoadingOverlay.propTypes = undefined;

const Subscription = () => {
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const { allApiUrl, apiPostMethod, toastMessage } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  const [countTotalSubscription, setCountTotalSubscription] = useState(0);
  const [countActiveSubscription, setCountActiveSubscription] = useState(0);
  const [countCancelSubscription, setCountCancelSubscription] = useState(0);
  const [countPaidDueSubscription, setCountPaidDueSubscription] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [initialLoading, setInitialLoading] = useState(true);

  const [multiDeleteId, setMultiDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords

  const [isReset, setIsReset] = useState(false);

  let tabs = [
    { title: "Active", value: "Active" },
    { title: "Cancelled", value: "Cancelled" },
    { title: "Past Due", value: "pastDue" },
    { title: "All", value: "All" },
  ];
  useEffect(() => {
    getSubscriptionListing();
  }, [activeTab, itemOffset, itemsPerPage]);

  useEffect(() => {
    setItemOffset(1);
  }, [activeTab]);
  const businessId = useSelector(
    (state) => state.loginReducer.loginUserInfo.businessId
  );
  const getSubscriptionListing = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    let data = {};

    data.status = activeTab;
    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.businessId = businessId;
    apiPostMethod(allApiUrl.FILTER_SUBSCRIPTIONS, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setSubscriptions([...res.data.subscriptions]);

          setCountTotalSubscription(res.data.countAll);
          setCountActiveSubscription(res.data.countActiveSubscription);
          setCountCancelSubscription(res.data.countCancelSubscription);
          setCountPaidDueSubscription(res.data.countPaidDueSubscription);
          setInitialLoading(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setInitialLoading(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setMultiDeleteId([...[]]);
  }, [activeTab]);
  const checkedAll = () => {
    if (subscriptions.length === multiDeleteId.length) {
      setMultiDeleteId([...[]]);
    } else {
      let ids = subscriptions.map((e) => {
        return e._id;
      });
      setMultiDeleteId([...ids]);
    }
  };

  const onChangeCheckbox = (id, val) => {
    if (val) {
      multiDeleteId.push(id);
      setMultiDeleteId([..._.uniq(multiDeleteId)]);
    } else {
      _.remove(multiDeleteId, (o) => o === id);
      setMultiDeleteId([...multiDeleteId]);
    }
  };
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  function deleteMultiple() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(
      allApiUrl.DELETE_SUBSCRIPTION_BULK,
      { ids: multiDeleteId },
      header
    )
      .then((res) => {
        toastMessage("success", res.data.message);
        getSubscriptionListing();
        setMultiDeleteId([...[]]);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  useEffect(() => {
    setMultiDeleteId([...[]]);
  }, [isReset]);

  const getTabCount = (val) => {
    return val === "Active"
      ? countActiveSubscription
      : val === "Cancelled"
        ? countCancelSubscription
        : val === "pastDue"
          ? countPaidDueSubscription
          : countTotalSubscription;
  };

  const TabsList = () => {
    const tempArray = [];
    tabs.map((item, idx) => {
      tempArray.push(
        <li key={idx} className="nav-item" role="presentation">
          <button
            className={
              activeTab === item.value ? "nav-link active" : "nav-link"
            }
            id="pills-overdue-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-overdue"
            type="button"
            role="tab"
            aria-controls="pills-overdue"
            aria-selected="true"
            onClick={() => {
              setActiveTab(item.value);
            }}
          >
            {item.title}
            <span>({getTabCount(item.value)})</span>
          </button>
        </li>
      );
    });
    return tempArray;
  };

  return (
    <div>
      <Sidebar
        activePage="subscription"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0 subscriptionheader">
                    Subscriptions <span className="userCounter">&nbsp;</span>
                    <a
                      onClick={() =>
                        navigate("/add-invoice", {
                          state: {
                            page: "subscription",
                          },
                        })
                      }
                      className="btn btn-addOrder"
                    >
                      Add Subscription
                    </a>
                  </h2>
                  <span
                    className="openArrowIcon"
                    onClick={() => {
                      setToggleSidebar(!toggleSideBar);
                    }}
                  >
                    <img src={openIcon} />
                  </span>
                </div>
              </div>

              <PaginationNewUI
                itemsPerPage={itemsPerPage}
                itemOffset={itemOffset}
                setItemOffset={setItemOffset}
                pageCount={pageCount}
                totalRecordsCount={totalRecordsCount}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </div>
        {!initialLoading && (
          <div className="orders-section commonPadding horizontalPadding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 col-12"></div>
              </div>

              <LoadingOverlay
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                      stroke: "#007D87",
                    },
                  }),
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.5)",
                  }),
                }}
                active={isLoading}
                spinner
                text="Loading content..."
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-tabs">
                      <ul
                        className="nav nav-pills"
                        id="pills-tab"
                        role="tablist"
                      >
                        <TabsList />
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div className="common-table clients-table rounded-1">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="ps-4 w65-px">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="item1"
                                      checked={
                                        subscriptions.length &&
                                        subscriptions.length ===
                                        multiDeleteId.length
                                      }
                                      onChange={checkedAll}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="item1"
                                    ></label>
                                  </div>
                                </th>
                                <th>
                                  Subscription Id{" "}
                                  <span>
                                    {" "}
                                    <img src={sortIcon} />
                                  </span>
                                </th>
                                <th>
                                  Client{" "}
                                  <span>
                                    {" "}
                                    <img src={sortIcon} />
                                  </span>
                                </th>
                                <th>
                                  Service{" "}
                                  <span>
                                    {" "}
                                    <img src={sortIcon} />
                                  </span>
                                </th>
                                <th>
                                  No of Payments{" "}
                                  <span>
                                    {" "}
                                    <img src={sortIcon} />
                                  </span>
                                </th>
                                <th>
                                  Last Payment Date{" "}
                                  <span>
                                    {" "}
                                    <img src={sortIcon} />
                                  </span>
                                </th>
                                <th>
                                  Next Payment Date{" "}
                                  <span>
                                    {" "}
                                    <img src={sortIcon} />
                                  </span>
                                </th>
                                <th>
                                  Status{" "}
                                  <span>
                                    {" "}
                                    <img src={sortIcon} />
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {subscriptions.map((item, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td className="ps-4">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={"active" + item._id}
                                          checked={
                                            !!_.includes(
                                              multiDeleteId,
                                              item._id
                                            )
                                          }
                                          onChange={(e) => {
                                            onChangeCheckbox(
                                              item._id,
                                              e.target.checked
                                            );
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"active" + item._id}
                                        ></label>
                                      </div>
                                    </td>

                                    <td>
                                      <a
                                        onClick={() => {
                                          navigate(
                                            `/view-subscription/${item.orderId}`,
                                            {
                                              state: item._id,
                                            }
                                          );
                                        }}
                                      >
                                        {item.subscriptionId
                                          ? item.subscriptionId
                                          : item.orderId}
                                      </a>
                                    </td>
                                    <td>
                                      <div className="clientSection">
                                        <div className="clientName">
                                          <p>
                                            <a
                                              onClick={() => {
                                                navigate(
                                                  `/view-client/${item.userDetails._id}`
                                                );
                                              }}
                                            >
                                              {item.userDetails &&
                                                item.userDetails &&
                                                item.userDetails.firstName &&
                                                `${item.userDetails.firstName
                                                } ${item.userDetails.lastName ||
                                                " "
                                                }`}
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {item?.product && item?.product?.length
                                        ? item?.product
                                          .map((x) => x.name)
                                          .join(", ")
                                        : "-"}
                                    </td>
                                    <td>{item.paymentCount}</td>
                                    <td>
                                      {formatMonthNameAndDate(
                                        item.paidDate,
                                        companyData.timezone,
                                        true
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      {formatMonthNameAndDate(
                                        item.nextDueDate,
                                        companyData.timezone,
                                        true
                                      )}
                                    </td>
                                    <td>
                                      <span className={`pill ${activeTab === 'Cancelled' ? 'pill-red' : item.paymentStatus === 'paid' && item.serviceStatus == true ? 'pill-green' : item.paymentStatus === 'paid' && item.serviceStatus == false ? 'pill-red' : item.paymentStatus === 'past due' ? 'pill-blue' : ''} fs-12 d-block text-capitalize`}>
                                        {activeTab === 'Cancelled' ? 'Cancelled' : item.paymentStatus === 'paid' && item.serviceStatus == true ? 'Active' : item.paymentStatus === 'paid' && item.serviceStatus == false ? 'Cancelled' : item.paymentStatus === 'past due' ? 'Past Due' : ''}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                              {subscriptions.length <= 0 && (
                                <tr>
                                  <td colSpan={7}>
                                    <div className="noData-section">
                                      <img src={noData} />
                                      <p>No Data Found</p>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {multiDeleteId.length > 0 && (
                          <div className="table-bottom-option">
                            <div className="row">
                              <div className="col-lg-3 col-md-4">
                                <div className="input-group">
                                  <select
                                    id="selectOption"
                                    value={selectOption.target}
                                    onChange={(e) => selectOption(e)}
                                    className="form-control"
                                  >
                                    <option value="">With Selected...</option>
                                    <option value="Delete">Delete</option>
                                  </select>
                                  <button
                                    className="btn btn-apply"
                                    onClick={() => {
                                      if (selectedOption === "Delete") {
                                        deleteMultiple();
                                      }
                                    }}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Subscription;
