import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/avatar.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AllContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import { apiPutMethod } from "../../api/rest";
import noData from "../../assets/img/nodata-img.svg";
import PageLoader from "../../components/pageLoader";
import Good from "../../assets/img/good.png";
import Great from "../../assets/img/great.png";
import Okay from "../../assets/img/okay.png";
import Sad from "../../assets/img/sad.png";
import Terrible from "../../assets/img/terrible.png";
import { capitalizeFirstLetter, completeStatusTypeCheck, handleOffSetDateTime, pendingStatusTypeCheck, submittedStatusTypeCheck } from "../../shared/functions";
import { editorApiKey } from "../../shared/constants";
const ViewPurchase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const context = useContext(AllContext);
  const { allApiUrl, redirectToLogin, toastMessage, apiGetMethod } = context;
  const { id } = useParams();

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);

  const editorRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState({});
  const [messageCotent, setMessageCotent] = useState("");
  const [checked, setChecked] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [orderLocked, setOrderLocked] = useState(false);
  const [ratingResponse, setRatingResponse] = useState(false);
  const [ratingNumber, setRatingNumber] = useState({
    img: "",
    name: "",
  });

  const [orderDetail, setOrderDetail] = useState({
    assignedUsers: [],
    cc: [],
    date: "",
    message: [],
    note: "",
    relatedOrder: [],
    status: "open",
    subject: "",
    tags: [],
    to: "",
    title: "",
  });
  const { state } = useLocation();
  useEffect(() => {
    getAllTicketStatus();
  }, []);
  let orderId = window.location.pathname.split("/")[2];
  function getAllTicketStatus() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.GET_ALL_ACCOUNT_STATUS, "", header)
      .then((res) => {
        if (res.status === 200) {
          setChecked(res.data[0].rating);
          setOrderLocked(res.data[0].lockOrder);

          getAllOrders();
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  useEffect(() => {
    if (!orderDetail.rating) {
      if (completeStatusTypeCheck(orderDetail) && checked) {
        setShowRating(true);
      } else {
        setShowRating(false);
      }
    }
  }, [orderDetail]);
  async function getAllOrders() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let id = state && state._id;
    try {
      const response = await apiGetMethod(
        allApiUrl.GET_SINGLE_ORDER + "/" + orderId,
        "",
        header
      );
      if (response.status === 200) {
        setRatingResponse(response.data.order.rating);
        if (response.data.order.rating) {
          if (response.data.order.rating == 1) {
            setRatingNumber({ img: Terrible, name: "Terrible" });
            //   Terrible
          } else if (response.data.order.rating == 2) {
            setRatingNumber({ img: Sad, name: "Sad" });
          } else if (response.data.order.rating == 3) {
            setRatingNumber({ img: Okay, name: "Okay" });
          } else if (response.data.order.rating == 4) {
            setRatingNumber({ img: Good, name: "Good" });
          } else {
            setRatingNumber({ img: Great, name: "Great" });
          }
        }
        setOrderDetail(response.data.order);
        setInitialLoading(false);
      }
    } catch (err) {
      if (err.data.message == "Order belongs to other customer") {
        navigate("/IncorrectOrder");
      } else if (err.data.message == "No such order found") {
        navigate("/purchases");
      }
      setInitialLoading(false);
      toastMessage("error", err.data.message);
    }
  }
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  function formatAMPMWithoutTime(date) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dateString = monthNames[date.getMonth()] + " " + date.getDate() + "th";

    return dateString;
  }
  function handleMessageEditorChange(e) {
    setMessageCotent(e);
  }
  function updateTicket(data) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(allApiUrl.EDIT_ORDER + "/" + state._id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        setMessageCotent("");
        setShowRating(false);
        getAllOrders();
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function AttachmentFiles() {
    if (selectedFile && selectedFile.length > 0) {
      let files = [...selectedFile];
      return files.map((selected, index) => {
        return (
          <span key={index}>
            <a>
              {" "}
              <span className="innerIcon attachIcon me-1">
                <i className="fa-solid fa-link"></i>{" "}
              </span>
              {selected.name}{" "}
              <span className="innerIcon crossIcon ms-2">
                <i
                  className="fa-solid fa-xmark"
                  onClick={() => {
                    var dt = new DataTransfer();
                    let input = document.getElementById("myFileInput");
                    var { files } = input;
                    for (var i = 0; i < files.length; i++) {
                      var file = files[i];
                      if (index !== i) dt.items.add(file);
                      input.files = dt.files;
                    }
                    setSelectedFile(input.files);
                  }}
                ></i>
              </span>
            </a>
          </span>
        );
      });
    }
    return "";
  }
  function updateTicketMessage(message) {
    setIsLoading(true);
    if (selectedFile.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append("files", selectedFile[i]);
      }
      formData.append('source', 'client')
      formData.append("from", userInfo.id);
      formData.append("message", message);
      formData.append("date", new Date().getTime());
      formData.append("to", "Client");
      formData.append("type", "Order");
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
        "Content-Type": "multipart/form-data",
      };
      apiPutMethod("/settings/sendMessage" + "/" + id, formData, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          getAllOrders();
          setSelectedFile({});
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    } else {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      let data = {
        source: 'client',
        from: userInfo.id,
        message: message,
        date: new Date().getTime(),
        to: "Client",
        type: "Order",
      };
      apiPutMethod("/settings/sendMessage" + "/" + id, data, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          setSelectedFile({});
          getAllOrders();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    }
  }
  return (
    <div>
      <Sidebar
        activePage="purchases"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header
        handleSidebar={handleSidebar}
        activePage="purchases"
        isLoading={isLoading}
      />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="commonSlidePl">
          <div className="viewOrders pt-3 pb-3 horizontalPadding">
            <div className="container-fluid">
              <div className="row mb-3">
                <div className="col-lg-6">
                  <div className="section-back">
                    <a
                      href="#"
                      onClick={() => {
                        navigate("/purchases");
                      }}
                    >
                      <i className="fa-solid fa-angle-left"></i> Purchases
                    </a>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto">
                    <div className="sectionHeading">
                      <h3>
                        {orderDetail?.title}
                        <span className="pill pill-orange ms-1" style={orderDetail?.statusData ? { backgroundColor: orderDetail?.statusData.bgColor, color: orderDetail?.statusData.textColor } : {}}>
                          {capitalizeFirstLetter(orderDetail?.statusData ? orderDetail?.statusData.name : orderDetail.status)}
                        </span>
                      </h3>
                    </div>
                    <div className="client-details rounded-1">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-5">
                          <p className="clientLabel"> Order Id</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-7">
                          <p className="clientText">{orderDetail.orderId}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-5">
                          <p className="clientLabel">Service</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-7">
                          <p className="clientText">{orderDetail.title}</p>
                        </div>
                      </div>

                      {/* <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <p className="clientLabel">Payment Method</p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <p className="clientText">Payment Method Name</p>
                    </div>
                  </div> */}

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-5">
                          <p className="clientLabel mb-0">Created Date</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-7">
                          <p className="clientText mb-0">
                            {handleOffSetDateTime(
                              orderDetail?.createdDate,
                              companyData.timezone
                            )}
                          </p>
                        </div>
                      </div>
                    </div>

                    {orderDetail && pendingStatusTypeCheck(orderDetail) && (
                      <div className="projectData mt-4">
                        <div className="sectionHeading mb-0">
                          <h3>Project Data </h3>
                        </div>
                        <div className="projectdataContent rounded-1">
                          <div>
                            <span
                              className="pill pill-grey"
                              style={{
                                cursor: "pointer",
                                background: "#5a4cdc",
                                color: "white",
                              }}
                              onClick={() => {
                                navigate("/portal/customIntakeForm", {
                                  state: {
                                    fieldInfo: orderDetail.customForm,
                                    mode: "Add",
                                    orderId: orderDetail._id,
                                  },
                                });
                              }}
                            >
                              Start Order
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {orderDetail &&
                      (submittedStatusTypeCheck(orderDetail) ||
                        completeStatusTypeCheck(orderDetail)) &&
                      orderDetail &&
                      orderDetail.projectData &&
                      orderDetail.projectData.length > 0 && (
                        <div className="projectData mt-4">
                          <div className="sectionHeading mb-0">
                            <h3>Project Data </h3>
                          </div>
                          <div className="projectdataContent rounded-1">
                            {orderDetail.projectData.map((item, idx) => {
                              return (
                                <div className="row" key={idx}>
                                  <div className="questionSection">
                                    <div className="">
                                      {((item.type !== "file" &&
                                        item.type == "header") ||
                                        item.type == "paragraph") && (
                                          <>
                                            {item.type == "header" ? (
                                              <h1>{item.label}</h1>
                                            ) : (
                                              <h3>{item.label}</h3>
                                            )}
                                          </>
                                        )}
                                    </div>
                                  </div>
                                  <div className="">
                                    <div className="d-flex">
                                      {item.type === "file" ? (
                                        item &&
                                        item.value &&
                                        item.value.map((images, idx) => {
                                          return (
                                            <img
                                              key={idx}
                                              className="mx-1"
                                              src={images}
                                              style={{
                                                width: "70px",
                                                height: "70px",
                                                objectFit: "contain",
                                              }}
                                            />
                                          );
                                        })
                                      ) : item.type == "date" ? (
                                        <small>
                                          {formatAMPMWithoutTime(
                                            new Date(item.value)
                                          )}
                                        </small>
                                      ) : (
                                        <p>
                                          <small>{item.value}</small>
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                    <div className="sectionMessage mt-5">
                      <h3>Messages</h3>

                      {orderDetail.message.length > 0 &&
                        orderDetail.message.map((value, idx) => {
                          return (
                            value.to === "Client" && (
                              <div key={idx} className="clientMessage">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="messageUser">
                                      <div className="userImg">
                                        <img
                                          src={
                                            value.from && value.from.image
                                              ? value.from.image
                                              : Avatar
                                          }
                                        />
                                      </div>
                                      <div className="userName">
                                        <h4>
                                          {value.from ? value.from.firstName : ""}{" "}
                                          {value.from ? value.from.lastName : ""}
                                        </h4>
                                        {/* <p>{value.date}</p> */}
                                        <p>
                                          {handleOffSetDateTime(
                                            value.date,
                                            companyData.timezone
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="message-content">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: value.message,
                                    }}
                                  ></div>
                                </div>
                                <div
                                  id="attachment"
                                  className="messageAttachment"
                                >
                                  {value.attachments.map((attachment, idx) => {
                                    return (
                                      <span key={idx} id="addedfiles">
                                        <a>
                                          {" "}
                                          <span className="innerIcon attachIcon">
                                            <i className="fa-solid fa-link"></i>{" "}
                                          </span>
                                          {attachment.split("/uploads/")[1]}
                                        </a>
                                        {/* <span className="innerIcon downloadIcon mx-2 ms-3" onClick={() => { onButtonClick(attachment) }}><i class="fa-solid fa-download"></i></span> */}
                                        <a href={attachment} target="_blank">
                                          <span className="innerIcon mx-2 ms-3">
                                            <i className="fa-solid fa-eye"></i>
                                          </span>
                                        </a>
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                            )
                          );
                        })}
                      {orderDetail.message && orderDetail.message.length <= 0 && (
                        <div className="noData-section text-center mb-4">
                          <img src={noData} />
                          <p>No Messages Found</p>
                        </div>
                      )}

                      {orderLocked && completeStatusTypeCheck(orderDetail) ? (
                        ""
                      ) : (
                        <div className="reply-section mb-4">
                          <form className="mt-4 rounded-1">
                            <div className="form-group">
                              <Editor
                                className="form-control"
                                placeholder="Add a note for your team..."
                                apiKey={editorApiKey}
                                onInit={(evt, editor) =>
                                  (editorRef.current = editor)
                                }
                                value={messageCotent}
                                init={{
                                  selector: "textarea",
                                  height: 200,
                                  menu: {
                                    custom: {
                                      title: "custom",
                                      items: "basicitem nesteditem toggleitem",
                                    },
                                  },
                                  toolbar:
                                    "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                    "fullpage" +
                                    "indent | blockquote | codesample | fontselect ",
                                  font_formats:
                                    "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                  block_formats:
                                    "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                  plugins: [
                                    "image",
                                    "help",
                                    "link",
                                    "codesample",
                                    "lists",
                                  ],
                                  branding: false,
                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                                  menubar: false,
                                }}
                                // onChange={(e) => handleEditorChange(e)}
                                onEditorChange={(e) =>
                                  handleMessageEditorChange(e)
                                }
                              />
                              <div className="row"></div>
                              <div className="row mt-3">
                                <div className="col-lg-6">
                                  <div className="text-start">
                                    <div
                                      id="attachment"
                                      className="messageAttachment"
                                    >
                                      <AttachmentFiles />
                                    </div>
                                    <input
                                      type="file"
                                      className="btn btn-secondary"
                                      hidden
                                      multiple
                                      id="myFileInput"
                                      onChange={(e) => {
                                        setSelectedFile(e.target.files);
                                      }}
                                    />
                                    <span
                                      className="delete"
                                      onClick={() => {
                                        document
                                          .getElementById("myFileInput")
                                          .click();
                                      }}
                                    >
                                      <i className="fa-solid fa-link"></i>
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="send-btn">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      value={orderDetail.message}
                                      onClick={(e) => {
                                        if (messageCotent.length > 0) {
                                          updateTicketMessage(messageCotent);
                                          setMessageCotent("");
                                        } else {
                                          alert("Please insert a message");
                                        }
                                      }}
                                    >
                                      Send
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>

                          {/* <p className="ccText">
                      Add:{" "}
                      <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#ccModal"
                      >
                      {orderDetail.cc.length > 0 ? orderDetail.cc : "CC"}
                      </a>
                    </p> */}
                        </div>
                      )}

                      {showRating && (
                        <div className="emojisection">
                          <h4>How did we do?</h4>
                          <div className="emojibutton">
                            <button onClick={(e) => updateTicket({ rating: 1 })}>
                              <img src={Terrible} /> <br />
                              Terrible
                            </button>

                            <button onClick={(e) => updateTicket({ rating: 2 })}>
                              <img src={Sad} /> <br />
                              Bad
                            </button>

                            <button onClick={(e) => updateTicket({ rating: 3 })}>
                              <img src={Okay} /> <br />
                              Okay
                            </button>

                            <button onClick={(e) => updateTicket({ rating: 4 })}>
                              <img src={Good} /> <br />
                              Good
                            </button>

                            <button onClick={(e) => updateTicket({ rating: 5 })}>
                              <img src={Great} /> <br />
                              Great
                            </button>
                          </div>
                        </div>
                      )}

                      {ratingResponse &&
                        ratingResponse &&
                        completeStatusTypeCheck(orderDetail) && (
                          <div className="emojisection">
                            <h4>How did we do?</h4>
                            <div className="emojibutton">
                              <img src={ratingNumber.img} /> <br />
                              {ratingNumber.name}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* cc modal */}
      <div
        className="modal fade history-modal"
        id="ccModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <label>CC</label>
                    <input
                      type="text"
                      className="form-control"
                      value={orderDetail.cc}
                      onChange={(e) => {
                        setOrderDetail((prevData) => {
                          return { ...prevData, cc: [e.target.value] };
                        });
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => getAllOrders()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  if (orderDetail.cc.length && orderDetail.cc.length > 0) {
                    orderDetail.cc.push(e.target.value);
                    updateTicket({ cc: orderDetail.cc });
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* cc modal */}
    </div>
  );
};
export default ViewPurchase;