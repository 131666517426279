//auth
const ADMIN_LOGIN_IN = "/login";
const REQUEST_RESET_EMAIL = "/forgot-password";
const PASSWORD_RESET = "/reset-password";
const SIGNUP_USER = "/create-user/2";
const SIDENAVBARS = "/settings/get-SideBar-menu";
const SIGN_AS_CLIENT = "/sign-as-client";
// ADMIN ROUTEs

//settings
const TAGS = "/settings/tags";
const SEARCH_LIST_TAGS = "/settings/searchListTags";
const FILES = "/settings/files";
const FILTER_FILES = "/settings/filterFiles";
const PAYMENT_METHOD = "/settings/payments";
const PAYMENT_METHOD_PUBLIC = "/settings/payments-public";
const COUPON = "/settings/coupon";
const DELETE_COUPON_BULK = "/settings/deleteCouponBulk";
const COUPON_LIST = "/settings/couponList";
const FILTER_COUPONS = "/settings/filterCoupons";
const MESSAGE = "/settings/message";
const SERVICES = "/settings/services";
const SEARCH_LIST_SERVICES = "/settings/searchListServices";
const DELETE_SERVICES_BULK = "/settings/deleteServicesBulk";
const LIST_SERVICES = "/settings/listServices";
const FILTER_SERVICES = "/settings/filterServices";
const ADD_SERVICE = "/settings/service";
const SUBSCRIPTIONS = "/admin/subscriptions";
const FILTER_SUBSCRIPTIONS = "/admin/filterSubscriptions";
const DELETE_SUBSCRIPTION = "/admin/subscription";
const DELETE_SUBSCRIPTION_BULK = "/admin/deleteSubscriptionBulk";
const EDITSIDENAVBARS = "/settings/edit-sidebar-menu";
const REORDER_SIDEBAR_MENU = "/settings/reorderSideBarMenu";
const PAYMENT_TAXES = "/settings/payments/taxes";
const MESSAGE_SETTINGS = "/settings/messageSettings";
const ADD_SUBMENU = "/settings/add-sidebar-menu";
 

//roles
const GET_ALL_ROLE = "/settings/roles";
const ADD_ROLE = "/settings/roles ";
const EDIT_ROLE = "/settings/roles";
const GET_SPECIFIC_ROLE = "/settings/specific-role";
//teams
const ADD_TEAM_MEMBER = "/signup/1";
const GET_ALL_TEAM = "/settings/team";
const SEARCH_LIST_TEAM = "/settings/searchListTeam";
const GET_SPECIFIC_TEAM = "/accounts/edit";
const EDIT_TEAM_MEMBER = "/accounts/edit-admin-team";
const DELETE_TEAM_MEMBER = "/settings/team";
//email-tempate
const EMAIL_TEMPLATE = "/settings/email-template";
// logs
const LOGS = "/settings/logs";
const FILTER_LOGS = "/settings/filterLogs";
const FILTER_LOGS_V2 = "/settings/filterLogsV2";
// profile
const EMAIL_PROFILE = "/change-email";
const CHANGE_PASSWORD = "/change-password";
const UPDATE_PROFILE = "/accounts/profile";
const EDIT_USER_DETAILS = "/accounts/edit/";
//Client Module
const GET_ALL_CLIENT = "/accounts";
const SEARCH_LIST_CLIENTS = "/accounts/searchListClients";
const ADD_CLIENT = "/signup/2";
const GET_SPECIFIC_CLIENT = "/accounts/edit";
const EDIT_CLIENT = "/accounts/edit-client";
const DELETE_CLIENT = "/accounts";
const DELETE_PROFILE_BULK = "/accounts/deleteProfileBulk";
const SESSION = "/session";
const SENDGRID = "/settings/sendgrid";
const CLIENT_DATA_LISTING = "/accounts/client-data-listing";
// Tickets
const ADD_TICKET = "/settings/addTicket";
const EDIT_TICKET = "/settings/updateTicket";
const UPDATE_TICKET_STATUS_BULK = "/settings/updateTicketStatusBulk";
const GET_TICKET = "/settings/tickets";
const SEARCH_LIST_TICKETS = "/settings/searchListTickets";
const DELETE_TICKET = "/settings/ticket";
const DELETE_TICKET_BULK = "/settings/deleteTicketBulk";
const FILTER_TICKET = "/settings/filterTickets";
const FILTER_TICKETS_V2 = "/settings/filterTicketsV2";
//Orders
const ORDER = "/admin/order";
const DELETE_ORDER_BULK = "/admin/deleteOrderBulk";
const ORDERS = "/admin/orders";
const GET_SINGLE_ORDER = "/admin/view-order";
const EDIT_ORDER = "/admin/order";
const UPDATE_ORDER_STATUS_BULK = "/admin/updateOrderStatusBulk";
const FILTER_ORDERS = "/admin/filterOrders";
const FILTER_ORDERS_V2 = "/admin/filterOrdersV2";
const SEARCH_LIST_ORDERS = "/admin/searchListOrders";
const ORDERBYID = "/admin/orderBy";
const ADD_TASK_ORDER = "/admin/order-add-Tasks";
const DELETE_TASK_ORDER = "/admin/delete-tasks";
const EDIT_TASK_ORDER = "/admin/edit-tasks";
//Invoice
const INVOICE = "/admin/invoice";
const UPDATE_INVOICE_STATUS_BULK = "/admin/updateInvoiceStatusBulk";
const DELETE_INVOICE_BULK = "/admin/deleteInvoiceBulk";
const EDIT_INVOICE = "/admin/invoice";
const UPDATE_INVOICE_PAID = "/admin/mark-paid";
const GET_INVOICE = "/admin/edit";
const INVOICE_BY_ORDER = "/admin/orderId";
const INVOICE_BY_ORDER_PUBLIC = "/admin/orderId-Public";
const GET_PDF = "/settings/pdf";
const DASHBOARD = "/settings/dashboard";
const SALES = "/settings/sales";
const SALES_DATE_FILTER = "/settings/salesDateFilter";
const DASHBOARD_DATE_FILTER = "/settings/dashboardFilter";
//subscription
const GET_ACTIVE_CLIENTS = "/admin/active-clients";
const GET_CLIENTS_WITH_ORDER_COUNT = "/admin/getClientsWithOrderCount";
const GET_CLIENTS_WITH_LIMIT = "/accounts/getClientsWithLimit";
const FILTER_CLIENTS_V2 = "/accounts/filterClientsV2";
const SUB = "/admin/specific-subscriptions";
//Account Status
const GET_ALL_ACCOUNT_STATUS = "/settings/account-status";
const ADD_ACCOUNT_STATUS = "/settings/add-account-status";
const DELETE_ACCOUNT_STATUS = "/settings/delete-account-status";
const EDIT_ACCOUNT_STATUS = "/settings/edit-account-status";
const RESET_ACCOUNT_STATUS = "/settings/reset-account-status";
const ADD_WORKFLOW = "/settings/add-workflow";
const UPDATE_WORKFLOW = "/settings/update-workflow";
const SET_DEFAULT_WORKFLOW = "/settings/set-default-workflow";
const ADD_WORKFLOW_STATUS = '/settings/add-workflow-order-status';
const GET_WORKFLOWS = "/settings/workflows";
const SEARCH_LIST_WORKFLOWS = "/settings/searchListWorkflows";
const SAVE_WORKFLOW_CRMFIELDS = "/settings/save-workflow-crmfields";
const DELETE_WORKFLOW = "/settings/workflows";

//Ticket Status
const GET_ALL_TICKET_STATUS = "/settings/ticket-status";
const ADD_TICKET_STATUS = "/settings/add-ticket-status";
const DELETE_TICKET_STATUS = "/settings/delete-ticket-status";
const EDIT_TICKET_STATUS = "/settings/edit-ticket-status";

//Shop Status
const EDIT_SHOP_STATUS = "/settings/edit-shop-status";
const SHOP_STATUS = "/settings/shop-status";

//company details
const COMPANY = "/settings/company";

// CLIENT ROUTE

//shop-articles
const SHOP_ARTICLE = "/portal/services";
const LIST_SERVICES_V2 = "/portal/listServicesV2";
const CART = "/portal/cart";
const CLIENT_COUPON = "/portal/coupon";
const CHECKOUT = "/portal/checkout";
const PAYMENTSUCCESS = "/portal/paymentSuccess";
const INVOICESUCCESS = "/portal/invoiceSuccess";
const PUBLICINVOICESUCCESS = "/portal/public/invoiceSuccess";
const PUBLICCHECKOUT = "/portal/public-checkout";
const CLIENT_ORDER = "/portal/order";
const DISPLAY_ORDERS_V2 = "/portal/displayOrdersV2";
const CLIENT_ORDER_ID = "/portal/client-order";
const FILTER_INVOICES = "/admin/filter-invoice";
const FILTER_INVOICE_V2 = "/admin/filterInvoiceV2";
// const CLIENT_INVOICE = "/portal/invoice";
const DISPLAY_INVOICE_V2 = "/portal/displayInvoiceV2";
const ADD_PROJECT_DATA = "/portal/add-project-data";
const GET_ALL_TEAM_MEMBERS = "/portal/teamMemberData";
const GET_CLIENT_TICKET = "/settings/client-tickets";
const GET_TICKETS_FOR_CLIENT_V2 = "/settings/getTicketsForClientV2";
const GET_ALL_CLIENT_TEAM_MEMBERS = "/portal/team";
const TEAM_DETAILS_V2 = "/portal/teamDetailsV2";
const INVOICE_EMAIL = "/admin/sendEmailInvoice";
const GET_ORDER_DETAILS = "/admin/orderId";
const CANCEL_SUBSCRIPTION = "/portal/cancel-subscription";
const LIST_CARDS = "/portal/list-cards";
const HOMEPAGE = "/portal/homePageViewList";
const HOME_PAGE_VIEW_LIST_V2 = "/portal/homePageViewListV2";
const PAYLATER_CHECKOUT = "/portal/payLaterCheckout";
const PUBLIC_SHOP_LIST_SERVICES = "/portal/public-shop-list-services";

//setting crm fields
const GET_CRM_FIELDS = "/settings/getCrmFields";
const GET_TICKETS_FIELDS = "/settings/getTicketsFields";
const SAVE_CRM_FIELDS = "/settings/saveCrmFields";
const SAVE_TICKETS_FIELDS = "/settings/saveTicketsFields";

//super admin endpoints
const SUPER_ADMIN_LOGIN = "/super-admin/login";
const GET_BUSINESSES = "/super-admin/business";
const CREATE_BUSINESS = "/super-admin/create-business";

const VERIFY_BUSINESS = "/verify-business";

export const allApiUrl = {
  SIDENAVBARS,
  EDITSIDENAVBARS,
  REORDER_SIDEBAR_MENU,
  GET_ALL_TICKET_STATUS,
  ADD_TICKET_STATUS,
  DELETE_TICKET_STATUS,
  DELETE_TASK_ORDER,
  EDIT_TICKET_STATUS,
  EDIT_TASK_ORDER,
  DELETE_ACCOUNT_STATUS,
  LIST_CARDS,
  SIGN_AS_CLIENT,
  ADD_TASK_ORDER,
  ADD_ACCOUNT_STATUS,
  CLIENT_DATA_LISTING,
  GET_ACTIVE_CLIENTS,
  GET_CLIENTS_WITH_ORDER_COUNT,
  GET_CLIENTS_WITH_LIMIT,
  FILTER_CLIENTS_V2,
  EDIT_ACCOUNT_STATUS,
  RESET_ACCOUNT_STATUS,
  HOMEPAGE,
  HOME_PAGE_VIEW_LIST_V2,
  GET_ALL_ACCOUNT_STATUS,
  ORDER,
  DELETE_ORDER_BULK,
  ORDERS,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_BULK,
  INVOICESUCCESS,
  PUBLICINVOICESUCCESS,
  PUBLICCHECKOUT,
  CANCEL_SUBSCRIPTION,
  SUB,
  ADD_PROJECT_DATA,
  SUBSCRIPTIONS,
  FILTER_SUBSCRIPTIONS,
  INVOICE,
  UPDATE_INVOICE_STATUS_BULK,
  DELETE_INVOICE_BULK,
  INVOICE_BY_ORDER,
  INVOICE_BY_ORDER_PUBLIC,
  // CLIENT_INVOICE,
  DISPLAY_INVOICE_V2,
  CLIENT_ORDER,
  DISPLAY_ORDERS_V2,
  CLIENT_ORDER_ID,
  ORDERBYID,
  EDIT_ORDER,
  UPDATE_ORDER_STATUS_BULK,
  ADMIN_LOGIN_IN,
  FILTER_ORDERS,
  FILTER_ORDERS_V2,
  SEARCH_LIST_ORDERS,
  INVOICE_EMAIL,
  COUPON_LIST,
  FILTER_COUPONS,
  GET_ORDER_DETAILS,
  LIST_SERVICES,
  FILTER_SERVICES,
  PAYMENTSUCCESS,
  REQUEST_RESET_EMAIL,
  PASSWORD_RESET,
  TAGS,
  SEARCH_LIST_TAGS,
  FILES,
  SERVICES,
  SEARCH_LIST_SERVICES,
  DELETE_SERVICES_BULK,
  PAYMENT_METHOD,
  PAYMENT_METHOD_PUBLIC,
  GET_ALL_ROLE,
  EDIT_USER_DETAILS,
  COUPON,
  DELETE_COUPON_BULK,
  MESSAGE,
  ADD_ROLE,
  EDIT_ROLE,
  GET_SPECIFIC_ROLE,
  EMAIL_TEMPLATE,
  SENDGRID,
  GET_ALL_TEAM,
  SEARCH_LIST_TEAM,
  ADD_TEAM_MEMBER,
  GET_SPECIFIC_TEAM,
  EDIT_TEAM_MEMBER,
  UPDATE_INVOICE_PAID,
  LOGS,
  FILTER_LOGS,
  GET_ALL_CLIENT,
  SEARCH_LIST_CLIENTS,
  ADD_CLIENT,
  EDIT_TICKET,
  UPDATE_TICKET_STATUS_BULK,
  EDIT_CLIENT,
  GET_SPECIFIC_CLIENT,
  DELETE_CLIENT,
  DELETE_PROFILE_BULK,
  DELETE_TEAM_MEMBER,
  EMAIL_PROFILE,
  CHANGE_PASSWORD,
  UPDATE_PROFILE,
  ADD_TICKET,
  GET_TICKET,
  SEARCH_LIST_TICKETS,
  DELETE_TICKET,
  DELETE_TICKET_BULK,
  FILTER_TICKET,
  FILTER_TICKETS_V2,
  SESSION,
  FILTER_FILES,
  FILTER_INVOICES,
  FILTER_INVOICE_V2,
  SHOP_ARTICLE,
  LIST_SERVICES_V2,
  CART,
  CLIENT_COUPON,
  CHECKOUT,
  ADD_SERVICE,
  GET_CLIENT_TICKET,
  GET_TICKETS_FOR_CLIENT_V2,
  GET_ALL_CLIENT_TEAM_MEMBERS,
  TEAM_DETAILS_V2,
  EDIT_INVOICE,
  GET_INVOICE,
  GET_SINGLE_ORDER,
  GET_ALL_TEAM_MEMBERS,
  GET_PDF,
  DASHBOARD,
  SALES,
  SALES_DATE_FILTER,
  DASHBOARD_DATE_FILTER,
  SIGNUP_USER,
  PAYLATER_CHECKOUT,
  GET_CRM_FIELDS,
  GET_TICKETS_FIELDS,
  SAVE_CRM_FIELDS,
  SAVE_TICKETS_FIELDS,
  COMPANY,
  PAYMENT_TAXES,
  MESSAGE_SETTINGS,
  ADD_SUBMENU,
  ADD_WORKFLOW,
  UPDATE_WORKFLOW,
  SET_DEFAULT_WORKFLOW,
  ADD_WORKFLOW_STATUS,
  GET_WORKFLOWS,
  SEARCH_LIST_WORKFLOWS,
  SAVE_WORKFLOW_CRMFIELDS,
  EDIT_SHOP_STATUS,
  SHOP_STATUS,
  FILTER_LOGS_V2,
  SUPER_ADMIN_LOGIN,
  GET_BUSINESSES,
  CREATE_BUSINESS,
  DELETE_WORKFLOW,
  VERIFY_BUSINESS,
  PUBLIC_SHOP_LIST_SERVICES
};
