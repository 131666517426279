import React from "react";
import * as _ from "lodash";

const FormElementView = (props) => {
  const getElementValue = (item) => {
    if (item.element === 'Checkboxes' || item.element === 'Dropdown') {
      return _.join(_.map(_.filter(item.options, o2 => {
        return _.includes(item.value, o2.value)
      }), o3 => o3.text), ', ');
    } else {
      return item.value;
    }
  }
  return (
    <>
      {!!(props.crmData && props.crmData.length && props.crmData.filter(o => o?.alternateForm).length) &&
        <div className="client-details mb-4">
          {props.crmData.map((item) => {
            if (item.alternateForm) {
              return (
                <div className="row" key={item.id}>
                  <div className="col-lg-6 col-md-6 col-4">
                    <p className="clientLabel">{item.label}</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-8">
                    <p className="clientText text-capitalize">
                      {getElementValue(item)}
                    </p>
                  </div>
                </div>
              )
            }
          })
          }
        </div>
      }
    </>
  )
}
export default FormElementView;
