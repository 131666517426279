import React, { useState, useContext, useEffect } from "react";
import noData from "../../../assets/img/nodata-img.svg";
import { AllContext } from "../../../context";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../pageLoader";
import LoadingOverlay from 'react-loading-overlay';
import {apiPostMethod} from "../../../api/rest";
import PaginationNewUI from "../../PaginationNewUI";
/*import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import * as _ from "lodash";*/
LoadingOverlay.propTypes = undefined;

const TableServices = ({searchText, isLoadingSearch, setIsLoadingSearch}) => {
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    // apiDeleteMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { currency } = useSelector((state) => state.sideMenuReducer.paymentSettingData);
  /*const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [bulkDeleteId, setBulkDeleteId] = React.useState([]);
  const [serviceId, setServiceId] = useState();
  const [serviceName, setServiceName] = useState();
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");*/
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [initialLoading, setInitialLoading] = useState(true);
  const editService = async (_id) => {
    document.getElementById('searchModalCloseId').click();
    navigate("/edit-service/" + _id);
  };

  useEffect(() => {
    if (isLoadingSearch) {
      filterServices();
    }
  }, [isLoadingSearch])
  useEffect(() => {
    filterServices();
  }, [itemOffset, itemsPerPage]);
  function filterServices() {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {};
    data.page = itemOffset;
    data.limit = itemsPerPage;
    if (searchText) { data.searchText = searchText; }

    apiPostMethod(
      allApiUrl.FILTER_SERVICES,
      data,
      header
    )
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setServices([...res.data.services]);
        }
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        // redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      });
  }

  /*const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  function deleteService(serviceId) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.SERVICES, serviceId, header)
      .then((res) => {
        handleToClose();
        filterServices();
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  const handleToClose = () => {
    setOpen(false);
  };
  function deleteMultiple() {
    bulkDeleteId.map((row) => {
      deleteService(row);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
  }

  function checkedAll() {
    if (services.length === bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = services.map((e) => {
        return e._id;
      });
      setBulkDeleteId([...ids]);
    }
  }

  const onChangeCheckbox = (id, val) => {
    if (val) {
      bulkDeleteId.push(id);
      setBulkDeleteId([..._.uniq(bulkDeleteId)]);
    } else {
      _.remove(bulkDeleteId, (o) => o === id);
      setBulkDeleteId([...bulkDeleteId]);
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }*/
  return (
    <>
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section horizontalPadding">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h2>Services</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-filter">
                <PaginationNewUI
                  itemsPerPage={itemsPerPage}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  pageCount={pageCount}
                  totalRecordsCount={totalRecordsCount}
                  setItemsPerPage={setItemsPerPage}
                  mainClassName="col-lg-12"
                />
              </div>
            </div>
          </div>
          <LoadingOverlay
            styles={{
              spinner: (base) => ({
              ...base,
              width: "50px",
              "& svg circle": {
                  stroke: "#007D87",
              },
              }),
              overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 0.5)",
              }),
          }}
          active={isLoading}
          spinner
          text="Loading content..."
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="common-table clients-table mt-3 files-table rounded-1">
                  <table className="table">
                    <thead>
                    <tr>
                      {/*<th className="ps-4 w65-px">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="item1"
                            checked={
                              services.length === bulkDeleteId.length &&
                              services.length > 0
                            }
                            onChange={() => checkedAll()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="item1"
                          ></label>
                        </div>
                      </th>*/}
                      <th>Name</th>
                      <th>Description</th>
                      <th>PRICE</th>
                      <th>Order Count</th>
                      {/*<th></th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {services.map((value, idx) => {
                      return (
                        <tr key={idx}>
                          {/*<td className="ps-4 w65-px">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={value._id}
                                checked={!!(_.includes(bulkDeleteId, value._id))}
                                onChange={(e) => {
                                  setIsSingleChecked(e.target.checked);
                                  onChangeCheckbox(
                                    value._id,
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={value._id}
                              ></label>
                            </div>
                          </td>*/}
                          <td>
                            <a onClick={() => editService(value._id)}>
                              {value.name}
                            </a>
                          </td>
                          <td>
                            {" "}
                            <div
                              className="serviceDescription"
                              dangerouslySetInnerHTML={{
                                __html: value.description,
                              }}
                            ></div>
                          </td>

                          <td>
                            <span>{currency?.symbol ? currency?.symbol : "$"}{value.price}</span>
                          </td>
                          <td>{value.orderCount}</td>
                          {/*<td className="text-end pe-4">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle btn-info"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a
                                    className="dropdown-item"
                                    // href="#"
                                    onClick={() => editService(value._id)}
                                  >
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                    onClick={() => {
                                      setOpen(true);
                                      setServiceId(value._id);
                                      setServiceName(value.name);
                                    }}
                                  >
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>*/}
                        </tr>
                      );
                    })}
                    {services && services.length <= 0 && (
                      <tr>
                        <td colSpan={7}>
                          <div className="noData-section">
                            <img src={noData} />
                            <p>No Data Found</p>
                          </div>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
                {/*{(bulkDeleteId.length > 0 || isSingleChecked) && (
                  <div className="table-bottom-option">
                    <div className="row">
                      <div className="col-lg-3 col-md-4">
                        <div className="input-group">
                          <select
                            id="selectOption"
                            value={selectOption.target}
                            onChange={(e) => selectOption(e)}
                            className="form-control"
                          >
                            <option value="">With Selected...</option>
                            <option value="Delete">Delete</option>
                          </select>
                          <button
                            className="btn btn-apply"
                            onClick={() => {
                              if (selectedOption === "Delete") {
                                deleteMultiple();
                              }
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}*/}
              </div>
            </div>
          </LoadingOverlay>
        </div>
      )}
    </>
  );
};
export default TableServices;
