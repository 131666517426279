import React, { useState, useContext, useEffect, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import noData from "../../../assets/img/nodata-img.svg";
import { AllContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import moment from "moment";
import PageLoader from "../../pageLoader";
import { editorApiKey } from "../../../shared/constants";
import PaginationNewUI from "../../PaginationNewUI";
import LoadingOverlay from "react-loading-overlay";
import {SpinnerCircularFixed} from "spinners-react";
import {convertToUpperCase} from "../../../shared/functions";
LoadingOverlay.propTypes = undefined;

const TableHelp = ({isPage, searchText, isLoadingSearch, setIsLoadingSearch}) => {
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [clientOrder, setClientOrder] = useState([]);
  const [content, setContent] = useState("");
  const [subjectTicket, setSubjectTicket] = useState("");
  const [realtedTicket, setRelatedTicket] = useState("");
  const [relatedOrder, setRelatedOrder] = useState("");
  const [clientOrderBackUp, setClientOrderBackup] = useState([]);
  const editorRef = useRef(null);

  const context = useContext(AllContext);
  const dispatch = useDispatch();
  const { allApiUrl, toastMessage, redirectToLogin, apiPostMethod, apiGetMethod } = context;
  const loginUserAccessToken = useSelector((state) => state.loginReducer.loginUserAccessToken);
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);

  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  let header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  useEffect(() => {
    if (isLoadingSearch) {
      getAllTickets();
    }
  }, [isLoadingSearch])
  useEffect(() => {
    getAllTickets();
  }, [itemOffset, itemsPerPage]);

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const getAllTickets= () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let data = {};
    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.id = userInfo.id;

    if (searchText) { data.searchText = searchText; }

    apiPostMethod(allApiUrl.GET_TICKETS_FOR_CLIENT_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setTickets([...res.data.dataList]);
        }
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err?.data?.message);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      });
  }

  function handleEditorChange(e) {
    setContent(e);
  }

  const onNewTicket = () => {
    setIsLoadingOrders(true);
    if (!clientOrderBackUp || !clientOrderBackUp.length) {
      getClientOrders();
    } else {
      setIsLoadingOrders(false);
      document.getElementById('ticketModalSpan').click();
    }
  }
  const getClientOrders = () => {
    apiGetMethod(allApiUrl.CLIENT_ORDER_ID + "/" + userInfo.id, "", header)
      .then((res) => {
        let clientOrder = [];
        res.data.order.map((item) => {
          clientOrder.push({
            title: item.title || " ",
            serviceName: item.serviceName || " ",
            label: (
              <>
                {item.title || " "} {"\n"}{" "}
                <small>{item.serviceName || " "}</small>
              </>
            ),
            value: item._id,
          });
        });
        setClientOrder([...clientOrder]);
        setClientOrderBackup([...clientOrder]);
        setIsLoadingOrders(false);
        document.getElementById('ticketModalSpan').click();
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoadingOrders(false);
      });
  }
  const addTicket = () => {
    if (content.length < 0 || content === "") {
      toastMessage("error", "The message field is required.");
    }

    const data = {
      to: userInfo.email,
      subject: "",
      relatedOrder: "",
      message: [],
      rating: null,
      client: userInfo.id,
    };
    if (subjectTicket) {
      // data.to = addTickets;
      data.subject = subjectTicket;
      data.relatedOrder = realtedTicket;
      data.message = [
        {
          from: userInfo.id,
          date: new Date().getTime(),
          message: content,
          to: "Client",
        },
      ];
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.ADD_TICKET, data, header)
      .then((res) => {
        if (res.status === 201) {
          toastMessage("success", res);
          // navigate("/help");
          getAllTickets();
          setSubjectTicket("");
          setRelatedTicket("");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  document.addEventListener("focusin", function (e) {
    if (
      e.target.closest(
        ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
      ) !== null
    ) {
      e.stopImmediatePropagation();
    }
  });
  return (
    <div>
      {!!(isPage) &&
        <>
          <Sidebar
            activePage="help"
            setToggleSidebar={setToggleSidebar}
            toggleSideBar={toggleSideBar}
          />
          <Header handleSidebar={handleSidebar} />
        </>
      }
      <PageLoader isLoading={initialLoading} />

      {!initialLoading && (
        <div className={isPage ? 'orders-section commonPadding horizontalPadding' : 'shopSection horizontalPadding'}>
          <div className={isPage ? 'commonSlidePl' : ''}>
            <div className="container-fluid ">
              <div className="row">
                <div className="col-lg-6">
                  <div className="heading">
                    <h2 className="fs-4">Help</h2>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="section-filter">
                    <PaginationNewUI
                      itemsPerPage={itemsPerPage}
                      itemOffset={itemOffset}
                      setItemOffset={setItemOffset}
                      pageCount={pageCount}
                      totalRecordsCount={totalRecordsCount}
                      setItemsPerPage={setItemsPerPage}
                      mainClassName={isPage ? "col-lg-7 d-inline-flex justify-content-end align-items-center" : "col-lg-12"}
                    />
                    {!!isPage &&
                      <div className="col=lg-3 d-inline-flex justify-content-end align-items-center">
                        <span data-bs-toggle="modal"
                              data-bs-target="#ticketModal"
                              id="ticketModalSpan"></span>
                        <button
                          className="btn btn-save"
                          onClick={onNewTicket}
                        >
                          New Ticket
                        </button>
                        {isLoadingOrders && (
                          <button className="btn">
                            <SpinnerCircularFixed
                              size="20"
                              secondaryColor=""
                              color="#201b50"
                              thickness="200"
                            />
                          </button>
                        )}
                      </div>
                    }
                  </div>
                </div>
              </div>

              <LoadingOverlay
                styles={{
                  spinner: (base) => ({
                  ...base,
                  width: "50px",
                  "& svg circle": {
                      stroke: "#007D87",
                  },
                  }),
                  overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 0.5)",
                  }),
              }}
              active={isLoading}
              spinner
              text="Loading content..."
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive common-table mt-3 files-table rounded-1">
                      <table className="table logs-table mb-0">
                        <thead>
                          <tr>
                            <th>Subject</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tickets.map((value, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <a
                                    onClick={() => {
                                      navigate(`/portal/view-ticket/${value._id}`)
                                      document.getElementById('searchModalCloseId').click();
                                    }}
                                  >
                                    {convertToUpperCase(value.subject)}
                                  </a>
                                </td>
                                <td>{moment(value.date).format("MMM DD")}</td>
                                <td>
                                  <span className="pill pill-orange">
                                    {value.status}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                          {!tickets.length && (
                            <tr>
                              <td colSpan={3}>
                                <div className="noData-section">
                                  <img alt="no data" src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      )}

      <div
        className="modal fade add-order-modals newTicket-modal"
        id="ticketModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                New Ticket
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-order-form">
                <div className="form-group">
                  <label>Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setSubjectTicket(e.target.value);
                    }}
                    value={subjectTicket}
                  />
                </div>

                <div className="form-group">
                  <label>
                    Related Order <small>(Optional)</small>
                  </label>
                  <Select
                    className="my-react-select my-react-select--compact relatedOrder"
                    classNamePrefix="lp-copy-sel"
                    isSearchable
                    value={relatedOrder}
                    options={clientOrder}
                    onInputChange={(e) => {
                      if (e.length > 0) {
                        let list = [];
                        list = clientOrderBackUp.filter((data) => {
                          if (
                            data.title.includes(e) ||
                            data.serviceName.includes(e)
                          ) {
                            return data;
                          }
                        });
                        setClientOrder(list);
                      } else {
                        setClientOrder(clientOrderBackUp);
                      }
                    }}
                    filterOption={false}
                    onChange={(e) => {
                      setRelatedOrder(e);
                    }}
                  />
                </div>

                <div className="form-group">
                  <label>Message</label>
                  <Editor
                    required
                    apiKey={editorApiKey}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={content}
                    init={{
                      selector: "textarea",
                      height: 200,
                      menu: {
                        custom: {
                          title: "custom",
                          items: "basicitem nesteditem toggleitem",
                        },
                      },
                      toolbar:
                        "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                        "fullpage" +
                        "indent | blockquote | codesample | fontselect ",
                      font_formats:
                        "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                      block_formats:
                        "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                      plugins: ["image", "help", "link", "codesample", "lists"],
                      branding: false,
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                      menubar: false,
                    }}
                    onEditorChange={(e) => handleEditorChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary close-btn"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => addTicket()}
              >
                Create Ticket
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TableHelp;
