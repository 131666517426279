import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import noData from "../../../assets/img/nodata-img.svg";
import { AllContext } from "../../../context";
import { formatMonthNameAndDate } from "../../../shared/functions";
import PageLoader from "../../pageLoader";
/*import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import openIcon from "../../../assets/img/icons/openSide.svg";
import searchicon from "../../../assets/img/searchIcon-black.svg";
import sortIcon from "../../../assets/img/icons/sortIcon.svg";*/
import LoadingOverlay from 'react-loading-overlay';
import PaginationNewUI from "../../PaginationNewUI";
LoadingOverlay.propTypes = undefined;

const TableFiles = ({searchText, isLoadingSearch, setIsLoadingSearch}) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [initialLoading, setInitialLoading] = useState(true);
  const dispatch = useDispatch();

  const context = useContext(AllContext);
  const { allApiUrl, apiPostMethod, redirectToLogin, toastMessage } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  /*const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [startdateFilter, setstartDateFilter] = useState(0);
  const [enddateFilter, setendDateFilter] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [users, setUsers] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [orderList, setOrderList] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({ label: "", value: "" });
  const [selectedTicket, setSelectedTicket] = useState({
    label: "",
    value: "",
  });
  const [isOrderActive, setOrderActive] = useState(false);
  const [isTicketActive, setTicketActive] = useState(false);

  useEffect(() => {
    getOrderListing();
    getTickets();
  }, []);

  async function getOrderListing() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.ORDER, "", header);
      if (response.status === 200) {
        setOrderList([...response.data.order]);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      toastMessage("error", err.data.message);
    }
  }

  const getTickets = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.GET_TICKET, "", header).then((res) => {
      if (res.status === 200) {
        setTicketList(res.data);
      }
    });
  };

  useEffect(() => {
    if (!filterCount && !initialLoading) {
      filterFiles();
    }
  }, [filterCount]);*/

  useEffect(() => {
    console.log("1")
    if (isLoadingSearch) {
      filterFiles();
    }
  }, [isLoadingSearch])
  useEffect(() => {
    filterFiles();
  }, [itemOffset, itemsPerPage]);

  const filterFiles = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    let data = {};
    // let data = getFilterData();

    data.page = itemOffset;
    data.limit = itemsPerPage;
    if (searchText) { data.searchText = searchText; }

    apiPostMethod(allApiUrl.FILTER_FILES, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.countAll);
          setFiles([...res.data.filteredFiles]);
        }
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        // redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      });
  };
  /*const getFilterData = () => {
    const data = {};
    if (accountId) data.userId = accountId;
    if (startdateFilter > 0) data.startdateFilter = startdateFilter;
    if (enddateFilter > 0) data.enddateFilter = enddateFilter;
    if (selectedOrder.value || selectedTicket.value) {
      let resourceId = [];
      if (selectedOrder.value) resourceId.push(selectedOrder.value);
      if (selectedTicket.value) resourceId.push(selectedTicket.value);
      data.resourceId = resourceId;
    }
    setIsLoading(true);
    let count = 0;
    for (let key in data) {
      if (key === "resourceId" && data[key].length > 1) {
        count += 1;
      }
      count += 1;
    }
    setFilterCount(count);
    return data;
  }*/

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  return (
    <>
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section horizontalPadding">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h2>Files</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-filter">
                <PaginationNewUI
                  itemsPerPage={itemsPerPage}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  pageCount={pageCount}
                  totalRecordsCount={totalRecordsCount}
                  setItemsPerPage={setItemsPerPage}
                  mainClassName="col-lg-12"
                />
              </div>
            </div>
          </div>
          <LoadingOverlay
            styles={{
              spinner: (base) => ({
              ...base,
              width: "50px",
              "& svg circle": {
                  stroke: "#007D87",
              },
              }),
              overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 0.5)",
              }),
          }}
          active={isLoading}
          spinner
          text="Loading content..."
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive common-table mt-3 files-table rounded-1">
                  <table className="table logs-table mb-0">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Size</th>
                      <th>Created Date</th>
                      <th>Resource Type</th>
                      <th>Owner</th>
                      <th>Resource ID</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!!(files.length) &&
                      files.map((value, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <a href={value?.url} target="_blank">
                                {value?.fileName}
                              </a>
                            </td>
                            <td>{formatBytes(value?.fileSize)}</td>
                            <td>
                              {formatMonthNameAndDate(value?.createdOn, companyData.timezone)}
                            </td>
                            <td>{value?.eventType}</td>
                            <td>
                              <a
                                href="#"
                                onClick={() => {
                                  document.getElementById('searchModalCloseId').click();
                                  navigate(
                                    `/${
                                      value.userId.group === 1
                                        ? "accounts/team"
                                        : "view-client"
                                    }/${value.userId._id}`
                                  )
                                }}
                              >
                                {value &&
                                value.userId &&
                                value.userId.firstName &&
                                value.userId.lastName
                                  ? value.userId.firstName +
                                  " " +
                                  value.userId.lastName
                                  : value.userId.email}
                              </a>
                            </td>
                            <td>
                              <a
                                href="#"
                                onClick={() => {
                                  document.getElementById('searchModalCloseId').click();
                                  navigate(
                                    `/${
                                      value?.eventType === "Order"
                                        ? "view-orders"
                                        : "view-ticket"
                                    }/${value.resourceId}`
                                  )
                                }}
                              >
                                {value?.resourceId}
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    {files && files.length <= 0 && (
                      <tr>
                        <td colSpan={7}>
                          <div className="noData-section">
                            <img src={noData} />
                            <p>No Data Found</p>
                          </div>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      )}
    </>
  );
};

export default TableFiles;
