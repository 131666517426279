import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import noData from "../../assets/img/nodata-img.svg";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import PageLoader from "../../components/pageLoader";
import { handleOffSetDateTime } from "../../shared/functions";
import openIcon from "../../assets/img/icons/openSide.svg";
import PaginationNewUI from "../../components/PaginationNewUI";

const Logs = () => {
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const location = useLocation();
  let { resource_id, resource } = queryString.parse(location.search);
  const { allApiUrl, apiPostMethod, redirectToLogin, toastMessage } = context;
  const [isLoading, setIsLoading] = useState(false);
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [itemOffset, setItemOffset] = useState(1);
  const [accountType, setAccountType] = useState("");
  const [accountTypeList, setAccountTypeList] = useState(["Client", "Admin"]);
  const [resourceType, setResourceType] = useState(resource ? resource : "");
  const [accountId, setAccountId] = useState("");
  const [resourceId, setResourceId] = useState(resource_id ? resource_id : "");
  const [startdateFilter, setstartDateFilter] = useState(0);
  const [enddateFilter, setendDateFilter] = useState(0);
  const [users, setUsers] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState("");
  const [logs, setLogs] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterCount, setFilterCount] = useState(0);

  const [initialLoading, setInitialLoading] = useState(true);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const { companyData } = useSelector((state) => state.sideMenuReducer);

  const { FILTER_LOGS_V2 } = allApiUrl;

  useEffect(() => {
    filterLogs();
  }, [itemOffset, itemsPerPage, resourceId]);
  useEffect(() => {
    if (!filterCount && !initialLoading) {
      filterLogs();
    }
  }, [filterCount]);

  const dispatch = useDispatch();

  const filterLogs = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let count = 0;
    const data = {};
    data.page = itemOffset;
    data.limit = itemsPerPage;
    if (accountType !== "") data.accountType = accountType;

    if (resourceType !== "") data.resourceType = resourceType;

    if (accountId !== "") data.accountId = accountId;
    if (startdateFilter > 0) data.startdateFilter = startdateFilter;
    if (enddateFilter > 0) data.enddateFilter = enddateFilter;
    if (resourceId != "") data.resourceId = resourceId;
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    for (let key in data) {
      count += 1;
    }
    setFilterCount(count);
    apiPostMethod(FILTER_LOGS_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setLogs([...res.data.logs]);
          setInitialLoading(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setInitialLoading(false);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} propSetAllClientList={setUsers} />
      <PageLoader isLoading={initialLoading} />

      <div className="orders-section commonPadding dashboardTop commonSlidePl">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-5 col-12">
              <div className="heading position-relative">
                <h2 className="mb-0">Logs</h2>
                <span className="openArrowIcon" onClick={() => { setToggleSidebar(!toggleSideBar) }}>
                  <img src={openIcon} />
                </span>
              </div>
            </div>

            <PaginationNewUI
              itemsPerPage={itemsPerPage}
              itemOffset={itemOffset}
              setItemOffset={setItemOffset}
              pageCount={pageCount}
              totalRecordsCount={totalRecordsCount}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
        </div>
      </div>
      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding commonSlidePl">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-12 col-12">
                {/* <div className="section-filter">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle btn-filter"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-filter"></i> Filter|{" "}
                      {filterCount}
                    </button>
                    <ul className="dropdown-menu filter-drowdown">
                      <div className="filter-btns">
                        <button
                          className="btn btn-clear"
                          onClick={() => {
                            if (resource_id) {
                              navigate("/logs");
                              getLogs();
                            } else {
                              setFilterCount(0);
                              setResourceType("");
                              setAccountType("");
                              setAccountId("");
                              setstartDateFilter(0);
                              setendDateFilter(0);
                              setShowDatePicker(false);
                              setUsers([]);
                              setItemPerPageFilterApplied(false);
                              setItemPerPageFilter(10);
                              getLogs();
                            }
                          }}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-update"
                          onClick={() => filterLogs()}
                        >
                          Done
                        </button>
                      </div>

                      <select
                        className="form-control"
                        value={accountType}
                        onChange={(value) => setAccountType(value.target.value)}
                      >
                        <option value="">Any Account</option>
                        {accountTypeList.length > 0 &&
                          accountTypeList.map((account) => {
                            return (
                              <option key={account} value={account}>
                                {account.charAt(0).toUpperCase() +
                                  account.slice(1)}
                              </option>
                            );
                          })}
                      </select>
                      <select
                        className="form-control"
                        value={resourceType}
                        onChange={(value) =>
                          setResourceType(value.target.value)
                        }
                      >
                        <option value="">Any resource</option>
                        <option value="account">Account</option>
                        <option value="order">Order</option>
                        <option value="ticket">Ticket</option>
                        <option value="invoice">Invoice</option>
                      </select>

                      <select
                        className="form-control"
                        onChange={(event) =>
                          setShowDatePicker("true" === event.target.value)
                        }
                      >
                        <option value={false}>Any date</option>
                        <option value={true}>Date Between</option>
                      </select>
                      {showDatePicker && (
                        <div className="d-flex dateBetween-filter">
                          <input
                            type="date"
                            name="startDate"
                            className="form-control"
                            onChange={(event) =>
                              setstartDateFilter(
                                new Date(event.target.value).getTime()
                              )
                            }
                            id=""
                          />
                          <span className="mx-2">and</span>
                          <input
                            className="form-control"
                            type="date"
                            name="endDate"
                            onChange={(event) =>
                              setendDateFilter(
                                new Date(event.target.value).getTime()
                              )
                            }
                            id=""
                          />
                        </div>
                      )}
                      <select
                        className="form-control"
                        value={accountId}
                        onChange={(value) => setAccountId(value.target.value)}
                      >
                        <option value="">Any User</option>
                        {users.length > 0 &&
                          users.map((user, index) => {
                            return (
                              <option key={index} value={user._id}>
                                {user.firstName + " " + user.lastName}
                              </option>
                            );
                          })}
                      </select>
                    </ul>
                  </div>
                </div> */}

                <div className="topFilters flex-wrap">
                  <p>Filter By:</p>
                  <select
                    className="form-control FiltersChild"
                    value={accountType}
                    onChange={(value) => setAccountType(value.target.value)}
                  >
                    <option value="">Any Account</option>
                    {accountTypeList.length > 0 &&
                      accountTypeList.map((account) => {
                        return (
                          <option key={account} value={account}>
                            {account.charAt(0).toUpperCase() +
                              account.slice(1)}
                          </option>
                        );
                      })}
                  </select>

                  <select
                    className="form-control FiltersChild"
                    value={resourceType.toLowerCase()}
                    onChange={(value) =>
                      setResourceType(value.target.value)
                    }
                  >
                    <option value="">Any resource</option>
                    <option value="account">Account</option>
                    <option value="order">Order</option>
                    <option value="ticket">Ticket</option>
                    <option value="invoice">Invoice</option>
                  </select>

                  <select
                    className="form-control FiltersChild date-selection"
                    onChange={(event) =>
                      setShowDatePicker(event.target.value)
                    }
                    value={showDatePicker}
                  >
                    <option value={""}>Any date</option>
                    <option value={"date"}>Date Between</option>
                  </select>
                  {showDatePicker === "date" && (
                    <div className="d-flex dateBetween-filter align-items-center">
                      <input
                        type="date"
                        name="startDate"
                        className="form-control date-input"
                        onChange={(event) =>
                          setstartDateFilter(
                            new Date(event.target.value).getTime()
                          )
                        }
                        id=""
                      />
                      <span className="mx-2">to</span>
                      <input
                        className="form-control date-input"
                        type="date"
                        name="endDate"
                        onChange={(event) =>
                          setendDateFilter(
                            new Date(event.target.value).getTime()
                          )
                        }
                        id=""
                      />
                    </div>
                  )}

                  <select
                    className="form-control FiltersChild"
                    value={accountId}
                    onChange={(value) => setAccountId(value.target.value)}
                  >
                    <option value="">Any User</option>
                    {users.length > 0 &&
                      users.map((user, idx) => {
                        if (user.userId)
                          return (
                            <option key={idx} value={user.userId._id}>
                              {user.userId.firstName +
                                " " +
                                user.userId.email}
                            </option>
                          );
                      })}
                  </select>

                  <button
                    className="btn btn-clear"
                    onClick={() => {
                      if (resource_id) {
                        navigate("/logs");
                        setResourceId("");
                        setResourceType("");
                      } else {
                        setFilterCount(0);
                        setResourceType("");
                        setAccountType("");
                        setAccountId("");
                        setstartDateFilter(0);
                        setendDateFilter(0);
                        setShowDatePicker("");
                      }
                    }}
                  >
                    Clear
                  </button>
                  <button
                    className="btn btn-update"
                    onClick={() => filterLogs()}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="common-table mt-3 table-responsive rounded-1">
                  <table className="table logs-table mb-0">
                    <thead>
                      <tr>
                        <th>Date Time</th>
                        <th>Message</th>
                        <th>Account Type</th>
                        <th>Account Name</th>
                        <th>Resource ID</th>
                        <th>Resource Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs.length > 0 &&
                        logs.map((value, key) => {
                          return (
                            <tr
                              key={key}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(`/logs/log/${value._id}`, {
                                  state: {
                                    dateAndTime: value?.timestamp || "",
                                    accountType: value?.accountType || "",
                                    firstName: value?.accountId.firstName || "",
                                    lastName: value?.accountId.lastName || "",
                                    resourceType: value?.resourceType || "",
                                    resourceId: value?.resourceId || "",
                                    message: value?.eventDescription || "",
                                    data: value?.data || "",
                                  },
                                });
                              }}
                            >
                              <td>
                                <a className="log-link">
                                  {handleOffSetDateTime(
                                    value.timestamp,
                                    companyData.timezone
                                  )}
                                </a>
                              </td>
                              <td>
                                <a
                                  className="log-link"
                                  onClick={() => {
                                    navigate(`/log/log/${value._id}`, {
                                      state: { id: 7, color: "green" },
                                    });
                                  }}
                                // href={`/logs/log/${value._id}`}
                                >
                                  {value.eventDescription}
                                </a>
                              </td>
                              <td>
                                <div className="clientDetail">
                                  <p className="name">
                                    <span>{value.accountType}</span>
                                  </p>
                                </div>
                              </td>
                              <td>
                                {value.accountId && value.accountId.firstName
                                  ? value.accountId.firstName ||
                                  " " + value.accountId.lastName
                                  : " "}
                              </td>
                              <td>
                                <div className="clientDetail">
                                  <p className="name">
                                    {value.resourceType == "account" &&
                                      (value.eventDescription ==
                                        "Account Created" ||
                                        value.eventDescription ==
                                        "Resource Deleted") &&
                                      value.data && (
                                        <span>
                                          {value.resourceType == "account" &&
                                            (value.eventDescription ==
                                              "Account Created" ||
                                              value.eventDescription ==
                                              "Resource Deleted") &&
                                            value.data
                                            ? value.data.firstName +
                                            " " +
                                            value.data.lastName
                                            : value.resourceId}
                                        </span>
                                      )}
                                    {value.resourceType != "account" && (
                                      <span>{value.resourceId}</span>
                                    )}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="clientDetail">
                                  <p className="name">
                                    <span>{value.resourceType}</span>
                                  </p>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      {logs && logs.length <= 0 && (
                        <tr>
                          <td colSpan={7}>
                            <div className="noData-section">
                              <img src={noData} />
                              <p>No Data Found</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* add order modal stasts */}
          <div
            className="modal fade add-order-modals"
            id="addorderModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add order
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="add-order-form">
                    <div className="add-alert">
                      <p>
                        Adding an order manually will not trigger any
                        notifications or payments. To add a paid order you can
                        create a new invoice and mark it as paid.
                      </p>
                    </div>
                    <form>
                      <div className="form-group">
                        <label>Clinet</label>
                        <select className="form-control">
                          <option>Client Name</option>
                          <option>Client Name</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label>Service</label>
                        <select className="form-control">
                          <option>Service 1</option>
                          <option>Service 2</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary close-btn"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    Add order
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* add order modal ends */}
        </div>
      )}
    </div>
  );
};
export default Logs;