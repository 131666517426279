import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import noData from "../../assets/img/nodata-img.svg";
import Header from "../../widgets/header";
import { apiGetMethod, apiDeleteMethod } from "../../api/rest";
import { AllContext } from "../../context";
import Modal from "../../components/modal";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../components/pageLoader";
import openIcon from "../../assets/img/icons/openSide.svg";
import delIcon from "../../assets/img/delIcon.svg";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";

const Tags = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  useEffect(() => {
    getTag();
  }, []);
  const context = useContext(AllContext);
  const { allApiUrl, redirectToLogin, toastMessage } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [event, setEvent] = useState("");
  const [tags, setTags] = useState([]);
  const [data, setData] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false)
  const { TAGS } = allApiUrl;

  const refresh = () => {
    getTag();
  };

  const getTag = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(TAGS, "", header)
      .then((res) => {
        if (res.status === 200) {
          setInitialLoading(false);
          setTags(res.data.tags);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const deleteTag = (id) => {
    setIsLoading(true);
    setDisabledBtn(true)
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(TAGS, id, header)
      .then((res) => {
        if (res.status === 200) {
          getTag();
          setIsLoading(false);
        }
        setDisabledBtn(false)
        setIsDeleteConfirmModalOpen(false)
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
        setDisabledBtn(false)
        setIsDeleteConfirmModalOpen(false)
      });
  };

  function deleteMultiple() {
    bulkDeleteId.map((row) => {
      deleteTag(row);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
  }

  function checkedAll() {
    if (tags.length == bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = tags.map((e) => {
        return e._id;
      });
      setBulkDeleteId(ids);
    }
  }

  function deleteIdList(status, id) {
    if (status) {
      let exist = bulkDeleteId.findIndex((e) => e == id);
      if (exist == -1) {
        bulkDeleteId.push(id);
        setBulkDeleteId(bulkDeleteId);
      }
    } else {
      let exist = bulkDeleteId.findIndex((e) => e == id);
      let ids = bulkDeleteId.splice(exist + 1, 1);
      setBulkDeleteId(ids);
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0 justify-content-between">
                    Tags
                    
                    <a
                    className="btn btn-addOrder"
                      data-bs-toggle="modal"
                      data-bs-target="#tagsModal"
                      href="#"
                      onClick={() => {
                        setEvent("Add");
                        setData({});
                      }}
                    >
                      Add Tag
                    </a>
                  </h2>
                  <span className="openArrowIcon" onClick={() => { setToggleSidebar(!toggleSideBar) }}>
                    <img src={openIcon} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="setting-form-section pt-3 orders-section horizontalPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-8 col-lg-8">
                <div className="setting-form mt-4">
                  <form className="tagsTable common-table p-0 mb-3 rounded-1">
                    <div className="table-responsive team-table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="ps-4">
                              <div className="form-group mb-0">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="item1"
                                    checked={
                                      tags.length == bulkDeleteId.length &&
                                      tags.length > 0
                                    }
                                    onChange={() => checkedAll()}
                                  />
                                  <label
                                    htmlFor="item1"
                                    className="form-check-label"
                                  ></label>
                                </div>
                              </div>
                            </th>
                            <th>Tag</th>
                            <th>Orders</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tags.map((value, key) => {
                            return (
                              <tr key={key}>
                                <td className="ps-4">
                                  <div className="form-group mb-0">
                                    <div className="">
                                      <input
                                        type="checkbox"
                                        id={value._id}
                                        className="form-check-input"
                                        name={value.name}
                                        checked={bulkDeleteId.find(
                                          (e) => e == value._id
                                        )}
                                        onChange={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            e.target.checked,
                                            value._id
                                          );
                                        }}
                                      />
                                      <label htmlFor={value._id}></label>
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#tagsModal"
                                    href="#"
                                    onClick={() => {
                                      setEvent("edit");
                                      setData(value);
                                    }}
                                  >
                                    {value.name}
                                  </a>
                                </td>
                                <td>
                                  <a href="#"></a>
                                </td>
                                <td>
                                  <a
                                  >
                                    <span
                                      className="iconDel"
                                      onClick={() => { setDeleteId(value._id); setIsDeleteConfirmModalOpen(true)}}
                                    >
                                      <img src={delIcon} />
                                    </span>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                          {tags && tags.length <= 0 && (
                            <tr>
                              <td colSpan={7}>
                                <div className="noData-section">
                                  <img src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </form>

                  {(bulkDeleteId.length > 0 || isSingleChecked) && (
                    <div className="table-bottom-option mt-2">
                      <div className="row">
                        <div className="col-xxl-4 col-lg-5 col-md-4">
                          <div className="input-group">
                            <select
                              id="selectOption"
                              value={selectOption.target}
                              onChange={(e) => selectOption(e)}
                              className="form-control"
                            >
                              <option value="">With Selected...</option>
                              <option value="Delete">Delete</option>
                            </select>
                            <button
                              className="btn btn-apply"
                              onClick={() => {
                                if (selectedOption == "Delete") {
                                  deleteMultiple();
                                }
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {isDeleteConfirmModalOpen ? (
            <DeleteConfirmModal
              isDeleteConfirmModalOpen={isDeleteConfirmModalOpen}
              handleDelete={() => { deleteTag(deleteId) }}
              handleCancelModal={() => { setIsDeleteConfirmModalOpen(false) }}
              modalContent={`Are you sure you want to delete this tag? The tag will be removed from all orders that have it.`}
              disabledBtn={disabledBtn}
            />
          ) : (
            ""
          )}
        </div>
        </div>
      )}

      <Modal event={event} data={data} refresh={refresh} />
    </div>
  );
};
export default Tags;