const INITIAL_STATE = {
    sideMenuData: [],
    companyData: {},
    paymentSettingData: {}
};
  
const sideMenuReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SAVE_SIDEMENU_DATA":
            return { ...state, sideMenuData: action.data };
        case "SAVE_COMPANY_DATA":
            return { ...state, companyData: action.data }; 
        case "SAVE_PAYMENt_SETTING_DATA":
            return { ...state, paymentSettingData: action.data || {} };
        default:
            return state;
    }
};

export default sideMenuReducer;