import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AllContext} from "../../../context";
import {useNavigate} from "react-router-dom";
import SettingTabs from "../tabs";
import 'react-form-builder2/dist/app.css';
import {ReactFormBuilder} from "react-form-builder2";
import './crm-fields.css';
import FormElementsEdit from "../../FormElementsEdit";

const CrmFields = () => {
  const dispatch = useDispatch();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [isLoading, setIsLoading] = useState(true);
  const {
    allApiUrl,
    apiPostMethod,
    toastMessage,
    apiGetMethod,
    redirectToLogin,
  } = context;
  let header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };


  const [jsonData, setJsonData] = useState([]);

  const toolbarItems = [
    {
      name: 'Single line of text',
      key: 'TextInput',
    }, {
      name: 'Multiple line of text',
      key: 'TextArea',
    }, {
      key: 'Checkboxes',
    }, {
      name: 'Dropdown Menu',
      key: 'Dropdown',
    }, /*{
      name: 'File Upload',
      key: 'FileUpload',
    }*/
  ];
  useEffect(() => {
    onLoad();
  }, []);
  useEffect(() => {
  }, [jsonData]);
  const onLoad = () => {
    setIsLoading(true);
    apiGetMethod(allApiUrl.GET_CRM_FIELDS, "", header)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setJsonData(res.data);
          setIsLoading(false);
        }
      }).catch((err) => {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    });
  };
  const onSubmitData = (d) => {
    const postData = d.task_data;
    apiPostMethod(allApiUrl.SAVE_CRM_FIELDS, postData, header).catch((err) => {
      redirectToLogin(dispatch, navigate, err);
      toastMessage("error", err.data.message);
    });
  }
  return (
    <div className="col-xxl-10 col-lg-10 mx-auto mt-4">
      <h3>CRM Fields</h3>
      <p><b>The fields you set up here will be associated with client accounts.<br/>
        Fields can be shown in client's profile or used internally.</b></p>
      <p>Drag and drop fields from the right column.</p>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <div className="setting-form">
            <div className="commonForm rounded-1">
              <div className="crm-form-builder">
                {!isLoading && <ReactFormBuilder
                  toolbarItems={toolbarItems}
                  data={jsonData}
                  onPost={onSubmitData}
                  renderEditForm={props => <FormElementsEdit showFieldIn="Show field in Client profile" {...props} />}
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CrmFields;
