import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AvatarImg from "../../assets/img/avatar.png";
import iconToggle from "../../assets/img/menu-icon.svg";
import searchIcon from "../../assets/img/searchIcon.svg";
import notificationIcon from "../../assets/img/notificationIcon.svg";
import {
  saveLoginUserAccessToken,
  userLogoutRequest,
} from "../../redux/action/login";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import { AllContext } from "../../context";
import { useContext } from "react";
import { saveLoginUserInfo } from "../../redux/action/login";
import { redirectToLogin, convertToUpperCase } from "../../shared/functions";
import { apiPostMethod } from "../../api/rest";
import { saveTeamData } from "../../redux/action/team";
import TableClients from "../../components/Tables/clients";
import TableOrders from "../../components/Tables/orders";
import TableTickets from "../../components/Tables/tickets";
import TableCoupons from "../../components/Tables/coupons";
import TableInvoices from "../../components/Tables/invoices";
import TableServices from "../../components/Tables/services";
import TableSubscriptions from "../../components/Tables/subscriptions";
import TableFiles from "../../components/Tables/files";
import moment from "moment";
import TableShop from "../../components/Tables/shop";
import TablePayments from "../../components/Tables/payments";
import * as _ from "lodash";
import TablePurchases from "../../components/Tables/purchases";
import TableHelp from "../../components/Tables/help";
import { handleOffSetDateTime } from "../../shared/functions";

const Header = ({ isLoading, handleSidebar, propSetAllClientList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);

  const { companyData } = useSelector((state) => state.sideMenuReducer);
  const [searchText, setSearchText] = useState("");
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [currActiveTab, setCurrActiveTab] = useState("");
  const { apiGetMethod, allApiUrl, toastMessage, apiGetMethodSession } =
    context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const { teamsData } = useSelector((state) => state.teamReducer);

  const tabs =
    userInfo?.roles?.type === "admin"
      ? [
        "clients",
        "orders",
        "files",
        "tickets",
        "invoices",
        "coupons",
        "subscriptions",
        "services",
      ]
      : ["purchase", "shop", "help", "payments"];
  function checkSession() {
    let page = window.location.pathname.split("/");
    var headers = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.SESSION, "", headers)
      .then((res) => {
        userInfo.roles = res?.data?.permissions;
        if (userInfo.group === 2) {
          userInfo.teamId = res?.data?.teamId;
        }
        dispatch(saveLoginUserInfo(userInfo));
        if (res.status === 400) {
          toastMessage("error", res.data.message);
          redirectToLogin(dispatch, navigate, res);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        redirectToLogin(dispatch, navigate, err, page);
      });
  }
  useEffect(() => {
    // if (!userInfo || !userInfo.roles) {
    checkSession();
    // }
  }, []);
  function signoutHandler() {
    dispatch(saveLoginUserAccessToken(""));
    dispatch(saveLoginUserInfo(""));
    localStorage.removeItem("userLoggedIn");
    dispatch(userLogoutRequest());
    navigate("/logout");
  }

  var header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };

  useEffect(() => {
    if (!teamsData || !teamsData.length) {
      getClientList();
    } else {
      if (propSetAllClientList !== undefined) {
        propSetAllClientList(teamsData);
      }
    }
  }, []);

  function onBackKeyDown(e) {
    e.preventDefault();
    window.location.reload();
  }
  window.addEventListener("popstate", onBackKeyDown, false);


  function getClientList() {
    if (propSetAllClientList !== undefined) {
      let page = window.location.pathname.split("/");
      apiGetMethod(allApiUrl.GET_ALL_CLIENT, "", header)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.team) {
              const sortedList = _.sortBy(res.data.team, [
                (item) => item.userId.firstName.toLowerCase(),
                "userId.email",
              ]);
              propSetAllClientList(sortedList);
              dispatch(saveTeamData(sortedList));
            }
          }
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err, page);
          toastMessage("error", err?.data?.message);
          //  const dispatch = useDispatch();
        });
    }
  }

  function backToAdminHandler() {
    const data = {
      email: userInfo.adminEmail,
      adminEmail: userInfo.adminEmail,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.SIGN_AS_CLIENT, data, header)
      .then((res) => {
        if (res.status === 200) {
          navigate("/clients");
          dispatch(saveLoginUserAccessToken(res.data.token));
          dispatch(saveLoginUserInfo(res.data));
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsLoadingSearch(true);
    }
  };
  const onClickSearch = () => {
    setSearchText('');
    setIsLoadingSearch(true);
    let page = window.location.pathname.split("/");
    let tabName = page.length && page.length === 2 ? page[1] : "clients";
    tabName = tabs.includes(tabName) ? tabName : tabs[0];
    setCurrActiveTab(tabName);
  };
  const TabsList = () => {
    const tempArray = [];
    tabs.map((item, idx) => {
      tempArray.push(
        <li key={idx} className="nav-item" role="presentation">
          <button
            className={
              currActiveTab.toLowerCase() === item.toLowerCase()
                ? "nav-link active"
                : "nav-link"
            }
            id="pills-overdue-header-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-overdue-header"
            type="button"
            role="tab"
            aria-controls="pills-overdue-header"
            aria-selected="true"
            onClick={() => {
              setSearchText("");
              setCurrActiveTab(item);
            }}
          >
            {" "}
            {convertToUpperCase(item)}
          </button>
        </li>
      );
    });
    return (
      <ul className="nav nav-pills" id="pills-tab" role="tablist">
        {tempArray}
      </ul>
    );
  };
  return (
    <div className="commonSlidePl">
      <div className="topbar">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-2 col-lg-3 col-md-12">
              <div className="search-parent">
                <div className="hamburger">
                  <img onClick={() => handleSidebar()} src={iconToggle} />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    onClick={onClickSearch}
                    className="form-control"
                    placeholder="Search"
                    data-bs-toggle="modal"
                    data-bs-target="#searchModal"
                    readOnly={true}
                  /*value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}*/
                  />
                  <span>
                    {/* <i className="fa-solid fa-magnifying-glass"></i> */}
                    <img src={searchIcon} />
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xxl-4 col-lg-3 col-md-6 col-sm-6 col-xs-6 col-7">
              <div className="helloText">
                <h3>
                  Hello {userInfo?.firstName} {userInfo?.lastName}
                </h3>

                <p>
                  Last login {handleOffSetDateTime(
                    userInfo.lastLogin?.date,
                    companyData.timezone
                  )}
                </p>

                {/* <p>{moment(userInfo.lastLogin?.date).format("lll")}</p> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-5 sideicon">
              <div>
                <Loader isLoading={isLoading} />
              </div>
              <div className="sidemenus">
                <ul>
                  <li>
                    <div className="dropdown headerdropdown">
                      <span className="notification">
                        <img src={notificationIcon} />
                      </span>
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>
                          {userInfo?.firstName} {userInfo?.lastName}{" "}
                        </span>
                        <img
                          src={userInfo?.image ? userInfo?.image : AvatarImg}
                        />{" "}
                        {/* <img className="arrowImg" src={ArrowDown} /> */}
                      </button>
                      <ul className="dropdown-menu">
                        <li onClick={() => navigate("/profile")}>
                          <a className="dropdown-item" href="#">
                            Your Profile
                          </a>
                        </li>
                        {userInfo?.group === 2 && (
                          <li onClick={() => navigate("/clientTeam")}>
                            <a className="dropdown-item" href="#">
                              Team
                            </a>
                          </li>
                        )}
                        {/* <hr /> */}
                        {userInfo &&
                          userInfo.showButton &&
                          userInfo.showButton == true ? (
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => backToAdminHandler()}
                            >
                              Back To Admin
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a
                              href="#"
                              className="dropdown-item"
                              onClick={signoutHandler}
                            >
                              Sign out
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* search modal starts */}
          <div
            className="modal fade searchModal"
            id="searchModal"
            data-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="modal-header">
                  {/* <h1 className="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                  <div className="input-group me-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={searchText}
                      onKeyDown={handleKeyDown}
                      disabled={isLoadingSearch}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <span>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </div>
                  <button
                    type="button"
                    id="searchModalCloseId"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="searchDetail-section">
                    <div className="searchButtons"></div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="common-tabs">
                          <TabsList />
                          {userInfo?.roles?.type === "admin" ? (
                            <div className="tab-content" id="pills-tabContent">
                              {currActiveTab.toLowerCase() === "clients" && (
                                <TableClients
                                  searchText={searchText}
                                  isLoadingSearch={isLoadingSearch}
                                  setIsLoadingSearch={setIsLoadingSearch}
                                />
                              )}
                              {currActiveTab.toLowerCase() === "orders" && (
                                <TableOrders
                                  searchText={searchText}
                                  isLoadingSearch={isLoadingSearch}
                                  setIsLoadingSearch={setIsLoadingSearch}
                                />
                              )}
                              {currActiveTab.toLowerCase() === "tickets" && (
                                <TableTickets
                                  searchText={searchText}
                                  isLoadingSearch={isLoadingSearch}
                                  setIsLoadingSearch={setIsLoadingSearch}
                                />
                              )}
                              {currActiveTab.toLowerCase() === "files" && (
                                <TableFiles
                                  searchText={searchText}
                                  isLoadingSearch={isLoadingSearch}
                                  setIsLoadingSearch={setIsLoadingSearch}
                                />
                              )}
                              {currActiveTab.toLowerCase() === "invoices" && (
                                <TableInvoices
                                  searchText={searchText}
                                  isLoadingSearch={isLoadingSearch}
                                  setIsLoadingSearch={setIsLoadingSearch}
                                />
                              )}
                              {currActiveTab.toLowerCase() === "coupons" && (
                                <TableCoupons
                                  searchText={searchText}
                                  isLoadingSearch={isLoadingSearch}
                                  setIsLoadingSearch={setIsLoadingSearch}
                                />
                              )}
                              {currActiveTab.toLowerCase() ===
                                "subscriptions" && (
                                  <TableSubscriptions
                                    searchText={searchText}
                                    isLoadingSearch={isLoadingSearch}
                                    setIsLoadingSearch={setIsLoadingSearch}
                                  />
                                )}
                              {currActiveTab.toLowerCase() === "services" && (
                                <TableServices
                                  searchText={searchText}
                                  isLoadingSearch={isLoadingSearch}
                                  setIsLoadingSearch={setIsLoadingSearch}
                                />
                              )}
                            </div>
                          ) : (
                            <div className="tab-content" id="pills-tabContent">
                              {currActiveTab.toLowerCase() === "purchase" && (
                                <TablePurchases
                                  isPage={false}
                                  searchText={searchText}
                                  isLoadingSearch={isLoadingSearch}
                                  setIsLoadingSearch={setIsLoadingSearch}
                                />
                              )}
                              {currActiveTab.toLowerCase() === "shop" && (
                                <TableShop
                                  isPage={false}
                                  searchText={searchText}
                                  isLoadingSearch={isLoadingSearch}
                                  setIsLoadingSearch={setIsLoadingSearch}
                                />
                              )}
                              {currActiveTab.toLowerCase() === "help" && (
                                <TableHelp
                                  isPage={false}
                                  searchText={searchText}
                                  isLoadingSearch={isLoadingSearch}
                                  setIsLoadingSearch={setIsLoadingSearch}
                                />
                              )}
                              {currActiveTab.toLowerCase() === "payments" && (
                                <TablePayments
                                  isPage={false}
                                  searchText={searchText}
                                  isLoadingSearch={isLoadingSearch}
                                  setIsLoadingSearch={setIsLoadingSearch}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* search modal ends */}
        </div>
      </div>
    </div>
  );
};
export default Header;
