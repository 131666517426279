import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { monthNameAndDateFormat } from "../../shared/functions";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  movieContainer: {
    backgroundColor: "#f6f6f5",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: "1 dashed #ddd",
    padding: 5,
  },
  billingContainer: {
    backgroundColor: "#f6f6f5",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 5,
    marginBottom: 20,
  },
  topContainer: {
    backgroundColor: "#f6f6f5",
    // display: "flex",
    // flexDirection: "row",
    padding: "5 10",
  },
  headerContainer: {
    backgroundColor: "#f9f9f9",
    padding: "5",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  movieDetails: {
    display: "flex",
    marginLeft: 5,
    width: "40%",
  },
  movieTitle: {
    fontSize: 13,
    // marginBottom: 10,
    color: "#00254d",
  },
  smallTitle: {
    fontSize: 13,
    marginBottom: 10,
    marginTop: 20,
  },
  thanksTitle: {
    fontSize: 13,
    marginBottom: 30,
  },
  receiptTitle: {
    fontSize: 18,
    color: "#00254d",
  },
  referenceTitle: {
    fontSize: 13,
    marginBottom: 30,
    textAlign: "center",
    color: "#777",
  },
  movieOverview: {
    fontSize: 10,
  },
  paidTitle: {
    fontSize: 14,
    fontWeight: "600",
  },
  emailTitle: {
    fontSize: 10,
    marginTop: 5,
    wordWrap: "break-word",
  },

  image: {
    height: 200,
    width: 150,
  },
  subtitle: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    alignItems: "center",
    marginBottom: 12,
  },
  vote: {
    display: "flex",
    flexDirection: "row",
  },
  rating: {
    height: 10,
    width: 10,
  },
  vote_text: {
    fontSize: 10,
  },
  vote_pop: {
    fontSize: 10,
    padding: 2,
    backgroundColor: "#61C74F",
    color: "#fff",
  },
  vote_pop_text: {
    fontSize: 10,
    marginLeft: 4,
  },
  overviewContainer: {
    // minHeight: 110
    width: "20%",
  },
  billingDetails: {
    width: "50%",
  },
  detailsFooter: {
    display: "flex",
    flexDirection: "row",
  },
  lang: {
    fontSize: 8,
    fontWeight: 700,
  },
  vote_average: {
    fontSize: 8,
    marginLeft: 4,
    fontWeight: "bold",
  },
  invoice: {
    color: "#0d6efd",
    fontSize: 12,
    marginBottom: 10,
  },
  invoiceHeader: {
    backgroundColor: "#ebebeb",
    padding: "7 7",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  invoiceStatus: {
    fontSize: 11,
    backgroundColor: "#196a0a",
    padding: "4 8",
    color: "#fff",
    borderRadius: 5,
  },
  invoiceStatus2: {
    fontSize: 11,
    backgroundColor: "#f00",
    padding: "4 8",
    color: "#fff",
    borderRadius: 5,
  },
  invoiceSetting: {
    display: "flex",
    flexDirection: "row",
  },
  left: {
    width: "50%",
    fontSize: 11,
    marginBottom: 5,
  },
  right: {
    width: "50%",
    fontSize: 11,
    marginBottom: 5,
  },
  headTitle: {
    fontSize: 11,
  },
});

export function PdfDocument(props) {
  const { currency } = props;
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.topContainer}>
          <View style={styles.invoiceHeader}>
            <Text style={styles.receiptTitle}>
              Invoice #{props.data.invoiceId}{" "}
            </Text>
            {props.data.paymentSuccess && (
              <Text style={styles.invoiceStatus}>Paid</Text>
            )}
          </View>

          <View style={styles.billingContainer}>
            <View style={styles.billingDetails}>
              <Text style={styles.paidTitle}>Invoice To:</Text>
              <Text style={styles.emailTitle}>
                {props.data.userDetails?.company || ""}
              </Text>
              <Text style={styles.emailTitle}>{`${
                props.data.userDetails?.firstName || ""
              } ${props.data.userDetails?.lastName || ""}`}</Text>
              <Text style={styles.emailTitle}>
                {props.data.userDetails?.billingAddress || ""}
              </Text>
              <Text style={styles.emailTitle}>{`${
                props.data.userDetails?.city + "," || ""
              } ${props.data.userDetails?.state || ""}`}</Text>
              <Text style={styles.emailTitle}>{`${
                props.data.userDetails?.country || ""
              } - ${props.data.userDetails?.postal || ""}`}</Text>
              <Text style={styles.emailTitle}>
                {props.data.userDetails?.taxId
                  ? "Tax ID - " + props.data.userDetails?.taxId
                  : ""}
              </Text>
              <Text style={styles.emailTitle}>
                {props.data.userDetails?.phone
                  ? "Phone Number - " + props.data.userDetails?.phone
                  : ""}
              </Text>
            </View>

            <View style={styles.billingDetails}>
              <Text style={styles.paidTitle}>Invoice From:</Text>
              <Text style={styles.emailTitle}>
                {props.data.invoiceFrom?.companyName || ""}
              </Text>
              <Text style={styles.emailTitle}>
                {props.data.invoiceFrom?.address || ""}
              </Text>
              <Text style={styles.emailTitle}>{`${
                props.data.invoiceFrom?.city + "," || ""
              } ${props.data.invoiceFrom?.homeProvince + "" || ""} ${
                props.data.invoiceFrom?.homeCountry || ""
              } - ${props.data.invoiceFrom?.postalCode || ""}`}</Text>
              <Text style={styles.emailTitle}>
                {props.data.invoiceFrom?.taxId
                  ? "Tax ID - " + props.data.invoiceFrom?.taxId
                  : ""}
              </Text>
              <Text style={styles.emailTitle}>
                {props.data.invoiceFrom?.phone
                  ? "Phone Number - " + props.data.invoiceFrom?.phone
                  : ""}
              </Text>
            </View>

            <View style={styles.billingDetails}>
              <View style={styles.invoiceSetting}>
                <Text style={styles.left}>Number</Text>
                <Text style={styles.right}>{props.data.invoiceId}</Text>
              </View>

              <View style={styles.invoiceSetting}>
                <Text style={styles.left}>Unique ID</Text>
                <Text style={styles.right}>#{props.data.orderId}</Text>
              </View>

              <View style={styles.invoiceSetting}>
                <Text style={styles.left}>Issued</Text>
                <Text style={styles.right}>
                  {monthNameAndDateFormat(
                    new Date(props.data.createdDate),
                    true
                  )}
                </Text>
              </View>

              <View style={styles.invoiceSetting}>
                <Text style={styles.left}>Due</Text>
                <Text style={styles.right}>
                  {monthNameAndDateFormat(
                    new Date(props.data.createdDate),
                    true
                  )}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.movieContainer}>
            <View style={styles.overviewContainer}>
              <Text style={styles.headTitle}>ITEM NAME</Text>
            </View>

            <View style={styles.overviewContainer}>
              <Text style={styles.headTitle}>PRICE</Text>
            </View>

            <View style={styles.overviewContainer}>
              <Text style={styles.headTitle}>QUANTITY</Text>
            </View>

            <View style={styles.overviewContainer}>
              <Text style={styles.headTitle}>ITEM TOTAL</Text>
            </View>
          </View>

          {props.data &&
            props.data.product &&
            props.data.product.map((item, index) => {
              return (
                <View key={index} style={styles.movieContainer}>
                  {/* <View style={styles.movieDetails}>
                      <Text style={styles.movieTitle}>Item Name</Text>
                    </View> */}
                  <View style={styles.overviewContainer}>
                    <Text style={styles.headTitle}>{item.id.name}</Text>
                  </View>

                  {/* <View style={styles.overviewContainer}>
                        <Text style={styles.movieOverview}>$0.00</Text>
                    </View> */}

                  {item.id.originalPrice ? (
                    <>
                      {/* <td>
                            <del>${item.id.originalPrice}</del>
                            <td>${item.id.price}</td> */}
                      <View style={styles.overviewContainer}>
                        <Text style={styles.headTitle}>
                          {currency?.symbol ? currency?.symbol : "$"}
                          {item.id.price}
                        </Text>
                      </View>
                      {/* </td> */}
                    </>
                  ) : (
                    <>
                      <View style={styles.overviewContainer}>
                        <Text style={styles.headTitle}>
                          {currency?.symbol ? currency?.symbol : "$"}
                          {item.id.price}
                        </Text>
                      </View>
                    </>
                  )}

                  <View style={styles.overviewContainer}>
                    <Text style={styles.headTitle}>x{item.quantity}</Text>
                  </View>

                  <View style={styles.overviewContainer}>
                    <Text style={styles.headTitle}>
                      {currency?.symbol ? currency?.symbol : "$"}
                      {item.quantity * item.id.price}
                    </Text>
                  </View>
                </View>
              );
            })}

          {/* <View key={index} style={styles.movieContainer}>
                    
                    <View style={styles.movieDetails}>
                      <Text style={styles.movieTitle}>Item Name</Text>
                    </View>
                    <View style={styles.movieDetails}>
                      <Text style={styles.movieTitle}>Talent Acquisition</Text>
                    </View>

                    <View style={styles.overviewContainer}>
                        <Text style={styles.movieOverview}>$0.00</Text>
                    </View>

                    <View style={styles.overviewContainer}>
                        <Text style={styles.movieOverview}>X1</Text>
                    </View>

                    <View style={styles.overviewContainer}>
                        <Text style={styles.movieOverview}>$0.00</Text>
                    </View>
                  </View> */}

          <View style={styles.movieContainer}>
            <View style={styles.movieDetails}>
              {/* <Text style={styles.movieTitle}>Talent Acquisition</Text> */}
            </View>

            <View style={styles.overviewContainer}>
              {/* <Text style={styles.movieOverview}>$0.00</Text> */}
            </View>

            <View style={styles.overviewContainer}>
              <Text style={styles.headTitle}>Sub Total</Text>
            </View>

            <View style={styles.overviewContainer}>
              <Text style={styles.headTitle}>
                {currency?.code ? currency?.code : "USD"}{" "}
                {currency?.symbol ? currency?.symbol : "$"}
                {props.data.totalPrice}
              </Text>
            </View>
          </View>

          {props.data.isTaxPaid &&
            (props.data.appliedTaxes ? (
              props.data.appliedTaxes.map((taxesData, index) => (
                <div key={index}>
                  <View style={styles.movieContainer}>
                    <View style={styles.movieDetails}></View>
                    <View style={styles.overviewContainer}></View>
                    <View style={styles.overviewContainer}>
                      <Text style={styles.headTitle}>{taxesData.taxName}</Text>
                    </View>
                    <View style={styles.overviewContainer}>
                      <Text style={styles.headTitle}>
                        {currency?.symbol ? currency?.symbol : "$"}
                        {taxesData.taxAmount.toFixed(2)}
                      </Text>
                    </View>
                  </View>
                </div>
              ))
            ) : (
              <>
                <View style={styles.movieContainer}>
                  <View style={styles.movieDetails}></View>
                  <View style={styles.overviewContainer}></View>
                  <View style={styles.overviewContainer}>
                    <Text style={styles.headTitle}>Tax & Fees</Text>
                  </View>
                  <View style={styles.overviewContainer}>
                    <Text style={styles.headTitle}>
                      {currency?.symbol ? currency?.symbol : "$"}
                      {props.data.taxAmount.toFixed(2)}
                    </Text>
                  </View>
                </View>
              </>
            ))}
          {/* <View style={styles.movieContainer}>
            <View style={styles.movieDetails}></View>
            <View style={styles.overviewContainer}></View>
            <View style={styles.overviewContainer}>
              <Text style={styles.headTitle}>Total Paid</Text>
            </View>
            <View style={styles.overviewContainer}>
              <Text style={styles.headTitle}>
                {currency?.code ? currency?.code : "USD"}{" "}
                {currency?.symbol ? currency?.symbol : "$"}
                {parseFloat(props.data.totalPrice) +
                  parseFloat(props.data.taxAmount).toFixed(2)}
              </Text>
            </View>
          </View> */}
        </View>
      </Page>
    </Document>
  );
}
