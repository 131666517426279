import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";

import SuperAdminHeader from "./SuperAdminHeader";
import { AllContext } from "../../context";
import noData from "../../assets/img/nodata-img.svg";
import sortIcon from "../../assets/img/icons/sortIcon.svg";
import { useNavigate } from "react-router-dom";

const SuperAdminDashboard = () => {
    const navigate = useNavigate();
    const {superAdminAccessToken} = useSelector((state) => state.loginReducer);
    const context = useContext(AllContext);
    const { apiGetMethod, allApiUrl, toastMessage } = context;

    const [businesses, setBusinesses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllBusinesses();
    }, []);

    const getAllBusinesses = async () => {
        let header = {
            Authorization: `Bearer ${superAdminAccessToken}`,
        };
        setIsLoading(true);
        try {
            const response = await apiGetMethod(allApiUrl.GET_BUSINESSES, "", header);
            if (response.status === 200) {
                setIsLoading(false);
                setBusinesses(response?.data?.business || []);
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            console.log("errr", err);
            setIsLoading(false);
            toastMessage("error", err.data.message);
        }
    }

    return (
        <>
            <SuperAdminHeader />
            <div className="container">
                <div className="super-admin-wrapper mt-4">
                    <h3 className="text-center mb-5">Welcome to Opflow Admin Panel!</h3>
                    <div className="businesses-list">
                        <div className="d-flex mb-3">
                            <h4>All Businesses</h4>
                            <div className="ms-auto">
                                <button className="commonButton" onClick={() => navigate('/super-admin/manage-business')}>
                                    Manage Business
                                </button>
                            </div>
                        </div>    
                        <LoadingOverlay
                            styles={{
                                spinner: (base) => ({
                                ...base,
                                width: "50px",
                                "& svg circle": {
                                    stroke: "#007D87",
                                },
                                }),
                                overlay: (base) => ({
                                ...base,
                                background: "rgba(255, 255, 255, 0.5)",
                                }),
                            }}
                            active={isLoading}
                            spinner
                            text="Loading content..."
                        >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="table-responsive common-table mt-3 files-table rounded-1">
                                    <table className="table logs-table mb-0">
                                        <thead>
                                            <tr>
                                                <th>
                                                Business Name{" "}
                                                <span>
                                                    <img alt="sort-icon" src={sortIcon} />
                                                </span>
                                                </th>
                                                <th>
                                                Email{" "}
                                                <span>
                                                    <img alt="sort-icon" src={sortIcon} />
                                                </span>
                                                </th>
                                                <th>
                                                Sub Domain{" "}
                                                <span>
                                                    <img alt="sort-icon" src={sortIcon} />
                                                </span>
                                                </th>
                                                <th>
                                                Created On{" "}
                                                <span>
                                                    <img alt="sort-icon" src={sortIcon} />
                                                </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {businesses &&
                                                businesses.length > 0 &&
                                                businesses.map((value, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{value?.businessName}</td>
                                                        <td>{value?.businessOwnerEmail}</td>
                                                        <td>{value?.businessSubDomain}</td>
                                                        <td>{value?.createdAt}</td>
                                                    </tr>
                                                );
                                            })}
                                            {businesses && businesses.length <= 0 && (
                                                <tr>
                                                <td colSpan={7}>
                                                    <div className="noData-section">
                                                        <img alt="nodata-icon" src={noData} />
                                                        <p>No Data Found</p>
                                                    </div>
                                                </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </LoadingOverlay>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuperAdminDashboard;