import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";

const AddEditStatusModal = ({ isModalOpen, setStatusName, statusName, setStatusDescription, statusDescription, editMode, setStatusBg, statusBg, statusColors, onAddStatus, updateStatus, disabledBtn, handleCancelModal }) => {
    return (
        <Modal
            show={isModalOpen}
            onHide={handleCancelModal}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            className="add-new-taxrule-modal add-order-modals"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {editMode ? 'Edit Status' : 'Add Status'}
                </Modal.Title>
            </Modal.Header>

            <ModalBody>
                <div>
                    <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Title"
                        onChange={(e) => {
                            setStatusName(e.target.value);
                        }}
                        value={statusName}
                        name="status"
                        required
                    />
                </div>
                <br />
                <div>
                    <input
                        type="text"
                        className="form-control"
                        id="floatingInput2"
                        placeholder="Description"
                        onChange={(e) => {
                            setStatusDescription(e.target.value);
                        }}
                        value={statusDescription}
                        name="statusdes"
                        required
                    />
                </div>
                <br />
                <div>
                    <select
                        value={statusBg}
                        className="form-control"
                        onChange={(e) => setStatusBg(e.target.value)}
                    >
                        {statusColors.map((item, idx) => {
                            return (
                                <option key={idx} value={item.value}>{item.label}</option>
                            );
                        })}
                    </select>
                </div>
            </ModalBody>

            <ModalFooter>
                <Button color="secondary" className="cancel-btn" onClick={handleCancelModal}>Cancel</Button>{' '}
                <Button color="primary" className="btn btn-save" disabled={disabledBtn} onClick={editMode ? updateStatus : onAddStatus}>Save</Button>
            </ModalFooter>
        </Modal>
    )
}

export default AddEditStatusModal;