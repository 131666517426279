import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import noData from "../../../assets/img/nodata-img.svg";
import { AllContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import { formatMonthNameAndDate } from "../../../shared/functions";
import PageLoader from "../../pageLoader";
import PaginationNewUI from "../../PaginationNewUI";
import LoadingOverlay from "react-loading-overlay";
LoadingOverlay.propTypes = undefined;

const TablePayments = ({
  isPage,
  searchText,
  isLoadingSearch,
  setIsLoadingSearch,
}) => {
  const navigate = useNavigate();

  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const dispatch = useDispatch();
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const { allApiUrl, redirectToLogin, toastMessage, apiPostMethod } = context;
  const [invoiceListing, setInvoiceListing] = useState([]);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  const { currency } = useSelector(
    (state) => state.sideMenuReducer.paymentSettingData
  );

  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [initialLoading, setInitialLoading] = useState(true);

  let header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  useEffect(() => {
    if (isLoadingSearch) {
      getInvoiceListing();
    }
  }, [isLoadingSearch]);
  useEffect(() => {
    getInvoiceListing();
  }, [itemOffset, itemsPerPage]);

  const getInvoiceListing = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let data = {};
    data.page = itemOffset;
    data.limit = itemsPerPage;
    if (searchText) {
      data.searchText = searchText;
    }

    apiPostMethod(allApiUrl.DISPLAY_INVOICE_V2, data, header)
      .then((res) => {
        setPageCount(res.data.totalPage);
        setTotalRecordsCount(res.data.totalItems);
        setInvoiceListing([...res.data.invoices]);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {
          setIsLoadingSearch(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err?.data?.message);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {
          setIsLoadingSearch(false);
        }
      });
  };

  return (
    <div>
      {!!isPage && (
        <>
          <Sidebar
            activePage="payments"
            setToggleSidebar={setToggleSidebar}
            toggleSideBar={toggleSideBar}
          />
          <Header handleSidebar={handleSidebar} />
        </>
      )}
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div
          className={
            isPage
              ? "shopSection commonPadding horizontalPadding"
              : "shopSection horizontalPadding"
          }
        >
          <div className={isPage ? "commonSlidePl" : ""}>
            <div className={"container-fluid pl-240"}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="heading">
                    <h2 className="fs-4">Payments</h2>
                  </div>
                </div>
                <div className="col-lg-6">
                  <PaginationNewUI
                    itemsPerPage={itemsPerPage}
                    itemOffset={itemOffset}
                    setItemOffset={setItemOffset}
                    pageCount={pageCount}
                    totalRecordsCount={totalRecordsCount}
                    setItemsPerPage={setItemsPerPage}
                    mainClassName="col-lg-12"
                  />
                </div>
              </div>
              <LoadingOverlay
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                      stroke: "#007D87",
                    },
                  }),
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.5)",
                  }),
                }}
                active={isLoading}
                spinner
                text="Loading content..."
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive common-table mt-3 files-table rounded-1">
                      <table className="table logs-table mb-0">
                        <thead>
                          <tr>
                            <th>Invoice ID</th>
                            <th>Date</th>
                            <th>Total Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceListing.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <a
                                    onClick={() => {
                                      navigate(
                                        `/view-payment/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      );
                                      document
                                        .getElementById("searchModalCloseId")
                                        .click();
                                    }}
                                  >
                                    {item.orderId}
                                  </a>
                                </td>
                                <td>
                                  {formatMonthNameAndDate(
                                    item.createdDate,
                                    companyData.timezone,
                                    true
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  {currency?.symbol
                                    ? currency?.symbol
                                    : "$"}{" "}
                                  {item &&
                                  item.partialUpfront &&
                                  item.partialUpfront.status === true
                                    ? item.paymentDue || item.paymentDue == 0
                                      ? item.totalPrice != 0
                                        ? (
                                            item.totalPrice - item.paymentDue
                                          ).toFixed(2)
                                        : 0.0
                                      : item.totalPrice != 0
                                      ? (
                                          item.totalPrice - item.paymentCredit
                                        ).toFixed(2)
                                      : 0.0
                                    : item.totalPrice.toFixed(2)}
                                </td>
                                <td>
                                  <span
                                    className={`fs-12 fw-normal pill ${
                                      item.status == "unpaid"
                                        ? "pill-grey"
                                        : "pill-green"
                                    }`}
                                  >
                                    {item.status}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                          {!invoiceListing.length && (
                            <tr>
                              <td colSpan={4}>
                                <div className="noData-section">
                                  <img src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TablePayments;
