import React, { useContext, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AllContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import {editorApiKey} from "../../shared/constants";

const AddMessage = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [isResetToDefault, setIsResetToDefault] = useState(false);

  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { MESSAGE } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  function handleFormSubmit() {
    const data = {
      name: name,
      body: content,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    // if (name && content) {
    apiPostMethod(MESSAGE, data, header)
      .then((res) => {
        if (res.status === 201) {
          toastMessage("success", res.data.message);

          setIsLoading(false);
          navigate("/messaging");
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
    // }
    // else {
    //   setIsLoading(false);
    //   toastMessage(
    //     "error",
    //     `${name == "" ? "Name " : "Body "} cannot be empty`
    //   );
    // }
  }

  function handleEditorChange(e) {
    setIsResetToDefault(false);
    setContent(e);
  }

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-back"
                onClick={() => navigate("/messaging")}
              >
                <a href="#">
                  <i class="fa-solid fa-angle-left"></i> Saved replies{" "}
                </a>
              </div>
            </div>

            <div className="col-lg-8 mx-auto">
              <div className="setting-form">
                <h3 className="emailHeading fs-4">Add reply template</h3>
                <form className="">
                  <div className="commonForm rounded-1">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        required
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Body</label>
                      <Editor
                        required
                        apiKey={editorApiKey}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        value={content}
                        init={{
                          selector: "textarea",
                          height: 500,
                          menu: {
                            custom: {
                              title: "custom",
                              items: "basicitem nesteditem toggleitem",
                            },
                          },
                          toolbar:
                            "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                            "fullpage" +
                            "indent | blockquote | codesample | fontselect ",
                          font_formats:
                            "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                          block_formats:
                            "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                          plugins: [
                            "image",
                            "help",
                            "link",
                            "codesample",
                            "lists",
                          ],
                          branding: false,
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                          menubar: false,
                          setup: function (editor) {
                            editor.ui.registry.addSplitButton("myButton", {
                              icon: "user",
                              onAction: function () {
                              },
                              onItemAction: function (api, value) {
                                editor.insertContent(value);
                              },
                              fetch: function (callback) {
                                var items = [
                                  {
                                    type: "choiceitem",
                                    text: "Client First Name",
                                    value: "&nbsp;<em>{{user.firstName}}</em>",
                                  },
                                  {
                                    type: "choiceitem",
                                    text: "Client Last Name",
                                    value: "&nbsp;<em>{{user.lastName}}</em>",
                                  },
                                  {
                                    type: "choiceitem",
                                    text: "Sender First Name",
                                    value: "&nbsp;<em>{{sender.firstName}}</em>",
                                  },
                                  {
                                    type: "choiceitem",
                                    text: "Sender First Name",
                                    value: "&nbsp;<em>{{sender.firstName}}</em>",
                                  },
                                  {
                                    type: "choiceitem",
                                    text: "Sender Email",
                                    value: "&nbsp;<em>{{sender.email}}</em>",
                                  },
                                ];
                                callback(items);
                              },
                            });
                          },
                        }}
                        onEditorChange={(e) => handleEditorChange(e)}
                      />
                    </div>
                  </div>
                </form>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="btn-section text-end">
                      <button
                        className="btn btn-save"
                        onClick={handleFormSubmit}
                        disabled={!name || !content}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddMessage;
