import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCSVReader } from "react-papaparse";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/avatar.png";
import noData from "../../assets/img/nodata-img.svg";
import openIcon from "../../assets/img/icons/openSide.svg";
import sortIcon from "../../assets/img/icons/sortIcon.svg";
import { AllContext } from "../../context";
import {
  saveLoginUserAccessToken,
  saveLoginUserInfo,
} from "../../redux/action/login";
import PageLoader from "../../components/pageLoader";
import Progress_bar from "../../components/ProgressBar";
import { convertToUpperCase, getBusinessSubdomain, yieldToMain } from "../../shared/functions";
import LoadingOverlay from "react-loading-overlay";
import * as _ from "lodash";
import PaginationNewUI from "../../components/PaginationNewUI";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
LoadingOverlay.propTypes = undefined;

const Clients = () => {
  //useState
  const [isLoading, setIsLoading] = useState(false);
  const [multiDeleteId, setMultiDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [isImportModuleVisible, setIsImportModuleVisible] = useState(false);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const [clientsList, setClientsList] = useState([]);
  const [countStatus, setCountStatus] = useState({});
  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords

  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  //contents
  const navigate = useNavigate();
  const { CSVReader } = useCSVReader();

  const context = useContext(AllContext);
  const {
    apiPostMethod,
    apiGetMethod,
    allApiUrl,
    apiDeleteMethod,
    toastMessage,
    redirectToLogin,
  } = context;

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const dispatch = useDispatch();
  const header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };

  useEffect(() => {
    filterClients();
  }, [activeTab, itemOffset, itemsPerPage]);
  useEffect(() => {
    setItemOffset(1);
  }, [activeTab]);
  const businessId = useSelector(
    (state) => state.loginReducer.loginUserInfo.businessId
  );
  function filterClients() {
    setIsLoading(true);

    const data = {};

    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.role = activeTab;
    data.businessId = businessId;
    apiPostMethod(allApiUrl.FILTER_CLIENTS_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setClientsList([...res.data.filteredClients]);
          setCountStatus({ ...res.data.countRole });
          setTabs([...res.data.roleList]);
          setInitialLoading(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setInitialLoading(false);
        setIsLoading(false);
      });
  }
  function deleteClient(couponId) {
    setIsLoading(true);
    setDisabledBtn(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.DELETE_CLIENT, couponId, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        setIsLoading(false);
        setDisabledBtn(false);
        setIsDeleteConfirmModalOpen(false);
        filterClients();
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function deleteMultiple() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.DELETE_PROFILE_BULK, { ids: multiDeleteId }, header)
      .then((res) => {
        setMultiDeleteId([]);
        toastMessage("success", res.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function checkedAll() {
    if (clientsList.length === multiDeleteId.length) {
      setMultiDeleteId([...[]]);
    } else {
      let ids = clientsList.map((e) => {
        return e.userId?._id;
      });
      setMultiDeleteId(ids);
    }
  }

  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }
  const roleList = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );
  const { clients: clientRoles } = roleList;

  //sign client handler
  function signInClientHandler(email) {
    const data = {
      email: email,
      adminEmail: userInfo.email,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    apiPostMethod(allApiUrl.SIGN_AS_CLIENT, data, header)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          navigate("/home", {
            state: {
              showBackToClientButton: true,
            },
          });
          dispatch(saveLoginUserAccessToken(res.data.token));
          dispatch(saveLoginUserInfo({ ...res.data, showButton: true }));
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  const [crfFields, setCrfFields] = useState([]);
  const [columnArray, setColumnArray] = useState([]);
  useEffect(() => { }, [crfFields, columnArray]);

  const getColumnVal = (item, o) => {
    let content = "-";
    if (item?.crmFields) {
      const matchData = _.find(item?.crmFields, (crm) => crm.id === o.id);
      if (matchData) {
        if (
          matchData.element === "Checkboxes" ||
          matchData.element === "Dropdown"
        ) {
          content = _.join(
            _.map(
              _.filter(matchData.options, (o2) => {
                return _.includes(matchData.value, o2.value);
              }),
              (o3) => o3.text
            ),
            ", "
          );
        } else {
          content = matchData.value;
        }
      }
    }
    return content;
  };

  const onChangeCheckbox = (id, val) => {
    if (val) {
      multiDeleteId.push(id);
      setMultiDeleteId([..._.uniq(multiDeleteId)]);
    } else {
      _.remove(multiDeleteId, (o) => o === id);
      setMultiDeleteId([...multiDeleteId]);
    }
  };
  const getTabCount = (val) => {
    return countStatus[val];
  };
  const TabsList = () => {
    const tempArray = [];
    tabs.map((item, idx) => {
      tempArray.push(
        <li key={idx} className="nav-item" role="presentation">
          <button
            className={
              activeTab.toLowerCase() === item.toLowerCase()
                ? "nav-link active"
                : "nav-link"
            }
            id="pills-overdue-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-overdue"
            type="button"
            role="tab"
            aria-controls="pills-overdue"
            aria-selected="true"
            onClick={() => {
              setMultiDeleteId([...[]]);
              setActiveTab(item);
            }}
          >
            {convertToUpperCase(item)}
            <span>({getTabCount(item)})</span>
          </button>
        </li>
      );
    });
    return tempArray;
  };

  return (
    <div>
      <Sidebar
        activePage="clients"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      {isImportModuleVisible ? (
        <ImportModule csvData={csvData} />
      ) : (
        <>
          <div className="commonSlidePl">
            <div className="orders-section commonPadding dashboardTop">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-5 col-12">
                    <div className="heading position-relative">
                      <h2 className="mb-0">
                        Clients <span className="userCounter">&nbsp;</span>
                        {clientRoles.add_edit_login && (
                          <a
                            onClick={() => navigate(`/add-client`)}
                            className="btn btn-addOrder"
                          >
                            Add Client
                          </a>
                        )}
                      </h2>
                      <span
                        className="openArrowIcon"
                        onClick={() => {
                          setToggleSidebar(!toggleSideBar);
                        }}
                      >
                        <img src={openIcon} />
                      </span>
                    </div>
                  </div>

                  <PaginationNewUI
                    itemsPerPage={itemsPerPage}
                    itemOffset={itemOffset}
                    setItemOffset={setItemOffset}
                    pageCount={pageCount}
                    totalRecordsCount={totalRecordsCount}
                    setItemsPerPage={setItemsPerPage}
                  />
                </div>
              </div>
            </div>
            {!initialLoading && (
              <div className="orders-section commonPadding horizontalPadding">
                <div className="container-fluid">
                  {/* <div className="row">
                  <div className="col-lg-6">
                    <div className="heading">
                      <h2>Clients</h2>
                    </div>
                  </div>
                  {clientRoles.add_edit_login && (
                    <div className="col-lg-12">
                      <div className="section-filter">
                        <div className="dropdown">
                          <a className="add-btn"  onClick={() => {navigate('/add-client')}} href="#">
                            Add Client
                          </a>
                          {!!(crfFields.length && crfFields.filter(o => o?.alternateForm).length) && <>
                            <button
                              className="btn btn-secondary dropdown-toggle btn-info"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#customizeColumnModal"
                                >
                                  Customize columns
                                </a>
                              </li>
                            </ul>
                          </>}
                        </div>
                      </div>
                    </div>
                  )}
                </div> */}
                  <LoadingOverlay
                    styles={{
                      spinner: (base) => ({
                        ...base,
                        width: "50px",
                        "& svg circle": {
                          stroke: "#007D87",
                        },
                      }),
                      overlay: (base) => ({
                        ...base,
                        background: "rgba(255, 255, 255, 0.5)",
                      }),
                    }}
                    active={isLoading}
                    spinner
                    text="Loading content..."
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="common-tabs">
                          <ul
                            className="nav nav-pills mb-3"
                            id="pills-tab"
                            role="tablist"
                          >
                            <TabsList />
                          </ul>

                          <div className="tab-content" id="pills-tabContent">
                            <div
                              className="tab-pane fade show active"
                              id="pills-overdue"
                              role="tabpanel"
                              aria-labelledby="pills-overdue-tab"
                              tabIndex="0"
                            >
                              <div className="common-table clients-table rounded-1">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th className="ps-4 w65-px">
                                        {clientRoles._delete && (
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="item1"
                                              checked={
                                                clientsList?.length &&
                                                clientsList?.length ===
                                                multiDeleteId?.length
                                              }
                                              onChange={() => checkedAll()}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="item1"
                                            ></label>
                                          </div>
                                        )}
                                      </th>
                                      <th>
                                        Name{" "}
                                        <span>
                                          <img src={sortIcon} />
                                        </span>
                                      </th>
                                      <th>
                                        Company{" "}
                                        <span>
                                          <img src={sortIcon} />
                                        </span>
                                      </th>
                                      <th>
                                        Account Manager{" "}
                                        <span>
                                          <img src={sortIcon} />
                                        </span>
                                      </th>
                                      <th>
                                        Created on{" "}
                                        <span>
                                          <img src={sortIcon} />
                                        </span>
                                      </th>
                                      <th>
                                        Status
                                        <span>
                                          <img src={sortIcon} />
                                        </span>
                                      </th>
                                      {!!columnArray.length && (
                                        <>
                                          {columnArray.map((o) => {
                                            return <th>{o.label}</th>;
                                          })}
                                        </>
                                      )}
                                      <th>
                                        Progress Bar{" "}
                                        <span>
                                          <img src={sortIcon} />
                                        </span>
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {clientsList.map((item, idx) => {
                                      return (
                                        <tr key={idx}>
                                          <td className="ps-4 w65-px">
                                            {clientRoles._delete && (
                                              <div className="form-check">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={
                                                    "contact" + item.userId?._id
                                                  }
                                                  checked={
                                                    !!_.includes(
                                                      multiDeleteId,
                                                      item?.userId?._id
                                                    )
                                                  }
                                                  onChange={(e) => {
                                                    onChangeCheckbox(
                                                      item?.userId?._id,
                                                      e.target.checked
                                                    );
                                                  }}
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={
                                                    "contact" + item.userId?._id
                                                  }
                                                ></label>
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            <div className="clientSection">
                                              <div className="img">
                                                <img
                                                  src={
                                                    item.userId &&
                                                      item.userId.image
                                                      ? item.userId.image
                                                      : Avatar
                                                  }
                                                />
                                              </div>
                                              <div className="clientName">
                                                <p
                                                  onClick={() =>
                                                    navigate(
                                                      `/view-client/${item.userId?._id}`
                                                    )
                                                  }
                                                >
                                                  <a>
                                                    {`${item.userId?.firstName ||
                                                      ""
                                                      } ${item.userId?.lastName ||
                                                      ""
                                                      }`}
                                                  </a>
                                                </p>
                                                <p className="clientEmail">
                                                  {item.userId?.email}
                                                </p>
                                              </div>
                                            </div>
                                          </td>

                                          <td>{item.company}</td>
                                          <td>
                                            {item.accountManager
                                              ? item.accountManager.firstName +
                                              " " +
                                              item.accountManager.lastName
                                              : ""}
                                          </td>
                                          <td>{item?.updatedDate}</td>
                                          <td>
                                            <span className="pill pill-grey fw-normal fs-12">
                                              {item?.accountStatus
                                                ? item?.accountStatus?.name
                                                : item.role}
                                            </span>
                                          </td>
                                          {!!columnArray?.length && (
                                            <>
                                              {columnArray.map((o) => {
                                                return (
                                                  <td>
                                                    {getColumnVal(item, o)}
                                                  </td>
                                                );
                                              })}
                                            </>
                                          )}

                                          <td>
                                            <Progress_bar
                                              bgcolor="#46850d"
                                              progress={item?.percentage}
                                              height={10}
                                            />
                                          </td>
                                          <td>
                                            {clientRoles.add_edit_login && (
                                              <div className="dropdown">
                                                <button
                                                  className="btn btn-secondary dropdown-toggle btn-info"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                                </button>
                                                <ul className="dropdown-menu">
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() =>
                                                        navigate(
                                                          `/edit-client/${item.userId?._id}`
                                                        )
                                                      }
                                                    >
                                                      Edit
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() =>
                                                        signInClientHandler(
                                                          item.userId?.email
                                                        )
                                                      }
                                                    >
                                                      Sign in as user
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      href="#"
                                                      onClick={() =>
                                                        navigate(
                                                          `/add-invoice`,
                                                          {
                                                            state: {
                                                              selectedUser:
                                                                item.userId
                                                                  ?._id,
                                                            },
                                                          }
                                                        )
                                                      }
                                                    >
                                                      New invoice
                                                    </a>
                                                  </li>
                                                  <li>
                                                    {roleList?.ticketManagement
                                                      ?.add_delete && (
                                                        <a
                                                          className="dropdown-item"
                                                          // href="#"
                                                          onClick={() =>
                                                            navigate(
                                                              "/add-ticket",
                                                              {
                                                                state: {
                                                                  mailTo:
                                                                    item.userId,
                                                                  from: "client",
                                                                },
                                                              }
                                                            )
                                                          }
                                                        >
                                                          New ticket
                                                        </a>
                                                      )}
                                                  </li>
                                                  {clientRoles._delete && (
                                                    <li>
                                                      <a
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                          setClientId(
                                                            item.userId?._id
                                                          );
                                                          setClientName(
                                                            item.userId?.email
                                                          );
                                                          setIsDeleteConfirmModalOpen(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        Delete
                                                      </a>
                                                    </li>
                                                  )}
                                                </ul>
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                    {clientsList.length <= 0 && (
                                      <tr>
                                        <td colSpan={7}>
                                          <div className="noData-section">
                                            <img src={noData} />
                                            <p>No Data Found</p>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {!!multiDeleteId.length && (
                                <div className="table-bottom-option">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-4">
                                      <div className="input-group">
                                        <select
                                          id="selectOption"
                                          value={selectOption.target}
                                          onChange={(e) => selectOption(e)}
                                          className="form-control"
                                        >
                                          <option value="">
                                            With Selected...
                                          </option>
                                          <option value="Delete">Delete</option>
                                          {/* <option value="Email">
                                    Send Welcome Email
                                  </option> */}
                                        </select>
                                        <button
                                          className="btn btn-apply"
                                          onClick={() => {
                                            if (selectedOption == "Delete") {
                                              deleteMultiple();
                                            }
                                          }}
                                        >
                                          Apply
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {/* import modal stasts */}
      <div
        className="modal fade add-order-modals"
        id="importModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <CSVReader
              skipEmptyLines={true}
              worker={true}
              onUploadAccepted={(results) => {
                setCsvData(results.data);
              }}
            >
              {({
                getRootProps,
                acceptedFile,
                ProgressBar,
                getRemoveFileProps,
              }) => (
                <>
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Import clients
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="add-order-form">
                      <form>
                        <div className="form-group">
                          <label>Select a csv file to import</label>
                          <div className="upload-file" {...getRootProps()}>
                            <i className="fa-solid fa-file-arrow-up"></i>
                            <p>
                              <span>Upload a file</span> or drag and drop
                            </p>
                            <input className="inputfile" />
                          </div>
                          <div>{acceptedFile && acceptedFile.name}</div>
                          <ProgressBar />
                          <small>
                            Download the
                            <a
                              target="_blank"
                              href="https://docs.google.com/spreadsheets/d/1o3Rolh0mG3DXPO7hUuQoDadR0FDvV04dBHZZo4DHY9g/edit#gid=0"
                            >
                              import template here.
                            </a>
                          </small>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary close-btn"
                      data-bs-dismiss="modal"
                      {...getRemoveFileProps()}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary "
                      data-bs-dismiss="modal"
                      onClick={() => setIsImportModuleVisible(true)}
                    // onClick={() => navigate("/accounts/import")}
                    >
                      Preview
                    </button>
                  </div>
                  {/* <div>
                    <button
                      type="button"
                      {...getRootProps()}
                      // style={styles.browseFile}
                    >
                      Browse file
                    </button>
                    <div>{acceptedFile && acceptedFile.name}</div>
                    <button {...getRemoveFileProps()}>Remove</button>
                  </div>
                  <ProgressBar /> */}
                </>
              )}
            </CSVReader>
          </div>
        </div>
      </div>
      {/* import modal ends */}
      {/* customize columns starts */}
      {/*<div
        className="modal fade add-order-modals"
        id="customizeColumnModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Customize Columns
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {crfFields.map(item => {
                return <div key={item.id}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={columnArray.length && columnArray.includes(item.id)}
                    onChange={(e) => {
                      item.checked = e.target.checked;
                    }}
                  />&nbsp;
                  <label>{item.label}</label>
                </div>
              })}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary close-btn"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary "
                data-bs-dismiss="modal"
                onClick={() => { setColumnArray(_.filter(crfFields, o => o.checked)) }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>*/}

      {/* customize columns ends */}

      {/* Delete Modal */}
      {isDeleteConfirmModalOpen ? (
        <DeleteConfirmModal
          isDeleteConfirmModalOpen={isDeleteConfirmModalOpen}
          handleDelete={() => deleteClient(clientId)}
          handleCancelModal={() => setIsDeleteConfirmModalOpen(false)}
          modalContent={`Are you sure you want to delete this account along with all
          their orders and messages:${clientName}? Their invoices will
          still be preserved for accounting purposes.`}
          disabledBtn={disabledBtn}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default Clients;

const ImportModule = ({ csvData }) => {
  const context = useContext(AllContext);
  const { apiPostMethod, allApiUrl, toastMessage } = context;
  const { ADD_CLIENT } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const createdBy = useSelector(
    (state) => state.loginReducer.loginUserInfo.email
  );
  var header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };

  const array1 = csvData;
  const firstElement = array1.shift();

  var arrToObj = array1.map((x) => ({
    billingAddress: x[0],
    city: x[1],
    company: x[2],
    country: x[3],
    // createdBy: createdBy,
    email: x[4],
    firstName: x[5],
    lastName: x[6],
    password: x[7],
    phone: x[8],
    postal: x[9],
    accountStatus: x[10],
    role: "client",
    state: x[10],
    taxId: x[11],
    businessSubDomain: getBusinessSubdomain()
  }));
  const importHandler = (event) => {
    // let arrToObj = arrToObj.splice(-1);
    arrToObj.forEach((client) => addClient(client));
    // setIsLoading(true);
    event.preventDefault();
    function addClient(client) {
      apiPostMethod(ADD_CLIENT, client, header)
        .then((res) => {
          if (res.status === 201) {
            toastMessage("success", res.data.message);
            // setIsSuccessFullyCreated(true);
            // redirectHandler();
          }
        })
        .catch((err) => {
          // redirectToLogin(dispatch, navigate, err);
          // setIsLoading(false);
          // toastMessage("error", err.data.message);
        });
    }
  };
  // function redirectHandler() {
  //   if (isSuccessFullyCreated) {
  //     navigate("/clients");
  //   }
  // }
  return (
    <div className="orders-section commonPadding">
      <div className="commonSlidePl">
        <div className="container-fluid">
          <div className="row">
            <div className="">
              <table className="">
                <thead>
                  <tr>
                    <th>billingAddress</th>
                    <th>city</th>
                    <th>company</th>
                    <th>country</th>
                    <th>createdBy</th>
                    <th>email</th>
                    <th>firstName</th>
                    <th>lastName</th>
                    <th>password</th>
                    <th>phone</th>
                    <th>postal</th>
                    <th>role</th>
                    <th>state</th>
                    <th>tax</th>
                  </tr>
                </thead>
                <tbody>
                  {arrToObj.map((item) => {
                    return (
                      <tr>
                        <th>{item.billingAddress}</th>
                        <th>{item.city}</th>
                        <th>{item.company}</th>
                        <th>{item.country}</th>
                        <th>{item.createdBy}</th>
                        <th>{item.email}</th>
                        <th>{item.firstName}</th>
                        <th>{item.lastName}</th>
                        <th>{item.password}</th>
                        <th>{item.phone}</th>
                        <th>{item.postal}</th>
                        <th>{item.role}</th>
                        <th>{item.state}</th>
                        <th>{item.taxId}</th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="btn-section text-end" onClick={importHandler}>
            <button className="btn btn-save">Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};
