import React from "react";
import "bootstrap/dist/css/bootstrap.css";

const Thankyou = () => {
  return (
    <main className="wrapper">
      <div className="section-login">
        <div className="container">
          <div className="row h-80vh mx-0 align-items-center">
            <div className="col-lg-6 mx-auto">
              <div className="loginForm">
                <a className="brandName">OpFlow CRM</a>
                <h3>Thank you for choosing OpFlow CRM</h3>

                <p className="text-muted mb-3 text-center">
                  Your payment was successful, we are currently processing your
                  order.
                </p>
                <div className="text-center mb-2">
                  <div className="payment-loader"></div>
                </div>
                <p className="text-muted mb-5 text-center loaderText">
                  If you stay on this page you'll be logged in as soon as the
                  order is done processing. you will also receive an email with
                  your login details.
                </p>
                <div className="sectionalert">
                  <p>
                    <strong>You are currently in test mode.</strong> if this
                    page does not refresh automatically it means we're not
                    receiving notification from your payment processor. please
                    follow the instructions to them for <strong>PayPal</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Thankyou;
