import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";

const EditOrderStatusModal = ({ isEditOrderStatusModalOpen, handleEditOrderStatus, handleCancelModal, selectedStatusData }) => {

    const [formData, setFormData] = useState(selectedStatusData);

    return (
        <Modal
            show={isEditOrderStatusModalOpen}
            onHide={handleCancelModal}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            className="add-new-taxrule-modal add-order-modals"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Order Status
                </Modal.Title>
            </Modal.Header>

            <ModalBody>
                <div className="add-orderstatus-form">
                    <div className="form-group mb-4">
                        <label>Name</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={formData.name}
                                placeholder="Enter Order status Name"
                                onChange={(e) => setFormData((prevData) => {
                                    prevData.name = e.target.value;
                                    return { ...prevData };
                                })}
                            />
                        </div>
                    </div>
                    { formData.name !== '' ?
                        <div className="order-status mb-4">
                            <span className="badge" style={{ backgroundColor: formData.bgColor, color: formData.textColor}}>{formData.name}</span>
                        </div> : ''
                    }
                    <div className="form-group mb-4">
                        <label>Description</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                name="description"
                                value={formData.description}
                                placeholder="Enter Order status Name"
                                onChange={(e) => setFormData((prevData) => {
                                    prevData.description = e.target.value;
                                    return { ...prevData };
                                })}
                            />
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label>Pick Text Color</label>
                        <div className="input-group">
                        <input
                            type="color"
                            id="color-picker"
                            value={formData.primaryColor}
                            onChange={(e) => {
                            setFormData((prevData) => {
                                    prevData.textColor = e.target.value;
                                    return { ...prevData };
                                });
                            }}
                        />
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label>Pick Background Color</label>
                        <div className="input-group">
                        <input
                            type="color"
                            id="color-picker"
                            value={formData.primaryColor}
                            onChange={(e) => {
                            setFormData((prevData) => {
                                    prevData.bgColor = e.target.value;
                                    return { ...prevData };
                                });
                            }}
                        />
                        </div>
                    </div>
                </div>        
            </ModalBody>    
            
            <ModalFooter>
                <Button color="secondary" className="cancel-btn" onClick={handleCancelModal}>Cancel</Button>{' '}
                <Button color="primary" disabled={formData.name === '' || formData.description === ''} className="btn btn-save" onClick={() => handleEditOrderStatus(formData)}>Edit</Button>
            </ModalFooter>    
        </Modal>
    )
}

export default EditOrderStatusModal;