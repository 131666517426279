import {  useEffect, useState } from "react";
import {ReactFormBuilder} from "react-form-builder2";
import FormElementsEdit from "../../components/FormElementsEdit";

const toolbarItems = [
    {
      name: 'Single line of text',
      key: 'TextInput',
    }, {
      name: 'Multiple line of text',
      key: 'TextArea',
    }, {
      key: 'Checkboxes',
    }, {
      name: 'Dropdown Menu',
      key: 'Dropdown',
    }, /*{
      name: 'File Upload',
      key: 'FileUpload',
    }*/
];

const WorkflowCrmFields = ({ crmFieldsData, handleWorkflowCrmfieldsData }) => {
    const [fieldsData, setFieldsData] = useState(crmFieldsData);
    const onSubmitData = (data) => {
        const postData = data.task_data;
        handleWorkflowCrmfieldsData(postData);
    }

    useEffect(() => {
        setFieldsData(crmFieldsData);
    }, [crmFieldsData]);

    return (
        <div className="my-4">
            <h3>Orders Fields for workflow to add in orders form</h3>
            <p><b>The fields you set up here will be associated with workflow and can be used in order form.</b></p>
            <p>Drag and drop fields from the right column.</p>
            <div className="commonForm rounded-1">
                    <div className="crm-form-builder">
                        <ReactFormBuilder
                            key={fieldsData.length}
                            toolbarItems={toolbarItems}
                            data={fieldsData}
                            onPost={onSubmitData}
                            renderEditForm={props => <FormElementsEdit showFieldIn="Show Field In Orders" {...props} />}
                        />
                    </div>
            </div>
        </div>
    )
}

export default WorkflowCrmFields;