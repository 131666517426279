import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AllContext } from "../../context";
import PageLoader from "../../components/pageLoader";

const PublicViewCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const [initialLoading, setInitialLoading] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);

  const {
    allApiUrl,
    toastMessage,
    apiPostMethod,
    apiGetMethod,
    apiDeleteMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { currency } = useSelector(
    (state) => state.sideMenuReducer.paymentSettingData
  );

  const [addToCartList, setAddToCartList] = useState([]);

  useEffect(() => {
    const getCartItems = localStorage.getItem('cartItems');
    if(getCartItems) {
      const cartData = JSON.parse(getCartItems);
      setAddToCartList(cartData);
    }
  }, []);

  useEffect(() => {
    if (
      addToCartList &&
      addToCartList.length > 0
    ) {
      let total = addToCartList?.map((item) => {
        return item.price * item.quantity;
      });

      setGrandTotal(
        total.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
      );
    } else {
      setGrandTotal(0);
    }
  }, [addToCartList]);

  function checkoutRedirect() {
    navigate("/login");
  }

  async function updateQtyFunc(value, index, eventType) {
    if(eventType === 'blur') {
      const addToCartData = addToCartList.slice();
      if(value === '') {
        addToCartData[index].quantity = 1;
        setAddToCartList(addToCartData);
        localStorage.setItem('cartItems', JSON.stringify(addToCartData));
      } else {
        localStorage.setItem('cartItems', JSON.stringify(addToCartData));
      }
    } else {
      const addToCartData = addToCartList.slice();
      addToCartData[index].quantity = value;
      setAddToCartList(addToCartData);
    }
  }
  async function deleteHandler(index) {
    const addToCartData = addToCartList.slice();
    addToCartData.splice(index, 1)
    setAddToCartList(addToCartData);
    localStorage.setItem('cartItems', JSON.stringify(addToCartData));
  }

  return (
    <div>
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="commonSlidep">
          <div className="cart-modal viewCart-section p-4">
            <div className="container-fluid pl-240">
              <div className="row">
                <div className="col-xxl-12 mx-auto">
                  <div className="section-back">
                    <a
                      onClick={() => {
                        navigate("/public-shop");
                      }}
                    >
                      <i className="fa-solid fa-angle-left"></i> Shop Articles
                    </a>
                  </div>
                </div>
                <div className="col-xxl-12 mx-auto">
                  <div className="heading mt-5">
                    <h2 className="fs-4">Shopping Cart</h2>
                  </div>
                  <div className="cart-content mt-3 rounded-1">
                    {addToCartList && addToCartList?.length > 0 ? (
                      <>
                        <table className="table">
                          <tbody>
                          {addToCartList &&
                              // addToCartList.length > 0 &&
                              addToCartList?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <strong>{item?.name}</strong> <br />
                                      <p
                                        className="mb-0 mt-1"
                                        style={{
                                          color: "grey",
                                          fontSize: "0.8em",
                                        }}
                                      >
                                        {(currency?.symbol
                                          ? currency?.symbol
                                          : "$") +
                                          `${
                                            item.price}`}
                                      </p>
                                    </td>
                                    <td>
                                      <div className="text-end cartQty">
                                        <a>
                                          <i className="fa-solid fa-xmark"></i>
                                        </a>
                                        <input
                                          className="rounded-1"
                                          type="text"
                                          onBlur={(e) => {
                                            updateQtyFunc(e.target.value, index, 'blur');
                                          }}
                                          onChange={(e) => {
                                            updateQtyFunc(e.target.value, index, 'change');
                                          }}
                                          value={item.quantity}
                                        />
                                        <a
                                          style={{ color: "#201b50" }}
                                          onClick={() => {
                                            deleteHandler(index);
                                          }}
                                        >
                                          Remove
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            <tr>
                              <td></td>
                              <td>
                                <div className="text-end cartQty">
                                  <strong>Total</strong>
                                  <strong>
                                    {currency?.symbol ? currency?.symbol : "$"}{" "}
                                    {grandTotal?.toFixed(2)}
                                  </strong>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div></div>
                        <div className="modalBtns">
                          <div className="row">
                            <div
                              className="col-lg-6"
                              onClick={() => navigate("/shop")}
                            >
                              <a onClick={() => {}}>
                                Continue Shopping
                              </a>
                            </div>
                            <div className="col-lg-6">
                              {grandTotal <= 0 ? (
                                <div
                                  className="text-end text-danger"
                                  style={{ fontSize: "14px" }}
                                >
                                  {`Minimum cart total should be greater than ${
                                    currency?.symbol ? currency?.symbol : "$"
                                  } 1`}
                                </div>
                              ) : (
                                <div className="text-end">
                                  <button
                                    // disabled={checkOutButtonDisable}
                                    className="btn btn-primary"
                                    // onClick={checkoutHandler}
                                    onClick={checkoutRedirect}
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#modalConfirmation"
                                  >
                                    Checkout
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <small>No items in your cart yet...</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PublicViewCart;
