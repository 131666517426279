import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import { countries } from "../../shared/constants";
import FormElementGenerate from "../../components/FormElementsEdit/FormElementGenerate";
import openIcon from "../../assets/img/icons/openSide.svg";
import arrowDown from "../../assets/img/greyArrow.svg";
import * as _ from "lodash";

const EditClient = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [isPurchasingForCompany, setIsPurchasingForCompany] = useState(false);
  const [company, setCompany] = useState("");
  const [taxId, setTaxId] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [isSendPasswordEmail, SetIsSendPasswordEmail] = useState(false);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [clientAccountStatusId, setClientAccountStatusId] = useState("");
  const [accountStatusList, setAccountStatusList] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [jsonData, setJsonData] = useState([]);

  const navigate = useNavigate();
  const context = useContext(AllContext);
  const { apiGetMethod, allApiUrl, toastMessage, apiPutMethod, redirectToLogin } = context;
  const { GET_SPECIFIC_CLIENT, EDIT_CLIENT, GET_ALL_ROLE } =
    allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  var header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  const dispatch = useDispatch();
  var specificClientId = window.location.pathname.split("/")[2];
  useEffect(() => {
    getSpecificClientDataHandler();
    getAllAccountStatus();
  }, []);
  function getAllAccountStatus() {
    // setIsLoading(true);
    apiGetMethod(allApiUrl.GET_ALL_ACCOUNT_STATUS, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAccountStatusList(res.data[0].status);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function getSpecificClientDataHandler() {
    setIsLoading(true);
    apiGetMethod(`${GET_SPECIFIC_CLIENT}/${specificClientId}`, "", header)
      .then((res) => {
        if (res.status === 200) {
          setIsPurchasingForCompany(
            !!(res.data.user[0].company || res.data.user[0].taxId)
          );
          getSpecificTeamMemberData(res.data.user[0]);
          setJsonData(res.data.user[0].crmFields);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err)
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data);
      });
  }

  function getSpecificTeamMemberData(values) {
    setEmail(values.userId.email);
    setFirstName(values.userId.firstName);
    setLastName(values.userId.lastName);
    setBillingAddress(values.billingAddress);
    setCity(values.city);
    setCountry(values.country);
    setState(values.state);
    setPostal(values.postal);
    setCompany(values.company);
    setTaxId(values.taxId);
    setPhone(values.phone);
    setClientAccountStatusId(values.accountStatusId);
  }
  const editEmailHandler = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const crmFields = jsonData;
    const data = {
      email: email ? email : "",
      firstName: firstName ? firstName : "",
      lastName: lastName ? lastName : "",
      billingAddress: billingAddress ? billingAddress : "",
      city: city ? city : "",
      country: country ? country : "",
      state: state ? state : "",
      postal: postal ? postal : "",
      company: company ? company : "",
      taxId: taxId ? taxId : "",
      phone: phone ? phone : "",
      password: password ? password : "",
      resetPassword: isSendPasswordEmail,
      accountStatusId: clientAccountStatusId ? clientAccountStatusId : "",
      crmFields,
    };
    apiPutMethod(`${EDIT_CLIENT}/${specificClientId}`, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        navigate("/clients");
        getSpecificClientDataHandler();
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  return (
    <div>
      <Sidebar
        activePage="clients"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <div className="commonSlidePl">

        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">Clients <span className="userCounter">&nbsp;</span></h2>
                  <span className="openArrowIcon" onClick={() => { setToggleSidebar(!toggleSideBar) }}>
                    <img src={openIcon} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="setting-form-section pt-3 horizontalPadding">
          <div className="container-fluid ">
            <form className="p-4" onSubmit={editEmailHandler}>
              <div className="row">
                <div className="col-xxl-9 col-md-12">
                  <div className="setting-form formOuter">

                    <div className="commonForm rounded-3">
                      <h3 className="fs-4 m-0 mb-4 p-3">Edit account   </h3>
                      <div className="px-4">


                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                className="form-control"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>
                                First Name <small>Optional</small>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                // required
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>
                                Last Name <small>Optional</small>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                // required
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>Billing address</label>
                              <input
                                type="text"
                                className="form-control"
                                // required
                                onChange={(e) => setBillingAddress(e.target.value)}
                                value={billingAddress}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>City</label>
                              <input
                                type="text"
                                className="form-control"
                                // required
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>Country</label>
                              <select
                                className="form-control"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                              >
                                {countries.map((item) => {
                                  return (
                                    <option key={item.name} value={item.name}>{item.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>State / Province / Region</label>
                              <input
                                type="text"
                                className="form-control"
                                // required
                                onChange={(e) => setState(e.target.value)}
                                value={state}
                              />
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>Postal / Zip Code</label>
                              <input
                                type="text"
                                className="form-control"
                                // required
                                onChange={(e) => setPostal(e.target.value)}
                                value={postal}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group mb-0">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="purchasingcompany"
                                  onClick={() => {
                                    setIsPurchasingForCompany(
                                      !isPurchasingForCompany
                                    );
                                  }}
                                  checked={isPurchasingForCompany}
                                  readOnly
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                  htmlFor="purchasingcompany"
                                >
                                  I'm purchasing for a company
                                </label>
                              </div>
                            </div>
                          </div>

                          {isPurchasingForCompany && (
                            <>
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setCompany(e.target.value)}
                                    value={company}
                                  />
                                  <small>Company</small>
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setTaxId(e.target.value)}
                                    value={taxId}
                                  />
                                  <small>Tax ID</small>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="commonForm mt-5 rounded-3 p-4">
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>
                                Phone <small>Optional</small>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                // required
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            {/* <select
                          className="form-control"
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          {countries.map((item) => {
                            return (
                              <option value={item.name}>{item.name}</option>
                            );
                          })}
                        </select> */}
                            <div className="form-group">
                              <label>Status</label>
                              <select
                                className="form-control"
                                value={clientAccountStatusId}
                                onChange={(e) => setClientAccountStatusId(e.target.value)}
                              >
                                {accountStatusList.map((item) => {
                                  return (
                                    <option key={item._id} value={item._id}>{item.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group mb-0">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="changepassword"
                                  onClick={() => {
                                    setIsChangePasswordVisible(
                                      !isChangePasswordVisible
                                    );
                                  }}
                                  checked={isChangePasswordVisible}
                                  readOnly
                                />
                                {/* {
                              isChangePasswordVisible &&
                            } */}
                                <label
                                  className="form-check-label checkbox-label d-block"
                                  htmlFor="changepassword"
                                >
                                  Change password
                                </label>
                                {isChangePasswordVisible && (
                                  <input
                                    className="form-control mt-2"
                                    type="password"
                                    placeholder="New Password"
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group mb-1">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="reserpassword"
                                  onClick={() => {
                                    SetIsSendPasswordEmail(!isSendPasswordEmail);
                                  }}
                                  checked={isSendPasswordEmail}
                                  readOnly
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                  htmlFor="reserpassword"
                                >
                                  Reset password and send welcome email
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Account balance</label>
                          <div className="input-group">
                            <div className="input-group-text">$</div>
                            <input type="text" className="form-control" />
                          </div>
                          <small>
                            Balance can be used to buy your services.
                          </small>
                        </div>
                      </div> */}
                        </div>


                      </div>

                      <FormElementGenerate jsonData={jsonData} setJsonData={setJsonData} showCardUI={true} />
                      <div className="btn-section text-end">

                      </div>

                    </div >
                  </div >


                </div >
                <div className="col-xxl-3 col-md-12 mx-auto">
                  <div className="commonBtn-section text-end">
                    <button type="button" className="btn btn-cancel" onClick={() => navigate("/clients")}>Cancel</button>
                    <button type="submit" className="btn btn-save">Save Changes</button>
                  </div>

                </div>
              </div>
            </form >
          </div >
        </div >
      </div >
    </div >
  );
};
export default EditClient;
