import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import validator from "validator";
import { useNavigate } from "react-router-dom";

import SuperAdminHeader from "./SuperAdminHeader";
import { AllContext } from "../../context";

const ManageBusiness = () => {
    const navigate = useNavigate();
    const {superAdminAccessToken} = useSelector((state) => state.loginReducer);
    const context = useContext(AllContext);
    const { apiPostMethod, allApiUrl, toastMessage } = context;

    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        businessOwnerEmail: "",
        businessName: "",
        businessSubDomain: "",
        password: ""
    });

    const createBusiness = (event) => {
        event.preventDefault();
        if (
            !validator.isStrongPassword(formValues.password, {
              minLength: 8,
              minLowercase: 1,
              minUppercase: 1,
              minNumbers: 1,
              minSymbols: 1,
            })
        ) {
            toastMessage("error", "Please choose a stronger password");
            return false;
        }
        if (!validator.isEmail(formValues.businessOwnerEmail)) {
            toastMessage("error", "Please enter valid email");
            return false;
        }

        let header = {
          Authorization: `Bearer ${superAdminAccessToken}`,
        };
        apiPostMethod(allApiUrl.CREATE_BUSINESS, formValues, header)
        .then(async (res) => {
            if (res.status === 200) {
                toastMessage("success", res.data.message);
                navigate("/super-admin/dashboard")
            }
        })
        .catch((err) => {
            toastMessage("error", err.data.message);
        });
    };

    const handleChangeValue = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }

    return (
        <>
            <SuperAdminHeader />

            <div className="container manage-business mt-4">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <h4>Manage Business</h4>
                        <div className="card mt-4">
                            <div className="card-body p-4">
                                <form onSubmit={createBusiness}>
                                    <div className="form-group mb-3">
                                        <label>Business Name</label>
                                        <input
                                            type="text"
                                            name="businessName"
                                            className="form-control"
                                            placeholder="Your Agency"
                                            onChange={handleChangeValue}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>Subdomain</label>
                                        <input
                                            type="text"
                                            name="businessSubDomain"
                                            className="form-control"
                                            placeholder="Enter Subdomain"
                                            onChange={handleChangeValue}
                                            required
                                        />
                                    </div>    
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    className="form-control"
                                                    placeholder="Enter First Name"
                                                    onChange={handleChangeValue}
                                                />
                                            </div>    
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    className="form-control"
                                                    placeholder="Enter Last Name"
                                                    onChange={handleChangeValue}
                                                />
                                            </div>    
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="businessOwnerEmail"
                                            className="form-control"
                                            placeholder="Enter Email"
                                            onChange={handleChangeValue}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            placeholder="Enter Password"
                                            onChange={handleChangeValue}
                                            required
                                        />
                                    </div>
                                    <div className="float-end">
                                        <button className="commonButton">
                                            Submit
                                        </button>
                                    </div>    
                                </form>
                            </div>
                        </div>
                    </div>    
                </div>    
            </div>
        </>    
    )
}

export default ManageBusiness;