import React from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveLoginUserInfo } from "../../redux/action/login";
import { useNavigate } from "react-router-dom";
import { countries } from "../../shared/constants";
import PageLoader from "../../components/pageLoader";

const Profile = () => {
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiPostMethod,
    apiPutMethod,
    apiGetMethod,
    redirectToLogin,
    toastMessage,
  } = context;

  const [isLoading, setIsLoading] = useState(false);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const dispatch = useDispatch();
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const { EDIT_USER_DETAILS } = allApiUrl;
  const [userProfileId, setUserProfileId] = useState(loginUserInfo.id);
  const [preview, setPreview] = useState([]);
  const [updateEmail, setUpdateEmail] = useState({
    email: "",
    password: "",
  });
  const [updatePassword, setUpdatePassword] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [value, setValue] = useState({
    firstName: "",
    lastName: "",
    image: preview,
    delete: false,
  });
  const [additionalProfile, setAdditionalProfile] = useState({
    billingAddress: "",
    country: "",
    state: "",
    city: "",
    postal: "",
    company: "",
    taxId: "",
    phone: "",
    timezone: "",
  });
  const [role, setRole] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  useEffect(() => {
    if (userProfileId) getUserDetails(loginUserInfo.id);
  }, [userProfileId]);

  useEffect(() => { }, [value]);
  function getUserDetails(userProfileId) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(EDIT_USER_DETAILS, userProfileId, header)
      .then((res) => {
        if (res.data.user.length > 0) {
          setUpdateEmail((prevData) => {
            return { ...prevData, email: res.data.user[0].userId.email };
          });
          setValue(res.data.user[0].userId);
          setAdditionalProfile(res.data.user[0]);
          setRole(res.data.user[0].userId.group);
          setInitialLoading(false);
        }
        setInitialLoading(false);
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        setInitialLoading(false);
      });
  }
  const navigate = useNavigate();

  const { EMAIL_PROFILE, CHANGE_PASSWORD, UPDATE_PROFILE } = allApiUrl;

  const editEmail = () => {
    let status = String(updateEmail.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (status !== null) {
      setIsLoading(true);
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiPostMethod(EMAIL_PROFILE, updateEmail, header)
        .then((res) => {
          if (res.status === 200) {
            toastMessage("success", res.data.message);
            setIsLoading(false);
            setUpdateEmail({
              email: "",
              password: "",
            });
            window.location.reload();
          }
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    } else {
      toastMessage("error", "please enter valid email");
    }
  };

  const changePassword = () => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(CHANGE_PASSWORD, updatePassword, header)
      .then((res) => {
        if (res.status === 200) {
          toastMessage("success", res.data.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const updateProfile = async (e) => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
      "Content-Type": "multipart/form-data",
    };
    if (role == 2) {
      if (!additionalProfile.billingAddress) {
        toastMessage("error", "Please Fill Billing Address");
      } else if (!additionalProfile.city) {
        toastMessage("error", "Please Fill City");
      } else if (!additionalProfile.country) {
        toastMessage("error", "Please Fill Country");
      } else if (!additionalProfile.state) {
        toastMessage("error", "Please Fill State");
      } else if (!additionalProfile.postal) {
        toastMessage("error", "Please Fill Postal Code");
      } else {
        const formData = new FormData();
        formData.append("file", value.image);
        formData.append("firstName", value.firstName);
        formData.append("lastName", value.lastName);
        formData.append("billingAddress", additionalProfile.billingAddress);
        formData.append("city", additionalProfile.city);
        formData.append("country", additionalProfile.country);
        formData.append("state", additionalProfile.state);
        formData.append("postal", additionalProfile.postal);
        formData.append("company", additionalProfile.company);
        formData.append("taxId", additionalProfile.taxId);
        formData.append("phone", additionalProfile.phone);
        setIsLoading(true);
        apiPutMethod(UPDATE_PROFILE, formData, header)
          .then((res) => {
            if (res.status === 200) {
              toastMessage("success", res.data.message);
              setIsLoading(false);
              if (res.data.image) {
                value.image = res.data.image;
                loginUserInfo.image = res.data.image;
                loginUserInfo.firstName = value.firstName;
                loginUserInfo.lastName = value.lastName;
              }
              dispatch(
                saveLoginUserInfo({
                  ...loginUserInfo,
                })
              );
              setUserProfileId();
              navigate("/profile");
            }
          })
          .catch((err) => {
            redirectToLogin(dispatch, navigate, err);
            setIsLoading(false);
            toastMessage("error", err.data.message);
          });
      }
    } else {
      const formData = new FormData();
      formData.append("file", value.image);
      formData.append("firstName", value.firstName);
      formData.append("lastName", value.lastName);
      formData.append("billingAddress", additionalProfile.billingAddress);
      formData.append("city", additionalProfile.city);
      formData.append("country", additionalProfile.country);
      formData.append("state", additionalProfile.state);
      formData.append("postal", additionalProfile.postal);
      formData.append("company", additionalProfile.company);
      formData.append("taxId", additionalProfile.taxId);
      formData.append("phone", additionalProfile.phone);
      setIsLoading(true);
      apiPutMethod(UPDATE_PROFILE, formData, header)
        .then((res) => {
          if (res.status === 200) {
            toastMessage("success", res.data.message);
            setIsLoading(false);
            if (res.data.image) {
              value.image = res.data.image;
              loginUserInfo.image = res.data.image;
              loginUserInfo.firstName = value.firstName;
              loginUserInfo.lastName = value.lastName;
            }
            dispatch(
              saveLoginUserInfo({
                ...loginUserInfo,
              })
            );
            setUserProfileId();
            navigate("/profile");
          }
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    }
  };

  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  return (
    <div>
      <Sidebar
        activePage="profile"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="setting-form-section pt-3">
          <div className="commonSlidePl">
            <div className="container-fluid ">
              <div className="row">
                {/* <div className="col-lg-12">
              <div
                className="section-back"
                onClick={() => navigate("/clients")}
              >
                <a href="#">
                  <i className="fa-solid fa-chevron-left"></i> Clients
                </a>
              </div>
            </div> */}

                <div className="col-xxl-6 col-lg-8 mx-auto">
                  <div className="setting-form">
                    <h3 className="fs-4">Profile</h3>
                    <div
                    // onSubmit={(e) => {
                    //   e.preventDefault();
                    //   // updateProfile();
                    // }}
                    >
                      <div className="commonForm rounded-1">
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={value.firstName}
                                onChange={(e) =>
                                  setValue((prevData) => {
                                    return {
                                      ...prevData,
                                      firstName: e.target.value,
                                    };
                                  })
                                }
                                required
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={value.lastName}
                                onChange={(e) =>
                                  setValue((prevData) => {
                                    return {
                                      ...prevData,
                                      lastName: e.target.value,
                                    };
                                  })
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                value={value.email}
                                className="form-control"
                                required
                                data-bs-toggle="modal"
                                data-bs-target="#changeEmailModal"
                                readOnly
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group profilePassword">
                              <label>Password</label>
                              <input
                                type="password"
                                className="form-control"
                                // required
                                data-bs-toggle="modal"
                              />
                              <span
                                className="groupIcon"
                                data-bs-toggle="modal"
                                data-bs-target="#changePasswordModal"
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-7 col-md-6">
                            <div className="form-group profilePhotoSection mb-0">
                              <label>Profile Photo</label>
                              <div className="d-flex align-items-center">
                                <div className="preview mt-2 mb-0">
                                  {preview.length > 0 ? (
                                    <>
                                      <img src={preview} />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <button className="btn btn-default">
                                  Upload Image{" "}
                                  <input
                                    type="file"
                                    onChange={(e) => {
                                      if (e) {
                                        const file = e.target.files;
                                        if (file) {
                                          setValue((prevData) => ({
                                            ...prevData,
                                            image: file[0],
                                          }));
                                        }
                                        setPreview(URL.createObjectURL(file[0]));
                                      }
                                    }}
                                  />
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-default"
                                  onClick={(e) => {
                                    setPreview([]);
                                    setValue((prevData) => ({
                                      ...prevData,
                                      delete: true,
                                    }));
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {role === 2 ? (
                        <div className="commonForm mt-5 rounded-1">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>Billing Address</label>
                                <textarea
                                  className="form-control"
                                  value={additionalProfile.billingAddress}
                                  onChange={(e) =>
                                    setAdditionalProfile((prevData) => {
                                      return {
                                        ...prevData,
                                        billingAddress: e.target.value,
                                      };
                                    })
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>Country</label>
                                <select
                                  className="form-control"
                                  value={additionalProfile.country}
                                  onChange={(e) =>
                                    setAdditionalProfile((prevData) => {
                                      return {
                                        ...prevData,
                                        country: e.target.value,
                                      };
                                    })
                                  }
                                >
                                  <option value="">
                                    Select Country
                                  </option>
                                  {countries.map((val, idx) => {
                                    return (
                                      <option key={idx} value={val.name}>{val.name}</option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>State/Province/Region</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  value={additionalProfile.state}
                                  onChange={(e) =>
                                    setAdditionalProfile((prevData) => {
                                      return {
                                        ...prevData,
                                        state: e.target.value,
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>City</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  value={additionalProfile.city}
                                  onChange={(e) => {
                                    setAdditionalProfile((prevData) => {
                                      return {
                                        ...prevData,
                                        city: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>Postal/Zip Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  value={additionalProfile.postal}
                                  onChange={(e) => {
                                    setAdditionalProfile((prevData) => {
                                      return {
                                        ...prevData,
                                        postal: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>
                                  Company ID <small>Optional</small>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={additionalProfile.company}
                                  onChange={(e) => {
                                    setAdditionalProfile((prevData) => {
                                      return {
                                        ...prevData,
                                        company: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>
                                  Tax ID <small>Optional</small>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={additionalProfile.taxId}
                                  onChange={(e) => {
                                    setAdditionalProfile((prevData) => {
                                      return {
                                        ...prevData,
                                        taxId: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-0">
                                <label>
                                  Phone <small>Optional</small>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={additionalProfile.phone}
                                  onChange={(e) => {
                                    setAdditionalProfile((prevData) => {
                                      return {
                                        ...prevData,
                                        phone: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            {/* <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Timezone</label>
                            <select className="form-control">
                              <option>Select timezone</option>
                              <option>Select timezone</option>
                              <option>Select timezone</option>
                            </select>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="btn-section text-end">
                        <button className="btn btn-save" onClick={updateProfile}>
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* change email modal starts */}
      <div
        className="modal fade history-modal"
        id="changeEmailModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label>Email</label>
                        <input
                          type="email"
                          onChange={(e) =>
                            setUpdateEmail((prevData) => {
                              return {
                                ...prevData,
                                email: e.target.value,
                              };
                            })
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          onChange={(e) =>
                            setUpdateEmail((prevData) => {
                              return {
                                ...prevData,
                                password: e.target.value,
                              };
                            })
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => editEmail()}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* change email modal ends */}

      {/* change email modal starts */}
      <div
        className="modal fade history-modal"
        id="changePasswordModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label>Current Password</label>
                        <input
                          type="password"
                          onChange={(e) =>
                            setUpdatePassword((prevData) => {
                              return {
                                ...prevData,
                                currentPassword: e.target.value,
                              };
                            })
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label>New Password</label>
                        <input
                          type="password"
                          onChange={(e) =>
                            setUpdatePassword((prevData) => {
                              return {
                                ...prevData,
                                newPassword: e.target.value,
                              };
                            })
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => changePassword()}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* change email modal ends */}
    </div>
  );
};
export default Profile;
