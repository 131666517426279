import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import { useSelector, useDispatch } from "react-redux";
import { AllContext } from "../../context";
import { } from "../../api/rest";
import SettingTabs from "../../components/settings/tabs";
import TicketsFields from "../../components/settings/tickets-fields";
import openIcon from "../../assets/img/icons/openSide.svg";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import EditTicketModal from "./EditTicketModal";
import { Badge } from "react-bootstrap";

const TicketSettings = () => {
	//Constants

	const dispatch = useDispatch();
	const loginUserAccessToken = useSelector(
		(state) => state.loginReducer.loginUserAccessToken
	);
	const header = {
		Authorization: `Bearer ${loginUserAccessToken}`,
	};
	const context = useContext(AllContext);
	const {
		toastMessage,
		apiGetMethod,
		redirectToLogin,
		apiPostMethod,
		apiDeleteMethod,
		allApiUrl,
		apiPutMethod,
	} = context;
	const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
	const navigate = useNavigate();

	//states
	const [toggleSideBar, setToggleSidebar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isTicketInputVisible, setIsTicketInputVisible] = useState(false);
	const [ticketName, setTicketName] = useState("");
	const [ticketDescription, setTicketDescription] = useState("");
	const [index, setIdx] = useState(null);
	const [initialLoading, setInitialLoading] = useState(true);
	const [ticketStatusList, setTicketStatusList] = useState([]);
	const [isDeletedClicked, setIsDeletedClicked] = useState(false);
	const [statusBg, setStatusBg] = useState("");
	const [prevTicketName, setPrevTicketName] = useState("");
	const [checked, setChecked] = useState(false);
	const [prevTicketDescription, setPrevTicketDescription] = useState("");
	const [editMode, setEditMode] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState({
		delete: false,
		edit: false,
	});
	const [disabledBtn, setDisabledBtn] = useState(false);
	const statusColors = [
		{ label: 'Select Color', value: '' },
		{ label: 'Danger', value: 'danger' },
		{ label: 'Dark', value: 'dark' },
		{ label: 'Info', value: 'info' },
		{ label: 'Light', value: 'light' },
		{ label: 'Primary', value: 'primary' },
		{ label: 'Secondary', value: 'secondary' },
		{ label: 'Success', value: 'success' },
		{ label: 'Warning', value: 'warning' },
	];
	// Functions
	function getAllTicketStatus() {
		apiGetMethod(allApiUrl.GET_ALL_TICKET_STATUS, "", header)
			.then((res) => {
				if (res.status === 200) {
					setChecked(res.data[0].rating);
					setTicketStatusList(res.data[0].status);
					setInitialLoading(false);
				}
			})
			.catch((err) => {
				redirectToLogin(dispatch, navigate, err);
				setInitialLoading(false);
				toastMessage("error", err.data.message);
			});
	}
	function updateTicketStatus(e) {
		e.preventDefault();
		setDisabledBtn(true);
		setIsTicketInputVisible(false);
		let header = {
			Authorization: `Bearer ${loginUserAccessToken}`,
		};
		if (
			ticketName !== prevTicketName ||
			ticketDescription !== prevTicketDescription
		) {
			let data = {
				currentName: prevTicketName,
				updatedName: ticketName,
				description: ticketDescription,
				bg: statusBg,

			};
			setIsLoading(true);
			apiPutMethod(allApiUrl.EDIT_TICKET_STATUS, data, header)
				.then((res) => {
					handleCancelModal();
					setDisabledBtn(false);
					if (res.status === 200 || res.status === 201) {
						setIsLoading(false);
						// setIsDeletedClicked(true);
						getAllTicketStatus();
						toastMessage("success", res.data.message);
					}
				})
				.catch((err) => {
					redirectToLogin(dispatch, navigate, err);
					setIsLoading(false);
					toastMessage("error", err.data.message);
					setDisabledBtn(false);
					handleCancelModal();
				});
		} else {
			toastMessage("error", "Same Status");
			setIsLoading(false);
			setDisabledBtn(false);
			handleCancelModal();
		}
	}

	const handleChange = (nextChecked) => {
		setChecked(nextChecked);
		const data = {
			rating: nextChecked,
		};
		let header = {
			Authorization: `Bearer ${loginUserAccessToken}`,
		};
		setIsLoading(true);
		apiPutMethod(allApiUrl.EDIT_TICKET_STATUS, data, header)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					setIsLoading(false);
					toastMessage("success", res.data.message);
				}
			})
			.catch((err) => {
				redirectToLogin(dispatch, navigate, err);
				setIsLoading(false);
				toastMessage("error", err.data.message);
			});
	};

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	useEffect(() => {
		getAllTicketStatus();
	}, []);
	const handleSidebar = () => {
		setToggleSidebar(!toggleSideBar);
	};

	function onSubmit(e) {
		e.preventDefault();
		// setTicketName("");
		setIsTicketInputVisible(false);
		setDisabledBtn(true);
		let header = {
			Authorization: `Bearer ${loginUserAccessToken}`,
		};
		let data = {
			name: capitalizeFirstLetter(ticketName.trim()),
			description: ticketDescription,
			isModified: true,
			bg: statusBg,
		};
		setIsLoading(true);
		if (ticketName) {
			apiPostMethod(allApiUrl.ADD_TICKET_STATUS, data, header)
				.then((res) => {
					handleCancelModal();
					setDisabledBtn(false);
					if (res.status === 200 || res.status === 201) {
						setIsLoading(false);
						setTicketDescription("");
						setTicketName("");
						getAllTicketStatus();
						if (res.data.message == "Name already exist") {
							toastMessage("error", res.data.message);
						} else {
							toastMessage("success", res.data.message);
						}

					}
				})
				.catch((err) => {
					redirectToLogin(dispatch, navigate, err);
					setIsLoading(false);
					toastMessage("error", err.data.message);
					handleCancelModal();
					setDisabledBtn(false);
				});
		} else {
			toastMessage("error", "Enter Something");
			setIsLoading(false);
			// handleCancelModal();
			setDisabledBtn(false);
		}
	}
	function deleteTicket() {
		setIsLoading(true);
		setDisabledBtn(true);
		let header = {
			Authorization: `Bearer ${loginUserAccessToken}`,
		};
		apiDeleteMethod(allApiUrl.DELETE_TICKET_STATUS, ticketName, header)
			.then((res) => {
				toastMessage("success", res.data.message);
				getAllTicketStatus();
				setIsLoading(false);
				handleCancelModal();
				setDisabledBtn(false);
			})
			.catch((err) => {
				redirectToLogin(dispatch, navigate, err);
				setIsLoading(false);
				toastMessage("error", err.data.message);
				handleCancelModal();
				setDisabledBtn(false);
			});
	}

	const handleCancelModal = () => {
		setIsModalOpen({
			delete: false,
			edit: false,
		});
	};

	return (
		<div>
			<Sidebar
				activePage="settings"
				setToggleSidebar={setToggleSidebar}
				toggleSideBar={toggleSideBar}
			/>
			<Header
				//isLoading={isLoading} 
				handleSidebar={handleSidebar} />
			<div className="commonSlidePl">
				<div className="orders-section commonPadding dashboardTop">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-5 col-12">
								<div className="heading position-relative">
									<h2 className="mb-0">Tickets Settings</h2>
									<span
										className="openArrowIcon"
										onClick={() => {
											setToggleSidebar(!toggleSideBar);
										}}
									>
										<img src={openIcon} />
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<SettingTabs currentTab="tickets" />
			<div className="setting-form-section mt-4 mb-3">
				<div className="commonSlidePl">
					<div className="container-fluid ">
						<div className="row">
							<div className="col-xxl-10 col-lg-10 mx-auto">
								<h3 className="mt-0">Statuses</h3>
								<div className="tab-content" id="pills-tabContent">
									<div
										className="tab-pane fade show active"
										id="pills-profile"
										role="tabpanel"
										aria-labelledby="pills-profile-tab"
										tabIndex="0"
									>
										<div className="setting-form">
											<form
												className="commonForm rounded-1"
											// onSubmit={
											//   editMode == true ? updateTicketStatus : onSubmit
											// }
											>
												{/* <div className="add-role mb-2">
								  {isTicketInputVisible ? (
									 <>
										<span className="btnDelete">
										  <i
											 className="fa-solid fa-trash"
											 onClick={() => {
												setTicketName("");
												setTicketDescription("");
												setIsTicketInputVisible(false);
											 }}
										  ></i>
										</span>
										<div className="">
										  <input
											 type="text"
											 className="form-control"
											 id="floatingInput"
											 placeholder="add status"
											 onChange={(e) => {
												setEditMode(false);
												setTicketName(e.target.value);
											 }}
											 value={ticketName}
											 name="status"
											 required
										  />
										  <input
											 type="text"
											 className="form-control"
											 id="floatingInput2"
											 placeholder="description"
											 onChange={(e) => {
												setEditMode(false);
												setTicketDescription(e.target.value);
											 }}
											 value={ticketDescription}
											 name="statusDescription"
											 required
										  />
										</div>
									 </>
								  ) : (
									 <a onClick={addTicketHandler}>+ Add Status</a>
								  )}
								</div> */}
												<div className="table-resposnive statusTable">
													<>
														{ticketStatusList.map((item, idx) => {
															return (
																<div
																	key={"statusList_" + idx}
																	className="d-flex justify-content-between"
																>
																	<div>

																		<Badge bg={item.name == "spam" ? "warning"
																			: item.name == "closed" ? "danger"
																				: item.name == "open" ? "primary"
																					: item.name == "pending" ? "info" :
																						item.bg}>
																			{capitalizeFirstLetter(item.name)}
																		</Badge>
																		<p>{item.description}</p>
																	</div>
																	{/* <div>
																	<p>{capitalizeFirstLetter(item.name)}</p>
																	 </div> */}
																	{item.isModified && (
																		<div className="dropdown">
																			<button
																				className="btn btn-secondary dropdown-toggle btn-light"
																				type="button"
																				data-bs-toggle="dropdown"
																				aria-expanded="false"
																			>
																				<i className="fa-solid fa-ellipsis-vertical"></i>
																			</button>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						onClick={() => {
																							setTicketName(item.name);
																							setTicketDescription(
																								item.description
																							);
																							setStatusBg(item?.bg || '');
																							setPrevTicketName(item.name);
																							setEditMode(true);
																							setIsModalOpen({
																								...isModalOpen,
																								edit: true,
																							});
																						}}
																					>
																						Edit
																					</a>
																				</li>

																				<li>
																					<a
																						className="dropdown-item"
																						href="#"
																						onClick={() => {
																							if (
																								userInfo.roles.name === "admin"
																							) {
																								setTicketName(item.name);
																								setEditMode(false);
																								setIsModalOpen({
																									...isModalOpen,
																									delete: true,
																								});
																							} else {
																								toastMessage(
																									"error",
																									"You dont have permission to access this resource"
																								);
																							}
																						}}
																					>
																						Delete
																					</a>
																				</li>
																			</ul>
																		</div>
																	)}
																</div>
															);
														})}
														{ticketStatusList &&
															ticketStatusList.length <= 0 && (
																<div className="d-table mx-0 my-auto">
																	<div className="noData-section">
																		<img src={noData} alt={""} />
																		<p>No Data Found</p>
																	</div>
																</div>
															)}
													</>
													{/* <table className="table ">
									 <tbody>
										{ticketStatusList.map((item, idx) => {
										  return (
											 <tr key={idx}>
												<td>
												  {((ticketName == item.name &&
													 ticketDescription ==
													 item.description) ||
													 index == idx) &&
													 !isDeletedClicked ? (
													 <div className="">
														<input
														  className="form-control"
														  value={ticketName}
														  onChange={(e) => {
															 setTicketName(e.target.value);
														  }}
														/>
														<input
														  className="form-control"
														  value={ticketDescription}
														  onChange={(e) => {
															 setTicketDescription(
																e.target.value
															 );
														  }}
														/>
													 </div>
												  ) : (
													 capitalizeFirstLetter(item.name)
												  )}
												</td>
												<td>
												  <div className="dropdown">
													 <button
														className="btn btn-secondary dropdown-toggle"
														type="button"
														data-bs-toggle="dropdown"
														aria-expanded="false"
													 >
														<i
														  className={`${item.isModified &&
															 item.isModified == true &&
															 "fa-solid fa-ellipsis-vertical"
															 }`}
														></i>
													 </button>
													 <ul className="dropdown-menu">
														<li>
														  <a
															 className="dropdown-item"
															 onClick={() => {
																setIdx(idx);
																setIsDeletedClicked(false);
																setTicketName(item.name);
																setTicketDescription(
																  item.description
																);
																setIsTicketInputVisible(
																  false
																);
																setPrevTicketName(item.name);
																setEditMode(true);
															 }}
														  >
															 Edit
														  </a>
														</li>

														<li>
														  <a
															 className="dropdown-item"
															 href="#"
															 data-bs-toggle="modal"
															 data-bs-target={`${userInfo.roles.name ===
																"admin" && "#deleteModal"
																}`}
															 onClick={() => {
																if (
																  userInfo.roles.name ===
																  "admin"
																) {
																  setIsDeletedClicked(true);
																  setTicketName(item.name);
																  setPrevTicketDescription(
																	 item.description
																  );
																  setIsTicketInputVisible(
																	 false
																  );
																  setEditMode(false);
																} else {
																  toastMessage(
																	 "error",
																	 "You dont have permission to access this resource"
																  );
																}
															 }}
														  >
															 Delete
														  </a>
														</li>
													 </ul>
												  </div>
												</td>
											 </tr>
										  );
										})}
									 </tbody>
								  </table> */}

													{/* <div className="">
									 <div className="btn-section text-end mb-0">
										<button className="btn btn-save">
										  Save Changes
										</button>
									 </div>
								  </div> */}
													<div className="add-role mb-2 d-flex justify-content-between">
														{/* <button className="btn text-primary ps-0" onClick={resetStatus}>
									 Reset Changes
								  </button> */}
														<div
															style={{ color: "#007D87" }}
															className="btn px-0"
															onClick={() => {
																setTicketName("");
																setStatusBg("");
																setTicketDescription("");
																setPrevTicketName("");
																setEditMode(false);
																setIsModalOpen({
																	...isModalOpen,
																	edit: true,
																});
															}}
														>
															+ Add Status
														</div>
													</div>
													<div className="d-flex align-items-center">
														<h3 className="me-3">Enable Rating</h3>
														<Switch
															onChange={handleChange}
															checked={checked}
															className="react-switch"
															onHandleColor="#007D87"
															handleDiameter={28}
															onColor="#007D87"
															uncheckedIcon={false}
															checkedIcon={false}
															boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
															activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
															height={18}
															width={45}
														/>
													</div>
													<p>
														(If toggle on, then it will show the rating option
														to client once Ticket status is completed)
													</p>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
							<TicketsFields></TicketsFields>
						</div>
					</div>
				</div>
			</div>
			{/* </> */}
			{/* )} */}
			{/* Delete Modal */}
			{isModalOpen.delete ? (
				<DeleteConfirmModal
					isDeleteConfirmModalOpen={isModalOpen.delete}
					handleDelete={deleteTicket}
					handleCancelModal={handleCancelModal}
					modalContent={`Are you sure you want to delete this Status: ${ticketName}`}
					disabledBtn={disabledBtn}
				/>
			) : (
				""
			)}

			{/* Add and Edit modal */}
			{isModalOpen.edit ? (
				<EditTicketModal
					isLoading={isLoading}
					isModalOpen={isModalOpen.edit}
					setTicketName={setTicketName}
					ticketName={ticketName}
					setTicketDescription={setTicketDescription}
					ticketDescription={ticketDescription}
					editMode={editMode}
					updateTicketStatus={updateTicketStatus}
					onSubmit={onSubmit}
					handleCancelModal={handleCancelModal}
					disabledBtn={disabledBtn}
					statusColors={statusColors}
					setStatusBg={setStatusBg}
					statusBg={statusBg}
				/>
			) : (
				""
			)}
		</div>
	);
};
export default TicketSettings;
