import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AllContext } from "../../context";
import {
  convertToUpperCase,
  formatMonthNameAndDate,
} from "../../shared/functions";
import PageLoader from "../../components/pageLoader";
import openIcon from "../../assets/img/icons/openSide.svg";
import sortIcon from "../../assets/img/icons/sortIcon.svg";
import LoadingOverlay from "react-loading-overlay";
import * as _ from "lodash";
import PaginationNewUI from "../../components/PaginationNewUI";
import SelectAsync from "../../components/SelectAsync";
LoadingOverlay.propTypes = undefined;

const Invoices = () => {
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const [filterCount, setFilterCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [startdateFilter, setstartDateFilter] = useState(0);
  const [enddateFilter, setendDateFilter] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { allApiUrl, toastMessage, redirectToLogin, apiPostMethod } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  const roleList = useSelector(
    (state) => state.loginReducer.loginUserInfo?.roles
  );

  const [invoiceList, setInvoiceList] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [initialLoading, setInitialLoading] = useState(true);

  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [countStatus, setCountStatus] = useState({});
  const [tabs, setTabs] = useState([]);

  const [selectedClient, setSelectedClient] = useState([] | null);
  const [selectedDatePicker, setSelectedDatePicker] = useState({} | null);

  useEffect(() => {
    filterInvoices();
  }, [activeTab, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (!filterCount && !initialLoading) {
      filterInvoices();
    }
  }, [filterCount]);

  function deleteMultiple() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.DELETE_INVOICE_BULK, { ids: bulkDeleteId }, header)
      .then((res) => {
        if (res.status === 200) {
          toastMessage("success", res.data.message);
          setBulkDeleteId([...[]]);
          filterInvoices();
        }
      })
      .catch((err) => {
        console.log("error", err);
        toastMessage("error", err.message);
      });
  }

  function updateInvoiceStatus(status) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(
      allApiUrl.UPDATE_INVOICE_STATUS_BULK,
      {
        ids: bulkDeleteId,
        status,
      },
      header
    )
      .then((res) => {
        toastMessage("success", res.data.message);
        setBulkDeleteId([...[]]);
        filterInvoices();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  useEffect(() => {
    setBulkDeleteId([]);
    setItemOffset(1);
  }, [activeTab]);
  function checkedAll() {
    if (invoiceList.length === bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = invoiceList.map((e) => {
        return e._id;
      });
      setBulkDeleteId([...ids]);
    }
  }

  const onChangeCheckbox = (id, val) => {
    if (val) {
      bulkDeleteId.push(id);
      setBulkDeleteId([..._.uniq(bulkDeleteId)]);
    } else {
      _.remove(bulkDeleteId, (o) => o === id);
      setBulkDeleteId([...bulkDeleteId]);
    }
  };
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }
  const businessId = useSelector(
    (state) => state.loginReducer.loginUserInfo.businessId
  );

  const filterInvoices = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    const data = {};
    if (selectedClient.length > 0) {
      let clientSelectedList = [...selectedClient];
      clientSelectedList.map((list, index) => {
        clientSelectedList[index] = list.value;
      });
      data.client = clientSelectedList;
    }
    if (startdateFilter > 0) data.startdateFilter = startdateFilter;
    if (enddateFilter > 0) data.enddateFilter = enddateFilter;

    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    let count = 0;
    for (let key in data) {
      count += 1;
    }
    setFilterCount(count);

    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.status = activeTab;
    data.businessId = businessId;
    apiPostMethod(allApiUrl.FILTER_INVOICE_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setInvoiceList([...res.data.filteredInvoices]);
          setCountStatus({ ...res.data.countStatus });
          setTabs(res.data.statusListNames);
          setInitialLoading(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setInitialLoading(false);
        setIsLoading(false);
      });
  };

  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const TabsList = () => {
    const tempArray = [];
    tabs.map((item, idx) => {
      tempArray.push(
        <li key={idx} className="nav-item" role="presentation">
          <button
            className={
              activeTab.toLowerCase() === item ? "nav-link active" : "nav-link"
            }
            id="pills-overdue-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-overdue"
            type="button"
            role="tab"
            aria-controls="pills-overdue"
            aria-selected="true"
            onClick={() => {
              setActiveTab(item);
            }}
          >
            {convertToUpperCase(item)}
            <span>({countStatus[item]})</span>
          </button>
        </li>
      );
    });
    return tempArray;
  };

  return (
    <div>
      <Sidebar
        activePage="invoices"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      <div className="orders-section commonPadding dashboardTop">
        <div className="commonSlidePl">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">
                    Invoices <span className="userCounter">&nbsp;</span>
                    {roleList?.invoiceManagement?.add_edit ? (
                      <a
                        onClick={() =>
                          navigate("/add-invoice", {
                            state: {
                              page: "invoice",
                            },
                          })
                        }
                        className="btn btn-addOrder"
                      >
                        Add Invoice
                      </a>
                    ) : (
                      ""
                    )}
                  </h2>
                  <span
                    className="openArrowIcon"
                    onClick={() => {
                      setToggleSidebar(!toggleSideBar);
                    }}
                  >
                    <img src={openIcon} />
                  </span>
                </div>
              </div>

              <PaginationNewUI
                itemsPerPage={itemsPerPage}
                itemOffset={itemOffset}
                setItemOffset={setItemOffset}
                pageCount={pageCount}
                totalRecordsCount={totalRecordsCount}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="commonSlidePl">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 col-12"></div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="topFilters flex-wrap">
                    <p>Filter By:</p>
                    <SelectAsync
                      isMulti={true}
                      placeholder="Any Client"
                      searchType="multiClients"
                      selectedValue={selectedClient}
                      onChange={(list) => {
                        const data = list.map((e) => {
                          return {
                            label:
                              typeof e.label === "object" && e.label !== null
                                ? e?.label?.props?.children[0]
                                : e?.label,
                            value: e.value,
                          };
                        });
                        setSelectedClient(data);
                      }}
                    />
                    <SelectAsync
                      placeholder="Any Date"
                      searchType="date"
                      selectedValue={selectedDatePicker}
                      onChange={(e) => {
                        setSelectedDatePicker({
                          label: e?.label,
                          value: e.value,
                        });
                      }}
                    />
                    {!!(selectedDatePicker && selectedDatePicker?.value) && (
                      <div className="d-flex dateBetween-filter align-items-center">
                        <div className="form-group">
                          <input
                            type="date"
                            name="startDate"
                            className="form-control date-input"
                            onChange={(event) =>
                              setstartDateFilter(
                                new Date(event.target.value).getTime()
                              )
                            }
                          />
                        </div>
                        <span className="mx-2">to</span>
                        <input
                          className="form-control date-input"
                          type="date"
                          name="endDate"
                          onChange={(event) =>
                            setendDateFilter(
                              new Date(event.target.value).getTime()
                            )
                          }
                          id=""
                        />
                      </div>
                    )}

                    <button
                      className="btn btn-clear"
                      onClick={() => {
                        setstartDateFilter(0);
                        setendDateFilter(0);
                        setSelectedClient([]);
                        setSelectedDatePicker(null);
                        setFilterCount(0);
                      }}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn-update"
                      onClick={() => filterInvoices()}
                    >
                      Apply
                    </button>
                  </div>
                </div>
                {/* <div className="col-lg-6" />
                <div className="col-lg-6">
                  <div className="section-filter">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle btn-filter"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa-solid fa-filter"></i> Filter |{" "}
                        {filterCount}
                      </button>
                      <ul className="dropdown-menu filter-drowdown">
                        <div className="filter-btns">

                        </div>


                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>

              <LoadingOverlay
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                      stroke: "#007D87",
                    },
                  }),
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.5)",
                  }),
                }}
                active={isLoading}
                spinner
                text="Loading content..."
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-tabs">
                      <ul
                        className="nav nav-pills"
                        id="pills-tab"
                        role="tablist"
                      >
                        <TabsList />
                      </ul>
                      <div
                        className="tab-content invoiceTable"
                        id="pills-tabContent"
                      >
                        <div
                          className="tab-pane fade active show"
                          id="pills-overdue"
                          role="tabpanel"
                          aria-labelledby="pills-overdue-tab"
                          tabIndex="0"
                        >
                          <div className="common-table clients-table rounded-1">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        className="selectAll form-check-input"
                                        type="checkbox"
                                        id="selectAll"
                                        checked={
                                          invoiceList.length &&
                                          invoiceList.length ===
                                          bulkDeleteId.length
                                        }
                                        onChange={() => checkedAll()}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="selectAll"
                                      ></label>
                                    </div>
                                  </th>
                                  <th>
                                    Client{" "}
                                    <span>
                                      {" "}
                                      <img src={sortIcon} />{" "}
                                    </span>
                                  </th>
                                  <th>
                                    Total ($){" "}
                                    <span>
                                      {" "}
                                      <img src={sortIcon} />{" "}
                                    </span>
                                  </th>
                                  <th>
                                    Status{" "}
                                    <span>
                                      {" "}
                                      <img src={sortIcon} />{" "}
                                    </span>
                                  </th>
                                  <th>
                                    Created Date{" "}
                                    <span>
                                      {" "}
                                      <img src={sortIcon} />{" "}
                                    </span>
                                  </th>
                                  <th>
                                    Paid Date{" "}
                                    <span>
                                      {" "}
                                      <img src={sortIcon} />{" "}
                                    </span>
                                  </th>
                                  <th>
                                    Invoice Number{" "}
                                    <span>
                                      {" "}
                                      <img src={sortIcon} />{" "}
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoiceList?.map((item, idx) => {
                                  return (
                                    <tr key={idx} style={{ cursor: "pointer" }}>
                                      <td className="ps-4 w65-px">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="item2"
                                            checked={
                                              !!_.includes(
                                                bulkDeleteId,
                                                item._id
                                              )
                                            }
                                            onChange={(e) => {
                                              onChangeCheckbox(
                                                item._id,
                                                e.target.checked
                                              );
                                            }}
                                          />
                                          <label
                                            htmlFor="item2"
                                            className="form-check-label"
                                          ></label>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="clientSection">
                                          <div
                                            className="clientName"
                                            onClick={() =>
                                              navigate(
                                                `/view-invoice/${item.orderId}`,
                                                {
                                                  state: {
                                                    _id: item._id,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            <p>
                                              <>
                                                <span
                                                  style={{
                                                    display: "block",
                                                    fontWeight: "bold",
                                                    color: "black",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  {item.userDetails?.firstName
                                                    ? `${item.userDetails
                                                      ?.firstName
                                                    } ${" "}  ${item.userDetails
                                                      ?.lastName || " "
                                                    }`
                                                    : item.userDetails?.email}
                                                </span>
                                                <small>{item.orderId}</small>
                                              </>
                                            </p>
                                          </div>
                                        </div>
                                      </td>

                                      <td
                                        onClick={() =>
                                          navigate(
                                            `/view-invoice/${item.orderId}`,
                                            {
                                              state: {
                                                _id: item._id,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        {" "}
                                        ${" "}
                                        {item &&
                                          item.partialUpfront &&
                                          item.partialUpfront.status === true
                                          ? item.paymentDue ||
                                            item.paymentDue == 0
                                            ? item.totalPrice != 0
                                              ? (
                                                item.totalPrice -
                                                item.paymentDue
                                              ).toFixed(2)
                                              : 0.0
                                            : item.totalPrice != 0
                                              ? (
                                                item.totalPrice -
                                                item.paymentCredit
                                              ).toFixed(2)
                                              : 0
                                          : item.totalPrice.toFixed(2)}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate(
                                            `/view-invoice/${item.orderId}`,
                                            {
                                              state: {
                                                _id: item._id,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        <span className="pill pill-grey fw-normal fs-12">
                                          {item.status}
                                        </span>
                                      </td>

                                      <td
                                        onClick={() =>
                                          navigate(
                                            `/view-invoice/${item.orderId}`,
                                            {
                                              state: {
                                                _id: item._id,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        {formatMonthNameAndDate(
                                          item.createdDate,
                                          companyData.timezone,
                                          true
                                        )}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate(
                                            `/view-invoice/${item.orderId}`,
                                            {
                                              state: {
                                                _id: item._id,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        {item.paidDate
                                          ? formatMonthNameAndDate(
                                            item.createdDate,
                                            companyData.timezone,
                                            true
                                          )
                                          : "--"}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate(
                                            `/view-invoice/${item.orderId}`,
                                            {
                                              state: {
                                                _id: item._id,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        {item.invoiceId}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {invoiceList.length <= 0 && (
                                  <tr>
                                    <td colSpan={7}>
                                      <div className="noData-section">
                                        <img src={noData} />
                                        <p>No Data Found</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {bulkDeleteId.length > 0 && (
                        <div className="table-bottom-option">
                          <div className="row">
                            <div className="col-lg-3 col-md-4">
                              <div className="input-group">
                                <select
                                  id="selectOption"
                                  value={selectOption.target}
                                  onChange={(e) => selectOption(e)}
                                  className="form-control"
                                >
                                  <option>With Selected...</option>
                                  <option value="paid">Mark as paid</option>
                                  <option value="unpaid">Mark as unpaid</option>
                                  {/* <option>Mark as refund</option>*/}
                                  <option value="void">Mark as void</option>
                                  <option>Delete</option>
                                </select>
                                <button
                                  className="btn btn-apply"
                                  onClick={() => {
                                    if (selectedOption === "Delete") {
                                      deleteMultiple();
                                    } else if (
                                      selectedOption === "paid" ||
                                      selectedOption === "unpaid" ||
                                      selectedOption === "void"
                                    ) {
                                      updateInvoiceStatus(selectedOption);
                                    }
                                  }}
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      )}
    </div>
    // </div>
  );
};
export default Invoices;
