import React, { useContext, useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import DummyAvatar from "../../../assets/img/dummyImg.jpeg";
import noData from "../../../assets/img/nodata-img.svg";
import { AllContext } from "../../../context";
/*import { apiDeleteMethod, apiPutMethod } from "../../../api/rest";
import openIcon from "../../assets/img/icons/openSide.svg";
import searchicon from "../../assets/img/searchIcon-black.svg";
import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";*/
import {convertToUpperCase, formatMonthNameAndDate} from "../../../shared/functions";
import ReactTooltip from "react-tooltip";
import LoadingOverlay from 'react-loading-overlay';
import PageLoader from "../../pageLoader";
import PaginationNewUI from "../../PaginationNewUI";
LoadingOverlay.propTypes = undefined;

const TableOrders = ({searchText, isLoadingSearch, setIsLoadingSearch}) => {
  const { state } = useLocation();

  const context = useContext(AllContext);
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const {
    allApiUrl,
    /*toastMessage,
    apiGetMethod,*/
    redirectToLogin,
    apiPostMethod,
    toastMessage
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);

  /*const [toggleSideBar, setToggleSidebar] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [clientListArr, setClientListArr] = useState([]);
  const [serviceListArr, setServiceListArr] = useState([]);
  const [addClientList, setAddClientList] = useState({
    client: "",
    service: "",
  });
  const [orderAddedSuccessfully, setOrderAddedSuccessfully] = useState(false);
  const [services, setServices] = useState([]);
  const [isActive, setActive] = useState(false);
  const [isAssignedActive, setAssignedActive] = useState(false);
  const [isStatusActive, setIsStatusActive] = useState(false);
  const [isServiceActive, setServiceActive] = useState(false);
  const [isTagActive, setIsTagActive] = useState(false);
  const [assignedList, setAssignedList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [allTeam, setAllTeam] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [tags, setTags] = useState([]);
  const [startdateFilter, setstartDateFilter] = useState(0);
  const [enddateFilter, setendDateFilter] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState("");
  const [serviceListArrBkup, setServiceListArrBkup] = useState([]);*/

  const [orderList, setOrderList] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [countStatus, setCountStatus] = useState({});
  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords

  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("all");

  useEffect(() => {
    if (isLoadingSearch) {
      filterOrders();
    }
  }, [isLoadingSearch])
  useEffect(() => {
    filterOrders();
  }, [state && state.orderId,
    // orderAddedSuccessfully,
    activeTab,
    itemOffset,
    itemsPerPage]);
  const filterOrders = (orderId = null) => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    // let data = getFilterData();
    let data = {};
    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.status = activeTab;
    if (searchText) { data.searchText = searchText; }

    apiPostMethod(allApiUrl.FILTER_ORDERS_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setOrderList([...res.data.filteredOrders]);
          setCountStatus({...res.data.countStatus});
          setTabs(res.data.tabList);
        }
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        // redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      });
  };

  /*useEffect(() => {
    if (!filterCount && !initialLoading) {
      filterOrders();
    }
  }, [filterCount]);
  const getFilterData = () => {
    let count = 0;
    const data = {};
    if (orderId) { data.orderId = orderId; }
    if (assignedList.length > 0) {
      let userlist = [...assignedList];
      userlist.map((list, index) => {
        userlist[index] = list.value;
      });
      data.assignedTo = userlist;
    }
    if (clientList.length > 0) {
      let clientSelectedList = [...clientList];
      clientSelectedList.map((list, index) => {
        clientSelectedList[index] = list.value;
      });
      data.client = clientSelectedList;
    }
    if (serviceList.length > 0) {
      let serviceSelectedList = [...serviceList];
      serviceSelectedList.map((list, index) => {
        serviceSelectedList[index] = list.value;
      });
      data.service = serviceSelectedList;
    }

    if (statusList.length > 0) {
      let statusSelectedList = [...statusList];
      statusSelectedList.map((list, index) => {
        statusSelectedList[index] = list.value.toLowerCase();
      });
      data.selectedStatus = statusSelectedList;
    }

    if (startdateFilter > 0) data.startdateFilter = startdateFilter;
    if (enddateFilter > 0) data.enddateFilter = enddateFilter;

    if (tagList.length > 0) {
      let userlist = [...tagList];
      userlist.map((list, index) => {
        userlist[index] = list.value;
      });
      data.tags = userlist;
    }
    for (let key in data) {
      count += 1;
    }
    setFilterCount(count);
    return data;
  }

  useEffect(() => {
    setAllClient(allTeam);
    let options = [];
    allTeam.map((item) => {
      if (item.userId) {
        options.push({
          value: item.userId._id,
          label: `${item.userId.firstName || " "} ${
            item.userId.lastName || ""
          } - ${item.userId.email}`,
        });
      }
    });
    setClientListArr(options);
  }, [allTeam]);

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const getTag = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.TAGS, "", header)
      .then((res) => {
        if (res.status === 200) {
          setTags(res.data.tags);
          setIsLoadingFilters(false);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        setIsLoadingFilters(false);
      });
  };

  function updateClient(Details) {
    if (clientList.find((row) => row.value == Details._id)) {
      let index = clientList.findIndex((row) => row.value == Details._id);
      clientList.splice(index, 1);
    } else {
      let label = "";
      if (Details.firstName && Details.lastName) {
        label = Details.firstName + " " + Details.lastName;
      } else {
        if (Details.firstName || Details.lastName) {
          if (Details.firstName) {
            label = Details.firstName;
          } else {
            label = Details.lastName;
          }
        } else {
          label = Details.email;
        }
      }
      let data = {
        label: label,
        value: Details._id,
      };
      clientList.push(data);
    }
    setClientList(clientList);
  }

  function listSelectedClient() {
    let selectedList = "";
    clientList.map((list, index) => {
      selectedList +=
        list.label + `${index !== clientList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  function listSelectedStatus() {
    let selectedList = "";
    statusList.map((list, index) => {
      selectedList +=
        list.label + `${index !== statusList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  function updateTag(Details) {
    if (tagList.find((row) => row.label.name == Details.name)) {
      let index = tagList.findIndex((row) => row.label.name == Details.name);
      tagList.splice(index, 1);
    } else {
      let label = "";
      if (Details) {
        label = Details;
      }
      let data = {
        label: label,
        value: Details.name,
      };
      tagList.push(data);
    }
    setTagList(tagList);
  }

  function updateAssignedUser(Details) {
    if (assignedList.find((row) => row.value == Details._id)) {
      let index = assignedList.findIndex((row) => row.value == Details._id);
      assignedList.splice(index, 1);
    } else {
      let label = "";
      if (Details.firstName && Details.lastName) {
        label = Details.firstName + " " + Details.lastName;
      } else {
        if (Details.firstName || Details.lastName) {
          if (Details.firstName) {
            label = Details.firstName;
          } else {
            label = Details.lastName;
          }
        } else {
          label = Details.email;
        }
      }
      let data = {
        label: label,
        value: Details._id,
      };
      assignedList.push(data);
    }
    setAssignedList(assignedList);
  }

  function updateService(Details) {
    if (serviceList.find((row) => row.value == Details._id)) {
      let index = serviceList.findIndex((row) => row.value == Details._id);
      serviceList.splice(index, 1);
    } else {
      let label = "";
      if (Details.name) {
        label = Details.name;
      }
      let data = {
        label: label,
        value: Details._id,
      };
      serviceList.push(data);
    }
    setServiceList(serviceList);
  }
  function updateStatus(Details) {
    if (statusList.find((row) => row.value === Details)) {
      let index = statusList.findIndex((row) => row.value === Details);
      statusList.splice(index, 1);
    } else {
      let label = "";
      if (Details) {
        label = Details;
      }
      let data = {
        label: label,
        value: Details,
      };
      statusList.push(data);
    }
    setStatusList(statusList);
  }

  function listSelectedAssignedUser() {
    let selectedList = "";
    assignedList.map((list, index) => {
      selectedList +=
        list.label + `${index !== assignedList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  function listSelectedTag() {
    let selectedList = "";
    tagList.map((list, index) => {
      selectedList +=
        list.label.name + `${index !== tagList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  function listSelectedService() {
    let selectedList = "";
    serviceList.map((list, index) => {
      selectedList +=
        list.label + `${index !== serviceList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  const ToggleAssignedClass = () => {
    setAssignedActive(!isAssignedActive);
    setIsTagActive(false);
    setIsStatusActive(false);
    setServiceActive(false);
    setActive(false);
  };
  const ToggleTagClass = () => {
    setAssignedActive(false);
    setIsTagActive(!isTagActive);
    setIsStatusActive(false);
    setServiceActive(false);
    setActive(false);
  };

  const ToggleStatusClass = () => {
    setAssignedActive(false);
    setIsTagActive(false);
    setIsStatusActive(!isStatusActive);
    setServiceActive(!isServiceActive);
    setActive(false);
  };
  const ToggleServiceClass = () => {
    setAssignedActive(false);
    setIsTagActive(false);
    setIsStatusActive(false);
    setServiceActive(!isServiceActive);
    setActive(false);
  };

  const ToggleClass = () => {
    setAssignedActive(false);
    setIsTagActive(false);
    setIsStatusActive(false);
    setServiceActive(false);
    setActive(!isActive);
  };

  function getAllServices() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.SERVICES, "", header)
      .then((res) => {
        setServices(res.data.data);

        let options = [];
        res.data.data.map((item) => {
          options.push({
            value: item._id,
            label: (
              <>
                {item.name || " "} {"\n"}
                <small>${item.price.toFixed(2)}</small>
              </>
            ),
            name: item.name,
          });
        });
        setServiceListArrBkup(options);
        setServiceListArr(options);
        setIsLoadingFilters(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setIsLoadingFilters(false);
      });
  }
  async function addOrderHandler() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const data = {
      userId: addClientList.client.value,
      productId: addClientList.service.value,
    };
    try {
      const response = await apiPostMethod(allApiUrl.ORDER, data, header);
      if (response.status === 200) {
        toastMessage("success", response.data.message);
        setOrderAddedSuccessfully(!orderAddedSuccessfully);
        setAddClientList({
          client: "",
          service: "",
        });
        setIsLoading(false);
        navigate(`/view-orders/${response.data.orderId}`, {
          state: {
            _id: response.data._id,
          },
        });
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [multiDeleteId, setMultiDeleteId] = useState([]);
  const [isChangeStatusVisible, setIsChangeStatusVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [orderStatus, setOrderStatus] = useState("pending");
  useEffect(() => {
    setMultiDeleteId([]);
  }, [activeTab]);

  const onChangeCheckbox = (id, val) => {
    if (val) {
      multiDeleteId.push(id);
      setMultiDeleteId([..._.uniq(multiDeleteId)]);
    } else {
      _.remove(multiDeleteId, (o) => o === id);
      setMultiDeleteId([...multiDeleteId]);
    }
  }
  function selectOption(selected) {
    if (selected.target.value === "changeStatus") {
      setIsChangeStatusVisible(true);
    } else {
      setIsChangeStatusVisible(false);
    }
    setSelectedOption(selected.target.value);
  }
  function orderStatusOption(selected) {
    setOrderStatus(selected.target.value);
  }

  function deleteMultiple() {
    setIsLoading(true);
    multiDeleteId.map((row) => {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiDeleteMethod(allApiUrl.ORDER, row, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          filterOrders();
          setIsSingleChecked(false);
          setMultiDeleteId([]);
          setIsLoading(false);
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    });
  }
  function updateMultiple(status) {
    let data = {
      status: status,
    };
    setActiveTab("all");
    multiDeleteId.map((row) => {
      if (row !== undefined) {
        updateTicket(row, data);
      }
    });
    setMultiDeleteId([]);
    setIsSingleChecked(false);
  }

  function updateTicket(id, data) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(allApiUrl.EDIT_ORDER + "/" + id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        setOrderAddedSuccessfully(!orderAddedSuccessfully);
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function checkedAll() {
    if (orderList.length === multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = orderList.map((e) => {
        return e._id;
      });
      setMultiDeleteId(ids);
    }
  }
  const getFilters = () => {
    setIsLoadingFilters(true);
    getAllServices();
    getTag();
  }

  const onClearFilters = () => {
    setClientList([]);
    setServiceList([]);
    setStatusList([]);
    setTagList([]);
    setAssignedList([]);
    setstartDateFilter(0);
    setendDateFilter(0);
    setShowDatePicker("");
    if (orderAddedSuccessfully) setOrderAddedSuccessfully(!orderAddedSuccessfully);
    setActiveTab("all");
    setFilterCount(0);
  }*/

  const getTabCount = (val) => {
    return countStatus[val];
  }
  const TabsList = () => {
    const tempArray = [];
    tabs.map((item, idx) => {
      if (getTabCount(item)) {
        tempArray.push(
          <li key={idx} className="nav-item" role="presentation">
            <button
              className={activeTab.toLowerCase() === item.toLowerCase() ? 'nav-link active' : 'nav-link'}
              id="pills-overdue-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-overdue"
              type="button"
              role="tab"
              aria-controls="pills-overdue"
              aria-selected="true"
              onClick={() => {
                // setMultiDeleteId([])
                setActiveTab(item);
              }}
            >
              <span>{getTabCount(item)}{" "}</span>{convertToUpperCase(item)}
            </button>
          </li>
        )
      }
    });
    return tempArray;
  }
  return (
    <>
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section horizontalPadding">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h2>Orders</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-filter">
                <PaginationNewUI
                  itemsPerPage={itemsPerPage}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  pageCount={pageCount}
                  totalRecordsCount={totalRecordsCount}
                  setItemsPerPage={setItemsPerPage}
                  mainClassName="col-lg-12"
                />
              </div>
            </div>
          </div>
          <LoadingOverlay
            styles={{
              spinner: (base) => ({
              ...base,
              width: "50px",
              "& svg circle": {
                  stroke: "#007D87",
              },
              }),
              overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 0.5)",
              }),
          }}
          active={isLoading}
          spinner
          text="Loading content..."
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="common-tabs orderTabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <TabsList />
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-overdue"
                      role="tabpanel"
                      aria-labelledby="pills-overdue-tab"
                      tabIndex="0"
                    >
                      <div className="common-table TableOrders rounded-1">
                        <table className="table">
                          <thead>
                          <tr>
                            {/*<th className="ps-4 w65-px">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckChecked"
                                  checked={ orderList?.length && orderList?.length === multiDeleteId?.length }
                                  onChange={() => checkedAll()}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked"
                                ></label>
                              </div>
                            </th>*/}
                            <th>Client</th>
                            <th>Assigned Team Member</th>
                            <th>Status</th>
                            <th>Added</th>
                          </tr>
                          </thead>
                          <tbody>
                          {orderList.length ? (
                            orderList.map((item, idx) => {
                              return (
                                <tr key={idx}>
                                  {/*<td className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={"order" + item._id}
                                        checked={!!(_.includes(multiDeleteId, item._id))}
                                        onChange={(e) => {
                                          setIsSingleChecked(
                                            e.target.checked
                                          );
                                          onChangeCheckbox(
                                            item._id,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={"order" + item.userId?._id}
                                      ></label>
                                    </div>
                                  </td>*/}
                                  <td>
                                    <div
                                      className="clientDetail"
                                      onClick={() => {
                                        document.getElementById('searchModalCloseId').click();
                                        navigate(
                                          `/view-orders/${item.orderId}`,
                                          {
                                            state: {
                                              _id: item._id,
                                              formType: item.inTakeForm,
                                            },
                                          }
                                        )
                                      }
                                      }
                                    >
                                      <p className="name mb-1">
                                        <a>
                                          {item.userId &&
                                          item.userId.firstName
                                            ? `${item.userId.firstName} ${
                                              item.userId?.lastName ? item.userId.lastName
                                                : ""
                                            }`
                                            : item?.userId?.email}

                                          {!!(item.message) && (
                                            <>
                                                  <span className="ms-1">
                                                    <i className="fa-solid fa-envelope"></i>
                                                    {item.message}
                                                  </span>
                                            </>
                                          )}
                                          {/* </span> */}
                                          {Object.values(item.tags).map(
                                            (tagItem, idx) => {
                                              return (
                                                <span key={idx}
                                                      className="mx-1"
                                                      style={{}}
                                                >
                                                      {tagItem}
                                                    </span>
                                              );
                                            }
                                          )}
                                        </a>
                                      </p>
                                      <p className="subject">
                                        <a href="#">{item.title} </a>
                                        {item.price && (
                                          <span>${item.price}</span>
                                        )}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="assignedImg">
                                      {item.assignedTo.map((item, idx) => {
                                        return (
                                          <div key={idx}>
                                            <img
                                              className="ms-n1"
                                              src={
                                                item.image
                                                  ? item.image
                                                  : DummyAvatar
                                              }
                                              data-tip={
                                                item.firstName +
                                                " " +
                                                item.lastName
                                              }
                                            />
                                            <ReactTooltip />
                                          </div>  
                                        );
                                      })}
                                    </div>
                                  </td>
                                  <td>
                                    <span className="pill pill-grey orderStatus fw-normal" style={{ backgroundColor: (item.orderStatus && item.orderStatus.bgColor) && item.orderStatus.bgColor, color: (item.orderStatus && item.orderStatus.textColor) && item.orderStatus.textColor }}>
                                      {(item.orderStatus && item.orderStatus.name) ? item.orderStatus.name.toUpperCase() : 'N/A'}
                                    </span>
                                  </td>
                                  <td>
                                    {formatMonthNameAndDate(
                                      item.createdDate,
                                      companyData.timezone
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className="noData-section">
                                  <img src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                          </tbody>
                        </table>
                      </div>
                      {/*{(multiDeleteId.length > 0 || isSingleChecked) && (
                        <div className="table-bottom-option">
                          <div className="row">
                            <div className="col-lg-3 col-md-4">
                              <div className="input-group">
                                <select
                                  id="selectOption"
                                  value={selectOption.target}
                                  onChange={(e) => selectOption(e)}
                                  className="form-control"
                                >
                                  <option value="">With selection</option>
                                  <option value="changeStatus">
                                    Change Status
                                  </option>
                                  <option value="Delete">Delete</option>
                                </select>

                                {isChangeStatusVisible && (
                                  <select
                                    id="orderStatusOption"
                                    value={orderStatusOption.target}
                                    onChange={(e) => orderStatusOption(e)}
                                    style={{
                                      marginLeft: "4px",
                                      marginRight: "4px",
                                    }}
                                    className="form-control"
                                  >
                                    <option value="pending">Pending</option>
                                    <option value="submitted">Submitted</option>
                                    <option value="working">Working</option>
                                    <option value="completed">Completed</option>
                                    <option value="cancelled">Cancelled</option>
                                    <option value="pending update">
                                      Pending Update
                                    </option>
                                    <option value="void">Void</option>
                                    <option value="info lacking">
                                      Info Lacking
                                    </option>
                                    <option value="preparing draft">
                                      Preparing Draft
                                    </option>
                                    <option value="draft for approval">
                                      Draft For Approval
                                    </option>

                                    <option value="editor is reviewing draft">
                                      Editor Is Reviewing Draft
                                    </option>

                                    <option value="edits are being made">
                                      Edits Are Being Made
                                    </option>

                                    <option value="edits done">
                                      Edits Done
                                    </option>

                                    <option value="draft for publication">
                                      Draft For Publication
                                    </option>

                                    <option value="needs response">
                                      Needs Response
                                    </option>
                                  </select>
                                )}
                                <button
                                  className="btn btn-apply"
                                  onClick={() => {
                                    if (selectedOption == "Delete") {
                                      deleteMultiple();
                                    } else if (
                                      selectedOption == "changeStatus"
                                    ) {
                                      updateMultiple(orderStatus);
                                      setIsChangeStatusVisible(false);
                                    }
                                  }}
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      )}
    </>
  );
};
export default TableOrders;
