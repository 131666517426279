import React, { useContext, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Sidebar from "../../widgets/sidebar";
import Select from "react-select";
import moment from "moment";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
const EditInvoice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const [serviceListArr, setServiceListArr] = useState([]);
  const [serviceListArrBkup, setServiceListArrBkup] = useState([]);
  const [multipleDiscountList, setMultipleDiscountList] = useState([
    {
      service: [],
      quantity: 1,
      id: {
        originalPrice: 0.0,
        discount: 0.0,
        name: "",
        description: "",
        price: 0,
      },
    },
  ]);
  const [duedate, setDueDate] = useState(new Date().getTime());
  const [noteToClient, setNotetoClient] = useState("");
  const [recurringInvoice, setRecurringInvoice] = useState(false);
  const [recurringInvoiceValue, setRecurringInvoiceValue] = useState(1);
  const [recurringInvoiceType, setRecurringInvoiceType] = useState("months");
  const [sendEmail, setSendEmail] = useState(false);
  const [partialUpfront, setPartialUpfront] = useState(false);
  const [partialUpfrontValue, setPartialUpfrontValue] = useState(0);
  const [clientListArr, setClientListArr] = useState([]);
  const [clientListArrBkup, setClientListArrBkup] = useState([]);
  const [addClientList, setAddClientList] = useState({
    client: "",
  });
  const [isRecurringServiceExist, setRecurringServiceExist] = useState(false);
  const [multipleDiscountCount, setMultipleDiscountCount] = useState(1);
  const {
    allApiUrl,
    toastMessage,
    redirectToLogin,
    apiGetMethod,
    apiPutMethod,
  } = context;

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { state } = useLocation();

  const [toggleSideBar, setToggleSidebar] = React.useState(false);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "grey" : "#333",
    }),
    control: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const increaseDiscount = (event, index) => {
    event.preventDefault();
    setMultipleDiscountCount(multipleDiscountCount + 1);
    setMultipleDiscountList((prevData) => {
      prevData[index + 1] = {
        service: [],
        quantity: 1,
        id: {
          _id: "",
          name: "",
          price: "",
          originalPrice: "",
          discount: 0,
          isRecurring: "",
          description: "",
        },
      };
      return [...prevData];
    });
  };

  useEffect(() => {
    serviceListArr.map((row, index) => {
      serviceListArr[index].isDisabled = false;
    });
    if (multipleDiscountList.length > 0 && serviceListArr.length > 0) {
      let discountList = [...multipleDiscountList];

      discountList.map((row) => {
        if (row && row.service) {
          let data = serviceListArr.findIndex(
            (service) => service.value == row.service.value
          );
          if (data > -1) serviceListArr[data].isDisabled = true;
        }
      });
      setServiceListArr(serviceListArr);
    }
    const recurringService = Object.values(multipleDiscountList).some(
      (item) => item.id.isRecurring === true
    );
    setRecurringServiceExist(recurringService);
  }, [multipleDiscountList, serviceListArr]);

  const decreaseDiscountCount = (event, index) => {
    event.preventDefault();
    if (multipleDiscountCount > 1 && multipleDiscountList.length > 0) {
      let multipleDiscountListCopy = [...multipleDiscountList];
      multipleDiscountListCopy.splice(index, 1);
      setMultipleDiscountList(multipleDiscountListCopy);
      setMultipleDiscountCount(multipleDiscountCount - 1);
    }
  };

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  useEffect(() => {
    getAllServicesList();
    getAllClientList();
    getInvoiceDetail();
  }, []);

  async function getInvoiceDetail() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.GET_ORDER_DETAILS + "/" + id,
        "",
        header
      );

      if (response.status === 200) {
        if (response.data.invoice.userDetails) {
          setAddClientList((PrevData) => {
            return {
              ...PrevData,
              client: {
                value: response.data.invoice.userDetails._id,
                label: (
                  <>
                    {response.data.invoice.userDetails.firstName || " "}{" "}
                    {response.data.invoice.userDetails.lastName || ""} {"\n"}{" "}
                    <small>{response.data.invoice.userDetails.email}</small>{" "}
                  </>
                ),
                firstName: response.data.invoice.userDetails.firstName,
                lastName: response.data.invoice.userDetails.lastName || " ",
                email: response.data.invoice.userDetails.email,
              },
            };
          });
        }

        setMultipleDiscountCount(response.data.invoice.product.length);
        let selectedProducts = [];
        response.data.invoice.product.map((row) => {
          selectedProducts.push({
            service: {
              value: row.id._id,
              label: (
                <>
                  {row.id.name || " "} {"\n"}{" "}
                  <small>
                    {" "}
                    {row.id.oneTimeService == true
                      ? row.id.originalPrice.toFixed(2)
                      : row.id.originalPrice.toFixed(2) +
                      "/" +
                      row.id.recurringOrder.durationTime +
                      " " +
                      row.id.recurringOrder.durationPeriod}
                  </small>{" "}
                </>
              ),
            },
            id: row.id,
            quantity: row.quantity,
          });
        });
        setMultipleDiscountList(selectedProducts);
        setPartialUpfront(response.data.invoice.partialUpfront.status);
        setPartialUpfrontValue(response.data.invoice.partialUpfront.amount);
        setRecurringInvoice(response.data.invoice.recurringInvoice.status);
        setRecurringInvoiceValue(
          response.data.invoice.recurringInvoice.durationTime
        );
        setRecurringInvoiceType(
          response.data.invoice.recurringInvoice.durationPeriod
        );
        setSendEmail(response.data.invoice.sendEmail);
        setNotetoClient(response.data.invoice.note);
        setDueDate(response.data.invoice.dueDate);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  async function getAllServicesList() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.SERVICES, "", header);
      if (response.status === 200) {
        let options = [];
        response.data.data.map((item) => {
          options.push({
            value: item._id,
            price: item.price,
            name: item.name,
            recurringOrder: item.recurringOrder,
            label: (
              <>
                {item.name || " "} {"\n"}{" "}
                <small>
                  {" "}
                  {item.oneTimeService == true
                    ? item.price.toFixed(2)
                    : item.price.toFixed(2) +
                    "/" +
                    item.recurringOrder.durationTime +
                    " " +
                    item.recurringOrder.durationPeriod}
                </small>{" "}
              </>
            ),
          });
        });
        options.unshift({
          value: "custom",
          label: "custom",
          name: "custom",
          _id: "custom",
        });
        setServiceListArrBkup(options);
        setServiceListArr(options);
        setIsLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  async function editInvoiceHandler(event) {
    event.preventDefault();
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let a = new Date();
    let dueDateNew = new Date(a.setDate(a.getDate() + 1));
    const data = {
      product: multipleDiscountList,
      note: noteToClient,
      partialUpfront: {
        status: partialUpfront,
        amount: partialUpfrontValue ? partialUpfrontValue : 0,
      },
      recurringInvoice: {
        status: recurringInvoice,
        durationTime: recurringInvoiceValue || "", //value
        durationPeriod: recurringInvoiceType || "", //type (month,year)
      },
      sendEmail,
      dueDate: duedate ? duedate : dueDateNew,
      userId: addClientList.client.value,
    };
    const amount = multipleDiscountList.reduce((acc, cv) => {
      return acc + cv.id.price;
    }, 0);
    const recurringServiceExist = Object.values(data.product).some(
      (item) => item.id.isRecurring === true
    );
    if (recurringServiceExist == true && partialUpfront == true) {
      toastMessage(
        "error",
        "Partial payments cannot be used with recurring services"
      );
    } else if (amount < partialUpfrontValue) {
      if (partialUpfront == true)
        toastMessage(
          "error",
          "Partial payment cannot be greater than actual payment"
        );
      else
        toastMessage("error", "Discount cannot be greater than actual payment");
    } else {
      if (data?.product[0]?.id?._id) {
        try {
          const response = await apiPutMethod(
            allApiUrl.EDIT_INVOICE + "/" + state._id,
            data,
            header
          );
          if (response.status === 200) {
            navigate(`/view-invoice/${id}`);
            toastMessage("success", response.data.message);
          }
        } catch (err) {
          redirectToLogin(dispatch, navigate, err);
          toastMessage("error", err.data.message);
        }
      } else {
        toastMessage("error", "Select any service");
      }
    }
  }

  async function getAllClientList() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.GET_ALL_CLIENT, "", header);
      if (response.status === 200) {
        let options = [];
        response.data.team.map((item) => {
          options.push({
            value: item.userId._id,
            label: (
              <>
                {item.userId.firstName || " "} {item.userId.lastName || ""}{" "}
                {"\n"} <small>{item.userId.email}</small>{" "}
              </>
            ),
            firstName: item.userId.firstName,
            lastName: item.userId.lastName || " ",
            email: item.userId.email,
          });
        });
        setClientListArr(options);
        setClientListArrBkup(options);
        setIsLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  function checkStatusOfDiscount() {
    let status = false;
    [...Array(multipleDiscountCount)].filter((value, index) => {
      if (
        multipleDiscountList[index]?.id?.originalPrice <
        multipleDiscountList[index]?.id?.discount
      ) {
        status = true;
      } else if (
        multipleDiscountList[index]?.id?.originalPrice < partialUpfrontValue
      ) {
        status = true;
      }
    });
    return status;
  }

  return (
    <div>
      <Sidebar
        activePage="invoices"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <div className="commonSlidePl">
        <div className="setting-form-section pt-3 horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-back">
                  <a
                    href="#"
                    onClick={() => navigate(`/view-invoice/${id}`)}
                  >
                    <i className="fa-solid fa-angle-left"></i> Invoices
                  </a>
                </div>
                <div className="col-lg-12 col-xxl-9 mx-auto">
                  <div className="setting-form invoiceForm p55-large">
                    <h3 className="fs-4 mb-4 fs-4">Edit Invoice</h3>
                    <form>
                      <div className="commonForm rounded-1">
                        <div className="row mb-5">
                          <div className="col-lg-8">
                            <div className="form-group">
                              <label>
                                <small style={{ marginLeft: "2px" }}>
                                  Optional
                                </small>
                              </label>
                              <Select
                                className="my-react-select my-react-select--compact"
                                classNamePrefix="lp-copy-sel"
                                isSearchable
                                styles={customStyles}
                                value={addClientList.client}
                                options={clientListArr}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "0.375rem",
                                  colors: {
                                    ...theme.colors,
                                    primary: "#ddd",
                                  },
                                })}
                                onInputChange={(e) => {
                                  if (e.length > 0) {
                                    let list = [];
                                    list = clientListArrBkup.filter((data) => {
                                      if (
                                        data.firstName.includes(e) ||
                                        data.lastName.includes(e) ||
                                        data.email.includes(e)
                                      ) {
                                        return data;
                                      }
                                    });
                                    setClientListArr(list);
                                  } else {
                                    setClientListArr(clientListArrBkup);
                                  }
                                }}
                                filterOption={false}
                                onChange={(e) =>
                                  setAddClientList((prev) => {
                                    return { ...prev, client: e };
                                  })
                                }
                              />
                              <small>
                                If you don't select a client here anyone can pay
                                this invoice. An account will be created for them
                                automatically.
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Date Due</label>
                              <div>
                                <ReactDatePicker
                                  id="myDate"
                                  className="form-control"
                                  onChange={(event) => {
                                    setDueDate(event.getTime());
                                  }}
                                  value={moment(duedate).format("YYYY-MM-DD")}
                                  selected={new Date(duedate)}
                                  minDate={new Date()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {[...Array(multipleDiscountCount)].map((value, index) => {
                          return (
                            <div className="row" key={index}>
                              <div className="col-lg-7 col-xxl-7">
                                <div className="form-group itemGroup">
                                  <label>Item</label>
                                  <Select
                                    className="my-react-select my-react-select--compact"
                                    classNamePrefix="lp-copy-sel"
                                    id={"select" + index}
                                    value={multipleDiscountList[index]?.service}
                                    options={serviceListArr}
                                    onInputChange={(e) => {
                                      if (e.length > 0) {
                                        let list = [];
                                        list = serviceListArrBkup.filter(
                                          (data) => {
                                            if (data.name.includes(e)) {
                                              return data;
                                            }
                                          }
                                        );
                                        setServiceListArr([...list]);
                                      } else {
                                        setServiceListArr(serviceListArrBkup);
                                      }
                                    }}
                                    filterOption={false}
                                    placeholder="All Services"
                                    onChange={(e) => {
                                      if (e.value && e.value == "custom") {
                                        setMultipleDiscountList((prevData) => {
                                          prevData[index] = {
                                            ...prevData[index],
                                            service: e,
                                            id: {
                                              ...prevData[index]?.id,
                                              _id: e.value,
                                              name: "",
                                              price: 0,
                                              originalPrice: 0,
                                              discount: 0,
                                              isRecurring:
                                                e.recurringOrder?.status,
                                            },
                                          };
                                          return [...prevData];
                                        });
                                      } else {
                                        setMultipleDiscountList((prevData) => {
                                          prevData[index] = {
                                            ...prevData[index],
                                            service: e,
                                            id: {
                                              ...prevData[index]?.id,
                                              _id: e.value,
                                              name: e.name,
                                              price: e.price,
                                              originalPrice: e.price,
                                              isRecurring:
                                                e.recurringOrder?.status,
                                              recurringOrder: e.recurringOrder,
                                              oneTimeService:
                                                !e.recurringOrder?.status,
                                            },
                                          };
                                          return [...prevData];
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="form-group">
                                  {multipleDiscountList &&
                                    multipleDiscountList[index] &&
                                    multipleDiscountList[index].id &&
                                    multipleDiscountList[index].id._id &&
                                    multipleDiscountList[index].id._id ===
                                    "custom" && (
                                      <input
                                        type="text"
                                        className="form-control mb-2"
                                        placeholder="Item Name"
                                        value={
                                          multipleDiscountList[index]?.id.name
                                        }
                                        onChange={(e) => {
                                          setMultipleDiscountList((prevData) => {
                                            prevData[index] = {
                                              ...prevData[index],
                                              id: {
                                                ...prevData[index]?.id,
                                                name: e.target.value,
                                              },
                                            };
                                            return [...prevData];
                                          });
                                        }}
                                      />
                                    )}

                                  <textarea
                                    className="form-control mt-2"
                                    placeholder="Description"
                                    value={
                                      multipleDiscountList[index]?.id?.description
                                    }
                                    onChange={(e) => {
                                      setMultipleDiscountList((prevData) => {
                                        prevData[index] = {
                                          ...prevData[index],
                                          id: {
                                            ...prevData[index]?.id,
                                            description: e.target.value,
                                          },
                                        };
                                        return [...prevData];
                                      });
                                    }}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="col-lg-2 col-xxl-2">
                                <div className="form-group itemGroup">
                                  <label>Price</label>
                                  <input
                                    type="number"
                                    placeholder="0.00"
                                    className="form-control"
                                    onWheel={(e) => e.target.blur()}
                                    value={
                                      multipleDiscountList[index]?.id
                                        ?.originalPrice
                                    }
                                    onChange={(e) => {
                                      setMultipleDiscountList((prevData) => {
                                        prevData[index] = {
                                          ...prevData[index],
                                          id: {
                                            ...prevData[index].id,
                                            originalPrice: parseInt(
                                              e.target.value
                                            ),
                                            price: parseInt(e.target.value),
                                          },
                                        };

                                        return [...prevData];
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-2 col-xxl-2">
                                <div className="form-group itemGroup">
                                  <label>Discount</label>
                                  <input
                                    type="number"
                                    placeholder="0.00"
                                    className="form-control"
                                    onWheel={(e) => e.target.blur()}
                                    value={
                                      multipleDiscountList[index]?.id?.discount
                                    }
                                    onChange={(e) => {
                                      setMultipleDiscountList((prevData) => {
                                        prevData[index] = {
                                          ...prevData[index],
                                          id: {
                                            ...prevData[index].id,
                                            discount: parseInt(e.target.value),
                                          },
                                        };
                                        prevData[index].id.price =
                                          multipleDiscountList[index].id
                                            .originalPrice -
                                          multipleDiscountList[index].id.discount;
                                        return [...prevData];
                                      });
                                    }}
                                  />
                                </div>
                                {multipleDiscountList[index]?.id?.originalPrice <
                                  multipleDiscountList[index]?.id?.discount && (
                                    <small>
                                      Discount cannot be greater than price
                                    </small>
                                  )}
                                {multipleDiscountList[index]?.id?.isRecurring ===
                                  true && <small>recurring Discount</small>}
                              </div>
                              <div className="col-lg-1 col-xxl-1">
                                <div className="form-group itemGroup">
                                  <label>QTY</label>
                                  <input
                                    placeholder="1"
                                    type="number"
                                    className="form-control"
                                    value={multipleDiscountList[index]?.quantity}
                                    onChange={(e) => {
                                      setMultipleDiscountList((prevData) => {
                                        prevData[index] = {
                                          ...prevData[index],
                                          quantity: parseInt(e.target.value),
                                        };

                                        return [...prevData];
                                      });
                                    }}
                                  />
                                </div>
                                <div>
                                  {multipleDiscountCount > 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-generate ms-3 delIcon"
                                      onClick={(event) =>
                                        decreaseDiscountCount(event, index)
                                      }
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                              {index == multipleDiscountCount - 1 && (
                                <div className="text-end">
                                  <a
                                    className="addItem-text"
                                    onClick={(event) =>
                                      increaseDiscount(event, index)
                                    }
                                  >
                                    + Add Item
                                  </a>
                                </div>
                              )}
                              {/* <div className="col-lg-2 col-xxl-2"> */}

                              {/* </div> */}
                            </div>
                          );
                        })}

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label>Note to Client</label>
                              <textarea
                                className="form-control"
                                value={noteToClient}
                                onChange={(e) => setNotetoClient(e.target.value)}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onClick={() =>
                                    setRecurringInvoice(!recurringInvoice)
                                  }
                                  checked={recurringInvoice}
                                  readOnly
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                // for="welcomeEmail"
                                >
                                  Recurring invoice <br />
                                  <small>
                                    Recurring invoices are generated by OpFlow-CRM
                                    and will show up as one-time payments in your
                                    payment processor. This is useful if invoice
                                    items change month to month, or if using a
                                    payment method that does not support
                                    subscriptions (e.g. bank transfer).
                                  </small>
                                  {recurringInvoice && (
                                    <small
                                      style={{
                                        display: "block",
                                        marginTop: "14px",
                                      }}
                                    >
                                      Recurring invoices are issued 5 days before
                                      they are due and charged automaically on the
                                      due date, if client has an active payment
                                      method in Stripe..{" "}
                                      <b>
                                        For other payment methods clients will be
                                        emailed a payment link for each new
                                        invoice..
                                      </b>
                                    </small>
                                  )}
                                  {recurringInvoice && (
                                    <div className="col-lg-5">
                                      <></>
                                      <div className="input-group align-items-center recurring-section">
                                        <label className="mr-2 checkbox-label">
                                          Issue every
                                        </label>
                                        <input
                                          type="number"
                                          inputmode="numeric"
                                          pattern="[0-9]*"
                                          className="form-control"
                                          placeholder=""
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                          value={recurringInvoiceValue}
                                          onChange={(e) =>
                                            setRecurringInvoiceValue(e.target.value)
                                          }
                                        />
                                        <span
                                          className="input-group-text"
                                          id="basic-addon2"
                                        >
                                          <select
                                            className="recurringDropdown"
                                            value={recurringInvoiceType}
                                            onChange={(e) =>
                                              setRecurringInvoiceType(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="months">Months</option>
                                            <option value="weeks">Weeks</option>
                                            <option value="days">Days</option>
                                          </select>
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </label>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="welcomeEmail"
                                  onClick={() => setSendEmail(!sendEmail)}
                                  checked={sendEmail}
                                  readOnly
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                // for="welcomeEmail"
                                >
                                  Send email notification <br />
                                  <small>
                                    If you don't notify the client they will still
                                    see the invoice in their account.
                                  </small>
                                </label>
                              </div>
                            </div>

                            {/* <div className="form-group mb-0">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              // id="welcomeEmail"
                              disabled={isRecurringServiceExist}
                              onClick={() => setPartialUpfront(!partialUpfront)}
                              checked={partialUpfront}
                            />
                            <label
                              className="form-check-label checkbox-label"
                            // for="welcomeEmail"
                            >
                              Partial upfront payment <br />
                              <small>
                                If you want to collect a deposit upfront and
                                charge the remaining amount later.
                              </small>
                              {partialUpfront && (
                                <div className="col-lg-5">
                                  <div className="form-group mt-2 mb-0">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="0.00"
                                      value={partialUpfrontValue}
                                      onChange={(e) =>
                                        setPartialUpfrontValue(
                                          parseInt(e.target.value)
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </label>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="btn-section text-end">
                        <button
                          className="btn btn-save"
                          disabled={checkStatusOfDiscount()}
                          onClick={(event) => {
                            editInvoiceHandler(event);
                          }}
                        >
                          Edit Invoice
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditInvoice;
