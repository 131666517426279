import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import AvatarImg from "../../assets/img/avatar.png";
import Logo from "../../assets/img/logo.svg";
import { saveSuperAdminAccessToken, saveSuperAdminLoginInfo, userLogoutRequest } from "../../redux/action/login";

const SuperAdminHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {superAdminUserInfo} = useSelector((state) => state.loginReducer);

    const signoutHandler = () => {
        dispatch(saveSuperAdminAccessToken(""));
        dispatch(saveSuperAdminLoginInfo({}));
        dispatch(userLogoutRequest());
        navigate('/super-admin/login')
    }

    return (
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-6">
                        <Link to={'/super-admin/dashboard'} className="navbar-brand ps-4">
                            <img src={Logo} width={150} />
                        </Link>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                        <div className="sidemenus">
                            <ul>
                                <li>
                                    <div className="dropdown">
                                    <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <span>{superAdminUserInfo?.firstName} {superAdminUserInfo?.lastName} </span>
                                        <img
                                        src={superAdminUserInfo?.image ? superAdminUserInfo?.image : AvatarImg}
                                        />{" "}
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a
                                            href="#"
                                            className="dropdown-item"
                                            onClick={signoutHandler}
                                            >
                                            Sign out
                                            </a>
                                        </li>
                                    </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>    
        </div>       
    )
}

export default SuperAdminHeader;