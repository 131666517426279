import React from "react";
import Spinner from 'react-bootstrap/Spinner';

const CommonButton = (props) => {
    const { buttonText = "Save", onClick, loading, disabled = false } = props;

    return (
        <div className="">
            <button
                className="btn btn-save "
                onClick={onClick}
                disabled={disabled}
            >
                {buttonText}
                {loading && <Spinner animation="border" size="sm" />}
            </button>
        </div>
    )
}

export default CommonButton