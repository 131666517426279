import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import { nanoid } from "nanoid";
import { AllContext } from "../../../context";
import {convertToUpperCase, getBusinessSubdomain} from "../../../shared/functions";
import PageLoader from "../../pageLoader";
import PaginationNewUI from "../../PaginationNewUI";

const TableClientTeam = ({isPage, searchText, isLoadingSearch, setIsLoadingSearch}) => {
  const loginUserInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const loginUserAccessToken = useSelector((state) => state.loginReducer.loginUserAccessToken);
  const context = useContext(AllContext);
  const { allApiUrl, redirectToLogin, toastMessage, apiGetMethod, apiPostMethod, apiDeleteMethod } = context;
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [contacts, setContacts] = useState([]);
  const [addFormData, setAddFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let currRemVal = "";

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [initialLoading, setInitialLoading] = useState(true);
  let header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  useEffect(() => {
    if (isLoadingSearch) {
      getList();
    }
  }, [isLoadingSearch])
  useEffect(() => {
    getList();
  }, [itemOffset, itemsPerPage]);

  const getList = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let data = {};
    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.id = loginUserInfo.id;
    data.isMembers = (loginUserInfo.teamId && loginUserInfo.teamId?.length);

    if (searchText) { data.searchText = searchText; }

    apiPostMethod(allApiUrl.TEAM_DETAILS_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setContacts([...res.data.dataList]);
        }
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err?.data?.message);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      });
  };

  const addTeamMember = () => {
    const data = {};
    if (addFormData.email !== "") data.email = addFormData.email;
    if (addFormData.firstName !== "") {
      data.firstName = addFormData.firstName;
    } else {
      data.firstName = data.email.substring(0, data.email.indexOf("@"));
    }
    if (addFormData.lastName !== "") data.lastName = addFormData.lastName;
    if (loginUserInfo.group !== "") data.groupId = loginUserInfo.group;
    data.role = "client";
    data.accountStatus = "client team";
    data.businessSubDomain = getBusinessSubdomain()
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.GET_ALL_CLIENT_TEAM_MEMBERS, data, header)
      .then((res) => {
        if (res.status === 200) {
          getList();
          setAddFormData({
            firstName: "",
            lastName: "",
            email: "",
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err);
      });
  };
  const deleteTeamMember = () => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = currRemVal + "/" + loginUserInfo.id;
    apiDeleteMethod(allApiUrl.GET_ALL_CLIENT_TEAM_MEMBERS, data, header)
      .then((res) => {
        if (res.status === 200) {
          getList();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err);
      });
  };
  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    const newContact = {
      id: nanoid(),
      firstName: addFormData.firstName,
      lastName: addFormData.lastName,
      email: addFormData.email,
    };
    const newContacts = [...contacts, newContact];
    setContacts(newContacts);
  };
  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };
  const settingCurrRemVal = (id) => {
    currRemVal = id;
  };

  return (
    <div>
      {!!(isPage) &&
        <>
          <Sidebar
            activePage="clientTeam"
            setToggleSidebar={setToggleSidebar}
            toggleSideBar={toggleSideBar}
          />
          <Header handleSidebar={handleSidebar} />
        </>
      }
      <PageLoader isLoading={initialLoading} />

      <div className="commonSlidePl">

        {!initialLoading && (
          <div className="setting-form-section pt-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xxl-10 col-lg-10 mx-auto">
                  <div className="setting-form">
                    <div className="col-lg-12 d-inline-flex justify-content-end align-items-center">
                      <h3 className="col-4">Team&nbsp;</h3>
                      <div className="col-8 d-inline-flex justify-content-end align-items-center">
                        <PaginationNewUI
                          itemsPerPage={itemsPerPage}
                          itemOffset={itemOffset}
                          setItemOffset={setItemOffset}
                          pageCount={pageCount}
                          totalRecordsCount={totalRecordsCount}
                          setItemsPerPage={setItemsPerPage}
                          mainClassName={isPage ? "d-inline-flex" : "col-lg-12"}
                        />
                        {!loginUserInfo?.teamId?.length && (
                          <button
                            className="btn btn-primary addTeam-btn m-0"
                            data-bs-toggle="modal"
                            data-bs-target="#addteamModal"
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="commonForm rounded-1 p-0">
                      <div className="table-resposnive">
                        <table className="table mb-0 rounded-1">
                          <tbody>
                          {contacts.map((contact, idx) => {
                            return (
                              (contact.userId || contact._id) && (
                                <tr key={idx}>
                                  <td>
                                    <a href={``}>
                                      {contact.userId.firstName
                                        ? convertToUpperCase(contact.userId.firstName)
                                        : "" + " " + contact.userId.lastName
                                          ? contact.userId.lastName
                                          : ""}
                                    </a>
                                  </td>
                                  <td>
                                    <span className="pill pill-orange">
                                      {contact.userId.email}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle btn-filter"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                      </button>
                                      <ul className="dropdown-menu filter-drowdown">
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteModal"
                                            onClick={() =>
                                              settingCurrRemVal(
                                                contact.userId._id
                                              )
                                            }
                                          >
                                            {loginUserInfo.teamId?.length ? 'Leave':'Remove'}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              )
                            );
                          })}

                          <tr>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className="modal fade history-modal"
          id="addteamModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <form onSubmit={handleAddFormSubmit}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add a coworker
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="history-content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label>First Name</label>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            value={addFormData.firstName}
                            onChange={handleAddFormChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label>Last Name</label>
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            value={addFormData.lastName}
                            onChange={handleAddFormChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="name@domain.com"
                            value={addFormData.email}
                            required
                            onChange={handleAddFormChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    data-bs-dismiss="modal"
                    className="btn btn-primary"
                    onClick={() => {
                      if (addFormData.email.length > 0) {
                        let status = String(addFormData.email)
                          .toLowerCase()
                          .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          );
                        if (status != null) {
                          addTeamMember();
                        } else {
                          toastMessage("error", "Invalid Email Address");
                        }
                      } else {
                        toastMessage("error", "Email Address Is Empty");
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div
          className="modal fade history-modal"
          id="deleteModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="delete-content">
                  <p>
                    Are you sure you want to delete client team member{" "}
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-danger"
                  onClick={() => deleteTeamMember()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TableClientTeam;
