import React from "react";

const PaginationNewUI = ({
  itemsPerPage,
  itemOffset,
  setItemsPerPage,
  setItemOffset,
  pageCount,
  mainClassName = "col-lg-7 ",
  totalRecordsCount,
}) => {
  const handleNextPage = (eventType) => {
    let newOffset = itemOffset;
    if (eventType === "increase" && itemOffset < pageCount) {
      newOffset += 1;
    }
    if (eventType === "decrease" && itemOffset > 1) {
      newOffset -= 1;
    }
    setItemOffset(newOffset);
  };
  return (
    !!pageCount && (
      <>
        <div className={mainClassName}>

          <div className="searchSection">
            <a className="viewLink" href="#">
              View
            </a>

            <div className="form-group position">
              <select
                className="form-control"
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(Number(e.target.value));
                  setItemOffset(1);
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>

            <ul>
              <li>
                {itemOffset * itemsPerPage - itemsPerPage + 1} -{" "}
                {itemOffset * itemsPerPage > totalRecordsCount
                  ? totalRecordsCount
                  : itemOffset * itemsPerPage}{" "}
                <span>of {totalRecordsCount}</span>
              </li>

              <li>
                <button onClick={() => handleNextPage("decrease")}>
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.71041 9.87999L2.83041 5.99999L6.71041 2.11999C6.80299 2.02741 6.87643 1.9175 6.92654 1.79653C6.97664 1.67557 7.00243 1.54592 7.00243 1.41499C7.00243 1.28406 6.97664 1.15441 6.92654 1.03345C6.87643 0.912482 6.80299 0.802571 6.71041 0.70999C6.61783 0.617408 6.50792 0.543968 6.38696 0.493863C6.26599 0.443758 6.13634 0.417969 6.00541 0.417969C5.87448 0.417969 5.74483 0.443758 5.62387 0.493863C5.50291 0.543968 5.393 0.617408 5.30041 0.70999L0.710413 5.29999C0.617709 5.3925 0.544162 5.50239 0.49398 5.62337C0.443799 5.74434 0.417969 5.87402 0.417969 6.00499C0.417969 6.13596 0.443799 6.26564 0.49398 6.38661C0.544162 6.50759 0.617709 6.61748 0.710413 6.70999L5.30041 11.3C5.69041 11.69 6.32041 11.69 6.71041 11.3C7.09041 10.91 7.10041 10.27 6.71041 9.87999Z" />
                  </svg>
                </button>

                <button onClick={() => handleNextPage("increase")}>
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.29007 9.87999L5.17007 5.99999L1.29007 2.11999C1.19749 2.02741 1.12405 1.9175 1.07394 1.79653C1.02384 1.67557 0.998047 1.54592 0.998047 1.41499C0.998047 1.28406 1.02384 1.15441 1.07394 1.03345C1.12405 0.912482 1.19749 0.802571 1.29007 0.70999C1.38265 0.617408 1.49256 0.543968 1.61352 0.493863C1.73449 0.443758 1.86414 0.417969 1.99507 0.417969C2.126 0.417969 2.25565 0.443758 2.37661 0.493863C2.49757 0.543968 2.60749 0.617408 2.70007 0.70999L7.29007 5.29999C7.68007 5.68999 7.68007 6.31999 7.29007 6.70999L2.70007 11.3C2.60755 11.3927 2.49767 11.4662 2.37669 11.5164C2.25572 11.5666 2.12604 11.5924 1.99507 11.5924C1.8641 11.5924 1.73442 11.5666 1.61344 11.5164C1.49247 11.4662 1.38258 11.3927 1.29007 11.3C0.910068 10.91 0.900068 10.27 1.29007 9.87999Z" />
                  </svg>
                </button>
              </li>
            </ul>
          </div>

        </div>
      </>
    )
  );
};
export default PaginationNewUI;
