import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";

const EditDefaultWorkflowModal = ({ isEditDefaultWorkflowModalOpen, handleEditDefaultWorkflow, handleCancelModal, defaultWorkflowName }) => {

    const [workflowName, setWorkflowName] = useState(defaultWorkflowName);

    return (
        <Modal
            show={isEditDefaultWorkflowModalOpen}
            onHide={handleCancelModal}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            className="add-new-taxrule-modal add-order-modals"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Workflow
                </Modal.Title>
            </Modal.Header>

            <ModalBody>
            <div className="edit-workflow-form">
                <div className="form-group mb-4">
                    <label>Workflow Name</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            name="workFlowName"
                            value={workflowName}
                            placeholder="Enter Workflow Name"
                            onChange={(e) => setWorkflowName(e.target.value)}
                        />
                    </div>
                </div>
            </div>        
            </ModalBody>    
            
            <ModalFooter>
                <Button color="secondary" className="cancel-btn" onClick={handleCancelModal}>Cancel</Button>{' '}
                <Button color="primary" className="btn btn-save" onClick={() => handleEditDefaultWorkflow(workflowName)}>Update</Button>
            </ModalFooter>    
        </Modal>
    )
}

export default EditDefaultWorkflowModal;