import React from "react";

const Progress_bar = ({ bgcolor, progress, height }) => {
  const Parentdiv = {
    height: height,
    width: "200px",
    backgroundColor: "#e0e0e0",
    borderRadius: 40,
    // margin: 50,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: progress < 100 ? "#f5a11c" : bgcolor,
    borderRadius: 40,
    textAlign: "center",
  };

  const progresstext = {
    padding: "0px 10px",
    color: "#6f6f6f",
    fontWeight: 900,
  };
  return (
    <div className="activeProgress">
      <div style={Parentdiv}>
        <div style={Childdiv}></div>
      </div>
      <span style={progresstext}>{`${progress}%`}</span>
    </div>
  );
};

export default Progress_bar;
