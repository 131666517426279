import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AllContext } from "../../context";
import { formatMonthNameAndDate } from "../../shared/functions";
import PageLoader from "../../components/pageLoader";

const ViewPayment = () => {
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allApiUrl, toastMessage, apiGetMethod } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { currency } = useSelector(
    (state) => state.sideMenuReducer.paymentSettingData
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  useEffect(() => {
    getInvoiceDetail();
  }, []);

  let orderId = window.location.pathname.split("/")[2];
  async function getInvoiceDetail() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.INVOICE_BY_ORDER + "/" + orderId,
        "",
        header
      );
      if (response.status === 200) {
        setInvoiceDetail(response.data.invoice);
        setInitialLoading(false);
      }
    } catch (err) {
      if (err.data.message == "Invoice not found") {
        navigate("/payments");
      } else if (err.data.message == "Invoice belong to other customer") {
        navigate("/incorrectInvoice");
      }
      setInitialLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  async function checkoutHandler() {
    navigate("/payment", {
      state: {
        from: "invoice",
        orderId,
        unAssigedInvoice: invoiceDetail.hasOwnProperty("userDetails")
          ? false
          : true,
      },
    });
  }
  return (
    <div>
      <Sidebar
        activePage="payments"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="commonSlidePl">
          <div className="setting-form-section pt-3 horizontalPadding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-back">
                    <a
                      href="#"
                      onClick={() => {
                        navigate("/payments");
                      }}
                    >
                      <i className="fa-solid fa-angle-left"></i> Invoices
                    </a>
                  </div>
                </div>

                <div className="col-xxl-8 col-lg-10 mx-auto">
                  <div className="client-heading mt-5">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="d-flex align-items-center">
                          <div className="userName m-0">
                            <h3 className="fs-4">
                              Invoice #{invoiceDetail.orderId}
                              <span
                                className={`pill ${
                                  invoiceDetail.status == "unpaid"
                                    ? "pill-grey"
                                    : "pill-green"
                                } ms-2`}
                              >
                                {invoiceDetail.status}
                              </span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-6">
            <div className="btns">
              <a className="btn-edit" href="#">
                Download
              </a>
            </div>
          </div> */}
                    </div>
                  </div>

                  <div className="client-details rounded-1">
                    <div className="row">
                      <div className="col-md-4">
                        <h5 className="clientText invoiceText">Invoiced To:</h5>

                        {invoiceDetail && invoiceDetail.userDetails && (
                          <div className="clientText p-0 mb-3">
                            <div className="detail-text">
                              {invoiceDetail.userDetails.company || ""}
                            </div>
                            <div className="detail-text">{`${
                              invoiceDetail.userDetails.firstName || ""
                            } ${
                              invoiceDetail.userDetails.lastName || ""
                            }`}</div>
                            <div className="detail-text">
                              {invoiceDetail.userDetails.billingAddress || ""}
                            </div>
                            <div className="detail-text">{`${
                              invoiceDetail.userDetails.city + "," || ""
                            } ${invoiceDetail.userDetails.state + "," || ""} ${
                              invoiceDetail.userDetails.country || ""
                            } - ${
                              invoiceDetail.userDetails.postal || ""
                            }`}</div>
                            <div className="detail-text">
                              {invoiceDetail.userDetails.taxId
                                ? "Tax ID - " + invoiceDetail.userDetails.taxId
                                : ""}
                            </div>
                            <div className="detail-text">
                              {invoiceDetail.userDetails.phone
                                ? "Phone Number - " +
                                  invoiceDetail.userDetails.phone
                                : ""}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-md-4">
                        <h5 className="invoiceText">Invoiced From:</h5>

                        {invoiceDetail && invoiceDetail.invoiceFrom && (
                          <div className="clientText mb-3">
                            <div className="detail-text">
                              {invoiceDetail.invoiceFrom.companyName || ""}
                            </div>
                            <div className="detail-text">
                              {invoiceDetail.invoiceFrom.address || ""}
                            </div>
                            <div className="detail-text">{`${
                              invoiceDetail.invoiceFrom.city + "," || ""
                            } ${
                              invoiceDetail.invoiceFrom.homeProvince + "" || ""
                            } ${
                              invoiceDetail.invoiceFrom.homeCountry || ""
                            } - ${
                              invoiceDetail.invoiceFrom.postalCode || ""
                            }`}</div>
                            <div className="detail-text">
                              {invoiceDetail.invoiceFrom.taxId
                                ? "Tax ID - " + invoiceDetail.invoiceFrom.taxId
                                : ""}
                            </div>
                            <div className="detail-text">
                              {invoiceDetail.invoiceFrom.phone
                                ? "Phone Number - " +
                                  invoiceDetail.invoiceFrom.phone
                                : ""}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-lg-6 col-4">
                            <p className="clientText fw-500">Number</p>
                          </div>
                          <div className="col-lg-6 col-8">
                            <p className="clientText">
                              {invoiceDetail.invoiceId}
                            </p>{" "}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-4">
                            <p className="clientText fw-500">Unique ID</p>
                          </div>
                          <div className="col-lg-6 col-8">
                            <p className="clientText">
                              #{invoiceDetail.orderId}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-4">
                            <p className="clientText fw-500">Issued</p>
                          </div>
                          <div className="col-lg-6 col-8">
                            <p className="clientText">
                              {formatMonthNameAndDate(
                                invoiceDetail.createdDate,
                                companyData.timezone,
                                true
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-4">
                            <p className="clientText fw-500">Due</p>
                          </div>
                          <div className="col-lg-6 col-8">
                            <p className="clientText">
                              {formatMonthNameAndDate(
                                invoiceDetail?.dueDate
                                  ? invoiceDetail?.dueDate
                                  : invoiceDetail.createdDate,
                                companyData.timezone,
                                true
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="client-details mt-5 rounded-1">
                    <div className="common-table clients-table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>ITEM</th>
                            <th>PRICE</th>
                            <th>QUANTITY</th>
                            <th>ITEM TOTAL</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceDetail &&
                            invoiceDetail.product &&
                            invoiceDetail.product.map((item, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>
                                    {item.id.oneTimeService ? (
                                      item.id.name
                                    ) : (
                                      <>
                                        <p>{item.id.name}</p>
                                        <small
                                          style={{
                                            display: "block",
                                            color: "#6b778c",
                                          }}
                                        >
                                          {`${
                                            currency?.symbol
                                              ? currency.symbol
                                              : "$"
                                          }${item.id.price.toFixed(2)}` +
                                            "/" +
                                            item.id.recurringOrder
                                              .durationTime +
                                            " " +
                                            item.id.recurringOrder
                                              .durationPeriod}
                                        </small>
                                      </>
                                    )}
                                  </td>
                                  {item.id.price == item.id.originalPrice ? (
                                    <td>
                                      {currency?.symbol ? currency.symbol : "$"}{" "}
                                      {item.id.price}
                                    </td>
                                  ) : (
                                    <>
                                      <td>
                                        {item.id.originalPrice && (
                                          <del>
                                            {currency?.symbol
                                              ? currency.symbol
                                              : "$"}
                                            {item.id.originalPrice ||
                                              item.id.price}
                                          </del>
                                        )}

                                        <td>
                                          {currency?.symbol
                                            ? currency.symbol
                                            : "$"}
                                          {item.id.price}
                                        </td>
                                      </td>
                                    </>
                                  )}

                                  <td>x{item.quantity}</td>
                                  <td>
                                    {currency?.symbol ? currency.symbol : "$"}
                                    {item.quantity * item.id.price}
                                  </td>
                                </tr>
                              );
                            })}
                          {invoiceDetail.paymentCredit ||
                          invoiceDetail.paymentDue ? (
                            <tr>
                              <td>
                                {invoiceDetail.paymentCredit
                                  ? "Credit"
                                  : "Payment Due Later"}
                              </td>
                              <td>
                                {currency?.symbol ? currency.symbol : "$"}
                                {invoiceDetail.paymentCredit
                                  ? invoiceDetail.paymentCredit
                                  : invoiceDetail.paymentDue}
                              </td>
                              <td>x1</td>
                              <td>
                                - {currency?.symbol ? currency.symbol : "$"}
                                {invoiceDetail.paymentCredit
                                  ? invoiceDetail.paymentCredit
                                  : invoiceDetail.paymentDue}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          <tr>
                            <td></td>
                            <td></td>
                            <td>Sub total</td>
                            <td>
                              {currency?.symbol ? currency.symbol : "$"}
                              {invoiceDetail.paymentCredit ||
                              invoiceDetail.paymentDue
                                ? invoiceDetail.totalPrice -
                                  (invoiceDetail.paymentCredit
                                    ? invoiceDetail.paymentCredit
                                    : invoiceDetail.paymentDue)
                                : invoiceDetail.totalPrice}
                            </td>
                          </tr>
                          {invoiceDetail?.isTaxPaid &&
                            (invoiceDetail?.appliedTaxes ? (
                              invoiceDetail?.appliedTaxes.map(
                                (taxesData, index) => (
                                  <tr key={index}>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <strong>{taxesData.taxName}</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {currency?.symbol
                                          ? currency?.symbol
                                          : "$"}{" "}
                                        {taxesData.taxAmount.toFixed(2)}
                                      </strong>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td></td>
                                <td></td>
                                <td>
                                  <strong>Taxes and Fees</strong>
                                </td>
                                <td>
                                  <strong>
                                    {currency?.symbol ? currency?.symbol : "$"}{" "}
                                    {invoiceDetail.taxAmount.toFixed(2)}
                                  </strong>
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td></td>
                            <td></td>
                            <td>
                              <strong>
                                {invoiceDetail.status == "paid" ||
                                invoiceDetail.status == "paid"
                                  ? "Total"
                                  : "Payment Due"}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {currency?.symbol ? currency.symbol : "$"}{" "}
                                {invoiceDetail.paymentCredit ||
                                invoiceDetail.paymentDue
                                  ? (
                                      invoiceDetail.totalPrice -
                                      (invoiceDetail.paymentCredit
                                        ? invoiceDetail.paymentCredit
                                        : invoiceDetail.paymentDue) +
                                      parseFloat(invoiceDetail?.taxAmount ? invoiceDetail?.taxAmount : 0)
                                    ).toFixed(2)
                                  : (
                                      invoiceDetail.totalPrice +
                                      parseFloat(invoiceDetail?.taxAmount ? invoiceDetail?.taxAmount : 0)
                                    ).toFixed(2)}
                                {invoiceDetail &&
                                  invoiceDetail.recurringInvoice &&
                                  invoiceDetail.recurringInvoice.status ==
                                    true && (
                                    <small
                                      style={{
                                        display: "block",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      Then{" "}
                                      {currency?.symbol ? currency.symbol : "$"}{" "}
                                      {invoiceDetail.paymentCredit ||
                                      invoiceDetail.paymentDue
                                        ? invoiceDetail.totalPrice -
                                          (invoiceDetail.paymentCredit
                                            ? invoiceDetail.paymentCredit
                                            : invoiceDetail.paymentDue
                                          ).toFixed(2)
                                        : invoiceDetail.totalPrice.toFixed(
                                            2
                                          )}{" "}
                                      /{" "}
                                      {
                                        invoiceDetail.recurringInvoice
                                          .durationTime
                                      }{" "}
                                      {
                                        invoiceDetail.recurringInvoice
                                          .durationPeriod
                                      }
                                    </small>
                                  )}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {invoiceDetail.note && (
                    <p className="invoiceNote">
                      <strong>Note:</strong> {invoiceDetail.note}
                    </p>
                  )}

                  <div className="text-end mt-3 mb-3">
                    {invoiceDetail.totalPrice -
                      (invoiceDetail.paymentCredit
                        ? invoiceDetail.paymentCredit
                        : invoiceDetail.paymentDue) ==
                      0 && <p>minimum amount should be greater than zero</p>}
                    {(invoiceDetail.status == "unpaid" ||
                      invoiceDetail.status == "Unpaid") && (
                      <button
                        className="btn btn-primary btn-payment"
                        onClick={checkoutHandler}
                        disabled={
                          invoiceDetail.totalPrice -
                            (invoiceDetail.paymentCredit
                              ? invoiceDetail.paymentCredit
                              : invoiceDetail.paymentDue) ==
                          0
                        }
                      >
                        Continue Payment
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ViewPayment;
