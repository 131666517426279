import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AllContext } from "../../context";
import { getBusinessSubdomain, monthNameAndDateFormat } from "../../shared/functions";
import { apiPostMethod } from "../../api/rest";

const ViewPublicInvoice = () => {
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { allApiUrl, redirectToLogin, toastMessage, apiGetMethod } = context;

  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [paymentSettingData, setPaymentSettingData] = useState({});
  useEffect(() => {
    getInvoiceDetail();
    getPaymentSettingData();
  }, []);
  async function getInvoiceDetail() {
    setIsLoading(true);
    let header = {
      //   Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.INVOICE_BY_ORDER_PUBLIC +
        "/" +
        location.pathname.split("/")[2],
        "",
        header
      );
      if (response.status === 200) {
        setIsLoading(false);
        setInvoiceDetail(response.data.invoice);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  async function getPaymentSettingData() {
    const subDomain = getBusinessSubdomain();
    setIsLoading(true);
    let header = {
      //   Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiPostMethod(allApiUrl.PAYMENT_METHOD_PUBLIC, {subDomain}, header);
      if (response.status === 200) {
        setIsLoading(false);
        setPaymentSettingData(response.data.values);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  const [isLoading, setIsLoading] = useState(false);
  async function checkoutHandler() {
    navigate("/payment", {
      state: {
        from: "invoice",
        url: "public-invoice",
        orderId: invoiceDetail.orderId,
        data: invoiceDetail,
        unAssigedInvoice: invoiceDetail.hasOwnProperty("userDetails")
          ? false
          : true,
      },
    });
  }
  return (
    <div className="col-xxl-8 col-lg-10 mx-auto">
      <div className="client-heading mt-5">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="d-flex align-items-center">
              <div className="userName m-0">
                <h3 className="fs-4">
                  Invoice #{invoiceDetail.orderId}
                  <span
                    className={`pill ${invoiceDetail.status == "unpaid"
                        ? "pill-grey"
                        : "pill-green"
                      } ms-2`}
                  >
                    {invoiceDetail.status}
                  </span>
                </h3>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-6">
          <div className="btns">
            <a className="btn-edit" href="#">
              Download
            </a>
          </div>
        </div> */}
        </div>
      </div>

      <div className="client-details rounded-1">
        <div className="row">
          <div className="col-md-4">
            <h5 className="clientText invoiceText">Invoiced To:</h5>

            {invoiceDetail && invoiceDetail.userDetails && (
              <div className="clientText p-0 mb-3">
                <div className="detail-text">
                  {invoiceDetail.userDetails.company || ""}
                </div>
                <div className="detail-text">{`${invoiceDetail.userDetails.firstName || ""
                  } ${invoiceDetail.userDetails.lastName || ""}`}</div>
                <div className="detail-text">
                  {invoiceDetail.userDetails.billingAddress || ""}
                </div>
                <div className="detail-text">{`${invoiceDetail.userDetails.city + "," || ""
                  } ${invoiceDetail.userDetails.state + "," || ""} ${invoiceDetail.userDetails.country || ""
                  } - ${invoiceDetail.userDetails.postal || ""}`}</div>
                <div className="detail-text">
                  {invoiceDetail.userDetails.taxId
                    ? "Tax ID - " + invoiceDetail.userDetails.taxId
                    : ""}
                </div>
                <div className="detail-text">
                  {invoiceDetail.userDetails.phone
                    ? "Phone Number - " + invoiceDetail.userDetails.phone
                    : ""}
                </div>
              </div>
            )}
          </div>

          <div className="col-md-4">
            <h5 className="invoiceText">Invoiced From:</h5>

            {invoiceDetail && invoiceDetail.invoiceFrom && (
              <div className="clientText mb-3">
                <div className="detail-text">
                  {invoiceDetail.invoiceFrom.companyName || ""}
                </div>
                <div className="detail-text">
                  {invoiceDetail.invoiceFrom.address || ""}
                </div>
                <div className="detail-text">{`${invoiceDetail.invoiceFrom.city + "," || ""
                  } ${invoiceDetail.invoiceFrom.homeProvince + "" || ""} ${invoiceDetail.invoiceFrom.homeCountry || ""
                  } - ${invoiceDetail.invoiceFrom.postalCode || ""}`}</div>
                <div className="detail-text">
                  {invoiceDetail.invoiceFrom.taxId
                    ? "Tax ID - " + invoiceDetail.invoiceFrom.taxId
                    : ""}
                </div>
                <div className="detail-text">
                  {invoiceDetail.invoiceFrom.phone
                    ? "Phone Number - " + invoiceDetail.invoiceFrom.phone
                    : ""}
                </div>
              </div>
            )}
          </div>

          <div className="col-md-4">
            <div className="row">
              <div className="col-lg-6">
                <p className="clientText fw-500">Number</p>
              </div>
              <div className="col-lg-6">
                <p className="clientText">{invoiceDetail.invoiceId}</p>{" "}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <p className="clientText fw-500">Unique ID</p>
              </div>
              <div className="col-lg-6">
                <p className="clientText">#{invoiceDetail.orderId}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <p className="clientText fw-500">Issued</p>
              </div>
              <div className="col-lg-6">
                <p className="clientText">
                  {monthNameAndDateFormat(
                    new Date(invoiceDetail.createdDate),
                    true
                  )}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <p className="clientText fw-500">Due</p>
              </div>
              <div className="col-lg-6">
                <p className="clientText">
                  {monthNameAndDateFormat(
                    new Date(
                      invoiceDetail?.dueDate
                        ? invoiceDetail?.dueDate
                        : invoiceDetail.createdDate
                    ),
                    true
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="client-details mt-5 rounded-1">
        <div className="common-table clients-table">
          <table className="table">
            <thead>
              <tr>
                <th>ITEM</th>
                <th>PRICE</th>
                <th>QUANTITY</th>
                <th>ITEM TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {invoiceDetail &&
                invoiceDetail.product &&
                invoiceDetail.product.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        {item.id.oneTimeService ? (
                          item.id.name
                        ) : (
                          <>
                            <h>{item.id.name}</h>
                            <small
                              style={{
                                display: "block",
                                color: "#6b778c",
                              }}
                            >
                              {`${paymentSettingData?.currency
                                  ? paymentSettingData?.currency?.symbol
                                  : "$"
                                } ${item.id.price.toFixed(2)}` +
                                "/" +
                                item.id.recurringOrder.durationTime +
                                " " +
                                item.id.recurringOrder.durationPeriod}
                            </small>
                          </>
                        )}
                      </td>
                      {item.id.price == item.id.originalPrice ? (
                        <td>
                          {paymentSettingData?.currency
                            ? paymentSettingData?.currency?.symbol
                            : "$"}{" "}
                          {item.id.price}
                        </td>
                      ) : (
                        <>
                          <td>
                            <del>
                              {paymentSettingData?.currency
                                ? paymentSettingData?.currency?.symbol
                                : "$"}{" "}
                              {item.id.originalPrice}
                            </del>
                            <td>
                              {paymentSettingData?.currency
                                ? paymentSettingData?.currency?.symbol
                                : "$"}{" "}
                              {item.id.price}
                            </td>
                          </td>
                        </>
                      )}

                      <td>x{item.quantity}</td>
                      <td>
                        {paymentSettingData?.currency
                          ? paymentSettingData?.currency?.symbol
                          : "$"}{" "}
                        {item.quantity * item.id.price}
                      </td>
                    </tr>
                  );
                })}
              {invoiceDetail.paymentCredit || invoiceDetail.paymentDue ? (
                <tr>
                  <td>
                    {invoiceDetail.paymentCredit
                      ? "Payment Credit"
                      : "Payment Due"}
                  </td>
                  <td>
                    {paymentSettingData?.currency
                      ? paymentSettingData?.currency?.symbol
                      : "$"}
                    {invoiceDetail.paymentCredit
                      ? invoiceDetail.paymentCredit
                      : invoiceDetail.paymentDue}
                  </td>
                  <td>x1</td>
                  <td>
                    -{" "}
                    {paymentSettingData?.currency
                      ? paymentSettingData?.currency?.symbol
                      : "$"}
                    {invoiceDetail.paymentCredit
                      ? invoiceDetail.paymentCredit
                      : invoiceDetail.paymentDue}
                  </td>
                </tr>
              ) : (
                ""
              )}

              <tr>
                <td></td>
                <td></td>
                <td>Sub total</td>
                <td>
                  {paymentSettingData?.currency
                    ? paymentSettingData?.currency?.symbol
                    : "$"}{" "}
                  {invoiceDetail.paymentCredit || invoiceDetail.paymentDue
                    ? invoiceDetail.totalPrice -
                    (invoiceDetail.paymentCredit
                      ? invoiceDetail.paymentCredit
                      : invoiceDetail.paymentDue)
                    : invoiceDetail.totalPrice}
                </td>
              </tr>

              {invoiceDetail?.isTaxPaid &&
                (invoiceDetail?.appliedTaxes ? (
                  invoiceDetail?.appliedTaxes.map((taxesData, index) => (
                    <tr key={index}>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>{taxesData.taxName}</strong>
                      </td>
                      <td>
                        <strong>
                          {paymentSettingData?.currency?.symbol
                            ? paymentSettingData?.currency?.symbol
                            : "$"}{" "}
                          {taxesData.taxAmount.toFixed(2)}
                        </strong>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <strong>Taxes and Fees</strong>
                    </td>
                    <td>
                      <strong>
                        {paymentSettingData?.currency?.symbol
                          ? paymentSettingData?.currency?.symbol
                          : "$"}{" "}
                        {invoiceDetail.taxAmount.toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                ))}

              <tr>
                <td></td>
                <td></td>
                <td>
                  <strong>
                    {invoiceDetail.status == "paid" ||
                      invoiceDetail.status == "paid"
                      ? "Total"
                      : "Payment Due"}
                  </strong>
                </td>
                <td>
                  <strong>
                    {paymentSettingData?.currency?.symbol
                      ? paymentSettingData?.currency.symbol
                      : "$"}
                    {invoiceDetail.paymentCredit || invoiceDetail.paymentDue
                      ? (
                        invoiceDetail.totalPrice -
                        (invoiceDetail.paymentCredit
                          ? invoiceDetail.paymentCredit
                          : invoiceDetail.paymentDue) +
                        parseFloat(invoiceDetail?.taxAmount ? invoiceDetail?.taxAmount : 0)
                      ).toFixed(2)
                      : (
                        invoiceDetail.totalPrice +
                        parseFloat(invoiceDetail?.taxAmount ? invoiceDetail?.taxAmount : 0)
                      ).toFixed(2)}
                    {invoiceDetail &&
                      invoiceDetail.recurringInvoice &&
                      invoiceDetail.recurringInvoice.status == true && (
                        <small
                          style={{
                            display: "block",
                            fontWeight: "normal",
                          }}
                        >
                          Then{" "}
                          {paymentSettingData?.currency
                            ? paymentSettingData?.currency?.symbol
                            : "$"}{" "}
                          {invoiceDetail.paymentCredit ||
                            invoiceDetail.paymentDue
                            ? invoiceDetail.totalPrice -
                            (invoiceDetail.paymentCredit
                              ? invoiceDetail.paymentCredit
                              : invoiceDetail.paymentDue
                            ).toFixed(2)
                            : invoiceDetail.totalPrice.toFixed(2)}{" "}
                          / {invoiceDetail.recurringInvoice.durationTime}{" "}
                          {invoiceDetail.recurringInvoice.durationPeriod}
                        </small>
                      )}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="text-end mt-5">
        {invoiceDetail.totalPrice -
          (invoiceDetail.paymentCredit
            ? invoiceDetail.paymentCredit
            : invoiceDetail.paymentDue) ==
          0 && <p>minimum amount should be greater than zero</p>}
        {(invoiceDetail.status == "unpaid" ||
          invoiceDetail.status == "Unpaid") && (
            <button
              className="btn btn-primary"
              onClick={checkoutHandler}
              disabled={
                invoiceDetail.totalPrice -
                (invoiceDetail.paymentCredit
                  ? invoiceDetail.paymentCredit
                  : invoiceDetail.paymentDue) ==
                0
              }
            >
              Continue Payment
            </button>
          )}
      </div>
    </div>
  );
};
export default ViewPublicInvoice;
