import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import moment from "moment";
import { AllContext } from "../../context";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../components/pageLoader";
import LoadingOverlay from "react-loading-overlay";
import * as _ from "lodash";
import openIcon from "../../assets/img/icons/openSide.svg";
import PaginationNewUI from "../../components/PaginationNewUI";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
LoadingOverlay.propTypes = undefined;

const Coupons = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const [isLoading, setIsLoading] = useState(false);
  const [couponId, setCouponId] = useState();
  const [couponCode, setCouponCode] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const {
    allApiUrl,
    apiPostMethod,
    apiDeleteMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const [coupon, setCoupon] = useState([]);
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [pageCount, setPageCount] = useState();
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const roleList = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );
  const businessId = useSelector(
    (state) => state.loginReducer.loginUserInfo.businessId
  );
  function getAllCoupon() {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {};
    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.businessId= businessId;
    console.log("data",data)
    apiPostMethod(allApiUrl.FILTER_COUPONS, data, header)
      .then((res) => {
        setPageCount(res.data.totalPage);
        setTotalRecordsCount(res.data.totalItems);
        setCoupon([...res.data.coupons]);
        setInitialLoading(false);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setInitialLoading(false);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getAllCoupon();
  }, [itemOffset, itemsPerPage]);

  const editCoupon = async (_id) => {
    navigate("/edit-coupon/" + _id);
  };

  function deleteCoupon(couponId) {
    setIsLoading(true);
    setDisabledBtn(true)
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.COUPON, couponId, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        getAllCoupon();
        setIsLoading(false);
        setDisabledBtn(true)
        setIsDeleteConfirmModalOpen(false)
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
        setDisabledBtn(false)
        setIsDeleteConfirmModalOpen(false)
      });
  }
  function deleteMultiple() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.DELETE_COUPON_BULK, { ids: bulkDeleteId }, header)
      .then((res) => {
        setBulkDeleteId([...[]]);
        toastMessage("success", res.data.message);
        getAllCoupon();
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function checkedAll() {
    if (coupon.length === bulkDeleteId.length) {
      setBulkDeleteId([...[]]);
    } else {
      let ids = coupon.map((e) => {
        return e._id;
      });
      setBulkDeleteId([...ids]);
    }
  }
  const onChangeCheckbox = (id, val) => {
    if (val) {
      bulkDeleteId.push(id);
      setBulkDeleteId([..._.uniq(bulkDeleteId)]);
    } else {
      _.remove(bulkDeleteId, (o) => o === id);
      setBulkDeleteId([...bulkDeleteId]);
    }
  };
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }

  return (
    <div>
      <Sidebar
        activePage="coupons"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      <div className="orders-section commonPadding dashboardTop">
        <div className="commonSlidePl">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">
                    Coupons <span className="userCounter">&nbsp;</span>
                    {roleList?.coupons?.view_add_edit_delete && (
                      <a
                        onClick={() =>
                          navigate("/add-coupon", {
                            state: {
                              page: "coupon",
                            },
                          })
                        }
                        className="btn btn-addOrder"
                      >
                        Add Coupons
                      </a>
                    )}
                  </h2>
                  <span
                    className="openArrowIcon"
                    onClick={() => {
                      setToggleSidebar(!toggleSideBar);
                    }}
                  >
                    <img src={openIcon} />
                  </span>
                </div>
              </div>

              <PaginationNewUI
                itemsPerPage={itemsPerPage}
                itemOffset={itemOffset}
                setItemOffset={setItemOffset}
                pageCount={pageCount}
                totalRecordsCount={totalRecordsCount}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="commonSlidePl">
            <div className="container-fluid">
              <LoadingOverlay
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                      stroke: "#007D87",
                    },
                  }),
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.5)",
                  }),
                }}
                active={isLoading}
                spinner
                text="Loading content..."
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-table clients-table mt-3 files-table rounded-1">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="ps-4 w65-px">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="selectAll form-check-input"
                                  id="selectAll"
                                  checked={
                                    coupon.length &&
                                    coupon.length === bulkDeleteId.length
                                  }
                                  onChange={() => checkedAll()}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="selectAll"
                                ></label>
                              </div>
                            </th>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Expires</th>
                            <th>Single use</th>
                            <th>Times used</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {coupon.map((value, index) => {
                            var single_use =
                              value.redemption_limit_one_use_per_customer ===
                              true
                                ? "Yes"
                                : "No";
                            let publishdate = moment(value.expiryDate).format(
                              "MMM DD , YYYY"
                            );
                            return (
                              <tr key={index}>
                                <td className="ps-4 w65-px">
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={value._id}
                                      checked={
                                        !!_.includes(bulkDeleteId, value._id)
                                      }
                                      onChange={(e) => {
                                        onChangeCheckbox(
                                          value._id,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={value._id}
                                    ></label>
                                  </div>
                                </td>
                                <td>
                                  <a onClick={() => editCoupon(value._id)}>
                                    {value.couponCode}
                                  </a>
                                </td>
                                <td>{value.description}</td>
                                <td>
                                  <span>
                                    {value.fixedAmount === false ? "%" : "$"}
                                  </span>
                                </td>
                                <td>
                                  {value.expiryDate == null ? "" : publishdate}
                                </td>
                                <td>{single_use}</td>
                                <td>{value.timesUsed}</td>
                                <td className="text-end pe-4">
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-secondary dropdown-toggle btn-info"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </button>
                                    <ul className="dropdown-menu">
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => editCoupon(value._id)}
                                        >
                                          Edit
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() => {
                                            setCouponId(value._id);
                                            setCouponCode(value.couponCode);
                                            setIsDeleteConfirmModalOpen(true)
                                          }}
                                        >
                                          Delete
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          {coupon && coupon.length <= 0 && (
                            <tr>
                              <td colSpan={7}>
                                <div className="noData-section">
                                  <img src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {bulkDeleteId.length > 0 && (
                      <div className="table-bottom-option">
                        <div className="row">
                          <div className="col-lg-3 col-md-4">
                            <div className="input-group">
                              <select
                                id="selectOption"
                                value={selectOption.target}
                                onChange={(e) => selectOption(e)}
                                className="form-control"
                              >
                                <option value="">With Selected...</option>
                                <option value="Delete">Delete</option>
                              </select>
                              <button
                                className="btn btn-apply"
                                onClick={() => {
                                  if (selectedOption == "Delete") {
                                    deleteMultiple();
                                  }
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      )}
      {/* delete modal */}
      {isDeleteConfirmModalOpen ? (
        <DeleteConfirmModal
          isDeleteConfirmModalOpen={isDeleteConfirmModalOpen}
          handleDelete={() => {deleteCoupon(couponId)}}
          handleCancelModal={() => {setIsDeleteConfirmModalOpen(false)}}
          modalContent={`Are you sure you want to delete coupon: ${couponCode}`}
          disabledBtn={disabledBtn}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default Coupons;
