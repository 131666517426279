import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getBusinessSubdomain } from "../shared/functions";

export const SuperAdminRoutes = ({ children }) => {
    const roleList = useSelector((state) => state?.loginReducer?.loginUserInfo);
    const subDomain = getBusinessSubdomain(); //check url have subdomain or not if yes then restrict super-admin routes

    if(roleList?.group !== 1 && roleList?.group !== 2 && !!subDomain === false) { 
      return children;
    } else {
        if(roleList?.group === 1) {
            return <Navigate to="/dashboard" />
        } else {
            return <Navigate to="/home" />
        }
    }
};

export default SuperAdminRoutes;