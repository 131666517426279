import React from "react";
import TablePublicShop from "../../components/Tables/public-shop";

const PublicShop = () => {
  return (
    <div>
      <TablePublicShop isPage={false} />
    </div>
  );
};

export default PublicShop;
