import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import PaginationNewUI from "../../components/PaginationNewUI";
import { AllContext } from "../../context";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Sales = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [adminData, setAdminData] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords

  const context = useContext(AllContext);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const {
    allApiUrl,
    apiGetMethod,
    toastMessage,
    redirectToLogin,
    apiPostMethod,
  } = context;
  const { SALES, SALES_DATE_FILTER } = allApiUrl;

  let name = "";
  if (userInfo?.email) {
    name = userInfo?.email;
  } else {
    name = "Test 123";
  }

  const navigate = useNavigate();
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const onChange = (dates) => {
    const [start, end] = dates;
    setDateRange(dates);
    if (end) {
      getDateFilterData(start, end);
    }
  };

  useEffect(() => {
    let loggedIn = localStorage.getItem("userLoggedIn");
    getAdminData();
  }, [itemOffset, itemsPerPage]);

  // const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
  const currentList = adminData;
  // .slice(itemOffset, endOffset);
  // let pageCount = Math.ceil(adminData.length / itemsPerPage);
  const [pageCount, setPageCount] = useState(0); // totalPages

  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % adminData.length;
  //   setItemOffset(newOffset);
  // };

  function getAdminData() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);

    apiGetMethod(SALES + "/" + itemOffset + "/" + itemsPerPage, "", header)
      .then((res) => {
        setPageCount(Math.ceil(res.data.totalCount / itemsPerPage));

        setTotalRecordsCount(res.data.totalCount);
        setAdminData(res.data.adminData);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function getDateFilterData(start, end) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {};
    if (end && end != startDate) {
      data = {
        startDate: startDate,
        endDate: end,
      };
    } else {
      data = {
        startDate: start ? start : startDate,
      };
    }
    setIsLoading(true);
    apiPostMethod(SALES_DATE_FILTER, data, header)
      .then((res) => {
        setAdminData(res.data.adminData);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function openCalender(e) {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  return (
    <div>
      <Sidebar
        activePage="dashboard"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      {!isLoading && (
        <div className="commonSlidePl">
          <div className="dashboardSection commonPadding pb-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-4">
                  <div className="heading">
                    <h2 style={{ overflow: "hidden" }}>Sales Performance</h2>
                  </div>
                </div>
                <div className="col-lg-6 col-md-8 d-flex align-items-center justify-content-end">
                  <div className="dashboardFilters">
                    <div className="dropdown d-flex">
                      <button
                        className="btn btn-secondary dropdown-toggle dashboardDropdown"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ width: "170px" }}
                      >
                        Sales Performance
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => navigate("/revenue")}
                          >
                            Revenue
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => navigate("/service-report")}
                          >
                            Services
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => navigate("/client-report")}
                          >
                            Clients
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => navigate("/sales")}
                          >
                            Sale Performance
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="datepicker-wrapper ms-3">
                    <DatePicker
                      className="form-control"
                      selectsRange
                      maxDate={moment().toDate()}
                      dateFormat="MM/dd/yyyy"
                      startDate={startDate}
                      endDate={endDate}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sectionPagination justify-content-end">
            <PaginationNewUI
              itemsPerPage={itemsPerPage}
              itemOffset={itemOffset}
              setItemOffset={setItemOffset}
              pageCount={pageCount}
              totalRecordsCount={totalRecordsCount}
              setItemsPerPage={setItemsPerPage}
            />
          </div>

          <div className="listingSection">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-lg-12">
                  <div className="common-table rounded-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Team Members</th>
                          <th>New Clients</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentList &&
                          currentList.length > 0 &&
                          currentList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.adminName}</td>
                                <td>{item.numberOfClients}</td>
                                <td>${item.revenueGenerated}</td>
                              </tr>
                            );
                          })}
                        {currentList && currentList.length <= 0 && (
                          <tr>
                            <td colSpan={7}>
                              <div className="noData-section">
                                <img src={noData} />
                                <p>No Data Found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Sales;
