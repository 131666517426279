import React, { useContext, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import FormBuilder from "../../components/FormBuilder";
import PageLoader from "../../components/pageLoader";
import openIcon from "../../assets/img/icons/openSide.svg";
import { editorApiKey } from "../../shared/constants";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import AddEditTaskModal from "./addEditTaskModal";

const EditService = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedMessageTemplateId, setSelectedMessageTemplateId] =
    useState("");

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiPutMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { SERVICES, MESSAGE, TAGS } = allApiUrl;
  const { id } = useParams();

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { currency } = useSelector(
    (state) => state.sideMenuReducer.paymentSettingData
  );

  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [image, setImage] = useState("");
  const [newTag, setNewTag] = useState([]);
  const [tag, setTag] = useState("");
  const [addServiceList, setAddServiceList] = useState({
    service: [{}],
  });
  const [templateSaved, setTemplateSaved] = useState(false);
  const [customIntakeFormData, setCustomIntakeFormData] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [form, setForm] = useState({
    name: "",
    description: "",
    imageUrl: "",
    price: 0,
    currency: currency?.code || "USD",
    durationTime: 0,
    durationPeriod: "months",
    orderCount: 0,
    addOn: [],
    tags: [],
    public: false,
    payLater: false,
    oneTimeService: false,
    intakeForm: "",
    orderService: false,
    customForm: [],
    tasks: [],
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [currActiveTab, setCurrActiveTab] = useState("Edit");
  const [serviceData, setServiceData] = useState({});

  //task module states
  const [task, setTask] = useState({
    taskName: "",
    description: "",
    deadline: 0,
    deadlineValue: "day",
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [delectTask, setDeleteTask] = useState("");
  const [editTask, setEditTask] = useState("");
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [selectedWorkflowOrderStatuses, setSelectedWorkflowOrderStatuses] =
    useState([]);
  const [defaultWorkflowOrderStatus, setDefaultWorkflowOrderStatus] =
    useState("");
  const [workflowData, setWorkflowData] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [clientListArr, setClientListArr] = useState([]);
  const [defaultAssignee, setDefaultAssignee] = useState("*");
  const [isModalOpen, setIsModalOpen] = useState({
    addEdit: false,
    delete: false
  })

  useEffect(() => {
    let options = [];
    allClients.map((item) => {
      if (item.userId) {
        options.push({
          value: item.userId._id,
          label: `${item.userId.firstName || " "} ${item.userId.lastName || ""
            } - ${item.userId.email}`,
        });
      }
    });
    setClientListArr(options);
  }, [allClients]);

  function handleFormSubmit() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    var formData = new FormData();
    let addOnList = addServiceList.service.map(({ value, name }) => ({
      value,
      name,
    }));

    addOnList = addOnList.map(({ name: name, ...rest }) => ({
      name,
      ...rest,
    }));

    let recurringOrder = {
      status: !form.oneTimeService && true,
      durationTime: form.recurringOrder.durationTime,
      durationPeriod: form.recurringOrder.durationPeriod,
      newOrder: form.recurringOrder.newOrder,
    };
    formData.append("imageUrl", form.imageUrl);
    if (currentServiceName !== form.name) {
      formData.append("name", form.name);
    }
    formData.append("price", form.price);
    formData.append("currency", currency?.code ? currency?.code : "USD");
    formData.append("description", form.description);
    formData.append(
      "orderCount",
      form.recurringOrder.newOrder == "newOrder" ? 1 : form.orderCount
    );
    formData.append("addOn", JSON.stringify(addOnList));
    formData.append("public", form.public);
    formData.append("oneTimeService", form.oneTimeService);
    formData.append("messageTemplateId", selectedMessageTemplateId);
    formData.append(
      "intakeForm",
      form.intakeForm != "" ? form.intakeForm : "no_form"
    );
    formData.append("tags", JSON.stringify(newTag));
    formData.append("payLater", form.payLater);
    formData.append("tasks", JSON.stringify(taskList));
    formData.append("workflowId", selectedWorkflow);
    formData.append("workflowOrderStatusId", defaultWorkflowOrderStatus);
    formData.append("defaultAssignee", defaultAssignee);
    let obj;
    if (templateSaved == true) {
      obj = JSON.parse(customIntakeFormData);
    } else {
      obj = form.customForm;
    }
    formData.append("customForm", JSON.stringify(obj));
    formData.append("recurringOrder", JSON.stringify(recurringOrder));
    apiPutMethod(SERVICES + "/" + id, formData, header)
      .then((res) => {
        if (res.status == 200) {
          addTag();
          toastMessage("success", res.data.message);
          navigate("/services");
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }
  function handleEditorChange(e) {
    setForm((prevData) => {
      return {
        ...prevData,
        description: e,
      };
    });
  }
  function getFilteredTags(value) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(TAGS, `/${value}`, header)
      .then((res) => {
        if (res.status === 200) {
          setFilterTags(res.data.tags);
        }
      })
      .catch((err) => {
        toastMessage("error", err);
      });
  }
  const addTag = () => {
    for (let i = 0; i < newTag.length; i++) {
      const data = {
        name: newTag[i],
      };
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiPostMethod(TAGS, data, header)
        .then((res) => {
          if (res.status === 201) {
            newTag.push(tag);
            setTag("");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  useEffect(() => {
    getAllServices();
    getAllMessages();
  }, []);
  function getAllServices() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setLoader(true);
    apiGetMethod(SERVICES, "", header)
      .then((res) => {
        let serviceList = res.data.data;
        setAllServices(
          serviceList.map((row) => {
            return {
              label: row.name,
              value: row._id,
              name: row.name,
              isDisabled: false,
            };
          })
        );
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }

  const getAllMessages = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(MESSAGE, "", header)
      .then((res) => {
        setMessages(res.data.data);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  };

  useEffect(() => {
    getServiceDetail();
  }, [id]);
  const [currentServiceName, setCurrentServiceName] = useState("");
  const getServiceDetail = async () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let workflowValues = [];
    const orderStatursDataRes = await apiGetMethod(allApiUrl.GET_WORKFLOWS, "", header);
    if (orderStatursDataRes.status === 200) {
      workflowValues = orderStatursDataRes.data;
      if (workflowValues.length) {
        setWorkflowData(workflowValues);
      }
    }
    // setLoader(true);
    apiGetMethod(SERVICES + "/" + id, "", header)
      .then((res) => {
        res.data.service["orderService"] =
          res.data.service &&
            res.data.service.addOn.length > 0 &&
            res.data.service.addOn[0] == ""
            ? false
            : true;
        setForm(res.data.service);
        setNewTag(res.data.service.tags);
        setSelectedMessageTemplateId(
          res.data.service?.messageTemplateId
            ? res.data.service?.messageTemplateId
            : ""
        );
        processWorkflowData(res.data.service, workflowValues);
        setTaskList(res.data.service.tasks);
        setCurrentServiceName(res.data.service.name);
        setServiceData(res.data.service);
        setInitialLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const processWorkflowData = (serviceData, workflowValues) => {
    setWorkflowData(workflowValues);
    if (serviceData?.workflowId !== "") {
      setSelectedWorkflow(serviceData?.workflowId);
      const workflowValue = workflowValues.findIndex(
        (data) => data._id === serviceData.workflowId
      );
      if (workflowValue !== -1) {
        const workflowOrderStatusData = workflowValues[workflowValue];
        if (workflowOrderStatusData?.orderStatuses.length) {
          setSelectedWorkflowOrderStatuses(
            workflowOrderStatusData?.orderStatuses
          );
          const orderStatusesValue =
            workflowOrderStatusData?.orderStatuses.find(
              (data) => data._id === serviceData.workflowOrderStatusId
            );
          setDefaultWorkflowOrderStatus(orderStatusesValue._id);
          setDefaultAssignee(serviceData.defaultAssignee);
        }
      }
    } else {
      const getDefaultWorkflow = workflowValues.findIndex(
        (data) => data.isDefaultWorkflow
      );
      if (getDefaultWorkflow !== -1) {
        setSelectedWorkflow(workflowValues[getDefaultWorkflow]._id);
        const workflowOrderStatusData = workflowValues[getDefaultWorkflow];
        if (workflowOrderStatusData?.orderStatuses.length) {
          setSelectedWorkflowOrderStatuses(
            workflowOrderStatusData?.orderStatuses
          );
          setDefaultWorkflowOrderStatus(
            workflowOrderStatusData?.orderStatuses[0]._id
          );
        }
      }
    }
  };

  function addTaskHandler() {
    setTaskList((prev) => [...prev, task]);
    setTask({
      taskName: "",
      description: "",
      deadline: 0,
      deadlineValue: "day",
    });
    handleCancelModal()
  }

  function deleteTaskHandler() {
    const removeItem = taskList.filter((taskItem) => {
      return taskItem.taskName !== delectTask;
    });
    setTaskList(removeItem);
    setDeleteTask("");
    handleCancelModal()
  }
  function setEditValue(value) {
    setIsEditMode(true);
    setTask(value);
    setEditTask(value.taskName);
  }
  function editHandler() {
    const update_obj = taskList.map((obj) => {
      if (obj.taskName == editTask) {
        return { ...task };
      }
      return obj;
    });
    setTaskList(update_obj);
    handleCancelModal()
  }

  const selectMessageOption = (e) => {
    setSelectedMessageTemplateId(e.target.value);
  };

  const selectWorkflowOption = (e) => {
    setSelectedWorkflow(e.target.value);
    const workflowOrderStatusData = workflowData.find(
      (data) => data._id === e.target.value
    );
    setSelectedWorkflowOrderStatuses(workflowOrderStatusData.orderStatuses);
    setDefaultWorkflowOrderStatus(workflowOrderStatusData.orderStatuses[0]._id);
  };

  const selectWorkflowOrderOption = (e) => {
    setDefaultWorkflowOrderStatus(e.target.value);
  };

  const handleCancelModal = () => {
    setIsModalOpen({
      delete: false,
      addEdit: false
    });
  }

  return (
    <div>
      <Sidebar
        activePage="services"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header
        handleSidebar={handleSidebar}
        propSetAllClientList={setAllClients}
      />
      <PageLoader initialLoading={initialLoading} />
      {!initialLoading && (
        <>
          <div className="commonSlidePl">
            <div className="orders-section commonPadding dashboardTop">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-5 col-12">
                    <div className="heading position-relative">
                      <div style={{ display: "flex" }}>
                        <h2 className="mb-0">
                          Services <span className="userCounter">&nbsp;</span>
                        </h2>
                        <h3 className="mb-0 mt-2">
                          {currentServiceName}</h3>
                      </div>
                      <span className="openArrowIcon" onClick={() => { setToggleSidebar(!toggleSideBar) }}>
                        <img src={openIcon} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="setting-form-section pt-3 horizontalPadding">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xxl-12 col-xl-12">

                    <div className="common-tabs serviceTabs mt-4">
                      <div className="tabs-content">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                currActiveTab == "Edit"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                              onClick={() => setCurrActiveTab("Edit")}
                            >
                              Edit
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                currActiveTab == "InTakeForm"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                              disabled={form.name == ""}
                              onClick={() => setCurrActiveTab("InTakeForm")}
                            >
                              Intake form
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                currActiveTab == "Tasks"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="pills-task-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-task"
                              type="button"
                              role="tab"
                              aria-controls="pills-task"
                              aria-selected="false"
                              onClick={() => setCurrActiveTab("Tasks")}
                              disabled={form.serviceName == ""}
                            >
                              Tasks
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="setting-form ticketForm formOuter mt-3">
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className={`tab-pane fade ${currActiveTab == "Edit" && "show active"
                            }`}
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                          tabIndex="0"
                        >

                          <div className="row">
                            <div className="col-lg-9">
                              <div className="commonForm rounded-3">

                                <h3 className="fs-4 m-0 my-3 p-3 pt-0">Edit Service</h3>
                                <div className="p-3">


                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="form-group">
                                        <label>Service Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="My Service"
                                          value={form.name}
                                          onChange={(e) => {
                                            setForm((prevData) => {
                                              return {
                                                ...prevData,
                                                name: e.target.value,
                                              };
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-12">
                                      <div className="form-group">
                                        <label>
                                          Description <small>Optional</small>
                                        </label>
                                        <Editor
                                          required
                                          apiKey={editorApiKey}
                                          onInit={(evt, editor) =>
                                            (editorRef.current = editor)
                                          }
                                          value={form.description}
                                          init={{
                                            selector: "textarea",
                                            height: 500,
                                            menu: {
                                              custom: {
                                                title: "custom",
                                                items:
                                                  "basicitem nesteditem toggleitem",
                                              },
                                            },
                                            toolbar:
                                              "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                              "fullpage" +
                                              "indent | blockquote | codesample | fontselect ",
                                            font_formats:
                                              "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                            block_formats:
                                              "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                            plugins: [
                                              "image",
                                              "help",
                                              "link",
                                              "codesample",
                                              "lists",
                                            ],
                                            branding: false,
                                            content_style:
                                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                                            menubar: false,
                                          }}
                                          onEditorChange={(e) => handleEditorChange(e)}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-12">
                                      <div className="form-group">
                                        <div className="d-flex">
                                          <button className="btn btn-default">
                                            Upload Image
                                            <input
                                              type="file"
                                              id="uploadCaptureInputFile"
                                              value={image}
                                              onChange={(e) =>
                                                setForm((prevData) => {
                                                  return {
                                                    ...prevData,
                                                    imageUrl: e.target.files[0],
                                                  };
                                                })
                                              }
                                            />
                                          </button>
                                        </div>
                                        <div className="previewImg">
                                          <span className="ImgSection">
                                            {form.imageUrl && (
                                              <>
                                                <img
                                                  src={
                                                    typeof form.imageUrl === "string"
                                                      ? form.imageUrl
                                                      : URL.createObjectURL(
                                                        form.imageUrl
                                                      )
                                                  }
                                                />
                                                <span
                                                  className="Del"
                                                  onClick={(e) => {
                                                    setForm((prevData) => {
                                                      return {
                                                        ...prevData,
                                                        imageUrl: "",
                                                      };
                                                    });
                                                    document.getElementById(
                                                      "uploadCaptureInputFile"
                                                    ).value = "";
                                                  }}
                                                >
                                                  <i className="fa-solid fa-trash"></i>
                                                </span>
                                              </>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="serviceTabs">
                                    <h3 className="m-0 mb-4 p-3 pt-0">Pricing</h3>
                                    <ul
                                      className="nav nav-tabs mb-3"
                                      id="myTab"
                                      role="tablist"
                                    >
                                      <li className="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${form.oneTimeService && "active"
                                            }`}
                                          id="home-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#home-tab-pane"
                                          type="button"
                                          role="tab"
                                          aria-controls="home-tab-pane"
                                          aria-selected="true"
                                          onClick={() =>
                                            setForm((prevData) => {
                                              return {
                                                ...prevData,
                                                oneTimeService: true,
                                              };
                                            })
                                          }
                                        >
                                          One-time service
                                        </button>
                                      </li>
                                      <li className="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${!form.oneTimeService && "active"
                                            }`}
                                          id="profile-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#profile-tab-pane"
                                          type="button"
                                          role="tab"
                                          aria-controls="profile-tab-pane"
                                          aria-selected="false"
                                          onClick={() =>
                                            setForm((prevData) => {
                                              return {
                                                ...prevData,
                                                oneTimeService: false,
                                              };
                                            })
                                          }
                                        >
                                          Recurring service
                                        </button>
                                      </li>
                                    </ul>

                                    <div className="tab-content" id="myTabContent">
                                      <div
                                        className={`tab-pane fade ${form.oneTimeService && "show active"
                                          }`}
                                        id="home-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                        tabIndex="0"
                                      >
                                        <div className="row">
                                          <div className="col-xxl-7 col-lg-7">
                                            <div className="form-group">
                                              <label>Price</label>
                                              <div className="row">
                                                <div className="col-lg-4">
                                                  <input
                                                    className="form-control mobileSpace"
                                                    type="text"
                                                    value={
                                                      currency?.code
                                                        ? currency?.code
                                                        : "USD"
                                                    }
                                                    disabled
                                                  />
                                                </div>
                                                <div className="col-lg-8">
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    value={form.price}
                                                    onChange={(e) => {
                                                      setForm((prevData) => {
                                                        return {
                                                          ...prevData,
                                                          price: e.target.value,
                                                        };
                                                      });
                                                    }}
                                                    onWheel={() =>
                                                      document.activeElement.blur()
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {/* <a href="#">Create multiple orders?</a> */}

                                            <div className="form-group">
                                              <div className="input-group">
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={form.orderCount}
                                                  onChange={(e) => {
                                                    setForm((prevData) => {
                                                      return {
                                                        ...prevData,
                                                        orderCount: e.target.value,
                                                      };
                                                    });
                                                  }}
                                                  onWheel={() =>
                                                    document.activeElement.blur()
                                                  }
                                                  onBlur={(e) => {
                                                    if (e.target.value < 1 || !!e.target.value === false) {
                                                      setForm((prevData) => {
                                                        return {
                                                          ...prevData,
                                                          orderCount: serviceData?.orderCount ? serviceData?.orderCount : 1,
                                                        };
                                                      });
                                                    }
                                                  }}
                                                  aria-describedby="basic-addon2"
                                                />
                                                <span
                                                  className="input-group-text"
                                                  id="basic-addon2"
                                                >
                                                  orders
                                                </span>
                                              </div>
                                              { form.orderCount < 1 ? (
                                                <small>
                                                  minimum 1 is required
                                                </small>
                                              ) : (
                                                <small>
                                                  {form.orderCount} new orders will be created when
                                                  this service is purchased.
                                                </small>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={`tab-pane fade ${!form.oneTimeService && "show active"
                                          }`}
                                        id="profile-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                        tabIndex="0"
                                      >
                                        <div className="row">
                                          <div className="form-group">
                                            <label>Price</label>
                                            <div className="row">
                                              <div className="col-lg-6">
                                                <div className="row">
                                                  <div className="col-lg-4">
                                                    <input
                                                      className="form-control  mobileSpace"
                                                      type="text"
                                                      value={
                                                        currency?.code
                                                          ? currency?.code
                                                          : "USD"
                                                      }
                                                      disabled
                                                    />
                                                  </div>
                                                  <div className="col-lg-8">
                                                    <input
                                                      className="form-control  mobileSpace"
                                                      type="number"
                                                      value={form.price}
                                                      onChange={(e) => {
                                                        setForm((prevData) => {
                                                          return {
                                                            ...prevData,
                                                            price: e.target.value,
                                                          };
                                                        });
                                                      }}
                                                      onWheel={() =>
                                                        document.activeElement.blur()
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-6">
                                                <div className="input-group">
                                                  <span
                                                    className="input-group-text"
                                                    id="basic-addon2"
                                                  >
                                                    every
                                                  </span>
                                                  <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-describedby="basic-addon2"
                                                    value={
                                                      form.recurringOrder?.durationTime
                                                    }
                                                    onChange={(e) => {
                                                      setForm((prevData) => {
                                                        return {
                                                          ...prevData,
                                                          recurringOrder: {
                                                            ...prevData.recurringOrder,
                                                            durationTime:
                                                              e.target.value,
                                                          },
                                                        };
                                                      });
                                                    }}
                                                    onWheel={() =>
                                                      document.activeElement.blur()
                                                    }
                                                  />
                                                  <select
                                                    value={
                                                      form.recurringOrder
                                                        ?.durationPeriod
                                                    }
                                                    className="form-control"
                                                    onChange={(e) => {
                                                      setForm((prevData) => {
                                                        return {
                                                          ...prevData,
                                                          recurringOrder: {
                                                            ...prevData.recurringOrder,
                                                            durationPeriod:
                                                              e.target.value,
                                                          },
                                                        };
                                                      });
                                                    }}
                                                  >
                                                    <option value="months">
                                                      Month
                                                    </option>
                                                    <option value="years">Year</option>
                                                    <option value="weeks">Week</option>
                                                    <option value="days">Day</option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="alert alert-warning"
                                          role="alert"
                                        >
                                          A price under $0.50 may not be supported in
                                          your payment processor.
                                        </div>

                                        <div className="form-group">
                                          <label className="mb-3">
                                            a recurring payment is received...
                                          </label>
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault"
                                              id="flexRadioDefault1"
                                              value={false}
                                              checked={
                                                form.recurringOrder?.newOrder == false
                                              }
                                              onChange={(e) => {
                                                setForm((prevData) => {
                                                  return {
                                                    ...prevData,
                                                    orderCount: 0,
                                                    recurringOrder: {
                                                      ...prevData.recurringOrder,
                                                      newOrder:
                                                        e.target.value == "true",
                                                    },
                                                  };
                                                });
                                              }}
                                            />
                                            <label
                                              className="form-check-label checkbox-label"
                                              htmlFor="flexRadiot1"
                                            >
                                              Do nothing <br />
                                              <small>
                                                Order status and due date will not
                                                change.
                                              </small>
                                            </label>
                                          </div>

                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault"
                                              id="flexRadioDefault1"
                                              checked={
                                                form.recurringOrder?.newOrder == true
                                              }
                                              value={true}
                                              onChange={(e) => {
                                                setForm((prevData) => {
                                                  return {
                                                    ...prevData,
                                                    orderCount: 1,
                                                    recurringOrder: {
                                                      ...prevData.recurringOrder,
                                                      newOrder:
                                                        e.target.value == "true",
                                                    },
                                                  };
                                                });
                                              }}
                                            />
                                            <label
                                              className="form-check-label checkbox-label"
                                              htmlFor="flexRadioDet1"
                                            >
                                              Create new orders <br />{" "}
                                              <small>
                                                If you want clients to fill out{" "}
                                                {form.quantity} new intake forms every
                                                day.
                                              </small>
                                            </label>
                                            {(form.recurringOrder?.newOrder == true ||
                                              form.recurringOrder?.newOrder ==
                                              "createNewOrder") && (
                                                <div className="col-lg-4">
                                                  <div className="input-group">
                                                    <input
                                                      type="number"
                                                      className="form-control"
                                                      placeholder=""
                                                      aria-describedby="basic-addon2"
                                                      value={form?.orderCount}
                                                      onChange={(e) => {
                                                        setForm((prevData) => {
                                                          return {
                                                            ...prevData,
                                                            orderCount: e.target.value,
                                                          };
                                                        });
                                                      }}
                                                      onWheel={() =>
                                                        document.activeElement.blur()
                                                      }
                                                    />
                                                    <span
                                                      className="input-group-text"
                                                      id="basic-addon2"
                                                    >
                                                      orders
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <h3 className="mt-4 mb-4 p-3 pt-0">Visibility</h3>
                                  <div className="form-group mb-0">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="showServicePage"
                                        checked={form.public}
                                        onChange={(e) => {
                                          setForm((prevData) => {
                                            return {
                                              ...prevData,
                                              public: e.target.checked,
                                            };
                                          });
                                        }}
                                      />
                                      <label
                                        className="form-check-label checkbox-label"
                                        htmlFor="showServePage"
                                      >
                                        Show in services page <br />
                                        <small>
                                          Choose whether to list this service in your
                                          Client Portal's services page. Service can
                                          still be used in order forms.
                                        </small>
                                      </label>
                                    </div>
                                  </div>

                                  <h3 className="mt-4 mb-4 p-3 pt-0">Message Template</h3>
                                  <Form.Select
                                    onChange={selectMessageOption}
                                    value={selectedMessageTemplateId}
                                  >
                                    <>
                                      <option value="">Select welcome template</option>
                                      {messages.map((message, index) => (
                                        <option key={index} value={message._id}>
                                          {message.name}
                                        </option>
                                      ))}
                                    </>
                                  </Form.Select>

                                  <h3 className="mt-4 mb-4 p-3 pt-0">Order Settings</h3>
                                  <div className="form-group mb-3">
                                    <label>Select Workflow</label>
                                    <Form.Select
                                      onChange={selectWorkflowOption}
                                      value={selectedWorkflow}
                                    >
                                      <>
                                        <option vlaue="" disabled>
                                          Select Workflow
                                        </option>
                                        {workflowData.map((workflow, index) => (
                                          <option key={index} value={workflow._id}>
                                            {workflow.workflowName}
                                          </option>
                                        ))}
                                      </>
                                    </Form.Select>
                                  </div>
                                  <div className="form-group mb-4">
                                    <label>Workflow default status</label>
                                    <Form.Select
                                      onChange={selectWorkflowOrderOption}
                                      value={defaultWorkflowOrderStatus}
                                    >
                                      <>
                                        {selectedWorkflowOrderStatuses.map(
                                          (orderStatuses, index) => (
                                            <option
                                              key={index}
                                              value={orderStatuses._id}
                                            >
                                              {orderStatuses.name}
                                            </option>
                                          )
                                        )}
                                      </>
                                    </Form.Select>
                                  </div>

                                  <div className="form-group mb-0">
                                    <label>Default order Assignee</label>
                                    <Form.Select
                                      onChange={(e) =>
                                        setDefaultAssignee(e.target.value)
                                      }
                                      value={defaultAssignee}
                                    >
                                      <>
                                        <option value={"*"}>Anyone</option>
                                        {clientListArr.map((client, index) => (
                                          <option key={index} value={client.value}>
                                            {client.label}
                                          </option>
                                        ))}
                                      </>
                                    </Form.Select>
                                  </div>

                                  <h3 className="mt-4 mb-4 p-3 pt-0">Pay Later</h3>
                                  <div className="form-group mb-0">
                                    <div className="form-check">
                                      <input
                                        required
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="showServicePage"
                                        checked={form.payLater}
                                        onChange={(e) => {
                                          setForm((prevData) => {
                                            return {
                                              ...prevData,
                                              payLater: e.target.checked,
                                            };
                                          });
                                        }}
                                      />
                                      <label
                                        className="form-check-label checkbox-label"
                                        htmlFor="showServicePag"
                                      >
                                        Pay Later <br />
                                        <small>
                                          Choose whether during checkout the customers
                                          should have option for Pay later
                                        </small>
                                        <br />
                                        <small>
                                          In case client selects multiple services in
                                          cart without Pay Later option then it will not
                                          show Pay Later button.
                                        </small>
                                      </label>
                                    </div>
                                  </div>

                                  <h3 className="mt-4 mb-4 p-3 pt-0">Tags</h3>
                                  <div className="form-group mb-0">
                                    <div className="input-group position-relative">
                                      <input
                                        className="form-control"
                                        placeholder="New Tag"
                                        value={tag}
                                        aria-label="New Tag"
                                        aria-describedby="basic-addon2"
                                        onChange={(e) => {
                                          setTag(e.target.value);
                                          if (e.target.value) {
                                            getFilteredTags(e.target.value);
                                          }
                                        }}
                                      />
                                      {filterTags && (
                                        <ul className="dataDropdown">
                                          {filterTags.length > 0 &&
                                            filterTags.map((tag, index) => {
                                              return (
                                                <li
                                                  key={index}
                                                  onClick={(e) => {
                                                    if (
                                                      !newTag.find(
                                                        (value) => value == tag.name
                                                      )
                                                    ) {
                                                      newTag.push(tag.name);
                                                      setFilterTags();
                                                      setTag("");
                                                    }
                                                  }}
                                                >
                                                  {tag.name}
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      )}
                                      <button
                                        className="input-group-text tagsButton"
                                        id="basic-addon2"
                                        onClick={(e) => {
                                          if (
                                            !newTag.find((tagName) => tagName == tag)
                                          ) {
                                            newTag.push(tag);
                                            setNewTag(newTag);
                                            setTag("");
                                          }
                                        }}
                                      >
                                        Add
                                      </button>
                                    </div>
                                    <div className="added-tags">
                                      {newTag.length > 0 &&
                                        newTag.map((tagName, index) => {
                                          return (
                                            <a
                                              key={index}
                                              onClick={() => {
                                                let index = newTag.findIndex(
                                                  (value) => value == tagName
                                                );
                                                newTag.splice(index, 1);
                                                setNewTag([...newTag]);
                                              }}
                                            >
                                              {tagName}{" "}
                                              <span className="cencelTag">
                                                <i className="fa-solid fa-xmark"></i>
                                              </span>
                                            </a>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  {/* <h3 className="mt-4">Orders of this service</h3>
                                  <div className="commonForm rounded-1">
                                  <div className="form-group">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      // id="newOrders"
                                      checked={form.orderService}
                                      onChange={(e) => {
                                        setForm((prevData) => {
                                          return {
                                            ...prevData,
                                            orderService:e.target.checked,
                                          };
                                        });
                                      }}
                                    />
                                    <label
                                      className="form-check-label checkbox-label"
                                      htmlFor="newOrders"
                                    >
                                      This is an add-on, don't create a new order <br />
                                      <small>
                                        An order will not be created when this service is
                                        purchased. Intake form will be appended to parent
                                        service. You can select one or more parent
                                        services below.
                                      </small>
                                      {form.orderService == true && (
                                        // [...Array(form)].map(
                                        //   (value, index) => {
                                        <Select
                                        id={"select"}
                                        className="my-react-select my-react-select--compact"
                                        isMulti
                                        classNamePrefix="lp-copy-sel"
                                        value={addServiceList.service}
                                        placeholder="All Services"
                                        options={allServices}
                                        onChange={(e) => {
                                          console.log('e',e)

                                          setAddServiceList((prevData) => {
                                            return { ...prevData, service: e };
                                          });
                                        }}
                                      />
                                        // <select
                                        //   className="form-control mt-2"
                                        //   isMulti
                                        //   value={form.addOn}
                                        //   onChange={(event) => {
                                        //     setForm((prevData) => {
                                        //       return {
                                        //         ...prevData,
                                        //         addOn: event.target.value,
                                        //       };
                                        //     });

                                        //     // let index = form.addOn.findIndex(
                                        //     //   (e) => e == event.target.value
                                        //     // );
                                        //     // if (index >= 0) {
                                        //     //   let data = [];
                                        //     //   if (form.addOn.length > 0) {
                                        //     //     data = form.addOn.slice(index, 1);
                                        //     //   }
                                        //     //   setForm((prevData) => {
                                        //     //     return {
                                        //     //       ...prevData,
                                        //     //       addOn: data,
                                        //     //     };
                                        //     //   });
                                        //     // } else {
                                        //     //   setForm((prevData) => {
                                        //     //     return {
                                        //     //       ...prevData,
                                        //     //       addOn: form.addOn.push(
                                        //     //         event.target.value
                                        //     //       ),
                                        //     //     };
                                        //     //   });
                                        //     // }
                                        //   }}
                                        // >
                                        //   <option value="" selected>
                                        //     Select Parent Service<body></body>
                                        //   </option>
                                        //   {allServices.map((val, key) => {
                                        //     return (
                                        //       <option value={val.name}>{val.name}</option>
                                        //     );
                                        //   })}
                                        // </select>
                                  ) }
                                    </label>
                                  </div>
                                  </div>
                                  </div> */}

                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="commonBtn-section">
                                <button type="button" className="btn btn-cancel" onClick={() => navigate("/services")}>Cancel</button>
                                <button
                                  className="btn btn-save"
                                  disabled={form.name == ""}
                                  onClick={(e) => {
                                    // e.stopPropagation();
                                    // setIntakeFormStatus(true);
                                    setCurrActiveTab("InTakeForm");
                                  }}
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </div>





                          <div className="btn-section text-end">

                          </div>
                        </div>
                        <div
                          className={`tab-pane fade ${currActiveTab == "InTakeForm" && "show active"
                            }`}
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                          tabIndex="0"
                        >
                          {/* <div className="commonForm mb-3 rounded-1">
                            <div className="form-group col-lg-4 mb-0 filter-drowdown">
                              <label>Select Intake Form</label>
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    return {
                                      ...prevData,
                                      intakeForm: e.target.value,
                                    };
                                  });
                                }}
                                value={form.intakeForm}
                              >
                                <option value="" default selected>
                                  No Intake Form
                                </option>
                                <option value="intake_form_1">
                                  Intake Form 1
                                </option>
                                <option value="intake_form_2">
                                  Intake Form 2
                                </option>
                              </select>
                            </div>
                          </div> */}

                          <div className="tab-content" id="pills-tabContent">
                            {form.intakeForm == "intake_form_1" && (
                              <div className="intakeForm">
                                <h3>Intake form for writing service</h3>
                                <p>
                                  Clients get access to this form after buying
                                  your service. Their order will remain{" "}
                                  <span>Pending</span> until the form is filled
                                  out.
                                </p>
                                <div className="commonForm rounded-1">
                                  <div className="form-group">
                                    <label>
                                      Upload upto 3 photos. Guidelines: Make sure
                                      to submit ONLY high quality landscape
                                      "horizontral" photos
                                    </label>

                                    {/* <div className="form-group">
                                    <label>
                                      Please tell us more about your brand that we
                                      won't be able to find on your website or any
                                      social media account: (ad least 100 words
                                      minimum) <small>Optional</small>
                                    </label>
                                    <textarea className="form-control"></textarea>
                                  </div> */}
                                    <div className="Upldimages">
                                      <input type="file" />
                                      <i className="fa-solid fa-file-arrow-up"></i>
                                      <p>
                                        <span>Browse or</span> drag and drop
                                      </p>
                                    </div>

                                    {/* <div className="previewImg">
                                    <span className="ImgSection">
                                      <img src={DummyAvatar} />
                                      <span className="Del">
                                        <i className="fa-solid fa-trash"></i>
                                      </span>
                                    </span>
                                    <span className="ImgSection">
                                      <img src={DummyAvatar} />
                                      <span className="Del">
                                        <i className="fa-solid fa-trash"></i>
                                      </span>
                                    </span>
                                  </div> */}
                                  </div>
                                  <div className="form-group">
                                    <label>
                                      Who is your audience for this featured
                                      article ? <small>Optional</small>
                                    </label>
                                    <textarea className="form-control"></textarea>
                                  </div>
                                  <div className="form-group">
                                    <label>
                                      What is the name of your brand?{" "}
                                      <small>Optional</small>
                                    </label>
                                    <input type="text" className="form-control" />
                                  </div>
                                  <div className="form-group">
                                    <label>
                                      Is there a special quote about your brand
                                      that you want us to use? if yes, what is it?{" "}
                                      <small>Optional</small>
                                    </label>
                                    <textarea className="form-control"></textarea>
                                  </div>

                                  <div className="form-group">
                                    <label>
                                      Please tell us more about your brand that we
                                      won't be able to find on your website or any
                                      social media account: (ad least 100 words
                                      minimum) <small>Optional</small>
                                    </label>
                                    <textarea className="form-control"></textarea>
                                  </div>

                                  <div className="form-group">
                                    <label>
                                      Please provide us your website, social media
                                      links <small>Optional</small>
                                    </label>
                                    <textarea className="form-control"></textarea>
                                  </div>

                                  <div className="form-group">
                                    <label>
                                      Please give us your brand bio (atleast 100
                                      words minimum) <small>Optional</small>
                                    </label>
                                    <textarea className="form-control"></textarea>
                                  </div>

                                  <div className="form-group mb-0">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="showServicePage"
                                      />
                                      <label
                                        className="form-check-label checkbox-label mb-0"
                                        htmlFor="showServicePage"
                                      >
                                        Approval and Confirmation <br />
                                        <small>
                                          I approve this content to get publish,
                                          so long as it passes the publication's
                                          guidelines
                                        </small>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {form.intakeForm == "intake_form_2" && (
                              <div className="intakeForm mb-4">
                                <h3>Intake form for writing service</h3>
                                <p>
                                  Clients get access to this form after buying
                                  your service. Their order will remain{" "}
                                  <span>Pending</span> until the form is filled
                                  out.
                                </p>
                                <div className="commonForm">
                                  <div className="form-group">
                                    <label>
                                      Please pick the publication you want this
                                      article to get published in
                                    </label>
                                    <select className="form-control">
                                      <option>Astist Weekly</option>
                                      <option>Astist Weekly</option>
                                      <option>Astist Weekly</option>
                                    </select>
                                  </div>

                                  <div className="form-group">
                                    <label>
                                      If you wrote your article. Paste a Google
                                      Doc link here. (Make sure the link access is
                                      set to "Anyone With the Link") Ignore this
                                      section if you want us to write your
                                      article. <small>Optional</small>
                                    </label>

                                    <textarea className="form-control"></textarea>
                                    <small>
                                      Click this link to see out photo submission
                                      guide: <br />{" "}
                                      <a
                                        className="text-decoration-none text-muted"
                                        href="#"
                                        target="_blank"
                                      >
                                        https://docs.google.com/document/d/1JqbZZdyzYkLjC95ps-N29sv1dDt-kEhtX9yEpVx5uw0/edit#
                                      </a>
                                    </small>
                                  </div>

                                  <div className="form-group">
                                    <label>
                                      Upload Up to 3 photos. Guidelines: Make sure
                                      to submit ONLY high quality landscape
                                      "horizontal" photos
                                    </label>
                                    <div className="Upldimages">
                                      <input type="file" />
                                      <i className="fa-solid fa-file-arrow-up"></i>
                                      <p>
                                        <span>Browse or</span> drag and drop
                                      </p>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label>
                                      Who is your audience for this featured
                                      acticle? <small>Optional</small>
                                    </label>
                                    <input type="" className="form-control" />
                                  </div>

                                  <div className="form-group">
                                    <label>
                                      What is your brand? <small>optional</small>
                                    </label>
                                    <input type="" className="form-control" />
                                  </div>

                                  <div className="form-group">
                                    <label>
                                      Is there a special quote about your brand
                                      that you want us to use? If yes, what is it?{" "}
                                      <small>optional</small>
                                    </label>
                                    <textarea className="form-control"></textarea>
                                  </div>

                                  <div className="form-group">
                                    <label>
                                      Please tell us more about your brand that we
                                      won't be able to find on your website or any
                                      social media account: (al least 100 word
                                      minimum) <small>optional</small>
                                    </label>
                                    <textarea className="form-control"></textarea>
                                  </div>

                                  <div className="form-group">
                                    <label>
                                      Please provide us your website, social media
                                      links <small>optional</small>
                                    </label>
                                    <textarea className="form-control"></textarea>
                                  </div>

                                  <div className="form-group">
                                    <label>
                                      Please give us your brand bio (at least 100
                                      word minimum) <small>optional</small>
                                    </label>
                                    <textarea className="form-control"></textarea>
                                  </div>

                                  <div className="form-group mb-0">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input checkbox-label"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                      />
                                      <label
                                        className="form-check-label mb-0"
                                        htmlFor="flexCheckDefault"
                                      >
                                        Approval and Confirmation <br />{" "}
                                        <small>
                                          I approve this content to get publish,
                                          so long as it passes the publication's
                                          guidelines
                                        </small>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            <FormBuilder
                              initialFormData={form && form.customForm}
                              setTemplateSaved={setTemplateSaved}
                              setCurrActiveTab={setCurrActiveTab}
                              setCustomIntakeFormData={setCustomIntakeFormData}
                            />
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade ${currActiveTab == "Tasks" && "show active"
                            }`}
                          id="pills-task"
                          role="tabpanel"
                          aria-labelledby="pills-task-tab"
                          tabIndex="0"
                        >
                          <div className="row">
                            <div className="col-lg-6 mx-auto">
                              <div className="commonForm mb-3 rounded-1 mt-3 p-4">
                                <div className="form-group mb-0 filter-drowdown">
                                  <div className="servicesTask">
                                    <h4>
                                      Tasks
                                      <button
                                        className="btn btn-add"
                                        onClick={() => {
                                          setIsEditMode(false);
                                          setTask({
                                            taskName: "",
                                            description: "",
                                            deadline: 0,
                                            deadlineValue: "day",
                                          });
                                          setIsModalOpen({
                                            ...isModalOpen,
                                            addEdit: true
                                          })
                                        }}
                                      >
                                        Add Task
                                      </button>
                                    </h4>

                                    <div className="form-group">
                                      <div className="table-resposnive">
                                        <table className="table mb-0 rounded-1">
                                          <tbody>
                                            {taskList &&
                                              taskList.map((taskItem, index) => {
                                                return (
                                                  <tr key={taskItem.taskName + index}>
                                                    <td>
                                                      <div className="form-check">
                                                        {/* <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id={`${taskItem.taskName}_flexCheckDefault`}
                                                      /> */}
                                                        <label
                                                          className="form-check-label"
                                                          style={{
                                                            marginBottom: "0px",
                                                          }}
                                                        // for="flexCheckDefault"
                                                        >
                                                          {taskItem.taskName}{" "}
                                                          <br />
                                                          <small>
                                                            {taskItem.description}{" "}
                                                            ({taskItem.deadline}{" "}
                                                            {
                                                              taskItem.deadlineValue
                                                            }
                                                            )
                                                          </small>
                                                        </label>
                                                        {/* <div>
                                                        <small
                                                          style={{
                                                            display: "block",
                                                          }}
                                                        >
                                                          {taskItem.deadline}{" "}
                                                          {taskItem.deadlineValue}
                                                        </small>
                                                      </div> */}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className="dropdown">
                                                        <button
                                                          className="btn btn-secondary dropdown-toggle"
                                                          type="button"
                                                          data-bs-toggle="dropdown"
                                                          aria-expanded="false"
                                                        >
                                                          <i className="fa-solid fa-ellipsis-vertical"></i>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                          <li
                                                            onClick={() => {
                                                              if (userInfo.roles.name === "admin") {
                                                                setEditValue(
                                                                  taskItem,
                                                                  index
                                                                )
                                                                setIsModalOpen({
                                                                  ...isModalOpen,
                                                                  addEdit: true
                                                                })
                                                              } else {

                                                                navigate(
                                                                  "/permissionDenied",
                                                                  {
                                                                    state: {
                                                                      noPermission: false,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            }
                                                            }
                                                          >
                                                            <a
                                                              className="dropdown-item btn btn-add"
                                                              href="#"
                                                            >
                                                              Edit
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              className="dropdown-item"
                                                              href="#"
                                                              onClick={() => {
                                                                if (
                                                                  userInfo.roles
                                                                    .name ===
                                                                  "admin"
                                                                ) {
                                                                  setDeleteTask(
                                                                    taskItem.taskName
                                                                  );
                                                                  setIsModalOpen({
                                                                    ...isModalOpen,
                                                                    delete: true
                                                                  })
                                                                } else {
                                                                  toastMessage(
                                                                    "error",
                                                                    "You dont have permission to access this resource"
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              Delete
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="btn-section text-end">
                                <button
                                  className="btn btn-save"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleFormSubmit();
                                  }}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {isModalOpen.addEdit ? (
        <AddEditTaskModal
          isModalOpen={isModalOpen.addEdit}
          isEditMode={isEditMode}
          task={task}
          setTask={setTask}
          editHandler={editHandler}
          addTaskHandler={addTaskHandler}
          handleCancelModal={handleCancelModal}
        />
      ) : (
        ""
      )}

      {isModalOpen.delete ? (
        <DeleteConfirmModal
          isDeleteConfirmModalOpen={isModalOpen.delete}
          handleDelete={deleteTaskHandler}
          handleCancelModal={handleCancelModal}
          modalContent={`Are you sure you want to delete this task: ${task.taskName}`}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default EditService;