import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AllContext } from "../../context";
import Loader from "../loader";
import { savePayLaterOrderDetail } from "../../redux/action/login";
export const ClientModal = ({
  id,
  showCart,
  setShowCart,
  deleteHandler,
  setCartCount,
  setIsLoading,
  setCouponCodeAppliedSuccessfully,
  couponCodeAppliedSuccessfully,
  isLoading,
  currency,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    toastMessage,
    redirectToLogin,
    apiDeleteMethod,
    apiPostMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);

  // //states
  const [addToCartList, setAddToCartList] = useState([]);
  const [isHaveCoupon, setisHaveCoupon] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState({});
  const [couponButton, setCouponButton] = useState(true);
  const [grandTotal, setGrandTotal] = useState(0);
  const [discount, setDiscount] = useState(null);
  const [totalDiscountOffered, setTotalDiscountOffered] = useState(null);
  const [isShowPayLater, setIsShowPayLater] = useState(false);
  const [userInformation, setUserInformation] = useState({});
  const {
    EDIT_USER_DETAILS,
    PAYLATER_CHECKOUT,
    CART,
    CLIENT_COUPON,
    CHECKOUT,
  } = allApiUrl;

  useEffect(() => {
    getAddToCartList();
    getUserDetails(userInfo.id);
  }, []);

  useEffect(() => {
    if (showCart) getAddToCartList();
    setShowCart(false);
  }, [showCart]);
  //functions
  async function getAddToCartList() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      setIsLoading(true);
      const response = await apiGetMethod(CART, "", header);
      if (response.status === 200) {
        let total = response.data.cart[0].product.map(
          (item) => item.price * item.quantity
        );
        setIsLoading(false);
        setGrandTotal(
          total.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          )
        );
        setCartCount(response.data.cart[0].product.length);
        if (response.data.couponData._id) {
          setCouponCode(response.data.cart[0].coupon);
          setCouponButton(response.data.couponData.couponCode == "" ? true :false)
          setisHaveCoupon(true);
          setCouponData(response.data.couponData);
          let fixedAmount = response.data.couponData.fixedAmount;
          response.data.couponData.applies_to.forEach((name) => {
            if (!Array.isArray(name.service) || !name.service.length) {
              response.data.cart[0]?.product.forEach((product, index) => {
                // if (item === product.id._id) {
                if (fixedAmount) {
                  response.data.cart[0].product[index].discountPrice =
                    response.data.cart[0].product[index].price - name.discount;
                  if (response.data.cart[0].product[index].discountPrice < 0) {
                    response.data.cart[0].product[index].discountPrice = 0;
                  }
                } else {
                  response.data.cart[0].product[index].discountPrice =
                    response.data.cart[0].product[index].price -
                    (
                      (name.discount / 100) *
                      response.data.cart[0].product[index].price
                    ).toFixed(2);
                  if (response.data.cart[0].product[index].discountPrice < 0) {
                    response.data.cart[0].product[index].discountPrice = 0;
                  }
                }
                // }
              });
            } else {
              name.service.forEach((item) => {
                response.data.cart[0]?.product.forEach((product, index) => {
                  if (item === product.id._id) {
                    if (fixedAmount) {
                      response.data.cart[0].product[index].discountPrice =
                        response.data.cart[0].product[index].price -
                        name.discount;
                      if (
                        response.data.cart[0].product[index].discountPrice < 0
                      ) {
                        response.data.cart[0].product[index].discountPrice = 0;
                      }
                    } else {
                      response.data.cart[0].product[index].discountPrice =
                        response.data.cart[0].product[index].price -
                        (
                          (name.discount / 100) *
                          response.data.cart[0].product[index].price
                        ).toFixed(2);
                      if (
                        response.data.cart[0].product[index].discountPrice < 0
                      ) {
                        response.data.cart[0].product[index].discountPrice = 0;
                      }
                    }
                  }
                });
              });
            }
          });
          setAddToCartList(response.data.cart);
          setCouponCodeAppliedSuccessfully(true);
        } else {
          setCouponCode("");
          setAddToCartList(response.data.cart);
        }
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      if (err.data.message === "Cart is empty") {
        setAddToCartList([]);
      }
      setIsLoading(false);
      // toastMessage("error", err.data?.message);
      // toastMessage("error", err.data?.message);
    }
  }

  const getUserDetails = (userProfileId) => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(EDIT_USER_DETAILS, userProfileId, header)
      .then((res) => {
        setUserInformation(res.data.user[0]);
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  };

  async function updateQtyFunc(value) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const data = {
        product: {
          id: value.id._id,
          price: value.price,
          name: value.id.name,
          quantity: parseInt(value.quantity),
        },
        addToCart: false,
      };
      if (parseInt(value.quantity) == 0 || value.quantity === "") {
        deleteHandler(value.id._id);
      } else {
        const response = await apiPostMethod(CART, data, header);
        if (response.status === 200) {
          getAddToCartList();
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
      redirectToLogin(dispatch, navigate, err);
      toastMessage("error", err.data?.message);
    }
  }
  async function removeCouponHandler() {
    setCouponCode("");
    applyCouponHandler("");
  }
  async function applyCouponHandler(couponString) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {
      couponCode: couponString !== "" ? couponCode : couponString,
    };

    try {
      const response = await apiPostMethod(CLIENT_COUPON, data, header);
      if (response.status === 200) {
        setCouponButton(couponString == "" ? true : false)
        setCouponList(response.data);
        // setCouponCodeAppliedSuccessfully(true);
        setCouponStatus(false);
        getAddToCartList();
        if (response.data.message === "No coupon found") {
          setCouponCodeAppliedSuccessfully(false);
        }
        // let fixedAmount = response.data.couponData[0].fixedAmount;
        // response.data.couponData[0].applies_to.forEach((name) => {
        //   name.service.forEach((item) => {
        //     addToCartList[0]?.product.forEach((product, index) => {
        //       if (item === product.id._id) {
        //         setAddToCartList((prevData) => {
        //           if (fixedAmount) {
        //             prevData[0].product[index].discountPrice =
        //               prevData[0].product[index].price - name.discount;
        //             return [...prevData];
        //           } else {
        //             prevData[0].product[index].discountPrice =
        //               prevData[0].product[index].price -
        //               (
        //                 (name.discount / 100) *
        //                 prevData[0].product[index].price
        //               ).toFixed(2);
        //             return [...prevData];
        //           }
        //         });
        //       }
        //     });
        //   });
        // });
        //

        // response.data.coupons[0].applies_to[0].service.forEach((item) => {
        //   addToCartList[0]?.product.forEach((product, index) => {
        //     if (item === product.id._id) {
        //       setAddToCartList((prevData) => {
        //         prevData[0].product[index].discountPrice =
        //           prevData[0].product[index].price -
        //           response.data.coupons[0].applies_to[0].discount;
        //         return [...prevData];
        //       });
        //     }
        //   });
        // });

        setIsLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      if (
        err.data.message === "Invalid coupon code" ||
        err.data.message === "No coupon found" ||
        err.data.message === "Already Avail benefits" ||
        err.data.message === "Coupon not applicable on services" ||
        err.data.message === "Coupon expired" ||
        err.data.message === "Valid for new customer only"
      ) {
        setCouponStatus(true);
        setCouponErrMsg(err.data.message);
        setCouponCodeAppliedSuccessfully(false);
      }
      getAddToCartList();
      // toastMessage("error", err.data?.message);
    }
  }
  const [couponStatus, setCouponStatus] = useState(false);
  const [couponErrMsg, setCouponErrMsg] = useState("");
  useEffect(() => {
    if (
      addToCartList &&
      addToCartList[0] &&
      addToCartList[0].product.length > 0
    ) {
      let total = addToCartList[0].product.map((item) => {
        if (item.discountPrice || item.discountPrice === 0) {
          return item.discountPrice * item.quantity;
        } else {
          return item.price * item.quantity;
        }
      });
      setGrandTotal(
        total.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
      );
      const isPayLater = addToCartList[0]?.product.every(
        (cart) => cart.id.payLater
      );
      setIsShowPayLater(isPayLater);
    }
    savedOnDiscountHandler(addToCartList[0]?.product);
  }, [addToCartList]);
  const [particularDiscount, setParticularDiscount] = useState([]);
  function savedOnDiscountHandler(value) {
    let savedPriceOnDiscount = value?.map((item) => {
      if (item.discountPrice || item.discountPrice === 0) {
        return item.price * item.quantity - item.discountPrice * item.quantity;
      } else {
        return 0;
      }
    });

    setParticularDiscount(savedPriceOnDiscount);
    setTotalDiscountOffered(
      savedPriceOnDiscount?.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );
  }
  function checkoutRedirect() {
    navigate("/payment", {
      state: {
        from: "cart",
      },
    });
  }
  async function checkoutHandler() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const newArray = addToCartList[0].product.map(
      ({ discountPrice, ...keepAttrs }) => keepAttrs
    );
    const productItem = newArray.map((obj) => ({ ...obj, id: obj.id._id }));
    let data = {
      couponCode,
      // { id: "63451d2a9e82f10e4f596267", quantity: 5, price: 9 },
      // { id: "63353ae41efff3e5356875f8", quantity: 5, price: 9 },
      // { id: "63353aed1efff3e5356875fa", quantity: 1, price: 10 }

      product: productItem,
      type: "cart",
    };
    try {
      const response = await apiPostMethod(CHECKOUT, data, header);
      if (response.status === 200) {
        openInNewTab(response.data.url);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data?.message);
    }
  }
  function openInNewTab(url) {
    var win = window.open(url, "_self");
    win.focus();
  }

  const handlePayLater = async () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    const data = {
      product: addToCartList[0]?.product,
      billingAddress: userInformation.billingAddress,
      city: userInformation.city,
      company: userInformation.company,
      postal: userInformation.postal,
      state: userInformation.state,
      taxId: userInformation.taxId,
      name: userInformation.userId.firstName,
      chargeAmount: grandTotal,
    };

    setIsLoading(true);

    try {
      const response = await apiPostMethod(PAYLATER_CHECKOUT, data, header);
      if (response.status === 200) {
        setIsLoading(false);
        dispatch(
          savePayLaterOrderDetail({
            ...data,
            ...userInformation,
          })
        );
        navigate("/invoice/payLaterOrderSuccess");
      }
    } catch (err) {
      setIsLoading(true);
    }
  };

  return (
    <div
      className="modal fade cart-modal"
      id="addcartModal"
      tabIndex="-1"
      aria-labelledby="modal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <Loader isLoading={isLoading} />
            <div className="cart-content">
              <table className="table">
                <thead>
                  {addToCartList &&
                    addToCartList.length > 0 &&
                    addToCartList[0]?.product.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <strong>{item?.id?.name}</strong> <br />
                            {item.hasOwnProperty("discountPrice") &&
                              couponCodeAppliedSuccessfully && (
                                <del
                                  className="mb-0 mt-1"
                                  style={{
                                    color: "grey",
                                    fontZize: "0.8em",
                                  }}
                                >
                                  {(currency?.symbol ? currency?.symbol : "$") +
                                    item?.price?.toFixed(2)}
                                </del>
                              )}
                            <p
                              className="mb-0 mt-1"
                              style={{
                                color: "grey",
                                fontZize: "0.8em",
                              }}
                            >
                              {(currency?.symbol ? currency?.symbol : "$") +
                                `${
                                  item.hasOwnProperty("discountPrice") &&
                                  couponCodeAppliedSuccessfully
                                    ? item?.discountPrice?.toFixed(2)
                                    : item.id.oneTimeService == true
                                    ? item?.price?.toFixed(2)
                                    : item.price?.toFixed(2) +
                                      "/" +
                                      +item.id.recurringOrder.durationTime +
                                      " " +
                                      item.id.recurringOrder?.durationPeriod
                                }`}
                            </p>
                            {couponCodeAppliedSuccessfully && (
                              <>
                                {particularDiscount &&
                                particularDiscount[index] > 0 ? (
                                  <div className="successMsg">
                                    Success! You save{" "}
                                    {currency?.symbol ? currency?.symbol : "$"}
                                    {particularDiscount &&
                                      particularDiscount[index].toFixed(2)}
                                  </div>
                                ) : (
                                  <div
                                    className="errorMessage"
                                    style={{
                                      color: "#f30d0d",
                                      backgroundColor: "rgb(242 209 209)",
                                    }}
                                  >
                                    This coupon is not applied on this service.
                                  </div>
                                )}
                              </>
                            )}
                          </td>
                          <td>
                            <div className="text-end cartQty">
                              <a>
                                <i className="fa-solid fa-xmark"></i>
                              </a>
                              <input
                                className="rounded-1"
                                type="text"
                                onBlur={(e) => {
                                  updateQtyFunc(item);
                                }}
                                onChange={(e) => {
                                  setAddToCartList((prevData) => {
                                    prevData[0].product[index].quantity =
                                      e.target.value;
                                    return [...prevData];
                                  });
                                }}
                                value={item.quantity}
                              />
                              <a
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  deleteHandler(item?.id?._id);
                                  setCartCount(
                                    addToCartList[0].product.length - 1
                                  );
                                }}
                              >
                                Remove
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}

                  <tr>
                    <td>
                      {/* {couponCodeAppliedSuccessfully && (
                        <>
                          {totalDiscountOffered > 0 ? (
                            <div
                              style={{
                                borderLeft: "6px solid #28a745",
                                color: "#138c2f",
                                marginTop: "1rem!important",
                                backgroundColor: "#d4edda",
                                borderColor: "#c3e6cb",
                              }}
                            >
                              Success! You save $
                              {totalDiscountOffered.toFixed(2)}
                            </div>
                          ) : (
                            <div
                              style={{
                                borderLeft: "6px solid red",
                                color: "red",
                              }}
                            >
                              {"This coupon is not applied on this service."}
                            </div>
                          )}
                        </>
                      )} */}
                      <small
                        onClick={() => setisHaveCoupon(!isHaveCoupon)}
                        style={{
                          cursor: "pointer",
                          color: "grey",
                          fontSize: "0.775em",
                        }}
                      >
                        Have a coupon ?
                      </small>
                      {isHaveCoupon && (
                        <div className="input-group col-lg-5">
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setCouponCode(e.target.value)}
                            value={couponCode}
                          />
                          {
                          couponButton == true   ?
                            <button
                              className="input-group-text"
                              id="basic-addon2"
                              onClick={applyCouponHandler}
                            >
                              Apply
                            </button> : <button
                              className="input-group-text"
                              id="basic-addon"
                              onClick={removeCouponHandler}
                            >
                              Remove
                            </button>
                            }
                        </div>
                      )}
                      {couponStatus && (
                        <div
                          className="mt-3 errorMessage"
                          style={{
                            color: "#f30d0d",
                            backgroundColor: "rgb(242 209 209)",
                          }}
                        >
                          {couponErrMsg}
                        </div>
                      )}
                    </td>
                    <td>
                      <div className="text-end cartQty">
                        <strong>Total</strong>
                        <strong>
                          {currency?.symbol ? currency?.symbol : "$"}{" "}
                          {grandTotal?.toFixed(2)}
                        </strong>
                      </div>
                    </td>
                  </tr>
                </thead>
              </table>

              <div className="modalBtns">
                <div className="row">
                  <div className="col-lg-6">
                    <a data-bs-dismiss="modal" href="#">
                      Continue Shopping
                    </a>
                  </div>
                  {grandTotal <= 0 ? (
                    <div
                      className="text-end text-danger"
                      style={{ fontSize: "14px" }}
                    >
                      {`Minimum cart total should be greater than ${
                        currency?.symbol ? currency?.symbol : "$"
                      } 1`}
                    </div>
                  ) : (
                    <div className="text-end">
                      {/* <button
                        // disabled={checkOutButtonDisable}
                        className="btn btn-primary"
                        // onClick={checkoutHandler}
                        onClick={checkoutRedirect}
                        data-bs-dismiss="modal"
                        // data-bs-toggle="modal"
                        // data-bs-target="#modalConfirmation"
                      >
                        Pay Later
                      </button> */}

                      {isShowPayLater && (
                        <button
                          className="btn pay-later-btn me-2"
                          onClick={handlePayLater}
                          data-bs-dismiss="modal"
                        >
                          Pay Later
                        </button>
                      )}
                      <button
                        // disabled={checkOutButtonDisable}
                        className="btn btn-primary"
                        // onClick={checkoutHandler}
                        onClick={checkoutRedirect}
                        data-bs-dismiss="modal"
                        // data-bs-toggle="modal"
                        // data-bs-target="#modalConfirmation"
                      >
                        Checkout
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
