import React from "react";
import TableShop from "../../components/Tables/shop";

const Shop = () => {
  return (
    <div>
      <TableShop isPage={true} />
    </div>
  );
};
export default Shop;
