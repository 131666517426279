import LoadingOverlay from "react-loading-overlay";
import Avatar from "../../../assets/img/avatar.png";
import Progress_bar from "../../ProgressBar";
import noData from "../../../assets/img/nodata-img.svg";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AllContext} from "../../../context";
import {useDispatch, useSelector} from "react-redux";
import {convertToUpperCase} from "../../../shared/functions";
import PageLoader from "../../pageLoader";
import PaginationNewUI from "../../PaginationNewUI";
LoadingOverlay.propTypes = undefined;

const TableClients = ({searchText, isLoadingSearch, setIsLoadingSearch}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const [clientsList, setClientsList] = useState([]);
  const [countStatus, setCountStatus] = useState({});
  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords

  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("all");

  const navigate = useNavigate();

  const context = useContext(AllContext);
  const {
    apiPostMethod,
    allApiUrl,
    toastMessage,
    redirectToLogin,
  } = context;

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  /*const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);*/
  const dispatch = useDispatch();
  const header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };

  useEffect(() => {
    if (isLoadingSearch) {
      filterClients();
    }
  }, [isLoadingSearch])
  useEffect(() => {
    filterClients();
  }, [activeTab, itemOffset, itemsPerPage]);

  function filterClients() {
    if (!initialLoading) {
      setIsLoading(true);
    }
    const data = {};

    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.role = activeTab;

    if (searchText) { data.searchText = searchText; }
    apiPostMethod(allApiUrl.FILTER_CLIENTS_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setClientsList([...res.data.filteredClients]);
          setCountStatus({...res.data.countRole});
          setTabs([...res.data.roleList]);
        }
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        // redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      });
  }

  /*function deleteClient(couponId) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.DELETE_CLIENT, couponId, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function deleteMultiple() {
    setIsLoading(true);
    multiDeleteId.map((row) => {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiDeleteMethod(allApiUrl.DELETE_CLIENT, row, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          setIsLoading(false);
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    });
  }
  function checkedAll() {
    if (clientsList.length === multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = clientsList.map((e) => {
        return e.userId?._id;
      });
      setMultiDeleteId(ids);
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }
  const roleList = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );
  const { clients: clientRoles } = roleList;
  function signInClientHandler(email) {
    const data = {
      email: email,
      adminEmail: userInfo.email,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    apiPostMethod(allApiUrl.SIGN_AS_CLIENT, data, header)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          navigate("/home", {
            state: {
              showBackToClientButton: true,
            },
          });
          dispatch(saveLoginUserAccessToken(res.data.token));
          dispatch(saveLoginUserInfo({ ...res.data, showButton: true }));
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  const [crfFields, setCrfFields] = useState([]);
  const [columnArray, setColumnArray] = useState([]);
  useEffect(() => {
  }, [crfFields, columnArray])
  useEffect(() => {
    apiGetMethod(allApiUrl.GET_CRM_FIELDS, "", header)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setCrfFields(res.data);
        }
      }).catch((err) => {
    });
  }, []);

  const getColumnVal = (item, o) => {
    let content = '-';
    if (item?.crmFields) {
      const matchData = _.find(item?.crmFields, crm => crm.id === o.id);
      if (matchData) {
        if (matchData.element === 'Checkboxes' || matchData.element === 'Dropdown') {
          content =  _.join(_.map(_.filter(matchData.options, o2 => {
            return _.includes(matchData.value, o2.value)
          }), o3 => o3.text), ', ');
        } else {
          content = matchData.value;
        }
      }
    }
    return content;
  }

  const onChangeCheckbox = (id, val) => {
    if (val) {
      multiDeleteId.push(id);
      setMultiDeleteId([..._.uniq(multiDeleteId)]);
    } else {
      _.remove(multiDeleteId, (o) => o === id);
      setMultiDeleteId([...multiDeleteId]);
    }
  }*/
  const getTabCount = (val) => {
    return countStatus[val];
  }
  const TabsList = () => {
    const tempArray = [];
    tabs.map((item, idx) => {
      tempArray.push(
        <li key={idx} className="nav-item" role="presentation">
          <button
            className={activeTab.toLowerCase() === item.toLowerCase() ? 'nav-link active' : 'nav-link'}
            id="pills-overdue-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-overdue"
            type="button"
            role="tab"
            aria-controls="pills-overdue"
            aria-selected="true"
            onClick={() => {
              // setMultiDeleteId([])
              setActiveTab(item);
            }}
          >
            <span>{getTabCount(item)}{" "}</span>{convertToUpperCase(item)}
          </button>
        </li>
      )
    });
    return tempArray;
  }
  return (
    <>
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section horizontalPadding">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h2>Clients</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-filter">
                <PaginationNewUI
                  itemsPerPage={itemsPerPage}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  pageCount={pageCount}
                  totalRecordsCount={totalRecordsCount}
                  setItemsPerPage={setItemsPerPage}
                  mainClassName="col-lg-12"
                />
              </div>
            </div>
          </div>

          <LoadingOverlay
              styles={{
                spinner: (base) => ({
                ...base,
                width: "50px",
                "& svg circle": {
                    stroke: "#007D87",
                },
                }),
                overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 0.5)",
                }),
            }}
            active={isLoading}
            spinner
            text="Loading content..."
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="common-tabs">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <TabsList />
                  </ul>

                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-overdue"
                      role="tabpanel"
                      aria-labelledby="pills-overdue-tab"
                      tabIndex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                          <tr>
                            {/*<th className="ps-4 w65-px">
                                {clientRoles._delete && (
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="item1"
                                      checked={clientsList?.length && clientsList?.length === multiDeleteId?.length}
                                      onChange={() => checkedAll()}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="item1"
                                    ></label>
                                  </div>
                                )}
                              </th>*/}
                            <th>Name</th>
                            <th>Company</th>
                            <th>Account Manager</th>
                            <th>Created on</th>
                            <th>Status</th>
                            <th>Progress Bar</th>
                            {/*<th></th>*/}
                          </tr>
                          </thead>
                          <tbody>
                          {clientsList.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                {/*<td className="ps-4 w65-px">
                                    {clientRoles._delete && (
                                      <div className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"contact" + item.userId?._id}
                                          checked={!!(_.includes(multiDeleteId, item?.userId?._id))}
                                          onChange={(e) => {
                                            onChangeCheckbox(
                                              item?.userId?._id,
                                              e.target.checked
                                            );
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"contact" + item.userId?._id}
                                        ></label>
                                      </div>
                                    )}
                                  </td>*/}
                                <td>
                                  <div className="clientSection">
                                    <div className="img">
                                      <img
                                        src={
                                          item.userId && item.userId.image
                                            ? item.userId.image
                                            : Avatar
                                        }
                                      />
                                    </div>
                                    <div className="clientName">
                                      <p
                                        onClick={() => {
                                          document.getElementById('searchModalCloseId').click();
                                          navigate(
                                            `/view-client/${item.userId?._id}`
                                          )
                                        }}
                                      >
                                        <a>
                                          {`${
                                            item.userId?.firstName || ""
                                          } ${
                                            item.userId?.lastName || ""
                                          }`}
                                        </a>
                                      </p>
                                      <p className="clientEmail">
                                        {item.userId?.email}
                                      </p>
                                    </div>
                                  </div>
                                </td>

                                <td>{item.company}</td>
                                <td>
                                  {item.accountManager
                                    ? item.accountManager.firstName +
                                    " " +
                                    item.accountManager.lastName
                                    : ""}
                                </td>
                                <td>{item?.updatedDate}</td>
                                <td>
                                    <span className="pill pill-grey fw-normal fs-12">
                                      {item?.accountStatus ? item?.accountStatus?.name : item.role}
                                    </span>
                                </td>
                                {/*{!!columnArray?.length && <>
                                    {columnArray.map(o => {
                                      return <td>{ getColumnVal(item, o)}</td>
                                    })}
                                  </>}*/}

                                <td>
                                  <Progress_bar
                                    bgcolor="#46850d"
                                    progress={item?.percentage}
                                    height={10}
                                  />
                                </td>
                                {/*<td>
                                    {clientRoles.add_edit_login && (
                                      <div className="dropdown">
                                        <button
                                          className="btn btn-secondary dropdown-toggle btn-info"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() =>
                                                navigate(
                                                  `/edit-client/${item.userId?._id}`
                                                )
                                              }
                                            >
                                              Edit
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() =>
                                                signInClientHandler(
                                                  item.userId?.email
                                                )
                                              }
                                            >
                                              Sign in as user
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                              onClick={() =>
                                                navigate(`/add-invoice`, {
                                                  state: {
                                                    selectedUser:
                                                    item.userId?._id,
                                                  },
                                                })
                                              }
                                            >
                                              New invoice
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              // href="#"
                                              onClick={() =>
                                                navigate("/add-ticket", {
                                                  state: {
                                                    mailTo: item.userId,
                                                    from: "client",
                                                  },
                                                })
                                              }
                                            >
                                              New ticket
                                            </a>
                                          </li>
                                          {clientRoles._delete && (
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#deleteModal"
                                                onClick={() => {
                                                  setClientId(
                                                    item.userId?._id
                                                  );
                                                  setClientName(
                                                    item.userId?.email
                                                  );
                                                }}
                                              >
                                                Delete
                                              </a>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    )}
                                  </td>*/}
                              </tr>
                            );
                          })}
                          {clientsList.length <= 0 && (
                            <tr>
                              <td colSpan={7}>
                                <div className="noData-section">
                                  <img src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                          </tbody>
                        </table>
                      </div>
                      {/*{!!(multiDeleteId.length) && (
                          <div className="table-bottom-option">
                            <div className="row">
                              <div className="col-lg-3 col-md-4">
                                <div className="input-group">
                                  <select
                                    id="selectOption"
                                    value={selectOption.target}
                                    onChange={(e) => selectOption(e)}
                                    className="form-control"
                                  >
                                    <option value="">With Selected...</option>
                                    <option value="Delete">Delete</option>
                                  </select>
                                  <button
                                    className="btn btn-apply"
                                    onClick={() => {
                                      if (selectedOption == "Delete") {
                                        deleteMultiple();
                                      }
                                    }}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      )}
    </>
  )
}
export default TableClients;
