import React, { useContext, useEffect, useState } from "react";
import noData from "../../../assets/img/nodata-img.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AllContext } from "../../../context";
import {convertToUpperCase, formatMonthNameAndDate} from "../../../shared/functions";
import PageLoader from "../../pageLoader";
// import Pagination from "../../Pagination";
import LoadingOverlay from 'react-loading-overlay';
import TableTabList from "../TabList";
import PaginationNewUI from "../../PaginationNewUI";
/*import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import * as _ from "lodash";*/
LoadingOverlay.propTypes = undefined;

const TableInvoices = ({searchText, isLoadingSearch, setIsLoadingSearch}) => {
  const context = useContext(AllContext);
  const navigate = useNavigate();
  /*const [showDatePicker, setShowDatePicker] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [isActive, setActive] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [startdateFilter, setstartDateFilter] = useState(0);
  const [enddateFilter, setendDateFilter] = useState(0);
  const [allClient, setAllClient] = useState([]);
  const [isSingleChecked, setIsSingleChecked] = useState(false);*/

  const [isLoading, setIsLoading] = useState(false);
  // const dispatch = useDispatch();
  const {
    allApiUrl,
    /*toastMessage,
    apiDeleteMethod,
    apiGetMethod,
    apiPutMethod,*/
    apiPostMethod,
    redirectToLogin, toastMessage
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  const dispatch = useDispatch();

  const [countStatus, setCountStatus] = useState({});
  const [tabs, setTabs] = useState([]);

  const [invoiceList, setInvoiceList] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [initialLoading, setInitialLoading] = useState(true);

  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  
  useEffect(() => {
    if (isLoadingSearch) {
      filterInvoices();
    }
  }, [isLoadingSearch])
  useEffect(() => {
    filterInvoices();
  }, [activeTab, itemOffset, itemsPerPage]);

  const filterInvoices = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    // const data = getFiltersData();
    const data = {};

    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.status = activeTab;
    if (searchText) { data.searchText = searchText; }

    apiPostMethod(allApiUrl.FILTER_INVOICE_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setInvoiceList([...res.data.filteredInvoices]);
          setCountStatus({...res.data.countStatus});
          setTabs(res.data.statusListNames);
        }
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        // redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      });
  };

  /*const getFiltersData = () => {
    const data = {};
    if (clientList.length > 0) {
      let clientSelectedList = [...clientList];
      clientSelectedList.map((list, index) => {
        clientSelectedList[index] = list.value;
      });
      data.client = clientSelectedList;
    }
    if (startdateFilter > 0) data.startdateFilter = startdateFilter;
    if (enddateFilter > 0) data.enddateFilter = enddateFilter;


    let count = 0;
    for (let key in data) {
      count += 1;
    }
    setFilterCount(count);
    return data;
  }

  useEffect(() => {
    if (!filterCount && !initialLoading) {
      filterInvoices();
    }
  }, [filterCount]);

  const ToggleClass = () => {
    setActive(!isActive);
  };
  function listSelectedClient() {
    let selectedList = "";
    clientList.map((list, index) => {
      selectedList +=
        list.label + `${index !== clientList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }

  function deleteMultiple() {
    bulkDeleteId.map((row) => {
      deleteInvoice(row);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
    filterInvoices();
  }

  function updateMultiple(status) {
    let data = {
      status: status,
    };
    bulkDeleteId.map((row) => {
      updateInvoice(row, data);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
  }

  function updateInvoice(id, data) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(allApiUrl.INVOICE + "/" + id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        filterInvoices();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  useEffect(() => {
    setBulkDeleteId([])
  }, [activeTab])
  function checkedAll() {
    if (invoiceList.length === bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = invoiceList.map((e) => {
        return e._id;
      });
      setBulkDeleteId([...ids]);
    }
  }

  const onChangeCheckbox = (id, val) => {
    if (val) {
      bulkDeleteId.push(id);
      setBulkDeleteId([..._.uniq(bulkDeleteId)]);
    } else {
      _.remove(bulkDeleteId, (o) => o === id);
      setBulkDeleteId( [...bulkDeleteId]);
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }
  const deleteInvoice = (id) => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.INVOICE + "/" + id, "", header)
      .then((res) => {
        if (res.status === 200) {
          toastMessage("success", res.data.message);

          filterInvoices();
        }
      })
      .catch((err) => {
        console.log("error", err);
        // toastMessage("error", err.);
      });
  };

  function updateClient(Details) {
    if (clientList.find((row) => row.value == Details._id)) {
      let index = clientList.findIndex((row) => row.value == Details._id);
      clientList.splice(index, 1);
    } else {
      let label = "";
      if (Details.firstName && Details.lastName) {
        label = Details.firstName + " " + Details.lastName;
      } else {
        if (Details.firstName || Details.lastName) {
          if (Details.firstName) {
            label = Details.firstName;
          } else {
            label = Details.lastName;
          }
        } else {
          label = Details.email;
        }
      }
      let data = {
        label: label,
        value: Details._id,
      };
      clientList.push(data);
    }
    setClientList(clientList);
  }

  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };*/
  return (
    <>
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section horizontalPadding">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h2>Invoices</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-filter">
                <PaginationNewUI
                  itemsPerPage={itemsPerPage}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  pageCount={pageCount}
                  totalRecordsCount={totalRecordsCount}
                  setItemsPerPage={setItemsPerPage}
                  mainClassName="col-lg-12"
                />
              </div>
            </div>
          </div>
          <LoadingOverlay
            styles={{
              spinner: (base) => ({
              ...base,
              width: "50px",
              "& svg circle": {
                  stroke: "#007D87",
              },
              }),
              overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 0.5)",
              }),
          }}
          active={isLoading}
          spinner
          text="Loading content..."
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="common-tabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <TableTabList tabs={tabs} activeTab={activeTab} countStatus={countStatus} setActiveTab={setActiveTab} />
                  </ul>
                  <div
                    className="tab-content invoiceTable"
                    id="pills-tabContent"
                  >
                    <div
                      className="tab-pane fade active show"
                      id="pills-overdue"
                      role="tabpanel"
                      aria-labelledby="pills-overdue-tab"
                      tabIndex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                          <tr>
                            {/*<th className="ps-4 w65-px">
                              <div className="form-check">
                                <input
                                  className="selectAll form-check-input"
                                  type="checkbox"
                                  id="selectAll"
                                  checked={invoiceList.length && invoiceList.length === bulkDeleteId.length}
                                  onChange={() => checkedAll()}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="selectAll"
                                ></label>
                              </div>
                            </th>*/}
                            <th>Client</th>
                            <th>Total ($)</th>
                            <th>Status</th>
                            <th>Created Date</th>
                            <th>Paid Date</th>
                            <th>Invoice Number</th>
                          </tr>
                          </thead>
                          <tbody>
                          {invoiceList?.map((item, idx) => {
                            return (
                              <tr key={idx} style={{ cursor: "pointer" }}>
                                {/*<td className="ps-4 w65-px">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="item2"
                                      checked={!!(_.includes(bulkDeleteId, item._id))}
                                      onChange={(e) => {
                                        setIsSingleChecked(e.target.checked);
                                        onChangeCheckbox(
                                          item._id,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor="item2"
                                      className="form-check-label"
                                    ></label>
                                  </div>
                                </td>*/}
                                <td>
                                  <div className="clientSection">
                                    <div
                                      className="clientName"
                                      onClick={() => {
                                        document.getElementById('searchModalCloseId').click();
                                        navigate(
                                          `/view-invoice/${item.orderId}`,
                                          {
                                            state: {
                                              _id: item._id,
                                            },
                                          }
                                        )
                                      }}
                                    >
                                      <p>
                                        <>
                                            <span
                                              style={{
                                                display: "block",
                                                fontWeight: "bold",
                                                color: "black",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {item.userDetails?.firstName
                                                ? `${
                                                  item.userDetails?.firstName
                                                } ${" "}  ${
                                                  item.userDetails
                                                    ?.lastName || " "
                                                }`
                                                : item.userDetails?.email}
                                            </span>
                                          <small>{item.orderId}</small>
                                        </>
                                      </p>
                                    </div>
                                  </div>
                                </td>

                                <td
                                  onClick={() => {
                                    document.getElementById('searchModalCloseId').click();
                                    navigate(
                                      `/view-invoice/${item.orderId}`,
                                      {
                                        state: {
                                          _id: item._id,
                                        },
                                      }
                                    )
                                  }}
                                >
                                  {" "}
                                  ${" "}
                                  {item &&
                                  item.partialUpfront &&
                                  item.partialUpfront.status === true
                                    ? item.paymentDue || item.paymentDue == 0
                                      ? item.totalPrice != 0
                                        ? (
                                          item.totalPrice - item.paymentDue
                                        ).toFixed(2)
                                        : 0.0
                                      : item.totalPrice != 0
                                        ? (
                                          item.totalPrice - item.paymentCredit
                                        ).toFixed(2)
                                        : 0
                                    : item.totalPrice.toFixed(2)}
                                </td>
                                <td
                                  onClick={() => {
                                    document.getElementById('searchModalCloseId').click();
                                    navigate(
                                      `/view-invoice/${item.orderId}`,
                                      {
                                        state: {
                                          _id: item._id,
                                        },
                                      }
                                    )
                                  }}
                                >
                                    <span className="pill pill-grey fw-normal fs-12">
                                      {item.status}
                                    </span>
                                </td>

                                <td
                                  onClick={() => {
                                    document.getElementById('searchModalCloseId').click();
                                    navigate(
                                      `/view-invoice/${item.orderId}`,
                                      {
                                        state: {
                                          _id: item._id,
                                        },
                                      }
                                    )
                                  }}
                                >
                                  {formatMonthNameAndDate(
                                    item.createdDate,
                                    companyData.timezone,
                                    true
                                  )}
                                </td>
                                <td
                                  onClick={() => {
                                    document.getElementById('searchModalCloseId').click();
                                    navigate(
                                      `/view-invoice/${item.orderId}`,
                                      {
                                        state: {
                                          _id: item._id,
                                        },
                                      }
                                    )
                                  }}
                                >
                                  {item.paidDate
                                    ? formatMonthNameAndDate(
                                      item.createdDate,
                                      companyData.timezone,
                                      true
                                    )
                                    : "--"}
                                </td>
                                <td
                                  onClick={() => {
                                    document.getElementById('searchModalCloseId').click();
                                    navigate(
                                      `/view-invoice/${item.orderId}`,
                                      {
                                        state: {
                                          _id: item._id,
                                        },
                                      }
                                    )
                                  }}
                                >
                                  {item.invoiceId}
                                </td>
                              </tr>
                            );
                          })}
                          {invoiceList.length <= 0 && (
                            <tr>
                              <td colSpan={7}>
                                <div className="noData-section">
                                  <img src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                          </tbody>
                        </table>
                      </div>
                      {/* <Pagination itemsPerPage={itemsPerPage} setItemOffset={setItemOffset} pageCount={pageCount} setItemsPerPage={setItemsPerPage} /> */}
                    </div>
                  </div>
                  {/*{(bulkDeleteId.length > 0 || isSingleChecked) && (
                    <div className="table-bottom-option">
                      <div className="row">
                        <div className="col-lg-3 col-md-4">
                          <div className="input-group">
                            <select
                              id="selectOption"
                              value={selectOption.target}
                              onChange={(e) => selectOption(e)}
                              className="form-control"
                            >
                              <option>With Selected...</option>
                              <option value="paid">Mark as paid</option>
                              <option value="unpaid">Mark as unpaid</option>
                              <option value="void">Mark as void</option>
                              <option>Delete</option>
                            </select>
                            <button
                              className="btn btn-apply"
                              onClick={() => {
                                if (selectedOption === "Delete") {
                                  deleteMultiple();
                                } else if (
                                  selectedOption === "paid" ||
                                  selectedOption === "unpaid" ||
                                  selectedOption === "void"
                                ) {
                                  updateMultiple(selectedOption);
                                }
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}*/}
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      )}
    </>
  );
};
export default TableInvoices;
