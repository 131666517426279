import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { AllContext } from "../../context";
import Loader from "../../components/loader";
import PageLoader from "../../components/pageLoader";
import { getBusinessSubdomain } from "../../shared/functions";

const Checkout = () => {
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiPostMethod,
    apiGetMethod,
    redirectToLogin,
    toastMessage,
  } = context;

  const [isLoading, setIsLoading] = useState(true);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentAddress = useSelector(
    (state) => state.loginReducer.paymentAddress
  );
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const [paymentSuccessDetails, setPaymentSuccessDetails] =
    useState(paymentAddress);
  const { EDIT_USER_DETAILS, PAYMENTSUCCESS, PAYMENT_METHOD } = allApiUrl;
  const [userInformation, setUserInformation] = useState([]);
  const [grandTotal, setGrandTotal] = useState(null);
  const [isTaxPaid, setIsTaxPaid] = useState(false);
  const [taxValue, setTaxValue] = useState(0);
  const [appliedTaxes, setAppliedTaxes] = useState([]);
  const [ paymentSettingData, setPaymentSettingData ] = useState({});
  const [ companySettingData, setCompanySettingData ] = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    processOrder();
  }, []);

  const processOrder = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    if(paymentAddress.stripeData?.isTaxPaid) {
      setIsTaxPaid(true);
      setTaxValue(paymentAddress.stripeData?.taxAmount);
      setAppliedTaxes(paymentAddress.stripeData?.appliedTaxes || [])
    }
    const data = {
      stripeData: paymentAddress.stripeData,
      chargeId: paymentAddress.id,
      billingAddress: paymentAddress.billingAddress,
      city: paymentAddress.city,
      company: paymentAddress.company,
      postal: paymentAddress.postal,
      state: paymentAddress.state,
      taxId: paymentAddress.taxId,
      name: paymentAddress.firstName,
      country: paymentAddress.country,
    };

    apiPostMethod(PAYMENTSUCCESS, data, header)
      .then((res) => {
        setIsLoading(false)
        // alert("working");
      })
      .catch((err) => {
        setIsLoading(false)

        // alert("not working");
        // toastMessage("error", err.data.message);
      });
  }
  useEffect(() => {
    let total =
      paymentSuccessDetails &&
      paymentSuccessDetails.stripeData &&
      paymentSuccessDetails.stripeData.product &&
      paymentSuccessDetails.stripeData.product.map(
        (item) => item.id.price * item.quantity
      );
    let amountCharged =  total.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );
    if(paymentSuccessDetails &&
      paymentSuccessDetails.stripeData &&
      paymentSuccessDetails.stripeData.isTaxPaid) {
      amountCharged = amountCharged + parseFloat(paymentSuccessDetails.stripeData.taxAmount);
    }
    setGrandTotal(amountCharged);
  });
  useEffect(() => {
    getUserDetails(loginUserInfo.id);
    getPaymentSettingData();
  }, []);
  function getUserDetails(userProfileId) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(EDIT_USER_DETAILS, userProfileId, header)
      .then((res) => {
        setUserInformation(res.data.user[0]);
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }
  async function getPaymentSettingData() {
    const subDomain = getBusinessSubdomain();
    let header = {
      //   Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiPostMethod(allApiUrl.PAYMENT_METHOD_PUBLIC, {subDomain}, header);
      if (response.status === 200) {
        // setIsLoading(false);
        setPaymentSettingData(response.data.values);
        setCompanySettingData(response.data.companyData);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      // setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }
  return (
    <div>
      {isLoading ? <PageLoader isLoading={isLoading} />:
        <div className="commonSlidePl">
        <section className="checkOut-page">
          <div className="container-fluid pl-240 pe-0 overflow-hidden">
            <div className="row h-100vh align-items-center">
              <div className="col-lg-7">
                <div className="checkoutListing">
                  <p className="agencyName">OpFlow</p>
                  <h3>Thank you, {userInformation.userId?.firstName}</h3>
                  <p>
                    We've accepted your order, and we're getting it ready. A
                    confirmation email has been sent to{" "}
                    <strong>{userInformation.userId?.email}</strong>.
                  </p>
                  <p>
                    Go to the{" "}
                    <a
                      href="#"
                      onClick={() => {
                        navigate("/home");
                      }}
                    >
                      client portal
                    </a>{" "}
                    to check the status of this order and receive updates.
                  </p>
  
                  <div className="row billingRow">
                    <div className="col-lg-6">
                      <div className="billingAddress">
                        <p>
                          <strong>Billing address</strong>
                        </p>
                        <p>{userInformation.billingAddress}</p>
                        <p>{userInformation.postal}</p>
                        <p>{userInformation.country}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="billingAddress">
                        {/* <p>
                          <strong>Order date</strong>
                        </p>
                        <p>Oct 11, 2022</p> */}
                        <p className="mt-2">
                          <strong>Payment method</strong>
                        </p>
                        {/* <p>Account Balance — $0.00</p> */}
                        <p>Card</p>
                      </div>
                    </div>
                  </div>
  
                  <div className="sectionHelp">
                    <p>
                      Need help? <a href={companySettingData?.contactLink ? companySettingData?.contactLink : '#'} target="_blank">Contact us</a>
                    </p>
                    <a
                      className="continueBtn"
                      href="#"
                      onClick={() => {
                        navigate("/home");
                      }}
                    >
                      Continue to client portal
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="summaryHead h-100vh">
                  <div className="summaryPage">
                    <h4>Summary</h4>
                    {paymentSuccessDetails &&
                      paymentSuccessDetails.stripeData &&
                      paymentSuccessDetails.stripeData.product &&
                      paymentSuccessDetails.stripeData.product.map((item, idx) => {
                        return (
                          <div className="d-flex" key={idx}>
                            <div>
                              <p>{item.id.name}</p>
                              <p>
                                <span>Qty</span> {item.quantity}
                              </p>
                            </div>
                            <div>
                              <div>
                                <p>{paymentSettingData?.currency?.symbol ? paymentSettingData?.currency?.symbol : "$"} {item.id.price?.toFixed(2)}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <hr />
                    { isTaxPaid && (
                      appliedTaxes.length ? (
                        appliedTaxes.map((taxData, index) => (
                          <div className="d-flex" key={index}>
                            <div>
                              <p>{taxData.taxName}</p>
                            </div>
                            <div>
                              <div>
                                <p>{paymentSettingData?.currency?.symbol ? paymentSettingData?.currency?.symbol : "$"} {parseFloat(taxData.taxAmount).toFixed(2)}</p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="d-flex">
                          <div>
                            <p>Taxes & Fees</p>
                          </div>
                          <div>
                            <div>
                              <p>{paymentSettingData?.currency?.symbol ? paymentSettingData?.currency?.symbol : "$"} {parseFloat(taxValue).toFixed(2)}</p>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    <div className="d-flex mt-2">
                      <div>
                        <p>Total</p>
                        {/* <p>USD</p> */}
                      </div>
                      <div>
                        <div>
                          <p>{paymentSettingData?.currency?.symbol ? paymentSettingData?.currency?.symbol : "$"} {grandTotal?.toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      }
    
    
    </div>
  );
};
export default Checkout;