export function saveLoginUserInfo(data) {
  return { type: "LOGIN_USER_INFO", data: data };
}
export function saveLoginUserAccessToken(data) {
  return { type: "LOGIN_USER_ACCESS_TOKEN", data: data };
}
export function saveAddToCartInformation(data) {
  return { type: "ADD_TO_CART", data: data };
}
export function savePaymentAddress(data) {
  return { type: "PAYMENT_ADDRESS", data: data };
}

export function savePayLaterOrderDetail(data) {
  return { type: "PAYLATER_ORDER_DETAIL", data: data };
}

export function saveSuperAdminLoginInfo(data) {
  return { type: "SUPER_ADMIN_LOGIN_INFO", data: data };
}

export function saveSuperAdminAccessToken(data) {
  return { type: "SUPER_ADMIN_ACCESS_TOKEN", data: data };
}

export function userLogoutRequest() {
  return { type: "USER_LOGOUT" };
}
