import moment from "moment";
import { useSelector } from "react-redux";
import {
  // saveAddToCartInformation,
  saveLoginUserAccessToken,
  savePublicInvoice,
} from "../../redux/action/login";

export const convertToUpperCase = (value) => {
  const labels = value.replace(/(^\w{1})|(\s+\w{1})/g, (item) =>
    item.toUpperCase()
  );
  return labels;
};
const redirectPages = [
  "view-purchase",
  "view-payment",
  "portal",
  "cart",
  "view-orders",
  "view-ticket",
  "view-invoice",
  "public-invoice",
];

export const monthNameAndDateFormat = (value, year) => {
  const currDate = new Date(value);
  const currMonthName = currDate.toLocaleString("default", {
    month: "short",
  });
  const currDay = currDate.getDate();
  if (year) {
    const year = currDate.getFullYear();
    return `${currMonthName} ${currDay}, ${year}`;
  }

  return `${currMonthName} ${currDay}`;
};

export const formatMonthNameAndDate = (dateTime, timezone, year) => {
  let offset = "";
  if(timezone) {
    offset = JSON.parse(timezone).offset;
  }
  const date = moment.utc(dateTime).utcOffset(offset);
  const monthName = date.format('MMM');
  const dayDate = date.format('D');
  if (year) {
    const year = date.format('YYYY');
    return `${monthName} ${dayDate}, ${year}`;
  }
  return `${monthName} ${dayDate}`;
};

export const redirectToLogin = (dispatch, navigate, err, page) => {
  if (
    err.status === 403 ||
    err?.data?.message == "User not exist." ||
    err == ""
  ) {
    dispatch(saveLoginUserAccessToken(""));
    // dispatch(savePublicInvoice(false));
    // dispatch(saveAddToCartInformation(false));
    localStorage.removeItem("userLoggedIn");
    if (page && page.length > 0 && redirectPages.includes(page[1])) {
      if (page[3]) {
        navigate(`/login?redirection=${page[1] + "/" + page[2]}&id=${page[3]}`);
      } else {
        navigate(
          `/login?redirection=${page[1]}${page[2] ? "&id=" + page[2] : ""}`
        );
      }
    } else {
      navigate("/login");
    }
  }
};

export const handleOffSetDateTime = (dateTime, timezone) => {
  let offset = "";
  if(timezone) {
    offset = JSON.parse(timezone).offset;
  }
  const date = moment.utc(dateTime).utcOffset(offset);
    const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const hours = date.format('hh:mm A');
  const month = date.format('M');
  const dayDate = date.format('Do');
  return `${monthNames[month - 1]} ${dayDate} at ${hours}`;
}

export const handleGetTime = (dateTime, timezone) => {
  let offset = "";
  if(timezone) {
    offset = JSON.parse(timezone).offset;
  }
  const date = moment.utc(dateTime).utcOffset(offset);
  const hours = date.format('hh:mm A');
  return hours;
}
export const  yieldToMain = () => {
  return new Promise(resolve => {
    setTimeout(resolve, 0);
  });
}
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const pendingStatusTypeCheck = (item) => {
  if(item.orderStatus) {
    return item?.orderStatus?.statusType?.toLowerCase() === "pending";
  } else {
    return item?.status?.toLowerCase() === "pending";
  }
}
export const getOrderStatus = (item) => {
  if(item.orderStatus) {
    return (
      <span className="pill pill-grey fs-12 fw-normal" style={{ backgroundColor: item.orderStatus.bgColor, color:  item.orderStatus.textColor }}>{item.orderStatus.name}</span>
    )
  } else {
    return (
      <span className="pill pill-grey fs-12 fw-normal">{item.status}</span>
    )
  }
}
export const completeStatusTypeCheck = (item) => {
  if(item.orderStatus) {
    return item.orderStatus?.statusType.toLowerCase() === "complete";
  } else {
    return item.status.toLowerCase() === "complete";
  }
}
export const submittedStatusTypeCheck = (item) => {
  if(item.orderStatus) {
    return item.orderStatus?.statusType.toLowerCase() === "submitted";
  } else {
    return item.status.toLowerCase() === "submitted";
  }
}
export const getBusinessSubdomain = () => {
  console.log("test",window.location.hostname.split('.').length ? window.location.hostname.split('.')[0] : window.location.hostname)
  return window.location.hostname.split('.').length ? window.location.hostname.split('.')[0] : window.location.hostname;

//   let domain = window.location.host.toLowerCase();
//   let path = window.location.pathname.toLowerCase();
//   console.log("domain",domain)

//   let domainChunks = domain.split('.');
//   console.log("domainChunks",domainChunks)
//  let checkDomain = domain.includes(".com")
//   // let subdomain = domainChunks[domainChunks.length - 2];
//   let subdomain = domainChunks[0];
//     return subdomain || ''

// console.log("subdomain",subdomain)
  // if(process.env.REACT_APP_NODE_ENV !== 'development') {
  // if(checkDomain) {

  //   console.log("1")
  //   // let prodSubDomain = domainChunks[domainChunks.length - 3];
  //   let prodSubDomain = domainChunks[0];
  //   console.log("prodSubDomain",prodSubDomain)
  //   return prodSubDomain || ''
  // } else {
  //   console.log("2")

  //   return subdomain || ''
  // }
}