export const fontAwesomeUrl = 'https://fontawesome.com/icons?d=gallery&s=solid';
export const editorApiKey = 'fjnv8pd6m40tkdl6tva9sklqdppliphhvofstqa48b92mxtb';

export const sidebarMenuCategories = [
  { id: 1, name: 'Activity' },
  { id: 2, name: 'Billing' },
  { id: 3, name: 'Account' },
]

export const teamAccountData = [
  {
    id: 1,
    userName: "Alex",
    email: "alex@example.com",
    userRole: "Admin",
    accountType: "AccountOwner",
  },
  {
    id: 2,
    userName: "Alex",
    email: "alex@example.com",
    userRole: "Manager",
    accountType: "",
  },
];
export const tabList = [
  "Pending",
  "Submitted",
  "Working",
  "Complete",
  "Cancelled",
  "Pending Update",
  "Void",
  "Info lacking",
  "Preparing Draft",
  "Draft for approval",
  "Editor is reviewing draft",
  "Edits are being made",
  "Edits Done",
  "Draft for publication",
  "Order is done",
  "Needs response",
  "All",
];
export const roleData = [
  {
    name: "Admin",
    _id: "asdhvhsf",
  },
  {
    name: "Admin",
    _id: "",
  },
];

export const types = {
  ADMIN: "admin",
  CLIENT: "client",
  CLIENT_TEAM: "client team",
  LEAD: "lead",
  CONTACT: "contact",
};

export const rolesData = [
  {
    key: ["orders", "order-status", "view-orders"],
    value: ["orderAccess", "orderManagement"],
  },
];
export const orderDummyData = {
  order: [
    {
      status: "submitted",
      assignedTo: [
        {
          _id: "6332788d57d69336a126901b",
          firstName: "Rahul",
          email: "tarun.test12@gmail.com",
          image: "https://crm-ssp.s3.amazonaws.com/uploads/BingWallpaper.jpg",
          lastName: "sharma",
        },
        {
          _id: "6332788d57d69336a126901b",
          firstName: "Akash",
          email: "tarun.test12@gmail.com",
          image: "https://crm-ssp.s3.amazonaws.com/uploads/BingWallpaper.jpg",
          lastName: "sharma",
        },
      ],
      id: "634e42fab361fb4589419c64",
      orderId: "WTS93MHG_1",
      product: {
        _id: "63353ae41efff3e5356875f8",
        name: "AWS 1",
        description: "Amazon web services",
        imageUrl: "THis is the url",
        price: 40,
        currency: "USD",
        durationsTime: 12,
        durationPeriod: "D",
        orderCount: 2,
        addOn: [],
        public: true,
        intakeFormQuantity: 1,
        __v: 0,
        audienceArticle: "asdfasdfadf",
        brandBio: "sdfadf",
        confirmation: true,
        socialMediaLinks: "asdfsadf",
      },
      price: 30,
      userId: {
        _id: "633e7ed4a13e373a3eb8df91",
        email: "tarun.test12@yopmail.com",
        firstName: "Rahul",
        lastName: "singh",
      },
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-43",
      tags: ["test1,test2"],
      createdDate: "2022-10-18T06:08:58.396Z",
      __v: 0,
    },
    {
      status: "pending",
      assignedTo: [],
      id: "634e42fab361fb4589419c65",
      orderId: "WTS93MHG_2",
      product: {
        _id: "63353ae41efff3e5356875f8",
        name: "AWS 1",
        description: "Amazon web services",
        imageUrl: "THis is the url",
        price: 40,
        currency: "USD",
        durationsTime: 12,
        durationPeriod: "D",
        orderCount: 2,
        addOn: [],
        public: true,
        intakeFormQuantity: 1,
        __v: 0,
        audienceArticle: "asdfasdfadf",
        brandBio: "sdfadf",
        confirmation: true,
        socialMediaLinks: "asdfsadf",
      },
      price: 30,
      userId: {
        _id: "633e7ed4a13e373a3eb8df91",
        email: "tarun.test12@yopmail.com",
        firstName: "Rahul",
        lastName: "",
      },
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-43",
      tags: [],
      createdDate: "2022-10-18T06:08:58.398Z",
      __v: 0,
    },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e42fab361fb4589419c66",
    //   orderId: "WTS93MHG_3",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 0,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-43",
    //   tags: [],
    //   createdDate: "2022-10-18T06:08:58.398Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e42fab361fb4589419c67",
    //   orderId: "WTS93MHG_4",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 0,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-43",
    //   tags: [],
    //   createdDate: "2022-10-18T06:08:58.400Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e42fab361fb4589419c68",
    //   orderId: "WTS93MHG_5",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 0,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-43",
    //   tags: [],
    //   createdDate: "2022-10-18T06:08:58.401Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e42fab361fb4589419c69",
    //   orderId: "WTS93MHG_6",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 0,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-43",
    //   tags: [],
    //   createdDate: "2022-10-18T06:08:58.402Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e42fab361fb4589419c6a",
    //   orderId: "WTS93MHG_7",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 0,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-43",
    //   tags: [],
    //   createdDate: "2022-10-18T06:08:58.403Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e47f1ac38d47a230d74a5",
    //   orderId: "XWWCZOW6_1",
    //   product: {
    //     _id: "63353ae41efff3e5356875f8",
    //     name: "AWS 1",
    //     description: "Amazon web services",
    //     imageUrl: "THis is the url",
    //     price: 40,
    //     currency: "USD",
    //     durationsTime: 12,
    //     durationPeriod: "D",
    //     orderCount: 2,
    //     addOn: [],
    //     public: true,
    //     intakeFormQuantity: 1,
    //     __v: 0,
    //     audienceArticle: "asdfasdfadf",
    //     brandBio: "sdfadf",
    //     confirmation: true,
    //     socialMediaLinks: "asdfsadf",
    //   },
    //   price: 30,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: "634539dd55b52d047e7808db",
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-44",
    //   tags: [],
    //   createdDate: "2022-10-18T06:30:09.095Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e47f1ac38d47a230d74a6",
    //   orderId: "XWWCZOW6_2",
    //   product: {
    //     _id: "63353ae41efff3e5356875f8",
    //     name: "AWS 1",
    //     description: "Amazon web services",
    //     imageUrl: "THis is the url",
    //     price: 40,
    //     currency: "USD",
    //     durationsTime: 12,
    //     durationPeriod: "D",
    //     orderCount: 2,
    //     addOn: [],
    //     public: true,
    //     intakeFormQuantity: 1,
    //     __v: 0,
    //     audienceArticle: "asdfasdfadf",
    //     brandBio: "sdfadf",
    //     confirmation: true,
    //     socialMediaLinks: "asdfsadf",
    //   },
    //   price: 30,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: "634539dd55b52d047e7808db",
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-44",
    //   tags: [],
    //   createdDate: "2022-10-18T06:30:09.096Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e47f1ac38d47a230d74a7",
    //   orderId: "XWWCZOW6_3",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 0,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: "634539dd55b52d047e7808db",
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-44",
    //   tags: [],
    //   createdDate: "2022-10-18T06:30:09.097Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e47f1ac38d47a230d74a8",
    //   orderId: "XWWCZOW6_4",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 0,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: "634539dd55b52d047e7808db",
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-44",
    //   tags: [],
    //   createdDate: "2022-10-18T06:30:09.098Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e47f1ac38d47a230d74a9",
    //   orderId: "XWWCZOW6_5",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 0,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: "634539dd55b52d047e7808db",
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-44",
    //   tags: [],
    //   createdDate: "2022-10-18T06:30:09.098Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e47f1ac38d47a230d74aa",
    //   orderId: "XWWCZOW6_6",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 0,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: "634539dd55b52d047e7808db",
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-44",
    //   tags: [],
    //   createdDate: "2022-10-18T06:30:09.099Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e47f1ac38d47a230d74ab",
    //   orderId: "XWWCZOW6_7",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 0,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: "634539dd55b52d047e7808db",
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 7,
    //   invoiceId: "CCD-44",
    //   tags: [],
    //   createdDate: "2022-10-18T06:30:09.100Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e4954ac38d47a230d74c9",
    //   orderId: "FM0HVACL_1",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 5,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 5,
    //   invoiceId: "CCD-45",
    //   tags: [],
    //   createdDate: "2022-10-18T06:36:04.342Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e4954ac38d47a230d74ca",
    //   orderId: "FM0HVACL_2",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 5,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 5,
    //   invoiceId: "CCD-45",
    //   tags: [],
    //   createdDate: "2022-10-18T06:36:04.345Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e4954ac38d47a230d74cb",
    //   orderId: "FM0HVACL_3",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 5,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 5,
    //   invoiceId: "CCD-45",
    //   tags: [],
    //   createdDate: "2022-10-18T06:36:04.346Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e4954ac38d47a230d74cc",
    //   orderId: "FM0HVACL_4",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 5,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 5,
    //   invoiceId: "CCD-45",
    //   tags: [],
    //   createdDate: "2022-10-18T06:36:04.347Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e4954ac38d47a230d74cd",
    //   orderId: "FM0HVACL_5",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 5,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 5,
    //   invoiceId: "CCD-45",
    //   tags: [],
    //   createdDate: "2022-10-18T06:36:04.348Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e49d4850e4b479a8a913f",
    //   orderId: "V3YXGJ7V_1",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 5,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 5,
    //   invoiceId: "CCD-46",
    //   tags: [],
    //   createdDate: "2022-10-18T06:38:12.531Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e49d4850e4b479a8a9140",
    //   orderId: "V3YXGJ7V_2",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 5,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 5,
    //   invoiceId: "CCD-46",
    //   tags: [],
    //   createdDate: "2022-10-18T06:38:12.533Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e49d4850e4b479a8a9141",
    //   orderId: "V3YXGJ7V_3",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 5,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 5,
    //   invoiceId: "CCD-46",
    //   tags: [],
    //   createdDate: "2022-10-18T06:38:12.535Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e49d4850e4b479a8a9142",
    //   orderId: "V3YXGJ7V_4",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 5,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 5,
    //   invoiceId: "CCD-46",
    //   tags: [],
    //   createdDate: "2022-10-18T06:38:12.536Z",
    //   __v: 0,
    // },
    // {
    //   status: "submitted",
    //   assignedTo: [],
    //   _id: "634e49d4850e4b479a8a9143",
    //   orderId: "V3YXGJ7V_5",
    //   product: {
    //     _id: "63451d2a9e82f10e4f596267",
    //     name: "Devops",
    //     description: "test devops",
    //     price: 5,
    //     currency: "usd",
    //     orderCount: 5,
    //     addOn: [""],
    //     public: true,
    //     oneTimeService: true,
    //     __v: 0,
    //   },
    //   price: 5,
    //   userId: {
    //     _id: "633e7ed4a13e373a3eb8df91",
    //     email: "tarun.test12@yopmail.com",
    //     firstName: "Rahul",
    //     lastName: "lastName",
    //   },
    //   coupon: null,
    //   cc: [],
    //   paymentSuccess: true,
    //   origin: "cart",
    //   otherItems: 5,
    //   invoiceId: "CCD-46",
    //   tags: [],
    //   createdDate: "2022-10-18T06:38:12.537Z",
    //   __v: 0,
    // },
  ],
};
export const orderDummyDataClient = {
  order: [
    {
      status: "submitted",
      id: "634e42fab361fb4589419c64",
      orderId: "WTS93MHG_1",
      product: {
        _id: "63353ae41efff3e5356875f8",
        name: "AWS 1",
        description: "Amazon web services",
        imageUrl: "THis is the url",
        price: 40,
        currency: "USD",
        durationsTime: 12,
        durationPeriod: "D",
        orderCount: 2,
        addOn: [],
        public: true,
        intakeFormQuantity: 1,
        __v: 0,
        audienceArticle: "asdfasdfadf",
        brandBio: "sdfadf",
        confirmation: true,
        socialMediaLinks: "asdfsadf",
      },
      price: 30,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-43",
      tags: ["test1"],
      createdDate: "2022-10-18T06:08:58.396Z",
      __v: 0,
      assignedTo: ["6332788d57d69336a126901b", "63367e12e36242d2afc6d7d3"],
      message: [],
    },
    {
      status: "pending",
      assignedTo: [],
      id: "634e42fab361fb4589419c65",
      orderId: "WTS93MHG_2",
      product: {
        _id: "63353ae41efff3e5356875f8",
        name: "AWS 1",
        description: "Amazon web services",
        imageUrl: "THis is the url",
        price: 40,
        currency: "USD",
        durationsTime: 12,
        durationPeriod: "D",
        orderCount: 2,
        addOn: [],
        public: true,
        intakeFormQuantity: 1,
        __v: 0,
        audienceArticle: "asdfasdfadf",
        brandBio: "sdfadf",
        confirmation: true,
        socialMediaLinks: "asdfsadf",
      },
      price: 30,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-43",
      tags: [],
      createdDate: "2022-10-18T06:08:58.398Z",
      __v: 0,
      message: [],
    },
    {
      status: "completed",
      assignedTo: [],
      _id: "634e42fab361fb4589419c66",
      orderId: "WTS93MHG_3",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 0,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-43",
      tags: [],
      createdDate: "2022-10-18T06:08:58.398Z",
      __v: 0,
      message: [],
    },
    {
      status: "cancelled",
      assignedTo: [],
      _id: "634e42fab361fb4589419c67",
      orderId: "WTS93MHG_4",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 0,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-43",
      tags: [],
      createdDate: "2022-10-18T06:08:58.400Z",
      __v: 0,
      message: [],
    },
    {
      status: "pending update",
      assignedTo: [],
      _id: "634e42fab361fb4589419c68",
      orderId: "WTS93MHG_5",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 0,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-43",
      tags: [],
      createdDate: "2022-10-18T06:08:58.401Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e42fab361fb4589419c69",
      orderId: "WTS93MHG_6",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 0,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-43",
      tags: [],
      createdDate: "2022-10-18T06:08:58.402Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e42fab361fb4589419c6a",
      orderId: "WTS93MHG_7",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 0,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-43",
      tags: [],
      createdDate: "2022-10-18T06:08:58.403Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e47f1ac38d47a230d74a5",
      orderId: "XWWCZOW6_1",
      product: {
        _id: "63353ae41efff3e5356875f8",
        name: "AWS 1",
        description: "Amazon web services",
        imageUrl: "THis is the url",
        price: 40,
        currency: "USD",
        durationsTime: 12,
        durationPeriod: "D",
        orderCount: 2,
        addOn: [],
        public: true,
        intakeFormQuantity: 1,
        __v: 0,
        audienceArticle: "asdfasdfadf",
        brandBio: "sdfadf",
        confirmation: true,
        socialMediaLinks: "asdfsadf",
      },
      price: 30,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: "634539dd55b52d047e7808db",
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-44",
      tags: [],
      createdDate: "2022-10-18T06:30:09.095Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e47f1ac38d47a230d74a6",
      orderId: "XWWCZOW6_2",
      product: {
        _id: "63353ae41efff3e5356875f8",
        name: "AWS 1",
        description: "Amazon web services",
        imageUrl: "THis is the url",
        price: 40,
        currency: "USD",
        durationsTime: 12,
        durationPeriod: "D",
        orderCount: 2,
        addOn: [],
        public: true,
        intakeFormQuantity: 1,
        __v: 0,
        audienceArticle: "asdfasdfadf",
        brandBio: "sdfadf",
        confirmation: true,
        socialMediaLinks: "asdfsadf",
      },
      price: 30,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: "634539dd55b52d047e7808db",
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-44",
      tags: [],
      createdDate: "2022-10-18T06:30:09.096Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e47f1ac38d47a230d74a7",
      orderId: "XWWCZOW6_3",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 0,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: "634539dd55b52d047e7808db",
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-44",
      tags: [],
      createdDate: "2022-10-18T06:30:09.097Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e47f1ac38d47a230d74a8",
      orderId: "XWWCZOW6_4",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 0,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: "634539dd55b52d047e7808db",
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-44",
      tags: [],
      createdDate: "2022-10-18T06:30:09.098Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e47f1ac38d47a230d74a9",
      orderId: "XWWCZOW6_5",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 0,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: "634539dd55b52d047e7808db",
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-44",
      tags: [],
      createdDate: "2022-10-18T06:30:09.098Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e47f1ac38d47a230d74aa",
      orderId: "XWWCZOW6_6",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 0,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: "634539dd55b52d047e7808db",
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-44",
      tags: [],
      createdDate: "2022-10-18T06:30:09.099Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e47f1ac38d47a230d74ab",
      orderId: "XWWCZOW6_7",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 0,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: "634539dd55b52d047e7808db",
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 7,
      invoiceId: "CCD-44",
      tags: [],
      createdDate: "2022-10-18T06:30:09.100Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e4954ac38d47a230d74c9",
      orderId: "FM0HVACL_1",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 5,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 5,
      invoiceId: "CCD-45",
      tags: [],
      createdDate: "2022-10-18T06:36:04.342Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e4954ac38d47a230d74ca",
      orderId: "FM0HVACL_2",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 5,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 5,
      invoiceId: "CCD-45",
      tags: [],
      createdDate: "2022-10-18T06:36:04.345Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e4954ac38d47a230d74cb",
      orderId: "FM0HVACL_3",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 5,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 5,
      invoiceId: "CCD-45",
      tags: [],
      createdDate: "2022-10-18T06:36:04.346Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e4954ac38d47a230d74cc",
      orderId: "FM0HVACL_4",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 5,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 5,
      invoiceId: "CCD-45",
      tags: [],
      createdDate: "2022-10-18T06:36:04.347Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e4954ac38d47a230d74cd",
      orderId: "FM0HVACL_5",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 5,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 5,
      invoiceId: "CCD-45",
      tags: [],
      createdDate: "2022-10-18T06:36:04.348Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e49d4850e4b479a8a913f",
      orderId: "V3YXGJ7V_1",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 5,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 5,
      invoiceId: "CCD-46",
      tags: [],
      createdDate: "2022-10-18T06:38:12.531Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e49d4850e4b479a8a9140",
      orderId: "V3YXGJ7V_2",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 5,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 5,
      invoiceId: "CCD-46",
      tags: [],
      createdDate: "2022-10-18T06:38:12.533Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e49d4850e4b479a8a9141",
      orderId: "V3YXGJ7V_3",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 5,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 5,
      invoiceId: "CCD-46",
      tags: [],
      createdDate: "2022-10-18T06:38:12.535Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e49d4850e4b479a8a9142",
      orderId: "V3YXGJ7V_4",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 5,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 5,
      invoiceId: "CCD-46",
      tags: [],
      createdDate: "2022-10-18T06:38:12.536Z",
      __v: 0,
      message: [],
    },
    {
      status: "submitted",
      assignedTo: [],
      _id: "634e49d4850e4b479a8a9143",
      orderId: "V3YXGJ7V_5",
      product: {
        _id: "63451d2a9e82f10e4f596267",
        name: "Devops",
        description: "test devops",
        price: 5,
        currency: "usd",
        orderCount: 5,
        addOn: [""],
        public: true,
        oneTimeService: true,
        __v: 0,
      },
      price: 5,
      userId: "633e7ed4a13e373a3eb8df91",
      coupon: null,
      cc: [],
      paymentSuccess: true,
      origin: "cart",
      otherItems: 5,
      invoiceId: "CCD-46",
      tags: [],
      createdDate: "2022-10-18T06:38:12.537Z",
      __v: 0,
      message: [],
    },
  ],
};
export const countries = [
  { name: "Afghanistan", code: "AF", label: "Afghanistan" },
  { name: "Åland Islands", code: "AX", label: "Åland Islands" },
  { name: "Albania", code: "AL", label: "Albania" },
  { name: "Algeria", code: "DZ", label: "Algeria" },
  { name: "American Samoa", code: "AS", label: "American Samoa" },
  { name: "AndorrA", code: "AD", label: "AndorrA" },
  { name: "Angola", code: "AO", label: "Angola" },
  { name: "Anguilla", code: "AI", label: "Anguilla" },
  { name: "Antarctica", code: "AQ", label: "Antarctica" },
  { name: "Antigua and Barbuda", code: "AG", label: "Antigua and Barbuda" },
  { name: "Argentina", code: "AR", label: "Argentina" },
  { name: "Armenia", code: "AM", label: "Armenia" },
  { name: "Aruba", code: "AW", label: "Aruba" },
  { name: "Australia", code: "AU", label: "Australia" },
  { name: "Austria", code: "AT", label: "Austria" },
  { name: "Azerbaijan", code: "AZ", label: "Azerbaijan" },
  { name: "Bahamas", code: "BS", label: "Bahamas" },
  { name: "Bahrain", code: "BH", label: "Bahrain" },
  { name: "Bangladesh", code: "BD", label: "Bangladesh" },
  { name: "Barbados", code: "BB", label: "" },
  { name: "Belarus", code: "BY", label: "" },
  { name: "Belgium", code: "BE", label: "" },
  { name: "Belize", code: "BZ", label: "" },
  { name: "Benin", code: "BJ", label: "" },
  { name: "Bermuda", code: "BM", label: "" },
  { name: "Bhutan", code: "BT", label: "" },
  { name: "Bolivia", code: "BO", label: "" },
  { name: "Bosnia and Herzegovina", code: "BA", label: "" },
  { name: "Botswana", code: "BW", label: "" },
  { name: "Bouvet Island", code: "BV", label: "" },
  { name: "Brazil", code: "BR", label: "" },
  { name: "British Indian Ocean Territory", code: "IO", label: "" },
  { name: "Brunei Darussalam", code: "BN", label: "" },
  { name: "Bulgaria", code: "BG", label: "" },
  { name: "Burkina Faso", code: "BF", label: "" },
  { name: "Burundi", code: "BI", label: "" },
  { name: "Cambodia", code: "KH", label: "" },
  { name: "Cameroon", code: "CM", label: "" },
  { name: "Canada", code: "CA", label: "" },
  { name: "Cape Verde", code: "CV", label: "" },
  { name: "Cayman Islands", code: "KY", label: "" },
  { name: "Central African Republic", code: "CF", label: "" },
  { name: "Chad", code: "TD", label: "" },
  { name: "Chile", code: "CL", label: "" },
  { name: "China", code: "CN", label: "" },
  { name: "Christmas Island", code: "CX", label: "" },
  { name: "Cocos (Keeling) Islands", code: "CC", label: "" },
  { name: "Colombia", code: "CO", label: "" },
  { name: "Comoros", code: "KM", label: "" },
  { name: "Congo", code: "CG", label: "" },
  { name: "Congo, The Democratic Republic of the", code: "CD", label: "" },
  { name: "Cook Islands", code: "CK", label: "" },
  { name: "Costa Rica", code: "CR", label: "" },
  { name: "Cote D'Ivoire", code: "CI", label: "" },
  { name: "Croatia", code: "HR", label: "" },
  { name: "Cuba", code: "CU", label: "" },
  { name: "Cyprus", code: "CY", label: "" },
  { name: "Czech Republic", code: "CZ", label: "" },
  { name: "Denmark", code: "DK", label: "" },
  { name: "Djibouti", code: "DJ", label: "" },
  { name: "Dominica", code: "DM", label: "" },
  { name: "Dominican Republic", code: "DO", label: "" },
  { name: "Ecuador", code: "EC", label: "" },
  { name: "Egypt", code: "EG", label: "" },
  { name: "El Salvador", code: "SV", label: "" },
  { name: "Equatorial Guinea", code: "GQ", label: "" },
  { name: "Eritrea", code: "ER", label: "" },
  { name: "Estonia", code: "EE", label: "" },
  { name: "Ethiopia", code: "ET", label: "" },
  { name: "Falkland Islands (Malvinas)", code: "FK", label: "" },
  { name: "Faroe Islands", code: "FO", label: "" },
  { name: "Fiji", code: "FJ", label: "" },
  { name: "Finland", code: "FI", label: "" },
  { name: "France", code: "FR", label: "" },
  { name: "French Guiana", code: "GF", label: "" },
  { name: "French Polynesia", code: "PF", label: "" },
  { name: "French Southern Territories", code: "TF", label: "" },
  { name: "Gabon", code: "GA", label: "" },
  { name: "Gambia", code: "GM", label: "" },
  { name: "Georgia", code: "GE", label: "" },
  { name: "Germany", code: "DE", label: "" },
  { name: "Ghana", code: "GH", label: "" },
  { name: "Gibraltar", code: "GI", label: "" },
  { name: "Greece", code: "GR", label: "" },
  { name: "Greenland", code: "GL", label: "" },
  { name: "Grenada", code: "GD", label: "" },
  { name: "Guadeloupe", code: "GP", label: "" },
  { name: "Guam", code: "GU", label: "" },
  { name: "Guatemala", code: "GT", label: "" },
  { name: "Guernsey", code: "GG", label: "" },
  { name: "Guinea", code: "GN", label: "" },
  { name: "Guinea-Bissau", code: "GW", label: "" },
  { name: "Guyana", code: "GY", label: "" },
  { name: "Haiti", code: "HT", label: "" },
  { name: "Heard Island and Mcdonald Islands", code: "HM", label: "" },
  { name: "Holy See (Vatican City State)", code: "VA", label: "" },
  { name: "Honduras", code: "HN", label: "" },
  { name: "Hong Kong", code: "HK", label: "" },
  { name: "Hungary", code: "HU", label: "" },
  { name: "Iceland", code: "IS", label: "" },
  { name: "India", code: "IN", label: "" },
  { name: "Indonesia", code: "ID", label: "" },
  { name: "Iran, Islamic Republic Of", code: "IR", label: "" },
  { name: "Iraq", code: "IQ", label: "" },
  { name: "Ireland", code: "IE", label: "" },
  { name: "Isle of Man", code: "IM", label: "" },
  { name: "Israel", code: "IL", label: "" },
  { name: "Italy", code: "IT", label: "" },
  { name: "Jamaica", code: "JM", label: "" },
  { name: "Japan", code: "JP", label: "" },
  { name: "Jersey", code: "JE", label: "" },
  { name: "Jordan", code: "JO", label: "" },
  { name: "Kazakhstan", code: "KZ", label: "" },
  { name: "Kenya", code: "KE", label: "" },
  { name: "Kiribati", code: "KI", label: "" },
  { name: "Korea, Democratic People'S Republic of", code: "KP", label: "" },
  { name: "Korea, Republic of", code: "KR", label: "" },
  { name: "Kuwait", code: "KW", label: "" },
  { name: "Kyrgyzstan", code: "KG", label: "" },
  { name: "Lao People'S Democratic Republic", code: "LA", label: "" },
  { name: "Latvia", code: "LV", label: "" },
  { name: "Lebanon", code: "LB", label: "" },
  { name: "Lesotho", code: "LS", label: "" },
  { name: "Liberia", code: "LR", label: "" },
  { name: "Libyan Arab Jamahiriya", code: "LY", label: "" },
  { name: "Liechtenstein", code: "LI", label: "" },
  { name: "Lithuania", code: "LT", label: "" },
  { name: "Luxembourg", code: "LU", label: "" },
  { name: "Macao", code: "MO", label: "" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK", label: "" },
  { name: "Madagascar", code: "MG", label: "" },
  { name: "Malawi", code: "MW", label: "" },
  { name: "Malaysia", code: "MY", label: "" },
  { name: "Maldives", code: "MV", label: "" },
  { name: "Mali", code: "ML", label: "" },
  { name: "Malta", code: "MT", label: "" },
  { name: "Marshall Islands", code: "MH", label: "" },
  { name: "Martinique", code: "MQ", label: "" },
  { name: "Mauritania", code: "MR", label: "" },
  { name: "Mauritius", code: "MU", label: "" },
  { name: "Mayotte", code: "YT", label: "" },
  { name: "Mexico", code: "MX", label: "" },
  { name: "Micronesia, Federated States of", code: "FM", label: "" },
  { name: "Moldova, Republic of", code: "MD", label: "" },
  { name: "Monaco", code: "MC", label: "" },
  { name: "Mongolia", code: "MN", label: "" },
  { name: "Montserrat", code: "MS", label: "" },
  { name: "Morocco", code: "MA", label: "" },
  { name: "Mozambique", code: "MZ", label: "" },
  { name: "Myanmar", code: "MM", label: "" },
  { name: "Namibia", code: "NA", label: "" },
  { name: "Nauru", code: "NR", label: "" },
  { name: "Nepal", code: "NP", label: "" },
  { name: "Netherlands", code: "NL", label: "" },
  { name: "Netherlands Antilles", code: "AN", label: "" },
  { name: "New Caledonia", code: "NC", label: "" },
  { name: "New Zealand", code: "NZ", label: "" },
  { name: "Nicaragua", code: "NI", label: "" },
  { name: "Niger", code: "NE", label: "" },
  { name: "Nigeria", code: "NG", label: "" },
  { name: "Niue", code: "NU", label: "" },
  { name: "Norfolk Island", code: "NF", label: "" },
  { name: "Northern Mariana Islands", code: "MP", label: "" },
  { name: "Norway", code: "NO", label: "" },
  { name: "Oman", code: "OM", label: "" },
  { name: "Pakistan", code: "PK", label: "" },
  { name: "Palau", code: "PW", label: "" },
  { name: "Palestinian Territory, Occupied", code: "PS", label: "" },
  { name: "Panama", code: "PA", label: "" },
  { name: "Papua New Guinea", code: "PG", label: "" },
  { name: "Paraguay", code: "PY", label: "" },
  { name: "Peru", code: "PE", label: "" },
  { name: "Philippines", code: "PH", label: "" },
  { name: "Pitcairn", code: "PN", label: "" },
  { name: "Poland", code: "PL", label: "" },
  { name: "Portugal", code: "PT", label: "" },
  { name: "Puerto Rico", code: "PR", label: "" },
  { name: "Qatar", code: "QA", label: "" },
  { name: "Reunion", code: "RE", label: "" },
  { name: "Romania", code: "RO", label: "" },
  { name: "Russian Federation", code: "RU", label: "" },
  { name: "RWANDA", code: "RW", label: "" },
  { name: "Saint Helena", code: "SH", label: "" },
  { name: "Saint Kitts and Nevis", code: "KN", label: "" },
  { name: "Saint Lucia", code: "LC", label: "" },
  { name: "Saint Pierre and Miquelon", code: "PM", label: "" },
  { name: "Saint Vincent and the Grenadines", code: "VC", label: "" },
  { name: "Samoa", code: "WS", label: "" },
  { name: "San Marino", code: "SM", label: "" },
  { name: "Sao Tome and Principe", code: "ST", label: "" },
  { name: "Saudi Arabia", code: "SA", label: "" },
  { name: "Senegal", code: "SN", label: "" },
  { name: "Serbia and Montenegro", code: "CS", label: "" },
  { name: "Seychelles", code: "SC", label: "" },
  { name: "Sierra Leone", code: "SL", label: "" },
  { name: "Singapore", code: "SG", label: "" },
  { name: "Slovakia", code: "SK", label: "" },
  { name: "Slovenia", code: "SI", label: "" },
  { name: "Solomon Islands", code: "SB", label: "" },
  { name: "Somalia", code: "SO", label: "" },
  { name: "South Africa", code: "ZA", label: "" },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    label: "",
  },
  { name: "Spain", code: "ES", label: "" },
  { name: "Sri Lanka", code: "LK", label: "" },
  { name: "Sudan", code: "SD", label: "" },
  { name: "Suriname", code: "SR", label: "" },
  { name: "Svalbard and Jan Mayen", code: "SJ", label: "" },
  { name: "Swaziland", code: "SZ", label: "" },
  { name: "Sweden", code: "SE", label: "" },
  { name: "Switzerland", code: "CH", label: "" },
  { name: "Syrian Arab Republic", code: "SY", label: "" },
  { name: "Taiwan, Province of China", code: "TW", label: "" },
  { name: "Tajikistan", code: "TJ", label: "" },
  { name: "Tanzania, United Republic of", code: "TZ", label: "" },
  { name: "Thailand", code: "TH", label: "" },
  { name: "Timor-Leste", code: "TL", label: "" },
  { name: "Togo", code: "TG", label: "" },
  { name: "Tokelau", code: "TK", label: "" },
  { name: "Tonga", code: "TO", label: "" },
  { name: "Trinidad and Tobago", code: "TT", label: "" },
  { name: "Tunisia", code: "TN", label: "" },
  { name: "Turkey", code: "TR", label: "" },
  { name: "Turkmenistan", code: "TM", label: "" },
  { name: "Turks and Caicos Islands", code: "TC", label: "" },
  { name: "Tuvalu", code: "TV", label: "" },
  { name: "Uganda", code: "UG", label: "" },
  { name: "Ukraine", code: "UA", label: "" },
  { name: "United Arab Emirates", code: "AE", label: "" },
  { name: "United Kingdom", code: "GB", label: "" },
  { name: "United States", code: "US", label: "" },
  { name: "United States Minor Outlying Islands", code: "UM", label: "" },
  { name: "Uruguay", code: "UY", label: "" },
  { name: "Uzbekistan", code: "UZ", label: "" },
  { name: "Vanuatu", code: "VU", label: "" },
  { name: "Venezuela", code: "VE", label: "" },
  { name: "Viet Nam", code: "VN", label: "" },
  { name: "Virgin Islands, British", code: "VG", label: "" },
  { name: "Virgin Islands, U.S.", code: "VI", label: "" },
  { name: "Wallis and Futuna", code: "WF", label: "" },
  { name: "Western Sahara", code: "EH", label: "" },
  { name: "Yemen", code: "YE", label: "" },
  { name: "Zambia", code: "ZM", label: "" },
  { name: "Zimbabwe", code: "ZW", label: "" },
];
