import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import salesIcon from "../../assets/img/icons/saleIcon.png";
import iconRevenue from "../../assets/img/icons/iconRevenue.png";
import { AllContext } from "../../context";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import PaginationNewUI from "../../components/PaginationNewUI";

const Revenue = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const [service, setService] = React.useState([]);
  const [totalRevenue, setTotalRevenue] = React.useState(null);
  const [averageRevenue, setAverageRevenue] = React.useState(null);
  const [numberOfSales, setNumberOfSales] = React.useState(null);
  const [dateRevenue, setDateRevenue] = React.useState([]);
  const [revenueByDate, setRevenueByDate] = useState([]);
  const { allApiUrl, apiPostMethod, toastMessage, redirectToLogin } = context;
  const { DASHBOARD_DATE_FILTER } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const { currency } = useSelector(
    (state) => state.sideMenuReducer.paymentSettingData
  );

  useEffect(() => {
    let loggedIn = localStorage.getItem("userLoggedIn");
    getTenDayData();
  }, []);

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const onChange = (dates) => {
    const [start, end] = dates;
    setDateRange(dates);
    getDateFilterData(start, end);
    setOpen(false);
  };
  function formatDateMonthName(d) {
    var date = new Date(d);

    if (isNaN(date.getTime())) {
      return d;
    } else {
      var month = new Array();
      month[0] = "Jan";
      month[1] = "Feb";
      month[2] = "Mar";
      month[3] = "Apr";
      month[4] = "May";
      month[5] = "Jun";
      month[6] = "Jul";
      month[7] = "Aug";
      month[8] = "Sept";
      month[9] = "Oct";
      month[10] = "Nov";
      month[11] = "Dec";

      let day = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      return day + " " + month[date.getMonth()];
    }
  }
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());
    date.setDate(date.getDate());
    const dates = [];
    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords

  const endOffset = parseInt(itemOffset) * parseInt(itemsPerPage);
  const currentList = dateRevenue.slice(
    itemOffset > 1 ? itemOffset * itemsPerPage - (itemsPerPage - 1) - 1 : 0,
    endOffset
  );

  let pageCount = Math.ceil(dateRevenue.length / itemsPerPage);

  function getTenDayData() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {
      startDate: new Date(new Date().setDate(new Date().getDate() - 10)),
      endDate: startDate,
    };
    setIsLoading(true);
    apiPostMethod(DASHBOARD_DATE_FILTER, data, header)
      .then((res) => {
        setService(res.data.data.serviceData);
        let totalRev = 0;
        let average;
        for (let item of res.data.data.serviceData) {
          totalRev += item.revenueGenerated;
        }
        average = parseInt(totalRev / res.data.data.invoiceCount);
        setTotalRevenue(totalRev);
        setAverageRevenue(average);
        setNumberOfSales(res.data.data.invoiceCount);
        let revenueDataTemp = res.data.data.revenueByDate;
        let tempArr = [];
        for (let item of revenueDataTemp) {
          // let data1 = item.split(":");
          if (item.name !== "undefined") {
            let obj = {};
            obj["date"] = item.name;
            obj["revenueGenerated"] = item.revenue;
            tempArr.push(obj);
          }
        }
        setTotalRecordsCount(tempArr.length);
        setDateRevenue(tempArr);
        //revenuByDate
        let startDateFilter = new Date(
          new Date().setDate(new Date().getDate() - 11)
        );

        let endDateFilter = startDate;
        let startDateSplits = startDateFilter.toLocaleString().split("/");
        let endDateSplits = endDateFilter.toLocaleString().split("/");

        setIsLoading(false);
        let numOfDays = 0;
        let year = startDateSplits[2];
        year = year.split(",")[0];
        if (startDateSplits[1] == endDateSplits[1]) {
          numOfDays = endDateSplits[0] - startDateSplits[0];

          let finalData = [...Array(numOfDays)].map((_a, index) => {
            let currentDate = index + parseInt(startDateSplits[0]) + 1;
            let dateExist = res.data.data.revenueByDate.find((row) => {
              let date = row.name.split("-")[2];
              if (date == currentDate) {
                return true;
              }
              return false;
            });
            if (dateExist) {
              return dateExist;
            } else {
              return {
                name: `${year}-${startDateSplits[1]}-${index + parseInt(startDateSplits[0]) > 9
                  ? currentDate
                  : "0" + currentDate
                  }`,
                revenue: 0,
              };
            }
          });
          finalData.forEach((item, index) => {
            setRevenueByDate(() => {
              finalData[index].name = formatDateMonthName(item.name);
              return [...finalData];
            });
          });
        } else {
          let var1 = new Date(
            startDateFilter.getTime() -
            startDateFilter.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          let var2 = new Date(
            endDateFilter.getTime() - endDateFilter.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          let currStartDate = startDateFilter.toLocaleString().split(",");
          currStartDate = currStartDate[0];
          currStartDate = currStartDate.replaceAll("/", "-");
          const date = currStartDate;
          const [day, month, year] = date.split("-");
          currStartDate = [year, month, day].join("-");
          let currEndDate = endDateFilter.toLocaleString().split(",");
          currEndDate = currEndDate[0];
          currEndDate = currEndDate.replaceAll("/", "-");
          const dateEnd = currEndDate;
          const [dayEnd, monthEnd, yearEnd] = dateEnd.split("-");
          currEndDate = [yearEnd, monthEnd, dayEnd].join("-");
          let dateRangeDate = getDatesInRange(new Date(var1), new Date(var2));
          function formatDate(date) {
            var d = new Date(date),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
          }
          dateRangeDate = dateRangeDate.flat();
          dateRangeDate = dateRangeDate.map((item) => {
            return formatDate(item);
          });
          let finalData = dateRangeDate.map((_a, index) => {
            let dateExist = res.data.data.revenueByDate.find((row) => {
              let date = row.name.split("-")[2];
              let month = row.name.split("-")[1];
              if (date == _a.split("-")[2] && month == _a.split("-")[1]) {
                return true;
              }
              return false;
            });

            if (dateExist) {
              return dateExist;
            } else {
              return {
                name: _a,
                revenue: 0,
              };
            }
          });
          finalData.forEach((item, index) => {
            setRevenueByDate(() => {
              finalData[index].name = formatDateMonthName(item.name);
              return [...finalData];
            });
          });
        }

        // setNewClients(res.data.data.clientCount);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err?.data?.message);
      });
  }
  function getDateFilterData(start, end) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {};
    if (end && end != startDate) {
      data = {
        startDate: startDate,
        endDate: end,
      };
    } else {
      data = {
        startDate: start ? start : startDate,
      };
    }

    setIsLoading(true);
    apiPostMethod(DASHBOARD_DATE_FILTER, data, header)
      .then((res) => {
        setService(res.data.data.serviceData);
        let totalRev = 0;
        let average;
        for (let item of res.data.data.serviceData) {
          totalRev += item.revenueGenerated;
        }
        average = parseInt(totalRev / res.data.data.invoiceCount);
        setTotalRevenue(totalRev);
        setAverageRevenue(average);
        setNumberOfSales(res.data.data.invoiceCount);
        let revenueDataTemp = res.data.data.revenueByDate;
        let tempArr = [];
        for (let item of revenueDataTemp) {
          // let data1 = item.split(":");
          if (item.name !== "undefined") {
            let obj = {};
            obj["date"] = item.name;
            obj["revenueGenerated"] = item.revenue;
            tempArr.push(obj);
          }
        }
        setTotalRecordsCount(tempArr.length);
        setDateRevenue(tempArr);
        if (startDate && end) {
          let startDateSplits = startDate.toLocaleString().split("/");
          let endDateSplits = end.toLocaleString().split("/");
          let numOfDays = 0;
          let year = startDateSplits[2];
          year = year.split(",")[0];
          if (startDateSplits[1] == endDateSplits[1]) {
            numOfDays = endDateSplits[0] - startDateSplits[0];
            numOfDays += 1;
            let finalData = [...Array(numOfDays)].map((_a, index) => {
              let currentDate = index + parseInt(startDateSplits[0]);
              let dateExist = res.data.data.revenueByDate.find((row) => {
                let date = row.name.split("-")[2];
                if (date == currentDate) {
                  return true;
                }
                return false;
              });
              if (dateExist) {
                return dateExist;
              } else {
                return {
                  name: `${year}-${startDateSplits[1]}-${index + parseInt(startDateSplits[0]) > 9
                    ? currentDate
                    : "0" + currentDate
                    }`,
                  revenue: 0,
                };
              }
            });
            finalData.forEach((item, index) => {
              setRevenueByDate(() => {
                finalData[index].name = formatDateMonthName(item.name);
                return [...finalData];
              });
            });
          } else {
            // else case

            let var1 = new Date(
              startDate.getTime() - startDate.getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[0];
            let var2 = new Date(end.getTime() - end.getTimezoneOffset() * 60000)
              .toISOString()
              .split("T")[0];
            let currStartDate = startDate.toLocaleString().split(",");
            currStartDate = currStartDate[0];
            currStartDate = currStartDate.replaceAll("/", "-");
            const date = currStartDate;
            const [day, month, year] = date.split("-");
            currStartDate = [year, month, day].join("-");
            let currEndDate = end.toLocaleString().split(",");
            currEndDate = currEndDate[0];
            currEndDate = currEndDate.replaceAll("/", "-");
            const dateEnd = currEndDate;
            const [dayEnd, monthEnd, yearEnd] = dateEnd.split("-");
            currEndDate = [yearEnd, monthEnd, dayEnd].join("-");

            let dateRangeDate = getDatesInRange(new Date(var1), new Date(var2));
            function formatDate(date) {
              var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

              if (month.length < 2) month = "0" + month;
              if (day.length < 2) day = "0" + day;

              return [year, month, day].join("-");
            }
            dateRangeDate = dateRangeDate.flat();
            dateRangeDate = dateRangeDate.map((item) => {
              return formatDate(item);
            });
            let finalData = dateRangeDate.map((_a, index) => {
              let dateExist = res.data.data.revenueByDate.find((row) => {
                let date = row.name.split("-")[2];
                let month = row.name.split("-")[1];
                if (date == _a.split("-")[2] && month == _a.split("-")[1]) {
                  return true;
                }
                return false;
              });

              if (dateExist) {
                return dateExist;
              } else {
                return {
                  name: _a,
                  revenue: 0,
                };
              }
            });
            finalData.forEach((item, index) => {
              setRevenueByDate(() => {
                finalData[index].name = formatDateMonthName(item.name);
                return [...finalData];
              });
            });
          }
        } else {
          let singleDate = new Date(
            start.getTime() - start.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          if (res.data.data.revenueByDate.length === 0) {
            res.data.data.revenueByDate[0] = {
              name: singleDate,
              revenue: 0,
            };
          }
          res.data.data.revenueByDate.forEach((item, index) => {
            setRevenueByDate(() => {
              res.data.data.revenueByDate[index].name = formatDateMonthName(
                item.name
              );
              return [...res.data.data.revenueByDate];
            });
          });
        }
        setIsLoading(false);
        // setNewClients(res.data.data.clientCount);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err?.data?.message);
      });
  }

  // function openCalender(e) {
  //   if (open === true) {
  //     setOpen(false);
  //   } else {
  //     setOpen(true);
  //   }
  // }

  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const formatter = (data) => {
    return `$${data}`;
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0 && payload[0].payload) {
      return (
        <div className="custom-tooltip" style={{ background: "white" }}>
          <p>{payload[0].payload.name}</p>
          <p>
            Revenue <b>${payload[0].payload.revenue.toFixed(2)}</b>
          </p>
        </div>
      );
    }
  };
  return (
    <div>
      <Sidebar
        activePage="dashboard"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <div className="commonSlidePl">
        <div className="dashboardSection commonPadding pb-4 horizontalPadding">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-lg-6 col-md-4">
                <div className="heading">
                  <h2>Revenue</h2>
                </div>
              </div>
              <div className="col-lg-6 col-md-8 d-flex align-items-center justify-content-end">
                <div className="dashboardFilters">
                  <div className="dropdown d-flex">
                    <button
                      className="btn btn-secondary dropdown-toggle dashboardDropdown"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Revenue
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/revenue")}
                        >
                          Revenue
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/service-report")}
                        >
                          Services
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/client-report")}
                        >
                          Clients
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/sales")}
                        >
                          Sale Performance
                        </a>
                      </li>
                      {/* <li>
                      <a className="dropdown-item" href="#">
                        Account Balances
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Coupon usage
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Response times
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Assigned orders
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" onClick={() => {navigate('/completed-order')}} href="#">
                        Completed orders
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" onClick={() => { navigate('/order-status')}} href="#">
                        Order statuses
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Taxes
                      </a>
                    </li> */}
                    </ul>

                    {/* <button
                    className="btn btn-secondary dropdown-toggle dashboardDropdown"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Export
                  </button> */}
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Accounts
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Orders
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Transactions
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Invoices
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Form Date
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Revenue By Client
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Ticket Rating
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <input type="date" className="form-control" /> */}
                {/* <input
                      className="form-control"
                      type="text"
                      id="expiryDate"
                      onClick={(e) => {
                        openCalender(e);
                      }}
                      value={`${startDate ? startDate : ""}, ${
                        endDate ? endDate : ""
                      }`}
                    /> */}

                {/* <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa-solid fa-calendar"></i>
                  </button> */}
                {/* <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Last 7 Days
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Last 4 Weeks
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Last 3 Months
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Previous Month
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Month to Date
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Quarter to Date
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Year to Date
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          All Time
                        </a>
                      </li> 
                    </ul>*/}
                {/* <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>{getDateFilterData()}}
                  >
                    Filter
                  </button> */}

                {/* {open === true ? ( */}
                <div className="datepicker-wrapper ms-3">
                  <DatePicker
                    className="form-control"
                    selectsRange
                    maxDate={moment().toDate()}
                    dateFormat="MM/dd/yyyy"
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onChange}
                  />
                  {/* <span className="tooltipdatepickertext">
                          Get date range by dragging on "FROM" date
                        </span> */}
                  {/* ) : (
                      ""
                    )} */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row mt-3">
              <div className="col-lg-4 col-md-4">
                <div className="tile">
                  <div className="row">
                    <div className="col-xxl-2 col-xl-3 col-lg-3">
                      <div className="tileImg">
                        <img src={iconRevenue} />
                      </div>
                    </div>
                    <div className="col-xxl-10 col-xl-9 col-lg-9">
                      <p>Revenue</p>
                      <h3>
                        {currency?.symbol ? currency?.symbol : "$"}
                        {totalRevenue ? totalRevenue : 0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4">
                <div className="tile">
                  <div className="row">
                    <div className="col-xxl-2 col-xl-3 col-lg-3">
                      <div className="tileImg">
                        <img src={salesIcon} />
                      </div>
                    </div>
                    <div className="col-xxl-10 col-xl-9 col-lg-9">
                      <p>Sales</p>
                      <h3>{numberOfSales ? numberOfSales : "NA"}</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4">
                <div className="tile">
                  <div className="row">
                    <div className="col-xxl-2 col-xl-3 col-lg-3">
                      <div className="tileImg">
                        <img src={salesIcon} />
                      </div>
                    </div>
                    <div className="col-xxl-10 col-xl-9 col-lg-9">
                      <p>Average Sale</p>
                      <h3>${averageRevenue ? averageRevenue : "NA"}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-4 mb-4">
              <div className="card-header">
                <h3 className="mb-0">Monthly Revenue</h3>
              </div>
              {/* <div className="card-body"> */}
              <BarChart
                width={1600}
                height={300}
                data={revenueByDate}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barSize={20}
              >
                <XAxis
                  dataKey="name"
                // scale="point"
                // padding={{ left: 10, right: 10 }}
                />
                <YAxis tickFormatter={formatter} />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <Bar
                  dataKey="revenue"
                  fill="#007d87"
                  background={{ fill: "#eee" }}
                />
              </BarChart>
              {/* </div> */}
            </div>
          </div>
        </div>

        {currentList && currentList.length > 0 ? (
          <div className="listingSection horizontalPadding">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-lg-12">
                  <div className="common-table rounded-1">
                    <div className="sectionPagination justify-content-end">
                      <PaginationNewUI
                        itemsPerPage={itemsPerPage}
                        itemOffset={itemOffset}
                        setItemOffset={setItemOffset}
                        pageCount={pageCount}
                        totalRecordsCount={totalRecordsCount}
                        setItemsPerPage={setItemsPerPage}
                      />
                    </div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentList &&
                          currentList.length > 0 &&
                          currentList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.date}</td>
                                <td>${item.revenueGenerated}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default Revenue;
