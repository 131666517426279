import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { formatMonthNameAndDate } from "../../shared/functions";
import PageLoader from "../../components/pageLoader";

import { AllContext } from "../../context";

const ViewTeamMember = () => {
  //constants
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const {
    apiPutMethod,
    apiGetMethod,
    allApiUrl,
    apiDeleteMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { GET_SPECIFIC_CLIENT, DELETE_TEAM_MEMBER, GET_ALL_TEAM, EDIT_CLIENT } =
    allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  var header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  var specificClientId = window.location.pathname.split("/")[3];
  //useState
  const [isLoading, setIsLoading] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [clientId, setClientId] = useState("");
  const [teamAccountList, setTeamAccountList] = useState([]);
  const [assignTeamMember, setAssignTeamMember] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  //   //functions
  useEffect(() => {
    setIsLoading(true);
    getSpecificClientDataHandler();
    getAllTeam();
    setIsLoading(false);
  }, []);
  function getSpecificClientDataHandler() {
    apiGetMethod(`${GET_SPECIFIC_CLIENT}/${specificClientId}`, "", header)
      .then((res) => {
        if (res.status === 200) {
          setClientData(res.data.user[0]);
          setAssignTeamMember(res.data.user[0].accountManager);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }
  const date = new Date(clientData?.userId?.createdOn);
  const monthName = date.toLocaleString("default", {
    month: "short",
  });
  const day = date.getDate();
  const createdOn = `${monthName} ${day}`;

  function deleteTeamMember(couponId) {
    apiDeleteMethod(DELETE_TEAM_MEMBER, couponId, header)
      .then((res) => {
        navigate("/settings/team");
        toastMessage("success", res.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.toastMessage);
        setIsLoading(false);
      });
  }
  function getAllTeam() {
    apiGetMethod(GET_ALL_TEAM, "", header)
      .then((res) => {
        if (res.status === 200) {
          setTeamAccountList(res.data.team);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  const assignTeamMemberHandler = (event) => {
    event.preventDefault();
    const data = {
      accountManager: assignTeamMember,
    };
    apiPutMethod(`${EDIT_CLIENT}/${specificClientId}`, data, header)
      .then((res) => {
        toastMessage("success", `Client Assigned to ${assignTeamMember}`);
        getSpecificClientDataHandler();
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };
  return (
    <div>
      <Sidebar activePage="clients" />
      <Header isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="commonSlidePl">
          <div className="setting-form-section pt-3 horizontalPadding">
            <div className="container-fluid pl-240">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="section-back"
                    onClick={() => navigate("/settings/team")}
                  >
                    <a href="#">
                      <i className="fa-solid fa-angle-left"></i> Team
                    </a>
                  </div>
                </div>

                <div className="col-lg-8 mx-auto">
                  <div className="client-heading mt-5">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="d-flex align-items-center">
                          <div className="userImg">
                            {/* <img src={DummyAvatar} /> */}
                          </div>
                          <div className="userName m-0">
                            <h3>
                              {clientData?.userId?.firstName +
                                " " +
                                clientData?.userId?.lastName}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="btns">
                          {/* <a className="btn-edit" href="#">
                  Sign in as user
                </a> */}
                          <a
                            className="btn-edit"
                            onClick={() => {
                              setIsLoading(true);
                              navigate(`/accounts/edit/${specificClientId}`);
                              setIsLoading(false);
                            }}
                          >
                            Edit
                          </a>
                          <div className="dropdown">
                            <button
                              className="btn btn-secondary dropdown-toggle btn-info"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  New invoice
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  New ticket
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#assignModal"
                                >
                                  Assign a manager
                                </a>
                              </li>
                              {/* <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#mergeModal"
                      >
                        Merge
                      </a>
                    </li> */}

                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                  onClick={() => {
                                    setClientId(specificClientId);
                                  }}
                                >
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="client-details rounded-1">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-4">
                        <p className="clientLabel">Role</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-8">
                        <p
                          className="setting teamRule-text"
                          onClick={() => {
                            navigate(`/role/role/${clientData.userId?.roleId}`);
                          }}
                        >
                          {clientData?.role}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-4">
                        <p className="clientLabel">Email</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-8">
                        <p className="clientText">
                          {clientData?.userId?.email}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-4">
                        <p className="clientLabel">created On</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-8">
                        <p className="clientText">{createdOn}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-4">
                        <p className="clientLabel">Last login</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-8">
                        <p className="clientText">
                          {clientData?.userId?.lastLogin ? (
                            <>
                              {clientData.userId &&
                                clientData.userId?.lastLogin &&
                                clientData.userId?.lastLogin.date && (
                                  <>
                                    {formatMonthNameAndDate(
                                      clientData.userId.lastLogin.date,
                                      companyData.timezone,
                                      true
                                    )}
                                    <br />
                                    {clientData.userId.lastLogin.ipAddress}
                                  </>
                                )}
                            </>
                          ) : (
                            "--"
                          )}{" "}
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-4">
                        <p className="clientLabel">Address</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-8">
                        <p className="clientText">--</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-4">
                        <p className="clientLabel">Balance</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-8">
                        <p className="clientText">--</p>
                      </div>
                    </div>
                  </div>
                  {/*
        <div className="notes mt-5  mb-5">
          <p>Notes</p>
          <div className="edid-note">
            <a href="#">Edit</a>
          </div>
        </div> */}
                  <div className="heading mt-4">
                    {/* <div className="heading">
          <h2>History</h2>
        </div> */}

                    {/* <div className="history-table mb-5">
          <div className="row">
            <div className="col-lg-2  col-md-2">
              <div className="history-date">
                <p>Sep 20</p>
              </div>
            </div>
            <div className="col-lg-8  col-md-8">
              <div className="history-detail">
                <p data-bs-toggle="modal" data-bs-target="#historyModal">
                  <span>Client name</span> Order created.
                </p>
                <p data-bs-toggle="modal" data-bs-target="#historyModal">
                  <span>Client name</span> Order created.
                </p>
                <p data-bs-toggle="modal" data-bs-target="#historyModal">
                  <span>Client name</span> Order created.
                </p>
              </div>
            </div>
            <div className="col-lg-2  col-md-2">
              <div className="history-date">
                <p>1:47 pm</p>
              </div>
            </div>
          </div>
        </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* history modal */}
            <div
              className="modal fade history-modal"
              id="historyModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Order Completed
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="history-content">
                      <div className="form-group">
                        <label>Time</label>
                        <p>Sep 19th at 12:37 pm BST</p>
                      </div>

                      <div className="form-group">
                        <label>User</label>
                        <p>
                          <a href="#">Client Name</a>
                        </p>
                      </div>

                      <div className="form-group">
                        <label>Order</label>
                        <p>
                          <a href="#">6B3500E7</a>
                        </p>
                      </div>

                      <div className="form-group">
                        <label>Data</label>
                        <p>Data</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* history modal */}

            {/* assign manager modal */}
            <div
              className="modal fade history-modal"
              id="assignModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Assign {clientData?.userId?.firstName}`to a team member
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="history-content">
                      <form>
                        {/* <div className="col-lg-4 col-md-4">
                  <div className="form-group">
                    <label>Country</label>
                    <select
                      className="form-control"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      {countries.map((item) => {
                        return (
                          <option value={item.name}>{item.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div> */}

                        <div className="form-group">
                          <label>Assign this customer's orders to </label>
                          <select
                            className="form-control"
                            value={assignTeamMember}
                            onChange={(e) =>
                              setAssignTeamMember(e.target.value)
                            }
                          >
                            {teamAccountList.map((account, idx) => {
                              return (
                                <option
                                  key={idx}
                                  value={account?.userId?.firstName}
                                >
                                  {/* <div> */}
                                  {account?.userId?.firstName}
                                  {/* <small>{account.role}</small> */}
                                  {/* </div> */}
                                </option>
                              );
                            })}
                          </select>
                          <small>
                            This customer's orders will be assigned to selected
                            team member.
                          </small>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={assignTeamMemberHandler}
                    >
                      Assign
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* assign manager modal */}

            {/* merge modal */}
            <div
              className="modal fade history-modal"
              id="mergeModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Merge Gamanjit Singh
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="history-content">
                      <form>
                        <div className="form-group">
                          <label>Select an account to merge into </label>
                          <select className="form-control">
                            <option>Select a team member</option>
                            <option>Team member</option>
                            <option>Team member</option>
                          </select>
                          <small>
                            All orders, messages, invoices, and tickets from
                            Gamanjit Singh will be moved to selected account.
                          </small>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="button" className="btn btn-danger">
                      Merge Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* merge modal */}

            {/* merge modal */}
            <div
              className="modal fade history-modal"
              id="deleteModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  {/* <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div> */}
                  <div className="modal-body">
                    <div className="delete-content">
                      <p>
                        Are you sure you want to delete this team account:
                        <strong>{clientData?.userId?.firstName}</strong>?
                      </p>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={() => deleteTeamMember(clientId)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* merge modal */}
          </div>
        </div>
      )}
    </div>
  );
};
export default ViewTeamMember;
