import { useNavigate } from "react-router-dom";
import errorImg from "../../assets/img/error-img.svg";
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="section-sermissionDenied">
      <div className="container">
        <div className="row h-100vh mx-0 align-items-center">
          <div className="col-xxl-4 mx-auto">
            {/* <h1>Page Not Found</h1> */}
            <img className="mb-5" src={errorImg} />
            {/* <p>This page is not found, sorry 🤕</p> */}
            <a href="#" onClick={() => navigate(-1)}>
              <i className="fa-solid fa-angles-left"></i> Go Back
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageNotFound
