import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AllContext } from "../../context";
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { useParams } from "react-router-dom";
import { editorApiKey } from "../../shared/constants";

const EditMessage = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [isResetToDefault, setIsResetToDefault] = useState(false);
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiPutMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { MESSAGE } = allApiUrl;
  const dispatch = useDispatch();
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { id } = useParams();
  const [body, setBody] = useState("");
  useEffect(() => {
    fetchMessage();
  }, [id]);

  function fetchMessage() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setLoader(true);
    apiGetMethod(MESSAGE + "/" + id, "", header)
      .then((res) => {
        setName(res.data.data[0].name);
        setBody(res.data.data[0].body);
        setLoader(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function handleFormSubmit() {
    const data = {
      name: name,
      body: body,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(MESSAGE + "/" + id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        navigate("/messaging");
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function handleEditorChange(e) {
    setIsResetToDefault(false);
    setBody(e);
  }

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="commonSlidePl">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="section-back"
                  onClick={() => navigate("/messaging")}
                >
                  <a href="#">
                    <i className="fa-solid fa-chevron-left"></i> Saved replies{" "}
                  </a>
                </div>
              </div>

              <div className="col-lg-8 mx-auto">
                <div className="setting-form">
                  <h3 className="emailHeading fs-4">Edit reply template</h3>
                  <form className="">
                    <div className="commonForm rounded-1">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          required
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>Body</label>
                        <Editor
                          required
                          apiKey={editorApiKey}
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          value={body}
                          init={{
                            selector: "textarea",
                            height: 500,
                            menu: {
                              custom: {
                                title: "custom",
                                items: "basicitem nesteditem toggleitem",
                              },
                              toolbar:
                                "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                "fullpage" +
                                "indent | blockquote | codesample | fontselect ",
                              font_formats:
                                "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                              block_formats:
                                "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                              plugins: [
                                "image",
                                "help",
                                "link",
                                "codesample",
                                "lists",
                              ],
                              branding: false,
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                              menubar: false,
                              setup: function (editor) {
                                editor.ui.registry.addSplitButton("myButton", {
                                  icon: "user",
                                  onAction: function () {},
                                  onItemAction: function (api, value) {
                                    editor.insertContent(value);
                                  },
                                  fetch: function (callback) {
                                    var items = [
                                      {
                                        type: "choiceitem",

                                        text: "first Name",
                                        value:
                                          "&nbsp;<em>{{user_firstName}}</em>",
                                      },
                                      {
                                        type: "choiceitem",

                                        text: "last Name",
                                        value:
                                          "&nbsp;<em>{{user_lastName}}</em>",
                                      },
                                      {
                                        type: "choiceitem",

                                        text: "email",
                                        value: "&nbsp;<em>{{user_email}}</em>",
                                      },
                                    ];
                                    callback(items);
                                  },
                                });
                              },
                            },
                          }}
                          onEditorChange={(e) => handleEditorChange(e)}
                        />
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="btn-section text-end">
                        <button
                          className="btn btn-save"
                          onClick={handleFormSubmit}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditMessage;
