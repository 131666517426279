import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AllContext } from "../../context";
import { countries } from "../../shared/constants";
import logo from "../../assets/img/logo.svg";
import signupImg from "../../assets/img/imgSignup.svg";
import accountCreated from "../../assets/img/account-created.svg";
import validator from "validator";
import { getBusinessSubdomain } from "../../shared/functions";

const Signup = () => {
  const navigate = useNavigate();

  const context = useContext(AllContext);

  const { allApiUrl, toastMessage } = context;
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const { apiPostMethod } = context;
  const { SIGNUP_USER } = allApiUrl;
  var header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state1, setState1] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [password, setPassword] = useState("");
  const [isSendPasswordEmail, setIsSendPasswordEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    let loggedIn = localStorage.getItem("userLoggedIn");

    if (loggedIn) {
      if (userInfo.roles.type === "admin") {
        navigate("/dashboard");
      } else {
        navigate("/home");
      }
    }
  }, []);

  const [passwordView, setPasswordView] = React.useState("password");

  const validate = (value) => {
    setPassword(value);
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorMessage("Is Strong Password");
    } else {
      setErrorMessage("Please choose a stronger password");
    }
  };

  const addClietHandler = (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (errorMessage == "Please choose a stronger password") {
      toastMessage(
        "error",
        " The password has to be at least 8 characters long,with at least 1 Uppercase letter, 1 Lowercase  letter, 1 Number and 1 Special Character."
      );
    } else {
      const autoFirstName = email ? email.substring(0, email.indexOf("@")) : "";
      const data = {
        role: "client",
        accountStatus: 'lead',
        billingAddress: billingAddress ? billingAddress : "",
        city: city ? city : "",
        country: country ? country : "",
        postal: postal ? postal : "",
        state: state1 ? state1 : "",
        phone: phone ? phone : "",
        firstName: firstName ? firstName : autoFirstName,
        lastName: lastName ? lastName : "",
        email: email ? email : "",
        password: password ? password : "",
        sendEmail: isSendPasswordEmail,
        businessSubDomain: getBusinessSubdomain()
      };
      apiPostMethod(SIGNUP_USER, data, header)
        .then((res) => {
          if (res.status === 201) {
            toastMessage("success", res.data.message);
            const locationModal = new window.bootstrap.Modal(
              document.getElementById("signupModal")
            );
            locationModal.show();
            setIsLoading(false);
          }
        })
        .catch((err) => {
          // redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    }
  };
  return (
    <div>
      <main className="wrapper">
        <div className="section-login">
          <div className="container-fluid">
            <div className="row h-100vh">
              <div className="col-lg-6 extralightGreyBg formOrder formGrid">
                <div className="loginForm">
                  <a className="brandName" href="#">
                    <img src={logo} />
                  </a>
                  <form className="formLogin" onSubmit={addClietHandler}>
                    <h3>Sign Up</h3>
                    <div className="formInner">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Email"
                              onChange={(e) => setFirstName(e.target.value)}
                              value={firstName}
                            />
                            <label htmlFor="floatingInput">First Name</label>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Email"
                              onChange={(e) => setLastName(e.target.value)}
                              value={lastName}
                            />
                            <label htmlFor="floatingInput">Last Name</label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Email"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                              required
                            />
                            <label htmlFor="floatingInput">Email</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-floating mb-3">
                            <input
                              type="number"
                              className="form-control"
                              id="floatingInput1"
                              placeholder="Phone Number"
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                            />
                            <label htmlFor="floatingInput1">Phone Number</label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-8 col-md-8">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Address"
                              onChange={(e) =>
                                setBillingAddress(e.target.value)
                              }
                              value={billingAddress}
                            />
                            <label htmlFor="floatingInput">Address</label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Email"
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                            />
                            <label htmlFor="floatingInput">City</label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="form-group mb-3">
                            <select
                              className="form-control"
                              onChange={(e) => setCountry(e.target.value)}
                            >
                              {countries.map((item, idx) => {
                                return (
                                  <option key={idx} value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                            {/* <label htmlFor="floatingInput">Address</label> */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="state"
                              onChange={(e) => setState1(e.target.value)}
                              value={state1}
                            />
                            <label htmlFor="floatingInput">
                              State / Province / Region
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Email"
                              onChange={(e) => setPostal(e.target.value)}
                              value={postal}
                            />
                            <label htmlFor="floatingInput">Postal / Zip Code</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-floating mb-3">
                        <input
                          type={passwordView}
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          name="password"
                          onChange={(e) => validate(e.target.value)}
                          value={password}
                          required
                        />
                        {password && errorMessage !== "Is Strong Password" && (
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "12px",
                              color: `${errorMessage == "Is Strong Password"
                                ? "green"
                                : "red"
                                }`,
                            }}
                          >
                            The password has to be at least 8 characters long,
                            with at least 1 Uppercase letter, 1 Lowercase
                            letter, 1 Number and 1 Special Character.
                          </span>
                        )}
                        <span
                          className="eyeIcon fa-solid fa-key"
                          onClick={() => {
                            if (passwordView == "password") {
                              setPasswordView("text");
                            } else {
                              setPasswordView("password");
                            }
                          }}
                        ></span>
                        <label htmlFor="floatingPassword">Password</label>
                      </div>

                      {/* <a className="forgotLink d-inline-block mb-5 text-start">
                      <span onClick={() => navigate("/forgot-password")}>
                        {" "}
                        Forgot password?
                      </span>
                    </a> */}
                      <div className="input-group">
                        <button
                          className="btn btn-primary btn-submit mt-4"
                          // data-bs-toggle="modal"
                          data-bs-target={`${email &&
                            password &&
                            errorMessage == "Is Strong Password"
                            ? "#signupModal"
                            : ""
                            }`}
                        >
                          Create Account
                        </button>
                      </div>
                      <p className="signupText">
                        Already have account?{" "}
                        <a href="#" onClick={() => navigate("/login")}>
                          Sign In
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
                <div className="formFooter">
                  <p className="copyrightText">© OPFLOW. All Rights Reserved.{" "}</p>
                </div>
              </div>
              <div className="col-lg-6 lightGreyBg border formGrid">
                <div className="formContent">
                  <h3>Welcome!</h3>
                  <p>Elevate your business operations with OpFlow – Sign up now for a smarter approach.</p>
                </div>
                <div className="loginImg">
                  <img src={signupImg} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* signup modal */}
        <div
          className="modal fade signupModal"
          id="signupModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => navigate("/login")}
                ></button>
              </div>
              <div className="modal-body">
                <div className="signupModal-content">
                  <img src={accountCreated} />
                  <h6>Your account has been created.</h6>
                  <button
                    className="btn"
                    data-bs-dismiss="modal"
                    onClick={() => navigate("/login")}
                  >
                    Click to Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Signup;
