import { combineReducers } from "redux";
// TODO Reducer
import loginReducer from "./login";
import csvReducer from "./csv";
import sideMenuReducer from "./sidemenu";
import teamReducer from "./team";

const appReducer = combineReducers({
  loginReducer: loginReducer,
  csvReducer: csvReducer,
  sideMenuReducer: sideMenuReducer,
  teamReducer: teamReducer
});

export default (state, action) =>
appReducer(action.type === 'USER_LOGOUT' ? undefined : state, action);
