import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import { useSelector, useDispatch } from "react-redux";
import { AllContext } from "../../context";
import { Badge } from "react-bootstrap";
import SettingTabs from "../../components/settings/tabs";
import CrmFields from "../../components/settings/crm-fields";
import openIcon from "../../assets/img/icons/openSide.svg";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import AddEditStatusModal from "./addEditStatusModal";

const AccountSettings = () => {
  //Constants
  const dispatch = useDispatch();
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  const context = useContext(AllContext);
  const {
    toastMessage,
    apiGetMethod,
    redirectToLogin,
    apiPostMethod,
    apiDeleteMethod,
    allApiUrl,
    apiPutMethod,
  } = context;
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const navigate = useNavigate();

  //states
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusName, setStatusName] = useState("");
  const [selectedstatusId, setSelectedStatusId] = useState("");
  const [statusDescription, setStatusDescription] = useState("");
  const [statusBg, setStatusBg] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [accountStatusList, setAccountStatusList] = useState([]);
  const [prevStatusName, setPrevStatusName] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    addEdit: false,
    delete: false
  })
  const [disabledBtn, setDisabledBtn] = useState(false)
  const statusColors = [
    { label: 'Select Color', value: '' },
    { label: 'Danger', value: 'danger' },
    { label: 'Dark', value: 'dark' },
    { label: 'Info', value: 'info' },
    { label: 'Light', value: 'light' },
    { label: 'Primary', value: 'primary' },
    { label: 'Secondary', value: 'secondary' },
    { label: 'Success', value: 'success' },
    { label: 'Warning', value: 'warning' },
  ];
  // Functions
  function getAllAccountStatus() {
    setInitialLoading(true);
    apiGetMethod(allApiUrl.GET_ALL_ACCOUNT_STATUS, "", header)
      .then(async (res) => {
        if (res.status === 200) {
          setAccountStatusList(res.data[0].status);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function updateStatus(e) {
    e.preventDefault();
    setDisabledBtn(true)
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    if (statusName.trim()) {
      // if (statusName.trim() !== prevStatusName.trim()) {
      let data = {
        currentName: prevStatusName,
        updatedName: statusName,
        description: statusDescription,
        bg: statusBg,
      };
      setIsLoading(true);
      apiPutMethod(allApiUrl.EDIT_ACCOUNT_STATUS, data, header)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setIsLoading(false);
            getAllAccountStatus();
            toastMessage("success", res.data.message);
          }
          setDisabledBtn(false)
          handleCancelModal()
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
          setDisabledBtn(false)
          handleCancelModal()
        });
      /*} else {
        toastMessage("error", "same status name ");
        setIsLoading(false);
      }*/
    } else {
      toastMessage("error", "Enter Something");
      setIsLoading(false);
      setDisabledBtn(false)
    }
  }

  useEffect(() => {
    getAllAccountStatus();
  }, []);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  function onAddStatus(e) {
    e.preventDefault();
    setDisabledBtn(true)
    let data = {
      name: statusName.trim().toLowerCase(),
      description: statusDescription.trim(),
      bg: statusBg.trim(),
      isModified: true,
    };

    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    setIsLoading(true);
    if (statusName.trim()) {
      apiPostMethod(allApiUrl.ADD_ACCOUNT_STATUS, data, header)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setStatusName("");
            setStatusDescription("");
            setStatusBg("");
            setSelectedStatusId("");

            setIsLoading(false);
            getAllAccountStatus();
            toastMessage("success", res.data.message);
            setDisabledBtn(false)
            handleCancelModal()
          }
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
          setDisabledBtn(false)
          handleCancelModal()
        });
    } else {
      toastMessage("error", "Enter Something");
      setIsLoading(false);
      setDisabledBtn(false);
    }
  }

  function deleteStatus() {
    setIsLoading(true);
    setDisabledBtn(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.DELETE_ACCOUNT_STATUS, selectedstatusId, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        getAllAccountStatus();
        setIsLoading(false);
        setDisabledBtn(false)
        handleCancelModal()
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
        setDisabledBtn(false)
        handleCancelModal()
      });
  }
  const resetStatus = (e) => {
    e.preventDefault();
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    apiPutMethod(allApiUrl.RESET_ACCOUNT_STATUS, {}, header)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          getAllAccountStatus();
          toastMessage("success", res.data.message);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleCancelModal = () => {
    setIsModalOpen({
      delete: false,
      addEdit: false
    });
  }

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">
                    Accounts Settings
                  </h2>
                  <span className="openArrowIcon" onClick={() => { setToggleSidebar(!toggleSideBar) }}>
                    <img src={openIcon} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SettingTabs currentTab="accounts" />
      {/* <PageLoader isLoading={initialLoading} /> */}
      {/* {!initialLoading && (
        <> */}
      <div className="commonSlidePl">
        <div className="setting-form-section mt-4 mb-4">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-xxl-10 col-lg-10 mx-auto">
                <div className="d-flex justify-content-between">
                  <h3 className="mt-0">Statuses </h3>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="setting-form">
                      <form className="commonForm rounded-1">
                        <div className="table-resposnive statusTable">
                          <>
                            {accountStatusList.map((item, idx) => {
                              return (
                                <div
                                  key={"statusList_" + idx}
                                  className="d-flex justify-content-between"
                                >
                                  <div>
                                    <Badge bg={item.bg}>
                                      {capitalizeFirstLetter(item.name)}
                                    </Badge>
                                    <p>{item.description}</p>
                                  </div>
                                  {item.isModified && (
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle btn-light"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                      </button>
                                      <ul className="dropdown-menu">
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() => {
                                              setStatusName(item.name);
                                              setStatusDescription(item?.description || '');
                                              setStatusBg(item?.bg || '');
                                              setPrevStatusName(item.name);
                                              setEditMode(true);
                                              setIsModalOpen({
                                                ...isModalOpen,
                                                addEdit: true
                                              })
                                              setSelectedStatusId(item._id)
                                            }}
                                          >
                                            Edit
                                          </a>
                                        </li>

                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={() => {
                                              if (
                                                userInfo.roles.name === "admin"
                                              ) {
                                                setStatusName(item.name);
                                                setSelectedStatusId(item._id)
                                                setEditMode(false);
                                                setIsModalOpen({
                                                  ...isModalOpen,
                                                  delete: true
                                                })
                                              } else {
                                                toastMessage(
                                                  "error",
                                                  "You dont have permission to access this resource"
                                                );
                                              }
                                            }}
                                          >
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                            {accountStatusList &&
                              accountStatusList.length <= 0 && (
                                <div className="d-table mx-0 my-auto">
                                  <div className="noData-section">
                                    <img src={noData} alt={""} />
                                    <p>No Data Found</p>
                                  </div>
                                </div>
                              )}
                          </>
                        </div>
                        {/* commented as these account statusIds will be associated to client temas table so, we can't reset these statuses */}
                        <div className="add-role mb-2 d-flex justify-content-between">
                          {/* <button className="btn text-primary ps-0" onClick={resetStatus}>
                            Reset Changes
                          </button> */}
                          <div
                            className="btn text-primary"
                            onClick={() => {
                              setPrevStatusName("");
                              setStatusName("");
                              setStatusDescription("");
                              setStatusBg("");
                              setEditMode(false)
                              setIsModalOpen({
                                ...isModalOpen,
                                addEdit: true
                              })
                            }}
                          >
                            + Add Status
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <CrmFields></CrmFields>
            </div>
          </div>
        </div>
      </div>
      {/* </>
      )} */}
      {/* Delete Modal */}
      {isModalOpen.delete ? (
        <DeleteConfirmModal
          isDeleteConfirmModalOpen={isModalOpen.delete}
          handleDelete={deleteStatus}
          handleCancelModal={handleCancelModal}
          modalContent={`Are you sure you want to delete this Status : ${statusName}`}
          disabled={disabledBtn}
        />
      ) : (
        ""
      )}

      {/* Add and Edit modal */}
      {isModalOpen.addEdit ? (<AddEditStatusModal
        isModalOpen={isModalOpen.addEdit}
        setStatusName={setStatusName}
        statusName={statusName}
        setStatusDescription={setStatusDescription}
        statusDescription={statusDescription}
        editMode={editMode}
        setStatusBg={setStatusBg}
        statusBg={statusBg}
        statusColors={statusColors}
        onAddStatus={onAddStatus}
        updateStatus={updateStatus}
        handleCancelModal={handleCancelModal}
        disabledBtn={disabledBtn}
      />) : (
        ""
      )}
    </div>
  );
};
export default AccountSettings;