import React, {useContext, useEffect} from "react";
import {AsyncPaginate} from "react-select-async-paginate";
import {AllContext} from "../../context";
import {useSelector} from "react-redux";
import {convertToUpperCase} from "../../shared/functions";

const SelectAsync = ({searchType, selectedValue, onChange, placeholder, isMulti, dataParam}) => {
  const context = useContext(AllContext);
  const { allApiUrl, apiPostMethod, toastMessage } = context;
  const loginUserAccessToken = useSelector((state) => state.loginReducer.loginUserAccessToken);
  let header = { Authorization: `Bearer ${loginUserAccessToken}` };

  const urls = {
    orders: allApiUrl.SEARCH_LIST_ORDERS,
    tickets: allApiUrl.SEARCH_LIST_TICKETS,
    clients: allApiUrl.SEARCH_LIST_CLIENTS,
    multiClients: allApiUrl.SEARCH_LIST_CLIENTS,
    tags: allApiUrl.SEARCH_LIST_TAGS,
    teams: allApiUrl.SEARCH_LIST_TEAM,
    workflows: allApiUrl.SEARCH_LIST_WORKFLOWS,
    services: allApiUrl.SEARCH_LIST_SERVICES,
  }

  useEffect(() => {
    if (searchType === 'workflows') {
      loadOptions('', []).catch(() => {})
    }
  }, []);
  const getListOptions = (resData, currentListLength) => {
    // searchType
    let options = [];
    let hasMore = resData.hasMore;
    switch (searchType) {
      case 'orders': {
        options = [...resData.list].map(o => {
          return {
            value: o.orderId,
            label: <>
              {o.title}<br />
              <small>{o.orderId}</small>
            </>
          }
        })
        break;
      }
      case 'tickets': {
        options = [...resData.list].map(o => {
          return {
            value: o._id,
            label: <>
              {o.to}<br />
              <small>{o.subject}</small>
            </>
          }
        })
        break;
      }
      case 'clients': {
        options = [...resData.list].map(o => {
          return {
            value: o?.userId?._id,
            label: <>
              {convertToUpperCase(o?.userId?.firstName)}<br />
              <small>{o?.userId?.email}</small>
            </>
          }
        })
        break;
      }
      case 'teams':
      case 'multiClients': {
        options = [...resData.list].map(o => {
          return {
            value: o?.userId?._id,
            label: <>
              {o.userId?.firstName &&
              o.userId?.lastName
                ? o.userId?.firstName +
                "" +
                o.userId?.lastName
                : o.userId?.email}{" "}
              <br /><small>{o?.role}</small>
            </>
          }
        })
        break;
      }
      case 'tags': {
        options = [...resData.list].map(o => {
          return {
            value: o?._id,
            label: o.name
          }
        })
        break;
      }
      case 'workflows': {
        options = [...resData.list].map(o => {
          return {
            value: o?._id,
            label: o.workflowName,
            workFlowCrmFields: o?.workFlowCrmFields,
            isDefaultWorkflow: o?.isDefaultWorkflow,
            orderStatuses: o?.orderStatuses
          }
        })

        if (currentListLength === 10) {
          const defaultSelected = options.filter(d => !!(d.isDefaultWorkflow))
          if (defaultSelected && defaultSelected.length) {
            onChange(defaultSelected[0])
          }
        }
        break;
      }
      case 'services': {
        options = [...resData.list].map(o => {
          return {
            value: o?._id,
            label: <>
              {o?.name}<br />
              <small>{"$" + o?.price.toFixed(2)}</small>
            </>,
            defaultAssignee: o?.defaultAssignee
          }
        })
        break;
      }
      case 'date': {
        options = [
          {
            value: false,
            label: 'Any Date'
          },
          {
            value: true,
            label: 'Date Between'
          }
        ]
        hasMore = false
        break;
      }
      default: {
        break;
      }
    }
    return { options, hasMore }
  }

  const loadOptions = async (searchText, prevOptions) => {
    if (searchType === 'date') {
      return getListOptions(false, 0)
    } else {
      const limit = 10
      let data = {};
      data.page = prevOptions.length / limit + 1;
      data.limit = limit;
      if (searchText) {
        data.searchText = searchText;
      }
      if (dataParam) {
        data = {
          ...data,
          ...dataParam
        }
      }

      const res = await apiPostMethod(urls[searchType], data, header);
      if (res && res.status === 200) {
        return getListOptions(res.data, prevOptions.length + limit);
      }
      return { options: [], hasMore: false };
    }
  };

  return (
    <>
      <AsyncPaginate
        debounceTimeout={300}
        value={selectedValue}
        loadOptions={loadOptions}
        onChange={onChange}
        isMulti={isMulti}
        
        placeholder={placeholder}
        classNamePrefix="mySelect"
      />
    </>
  )
}
export default SelectAsync;
