import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import noData from "../../assets/img/nodata-img.svg";
import PaginationNewUI from "../../components/PaginationNewUI";

const ServiceReport = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [service, setService] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [itemOffset, setItemOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const context = useContext(AllContext);
  const { allApiUrl, apiPostMethod, toastMessage, redirectToLogin } = context;
  const { DASHBOARD, DASHBOARD_DATE_FILTER } = allApiUrl;
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  useEffect(() => {
    getAllServices();
  }, [itemOffset, itemsPerPage]);

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const onChange = (dates) => {
    const [start, end] = dates;
    setDateRange(dates);
    if (end) {
      getDateFilterData(start, end);
    }
  };
  const currentList = service;

  function getAllServices() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const data = {};
    data.page = itemOffset;
    data.limit = itemsPerPage;
    setIsLoading(true);
    apiPostMethod(DASHBOARD, data, header)
      .then((res) => {
        setPageCount(Math.ceil(res.data.serviceCount / itemsPerPage));
        setService(res.data.serviceData);
        setTotalRecordsCount(res.data.serviceCount);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data);
      });
  }

  function getDateFilterData(start, end) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {};
    if (end && end != startDate) {
      data = {
        startDate: startDate,
        endDate: end,
      };
    } else {
      data = {
        startDate: start ? start : startDate,
      };
    }
    setIsLoading(true);
    apiPostMethod(DASHBOARD_DATE_FILTER, data, header)
      .then((res) => {
        setService(res.data.data.serviceData);
        setPageCount(
          Math.ceil(res.data.data.serviceData.length / itemsPerPage)
        );
        setTotalRecordsCount(res.data.data.serviceData.length);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  return (
    <div>
      <Sidebar
        activePage="dashboard"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      {!isLoading && (
        <div className="commonSlidePl">
          <div className="dashboardSection commonPadding pb-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-4">
                  <div className="heading">
                    <h2 style={{ overflow: "hidden" }}>Services</h2>
                  </div>
                </div>
                <div className="col-lg-6 col-md-8 d-flex align-items-center justify-content-end">
                  <div className="dashboardFilters">
                    <div className="dropdown d-flex">
                      <button
                        className="btn btn-secondary dropdown-toggle dashboardDropdown"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ width: "170px" }}
                      >
                        Services
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/revenue");
                            }}
                            href="#"
                          >
                            Revenue
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/service-report");
                            }}
                            href="#"
                          >
                            Services
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/client-report");
                            }}
                            href="#"
                          >
                            Clients
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/sales");
                            }}
                            href="#"
                          >
                            Sale Performance
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="datepicker-wrapper ms-3">
                    <DatePicker
                      className="form-control"
                      selectsRange
                      maxDate={moment().toDate()}
                      dateFormat="MM/dd/yyyy"
                      startDate={startDate}
                      endDate={endDate}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="sectionPagination justify-content-end mt-3">
                <PaginationNewUI
                  itemsPerPage={itemsPerPage}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  pageCount={pageCount}
                  totalRecordsCount={totalRecordsCount}
                  setItemsPerPage={setItemsPerPage}
                />
              </div>
              <div className="col-12">
                <div className="listingSection mt-4">
                  <div className="common-table rounded-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Service Name</th>
                          <th>Units Sold</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentList &&
                          currentList.length > 0 &&
                          currentList.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{item.serviceName}</td>
                                <td>{item.unitsSold}</td>
                                <td>${item.revenueGenerated}</td>
                              </tr>
                            );
                          })}
                        {currentList && currentList.length <= 0 && (
                          <tr>
                            <td colSpan={7}>
                              <div className="noData-section">
                                <img src={noData} />
                                <p>No Data Found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ServiceReport;
