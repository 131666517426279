import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import { editorApiKey } from "../../shared/constants";

const AddNotes = ({ noteData, noteFormValue, handleEditorChange, handleSaveNote, showForm, handleShowForm, placeholderText }) => {
    const editorRef = useRef(null);

    return (
        <>
            {!showForm && (
                <div className="teamNote mb-3 rounded-1">
                    {noteData && noteData.length > 0 ? (
                        <p>
                            <div
                                className="serviceDescription"
                                dangerouslySetInnerHTML={{
                                    __html: noteData,
                                }}
                            ></div>
                        </p>
                    ) : (
                        <p>{placeholderText}</p>
                    )}
                    <a
                        className=""
                        onClick={handleShowForm}
                    >
                        Edit
                    </a>
                </div>
            )}
            {showForm && (
                <div className="teamNote">
                    <div className="form-group">
                        <Editor
                            className="form-control"
                            placeholder="Add a note for your team..."
                            apiKey={editorApiKey}
                            onInit={(evt, editor) =>
                                (editorRef.current = editor)
                            }
                            value={noteFormValue}
                            init={{
                                selector: "textarea",
                                height: 200,
                                plugins: "advlist link image lists",
                                menubar: false,
                                toolbar: false,
                                menubar: "custom",
                                menu: {
                                    custom: {
                                        title: "custom",
                                        items: "basicitem nesteditem toggleitem",
                                    },
                                },
                                toolbar:
                                    "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                    "fullpage" +
                                    "indent | blockquote | fontselect ",
                                font_formats:
                                    "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                block_formats:
                                    "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                plugins: ["image", "help", "link", "lists"],
                                branding: false,
                                content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                                menubar: false,
                            }}
                            onEditorChange={(e) => handleEditorChange(e)}
                        />
                        <div className="text-end">
                            <button
                                className="btn btn-save-note formfooterbtn"
                                value={noteFormValue}
                                onClick={handleSaveNote}
                            >
                                Save note
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default AddNotes;