import React, { useContext, useEffect, useRef } from "react";
import Sidebar from "../../widgets/sidebar";
import { useSelector, useDispatch } from "react-redux";
import Switch from "react-switch";
import { Select } from "antd";
import { TimePicker } from "antd";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import { AllContext } from "../../context";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../components/pageLoader";
import { editorApiKey } from "../../shared/constants";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";

const weekDays = [
  { value: "Sunday", label: "Sunday" },
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
];
const format = "HH:mm";

const Messaging = () => {
  const editorRef = useRef(null);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const navigate = useNavigate();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiDeleteMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { MESSAGE, MESSAGE_SETTINGS } = allApiUrl;
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [messages, setMessages] = useState([]);
  const [messageId, setMessageId] = useState();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isAutoReplyEnabled, setIsAutoReplyEnabled] = useState(false);
  const [autoReplyMessage, setAutoReplyMessage] = useState("");
  const [signatureValue, setSignatureValue] = useState({
    status: false,
    sign: "",
  });
  const [businessHours, setBusinessHours] = useState({
    days: [],
    from: "08:00",
    to: "17:00",
  });
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false)

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  const dispatch = useDispatch();
  function getAllMessages() {
    // setIsLoading(true);
    apiGetMethod(MESSAGE, "", header)
      .then((res) => {
        setMessages(res.data.data);
        // setIsLoading(false);
        setInitialLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  useEffect(() => {
    getAllMessages();
    getMessageSettings();
  }, []);

  const getMessageSettings = () => {
    apiGetMethod(MESSAGE_SETTINGS, "", header)
      .then((res) => {
        processMessageSettingsData(res.data);
        setInitialLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const processMessageSettingsData = (data) => {
    if (data?.enableAutoReply) {
      setIsAutoReplyEnabled(data.enableAutoReply);
    }
    if (data?.signature) {
      setSignatureValue(data?.signature);
    }
    if (data?.autoReplyMessage) {
      setAutoReplyMessage(data?.autoReplyMessage);
    }
    if (data?.businessHours) {
      if (data?.businessHours?.days?.length) {
        setBusinessHours(data?.businessHours);
      }
    }
  };
  const updateMessageSettings = (data) => {
    setIsLoading(true);
    apiPostMethod(MESSAGE_SETTINGS, data, header)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          toastMessage("success", res.data.message);
          getMessageSettings();
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const editMessage = async (_id) => {
    navigate("/edit-message/" + _id);
  };

  function deleteMessage(id) {
    setIsLoading(true);
    setDisabledBtn(true)
    apiDeleteMethod(MESSAGE, id, header)
      .then((res) => {
        handleToClose();
        getAllMessages();
        setIsLoading(false);
        setDisabledBtn(false)
        setIsDeleteConfirmModalOpen(false)
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
        setDisabledBtn(false)
        setIsDeleteConfirmModalOpen(false)
      });
  }
  const handleToClose = () => {
    setOpen(false);
  };

  function deleteMultiple() {
    setIsLoading(true);
    bulkDeleteId.map((row) => {
      apiDeleteMethod(MESSAGE, row, header)
        .then((res) => {
          handleToClose();
          getAllMessages();
          setIsLoading(false);
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    });
  }

  function checkedAll() {
    if (messages.length === bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = messages.map((e) => {
        return e._id;
      });
      setBulkDeleteId(ids);
    }
  }

  function deleteIdList(status, id) {
    if (status) {
      let exist = bulkDeleteId.findIndex((e) => e === id);
      if (exist === -1) {
        bulkDeleteId.push(id);
        setBulkDeleteId(bulkDeleteId);
      }
    } else {
      let exist = bulkDeleteId.findIndex((e) => e === id);
      let ids = bulkDeleteId.splice(exist + 1, 1);
      setBulkDeleteId(ids);
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }

  const handleToggleChange = (toggleChecked) => {
    setIsAutoReplyEnabled(toggleChecked);
    const data = {
      enableAutoReply: toggleChecked,
      signature: signatureValue,
      autoReplyMessage,
      businessHours,
    };
    updateMessageSettings(data);
  };

  const handleAutoReplyEditorChange = (event) => {
    setAutoReplyMessage(event);
  };

  const handleSignatureEditorChange = (event) => {
    setSignatureValue({ ...signatureValue, sign: event });
  };

  const handleMessagingSetting = () => {
    const data = {
      enableAutoReply: isAutoReplyEnabled,
      signature: signatureValue,
      autoReplyMessage,
      businessHours,
    };
    updateMessageSettings(data);
  };
  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="commonSlidePl">
          <div className="setting-form-section pt-3 orders-section horizontalPadding">
            <div className="container-fluid">
              <div className="row">
                <div className="section-back ">
                  <a href="#" onClick={() => { navigate('/settings') }}>
                    <i className="fa-solid fa-angle-left"></i> Settings
                  </a>
                </div>
                <div className="col-lg-8 mx-auto">
                  <div className="setting-form">
                    <div className="reply-template-wrapper">
                      <h3 className="tags-heading fs-4">
                        Saved replies{" "}
                        <a onClick={() => { navigate('/add-message') }} href="#">Add reply template</a>
                      </h3>
                      <div className="commonForm common-table p-0 rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div className="form-check mb-0">
                                  <input
                                    type="checkbox"
                                    className="selectAll form-check-input"
                                    id="selectAll"
                                    checked={
                                      messages.length === bulkDeleteId.length &&
                                      messages.length > 0
                                    }
                                    defaultValue
                                    onClick={() => checkedAll()}
                                    onChange={(e) => console.log(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="selectAll"
                                  ></label>
                                </div>
                              </th>
                              <th>Name</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* no record found */}
                            {/* <tr>
                            <td colSpan={3}>
                              <div className="noData-section">
                                <img src={noData} />
                                <p>No Data Found</p>
                              </div>
                            </td>
                          </tr> */}
                            {/* no record found */}
                            {messages
                              .map((value, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="ps-4 w65-px">
                                      <div className="form-check mb-0">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={value._id}
                                          checked={bulkDeleteId.find(
                                            (e) => e === value._id
                                          )}
                                          onChange={(e) => {
                                            deleteIdList(
                                              e.target.checked,
                                              value._id
                                            );
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={value._id}
                                        ></label>
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        onClick={() => editMessage(value._id)}
                                      >
                                        {value.name}
                                      </a>
                                    </td>
                                    <td>
                                      <div className="dropdown text-center">
                                        <button
                                          className="btn btn-secondary dropdown-toggle btn-info"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                              onClick={() =>
                                                editMessage(value._id)
                                              }
                                            >
                                              Edit
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                              onClick={() => {
                                                setOpen(true);
                                                setMessageId(value._id);
                                                setIsDeleteConfirmModalOpen(true)
                                              }}
                                            >
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                              .reverse()}
                            {messages && messages.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img alt="no-img" src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-bottom-option">
                        <div className="row">
                          <div className="col-lg-6 col-md-4">
                            <div className="input-group">
                              <select
                                id="selectOption"
                                value={selectOption.target}
                                onChange={(e) => selectOption(e)}
                                className="form-control"
                              >
                                <option value="">With Selected...</option>
                                <option value="Delete">Delete</option>
                              </select>
                              <button
                                className="btn btn-apply"
                                onClick={() => {
                                  if (selectedOption === "Delete") {
                                    deleteMultiple();
                                  }
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="auto-reply-settings mb-4">
                      <div className="d-flex align-items-center mt-4 mb-3">
                        <h3 className="me-3">Enable Auto Reply</h3>
                        <Switch
                          onChange={handleToggleChange}
                          checked={isAutoReplyEnabled}
                          className="react-switch"
                          onHandleColor="#007D87"
                          handleDiameter={28}
                          onColor="#007D87"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={18}
                          width={45}
                        />
                      </div>
                      {isAutoReplyEnabled && (
                        <>
                          <div className="reply-msg-editor">
                            <Editor
                              required
                              apiKey={editorApiKey}
                              onInit={(evt, editor) =>
                                (editorRef.current = editor)
                              }
                              value={autoReplyMessage}
                              init={{
                                forced_root_block: "",
                                selector: "textarea",
                                height: 200,
                                menu: {
                                  custom: {
                                    title: "custom",
                                    items: "basicitem nesteditem toggleitem",
                                  },
                                },
                                toolbar:
                                  "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                  "fullpage" +
                                  "indent | blockquote | codesample | fontselect ",
                                font_formats:
                                  "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                block_formats:
                                  "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                plugins: [
                                  "image",
                                  "help",
                                  "link",
                                  "codesample",
                                  "lists",
                                ],
                                branding: false,
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                                menubar: false,
                              }}
                              onEditorChange={(e) =>
                                handleAutoReplyEditorChange(e)
                              }
                            />
                            <small>
                              Sent outside business hours, company timezone.
                            </small>
                          </div>

                          <div className="business-hours mt-4">
                            <h3>Business Hours</h3>
                            <div className="row">
                              <div className="col-md-6 px-2">
                                <Select
                                  mode="multiple"
                                  allowClear
                                  style={{
                                    width: "100%",
                                  }}
                                  placeholder="Business Days"
                                  defaultValue={businessHours.days}
                                  onChange={(event) => {
                                    setBusinessHours({
                                      ...businessHours,
                                      days: event,
                                    });
                                  }}
                                  showArrow
                                  options={weekDays}
                                  size="large"
                                  maxTagCount={2}
                                />
                              </div>
                              <div className="col-md-6 px-2">
                                <TimePicker
                                  format={format}
                                  size="large"
                                  allowClear={false}
                                  value={moment(businessHours.from, format)}
                                  onChange={(time) => {
                                    setBusinessHours({
                                      ...businessHours,
                                      from: moment(time).format(format),
                                    });
                                  }}
                                />
                                <span className="px-1">to</span>
                                <TimePicker
                                  format={format}
                                  size="large"
                                  allowClear={false}
                                  value={moment(businessHours.to, format)}
                                  onChange={(time) =>
                                    setBusinessHours({
                                      ...businessHours,
                                      to: moment(time).format(format),
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="signature-editor mt-4">
                            <h3>Signatures</h3>
                            {/* <Editor
                          required
                          apiKey={editorApiKey}
                          onInit={(evt, editor) =>
                            (editorRef.current = editor)
                          }
                          value={signatureValue.sign}
                          init={{
                            forced_root_block: "",
                            selector: "textarea",
                            height: 200,
                            branding: false,

                            plugins: "advlist link image lists",
                            menubar: false,
                            toolbar: false,
                            menubar: "custom",
                            menu: {
                              custom: {
                                title: "custom",
                                items: "basicitem nesteditem toggleitem",
                              },
                            },
                            toolbar:
                              "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                              "fullpage" +
                              "indent | blockquote | codesample | fontselect ",
                            font_formats:
                              "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                            block_formats:
                              "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                            plugins: [
                              "image",
                              "help",
                              "link",
                              "codesample",
                              "lists",
                            ],
                            branding: false,
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                            menubar: false,
                          }}
                          onEditorChange={(e) => handleSignatureEditorChange(e)}
                        /> */}
                            <Editor
                              required
                              apiKey={editorApiKey}
                              onInit={(evt, editor) =>
                                (editorRef.current = editor)
                              }
                              value={signatureValue.sign}
                              init={{
                                selector: "textarea",
                                height: 200,
                                menu: {
                                  custom: {
                                    title: "custom",
                                    items: "basicitem nesteditem toggleitem",
                                  },
                                },
                                toolbar:
                                  "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                  "fullpage" +
                                  "indent | blockquote | codesample | fontselect ",
                                font_formats:
                                  "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                block_formats:
                                  "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                plugins: [
                                  "image",
                                  "help",
                                  "link",
                                  "codesample",
                                  "lists",
                                ],
                                branding: false,
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                                menubar: false,
                                setup: function (editor) {
                                  editor.ui.registry.addSplitButton("myButton", {
                                    icon: "user",
                                    // tooltip: "This is an example split-button",
                                    onAction: function () {
                                      // editor.insertContent(
                                      //   "<p>You clicked the main button</p>"
                                      // );
                                    },
                                    onItemAction: function (api, value) {
                                      editor.insertContent(value);
                                    },
                                    fetch: function (callback) {
                                      var items = [
                                        {
                                          type: "choiceitem",
                                          text: "Sender First Name",
                                          value: "&nbsp;<em>{{sender_name_f}}</em>",
                                        },
                                        {
                                          type: "choiceitem",
                                          text: "Sender Last Name",
                                          value: "&nbsp;<em>{{sender_name_l}}</em>",
                                        },
                                        {
                                          type: "choiceitem",
                                          text: "Sender Email",
                                          value: "&nbsp;<em>{{sender_email}}</em>",
                                        },
                                        {
                                          text: "Sender Name",
                                          value: "&nbsp;<em>{{sender_name_f}}</em>",
                                        },
                                      ];
                                      callback(items);
                                    },
                                  });
                                },
                              }}
                              onEditorChange={(e) => handleSignatureEditorChange(e)}
                            />
                            <small>Signature is appended to outgoing messages.</small>
                          </div>

                          <div className="btn-section text-end mb-0 mt-4">
                            <button
                              className="btn btn-save"
                              onClick={handleMessagingSetting}
                            >
                              Save Settings
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* tags Modal */}
      <div
        className="modal fade tags-modal"
        id="tagsModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form className="commonForm">
                <div className="form-group">
                  <label>Tag name</label>
                  <input
                    type="text"
                    placeholder="Tag Name"
                    className="form-control"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Add tag
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* delete modal */}
      {isDeleteConfirmModalOpen ? (
        <DeleteConfirmModal
          isDeleteConfirmModalOpen={isDeleteConfirmModalOpen}
          handleDelete={() => { deleteMessage(messageId) }}
          handleCancelModal={() => setIsDeleteConfirmModalOpen(false)}
          modalContent={`Are you sure you want to delete this saved reply?`}
          disabledBtn={disabledBtn}
        />
      ) : (
        ""
      )}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <p>Are you sure you want to delete this saved reply?</p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => deleteMessage(messageId)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal */}
    </div>
  );
};
export default Messaging;