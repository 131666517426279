import { useState } from "react";
import EditTaxRuleModal from "./EditTaxRuleModal";

const  TaxRulesList = ({ taxRulesData, handleTaxRuleChange, handleDeleteTaxRule }) => {

    const [isEditTaxRuleModalOpen, setIsEditTaxRuleModalOpen] = useState();
    const [selectedTaxRule, setSelectedTaxRule] = useState(null);
    const [selectedTaxRuleIndex, setSelectedTaxRuleIndex] = useState(null);

    const handleEditRule = (formData) => {
        const taxRulesValues = taxRulesData.slice();
        taxRulesValues[selectedTaxRuleIndex] = {...formData, _id: taxRulesValues[selectedTaxRuleIndex]._id};
        handleTaxRuleChange(taxRulesValues);
        setIsEditTaxRuleModalOpen(false);
    }
    
    const handleCancelModal = () => {
        setIsEditTaxRuleModalOpen(false);
        setSelectedTaxRule(null);
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="common-table clients-table mt-3 files-table rounded-1">
                        <table className="table">
                        <thead>
                            <tr>
                                <th>Countries</th>
                                <th>Provinces</th>
                                <th>Tax Name</th>
                                <th>Tax Value</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        All Countries
                                    </td>
                                    <td>
                                        All Provinces
                                    </td>
                                    <td>Default</td>
                                    <td>20</td>
                                </tr>
                                {taxRulesData.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {value?.taxCountries.join(', ')}
                                            </td>
                                            <td>
                                                {value?.taxProvinces.join(', ')}
                                            </td>
                                            <td>{value.taxRuleName}</td>
                                            <td>{value.taxValue}</td>
                                            <td>
                                                <div className="dropdown text-start">
                                                    <button
                                                        className="btn btn-secondary dropdown-toggle"
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                    <li>
                                                        <button
                                                            className="btn btn-link dropdown-item ps-3"
                                                            onClick={() => {
                                                                setIsEditTaxRuleModalOpen(true);
                                                                setSelectedTaxRule(value);
                                                                setSelectedTaxRuleIndex(index)
                                                            }
                                                        }
                                                        >
                                                        Edit
                                                        </button>
                                                        <button
                                                            className="dropdown-item ps-3"
                                                            onClick={() => handleDeleteTaxRule(value)}
                                                        >
                                                        Delete
                                                        </button>
                                                    </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isEditTaxRuleModalOpen && <EditTaxRuleModal
                isEditTaxRuleModalOpen={isEditTaxRuleModalOpen}
                handleEditRule={handleEditRule}
                handleCancelModal={handleCancelModal}
                selectedTaxRule={selectedTaxRule}
            />}
        </>    
    );
}
export default TaxRulesList;