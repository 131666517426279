import Header from "../../widgets/header";
import Sidebar from "../../widgets/sidebar";
import { useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import { AllContext } from "../../context";
import PageLoader from "../../components/pageLoader";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
const InTakeForm = () => {
  const { state } = useLocation();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const { toastMessage } = context;
  const navigate = useNavigate();
  useEffect(() => {
    if (state.mode == "edit") {
      getData();
    }
  }, []);
  const [startData, setStartDate] = useState("");
  const [orderId, setOrderId] = useState("");
  const getData = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(allApiUrl.ORDER + "/" + state._id, "", header).then((res) => {
      if (res.status === 200) {
        setOrderId(res.data.order._id);
        setStartDate(res.data.order.startedDate);
        if (intakeFormType == "intake_form_1") {
          setValue((prevData) => ({
            ...prevData,
            images: res.data.order.projectData[1].value,
          }));
          setValue((prevData) => ({
            ...prevData,
            tellUsMore: res.data.order.projectData[0].value,
          }));
          setValue((prevData) => ({
            ...prevData,
            audience: res.data.order.projectData[2].value,
          }));
          setValue((prevData) => ({
            ...prevData,
            nameOfYourBrand: res.data.order.projectData[3].value,
          }));
          setValue((prevData) => ({
            ...prevData,
            specialQuote: res.data.order.projectData[4].value,
          }));
          setValue((prevData) => ({
            ...prevData,
            moreAboutYourBrand: res.data.order.projectData[5].value,
          }));
          setValue((prevData) => ({
            ...prevData,
            provideUsYourWebsite: res.data.order.projectData[6].value,
          }));
          setValue((prevData) => ({
            ...prevData,
            giveUsYourBrandBio: res.data.order.projectData[7].value,
          }));
        } else if (intakeFormType == "intake_form_2") {
          setValue((prevData) => ({
            ...prevData,
            images: res.data.order.projectData[1].value,
          }));
          setForm2((prevData) => ({
            ...prevData,
            pickPublication: res.data.order.projectData[0].value,
          }));
          setForm2((prevData) => ({
            ...prevData,
            IfYouWroteYourArticle: res.data.order.projectData[2].value,
          }));
          setForm2((prevData) => ({
            ...prevData,
            WhoIsyourAudienceForThisFeaturedActicle:
              res.data.order.projectData[3].value,
          }));
          setForm2((prevData) => ({
            ...prevData,
            WhatIsYourBrand: res.data.order.projectData[4].value,
          }));
          setForm2((prevData) => ({
            ...prevData,
            IsThereAspecialQuoteAboutYourBrand:
              res.data.order.projectData[5].value,
          }));
          setForm2((prevData) => ({
            ...prevData,
            PleaseTellUsMoreAboutYourBrand: res.data.order.projectData[6].value,
          }));
          setForm2((prevData) => ({
            ...prevData,
            PleaseProvideUsYourWebsiteSocialMediaLink:
              res.data.order.projectData[7].value,
          }));
          setForm2((prevData) => ({
            ...prevData,
            PleaseGiveUsYourBrandBio: res.data.order.projectData[8].value,
          }));
        }
      }
    });
  };

  var intakeFormType = window.location.pathname.split("/")[3];
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  function handleFormSubmit() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const formData = new FormData();
    if (value && value.image) {
      for (const key of Object.keys(value.image)) {
        formData.append("files", value.image[key]);
      }
      setValue((prevData) => ({
        ...prevData,
        images: "",
      }));
    }
    formData.append("orderId", state.mode == "add" ? state.orderId : orderId);
    formData.append(
      "startedDate",
      state.mode == "edit" ? startData : Date.now()
    );
    let dataList = [
      {
        key: `Please tell us more about your brand that we won't be able to find on your website or any social media account: (ad least 100 words minimum)`,
        value: value.tellUsMore,
        type: "string",
      },
      {
        key: `Please tell us more about your brand that we won't be able to find on your website or any social media account: (ad least 100 words minimum)`,
        value: value && value.image ? "" : value.images,
        type: "file",
      },
      {
        key: `Who is your audience for this featured article ?`,
        value: value.audience,
        type: "string",
      },
      {
        key: `What is the name of your brand?`,
        value: value.nameOfYourBrand,
        type: "string",
      },
      {
        key: `Is there a special quote about your brand that you want us to use? if yes,what is it?`,
        value: value.specialQuote,
        type: "string",
      },
      {
        key: `Please tell us more about your brand that we won't be able to find on your website or any social media account: (adeast 100 words minimum)`,
        value: value.moreAboutYourBrand,
        type: "string",
      },
      {
        key: `Please provide us your website, social media links`,
        value: value.provideUsYourWebsite,
        type: "string",
      },
      {
        key: ` Please give us your brand bio (atleast 100 words minimum)`,
        value: value.giveUsYourBrandBio,
        typ: "string",
      },
    ];
    let dataList2 = [
      {
        key: `Please pick the publication you want this article to get published in`,
        value: form2.pickPublication,
        type: "string",
      },
      {
        key: `Please pick the publication you want this article to get published in`,
        value: value && value.image ? "" : value.images,
        type: "file",
      },
      {
        key: `If you wrote your article. Paste a Google Doc link here. (Make sure the link access is set to "Anyone With the Link") Ignore this section if you want us to write your article. `,
        value: form2.IfYouWroteYourArticle,
        type: "string",
      },
      {
        key: `Who is your audience for this featured acticle? `,
        value: form2.WhoIsyourAudienceForThisFeaturedActicle,
        type: "string",
      },
      {
        key: `What is your brand?`,
        value: form2.WhatIsYourBrand,
        type: "string",
      },
      {
        key: `Is there a special quote about your brand that you want us to use? If yes, what is it? `,
        value: form2.IsThereAspecialQuoteAboutYourBrand,
        type: "string",
      },
      {
        key: `Please tell us more about your brand that we won't be able to find on your website or any social media account: (al least 100 word minimum) `,
        value: form2.PleaseTellUsMoreAboutYourBrand,
        type: "string",
      },
      {
        key: ` Please provide us your website, social media links`,
        value: form2.PleaseProvideUsYourWebsiteSocialMediaLink,
        type: "string",
      },
      {
        key: `Please give us your brand bio (at least 100 word minimum) `,
        value: form2.PleaseGiveUsYourBrandBio,
        type: "string",
      },
    ];
    formData.append(
      "formData",
      JSON.stringify(
        intakeFormType == "intake_form_1"
          ? dataList
          : intakeFormType == "intake_form_2"
            ? dataList2
            : ""
      )
    );

    apiPostMethod(allApiUrl.ADD_PROJECT_DATA, formData, header)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setValue({
            image: "",
            tellUsMore: "",
            audience: "",
            nameOfYourBrand: "",
            specialQuote: "",
            moreAboutYourBrand: "",
            provideUsYourWebsite: "",
            giveUsYourBrandBio: "",
          });
          setForm2({
            image: "",
            pickPublication: "",
            IfYouWroteYourArticle: "",
            WhoIsyourAudienceForThisFeaturedActicle: "",
            WhatIsYourBrand: "",
            IsThereAspecialQuoteAboutYourBrand: "",
            PleaseTellUsMoreAboutYourBrand: "",
            PleaseProvideUsYourWebsiteSocialMediaLink: "",
            PleaseGiveUsYourBrandBio: "",
          });

          if (state.mode == "edit") {
            navigate("/orders");
          } else {
            navigate(-1);
          }
          toastMessage("success", res.data.message);
        }
      })
      .catch((err) => {
        // redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  const [value, setValue] = useState({
    tellUsMore: "",
    audience: "",
    nameOfYourBrand: "",
    specialQuote: "",
    moreAboutYourBrand: "",
    provideUsYourWebsite: "",
    giveUsYourBrandBio: "",
  });
  const [form2, setForm2] = useState({
    pickPublication: "",
    IfYouWroteYourArticle: "",
    WhoIsyourAudienceForThisFeaturedActicle: "",
    WhatIsYourBrand: "",
    IsThereAspecialQuoteAboutYourBrand: "",
    PleaseTellUsMoreAboutYourBrand: "",
    PleaseProvideUsYourWebsiteSocialMediaLink: "",
    PleaseGiveUsYourBrandBio: "",
  });
  const [isApproved, setIsApproved] = useState(false);

  function getselectedImages(images) {
    let selectedImages = "";
    if (images && images.length)
      for (let i = 0; i < images.length; i++) {
        selectedImages += images[i].name + `${images.length > i ? "\n" : ""}`;
      }
    return selectedImages;
  }
  return (
    <div>
      <Sidebar
        activePage="purchases"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={isLoading} />
      <div className="commonSlidePl">
      <div className="setting-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="setting-form-section pt-3">
                <div className="container-fluid ">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-back mb-4">
                        <a href="#" onClick={() => navigate(-1)}>
                          <i class="fa-solid fa-angle-left"></i>
                          {state.mode == "edit"
                            ? "view-order"
                            : state.mode == "add" && state.page == "view-orders"
                            ? " View Orders"
                            : "Purchase"}
                        </a>
                      </div>

                      <div className="col-xxl-6 col-xl-8 mx-auto">
                        <div className="setting-form">
                          <div className="tab-content" id="pills-tabContent">
                            <div
                              id="pills-home"
                              role="tabpanel"
                              aria-labelledby="pills-home-tab"
                              tabIndex="0"
                            >
                              <div class="tab-content" id="pills-tabContent">
                                <div className="intakeForm">
                                  <h3>Intake form for writing service</h3>
                                  <p>
                                    Clients get access to this form after buying
                                    your service. Their order will remain{" "}
                                    <span>Pending</span> until the form is filled
                                    out.
                                  </p>
                                  {intakeFormType == "intake_form_1" ? (
                                    <div className="commonForm">
                                      <div className="form-group">
                                        <label>
                                          Upload upto 3 photos. Guidelines: Make
                                          sure to submit ONLY high quality
                                          landscape "horizontral" photos
                                        </label>
                                        <div className="Upldimages">
                                          <input
                                            type="file"
                                            multiple
                                            onChange={(e) => {
                                              if (e) {
                                                const file = e.target.files;
                                                if (file) {
                                                  setValue((prevData) => ({
                                                    ...prevData,
                                                    image: file,
                                                  }));
                                                }
                                              }
                                            }
                                          }
                                          accept="image/*"
                                        />
                                        {getselectedImages(value.image)}
                                        <i class="fa-solid fa-file-arrow-up"></i>
                                        <p>
                                          <span>Browse or</span> drag and drop
                                        </p>
                                      </div>
                                      <div className="preview mt-2 mb-0">
                                        {value &&
                                          value.images &&
                                          value.images.length > 0 &&
                                          state.mode == "edit" &&
                                          value.images.map((item) => {
                                            return <img src={item} />;
                                          })}

                                          <small
                                            onClick={() => {
                                              setValue((prevData) => ({
                                                ...prevData,
                                                images: "",
                                              }));
                                            }}
                                          >
                                            {state.mode == "edit"
                                              ? "Remove All"
                                              : ""}
                                          </small>
                                        </div>
                                        {/* {selectedImages.length > 0 &&
                                        (selectedImages.length > 3 ? (
                                          <small>
                                            You cannot upload more than 3 images{" "}
                                            <hr />
                                            <span>
                                              please delete{" "}
                                              <b>{selectedImages.length - 3}</b>
                                              of them
                                            </span>
                                          </small>
                                        ) : (
                                          ""
                                        ))} */}
                                      </div>
                                      <div className="form-group">
                                        <label>
                                          Who is your audience for this featured
                                          article ? <small>Optional</small>
                                        </label>
                                        <textarea
                                          className="form-control"
                                          value={value.audience}
                                          onChange={(e) => {
                                            setValue((prevData) => ({
                                              ...prevData,
                                              audience: e.target.value,
                                            }));
                                          }}
                                        ></textarea>
                                      </div>
                                      <div className="form-group">
                                        <label>
                                          What is the name of your brand?{" "}
                                          <small>Optional</small>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={value.nameOfYourBrand}
                                          onChange={(e) => {
                                            setValue((prevData) => ({
                                              ...prevData,
                                              nameOfYourBrand: e.target.value,
                                            }));
                                          }}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label>
                                          Is there a special quote about your
                                          brand that you want us to use? if yes,
                                          what is it? <small>Optional</small>
                                        </label>
                                        <textarea
                                          className="form-control"
                                          value={value.specialQuote}
                                          onChange={(e) => {
                                            setValue((prevData) => ({
                                              ...prevData,
                                              specialQuote: e.target.value,
                                            }));
                                          }}
                                        ></textarea>
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          Please tell us more about your brand
                                          that we won't be able to find on your
                                          website or any social media account: (ad
                                          least 100 words minimum){" "}
                                          <small>Optional</small>
                                        </label>
                                        <textarea
                                          className="form-control"
                                          value={value.moreAboutYourBrand}
                                          onChange={(e) => {
                                            setValue((prevData) => ({
                                              ...prevData,
                                              moreAboutYourBrand: e.target.value,
                                            }));
                                          }}
                                        ></textarea>
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          Please provide us your website, social
                                          media links <small>Optional</small>
                                        </label>
                                        <textarea
                                          className="form-control"
                                          value={value.provideUsYourWebsite}
                                          onChange={(e) => {
                                            setValue((prevData) => ({
                                              ...prevData,
                                              provideUsYourWebsite:
                                                e.target.value,
                                            }));
                                          }}
                                        ></textarea>
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          Please give us your brand bio (atleast
                                          100 words minimum)
                                          <small>Optional</small>
                                        </label>
                                        <textarea
                                          className="form-control"
                                          value={value.giveUsYourBrandBio}
                                          onChange={(e) => {
                                            setValue((prevData) => ({
                                              ...prevData,
                                              giveUsYourBrandBio: e.target.value,
                                            }));
                                          }}
                                        ></textarea>
                                      </div>

                                      <div className="form-group mb-0">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={isApproved}
                                            onClick={() => {
                                              setIsApproved(!isApproved);
                                            }}
                                            id="showServicePage"
                                          />
                                          <label
                                            className="form-check-label checkbox-label mb-0"
                                            for="showServicePage"
                                          >
                                            Approval and Confirmation <br />
                                            <small>
                                              I approve this content to get
                                              publish, so long as it passes the
                                              publication's guidelines
                                            </small>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="commonForm">
                                      <div className="form-group">
                                        <label>
                                          Please pick the publication you want
                                          this article to get published in
                                        </label>
                                        <select
                                          className="form-control"
                                          value={form2.pickPublication}
                                          onChange={(e) => {
                                            setForm2((prevData) => ({
                                              ...prevData,
                                              pickPublication: e.target.value,
                                            }));
                                          }}
                                        >
                                          <option value="astistWeekly1">
                                            Astist Weekly1
                                          </option>
                                          <option value="astistWeekly2">
                                            Astist Weekly2
                                          </option>
                                          <option value="astistWeekly3">
                                            Astist Weekly3
                                          </option>
                                        </select>
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          If you wrote your article. Paste a
                                          Google Doc link here. (Make sure the
                                          link access is set to "Anyone With the
                                          Link") Ignore this section if you want
                                          us to write your article.{" "}
                                          <small>Optional</small>
                                        </label>

                                        <textarea
                                          className="form-control"
                                          value={form2.IfYouWroteYourArticle}
                                          onChange={(e) => {
                                            setForm2((prevData) => ({
                                              ...prevData,
                                              IfYouWroteYourArticle:
                                                e.target.value,
                                            }));
                                          }}
                                        ></textarea>
                                        {/* <small>
                                        Click this link to see out photo
                                        submission guide: <br />{" "}
                                        <a
                                          className="text-decoration-none text-muted"
                                          href="#"
                                          target="_blank"
                                        >
                                          https://docs.google.com/document/d/1JqbZZdyzYkLjC95ps-N29sv1dDt-kEhtX9yEpVx5uw0/edit#
                                        </a>
                                      </small> */}
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          Upload Up to 3 photos. Guidelines: Make
                                          sure to submit ONLY high quality
                                          landscape "horizontal" photos
                                        </label>
                                        <div class="Upldimages">
                                          <input
                                            type="file"
                                            multiple
                                            // onChange={onImageChange}
                                            onChange={(e) => {
                                              if (e) {
                                                const file = e.target.files;
                                                if (file) {
                                                  setValue((prevData) => ({
                                                    ...prevData,
                                                    image: file,
                                                  }));
                                                }
                                                // URL.createObjectURL(item)
                                              }
                                            }}
                                            accept="image/*"
                                          />
                                          {getselectedImages(value.image)}
                                          <i class="fa-solid fa-file-arrow-up"></i>
                                          <p>
                                            <span>Browse or</span> drag and drop
                                          </p>
                                        </div>
                                      </div>
                                      {value &&
                                        value.images &&
                                        value.images.length > 0 &&
                                        state.mode == "edit" &&
                                        value.images.map((item) => {
                                          return (
                                            <img
                                              src={item}
                                              style={{ width: "80px" }}
                                            />
                                          );
                                        })}

                                      <small
                                        onClick={() => {
                                          setValue((prevData) => ({
                                            ...prevData,
                                            images: "",
                                          }));
                                        }}
                                      >
                                        {state.mode == "edit" ? "Remove All" : ""}
                                      </small>
                                      <div className="form-group">
                                        <label>
                                          Who is your audience for this featured
                                          acticle? <small>Optional</small>
                                        </label>
                                        <input
                                          type=""
                                          className="form-control"
                                          value={
                                            form2.WhoIsyourAudienceForThisFeaturedActicle
                                          }
                                          onChange={(e) => {
                                            setForm2((prevData) => ({
                                              ...prevData,
                                              WhoIsyourAudienceForThisFeaturedActicle:
                                                e.target.value,
                                            }));
                                          }}
                                        />
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          What is your brand?{" "}
                                          <small>optional</small>
                                        </label>
                                        <input
                                          type=""
                                          className="form-control"
                                          value={form2.WhatIsYourBrand}
                                          onChange={(e) => {
                                            setForm2((prevData) => ({
                                              ...prevData,
                                              WhatIsYourBrand: e.target.value,
                                            }));
                                          }}
                                        />
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          Is there a special quote about your
                                          brand that you want us to use? If yes,
                                          what is it? <small>optional</small>
                                        </label>
                                        <textarea
                                          className="form-control"
                                          value={
                                            form2.IsThereAspecialQuoteAboutYourBrand
                                          }
                                          onChange={(e) => {
                                            setForm2((prevData) => ({
                                              ...prevData,
                                              IsThereAspecialQuoteAboutYourBrand:
                                                e.target.value,
                                            }));
                                          }}
                                        ></textarea>
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          Please tell us more about your brand
                                          that we won't be able to find on your
                                          website or any social media account: (al
                                          least 100 word minimum){" "}
                                          <small>optional</small>
                                        </label>
                                        <textarea
                                          className="form-control"
                                          value={
                                            form2.PleaseTellUsMoreAboutYourBrand
                                          }
                                          onChange={(e) => {
                                            setForm2((prevData) => ({
                                              ...prevData,
                                              PleaseTellUsMoreAboutYourBrand:
                                                e.target.value,
                                            }));
                                          }}
                                        ></textarea>
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          Please provide us your website, social
                                          media links <small>optional</small>
                                        </label>
                                        <textarea
                                          className="form-control"
                                          value={
                                            form2.PleaseProvideUsYourWebsiteSocialMediaLink
                                          }
                                          onChange={(e) => {
                                            setForm2((prevData) => ({
                                              ...prevData,
                                              PleaseProvideUsYourWebsiteSocialMediaLink:
                                                e.target.value,
                                            }));
                                          }}
                                        ></textarea>
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          Please give us your brand bio (at least
                                          100 word minimum){" "}
                                          <small>optional</small>
                                        </label>
                                        <textarea
                                          className="form-control"
                                          value={form2.PleaseGiveUsYourBrandBio}
                                          onChange={(e) => {
                                            setForm2((prevData) => ({
                                              ...prevData,
                                              PleaseGiveUsYourBrandBio:
                                                e.target.value,
                                            }));
                                          }}
                                        ></textarea>
                                      </div>

                                      <div className="form-group mb-0">
                                        <div class="form-check">
                                          <input
                                            class="form-check-input checkbox-label"
                                            type="checkbox"
                                            checked={isApproved}
                                            onClick={() => {
                                              setIsApproved(!isApproved);
                                            }}
                                            id="flexCheckDefault"
                                          />
                                          <label
                                            class="form-check-label mb-0"
                                            for="flexCheckDefault"
                                          >
                                            Approval and Confirmation <br />{" "}
                                            <small>
                                              I approve this content to get
                                              publish, so long as it passes the
                                              publication's guidelines
                                            </small>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="btn-section text-end">
                                  <button
                                    disabled={!isApproved}
                                    className="btn btn-save"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleFormSubmit();
                                    }}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default InTakeForm;
