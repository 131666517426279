import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AllContext } from "../../context";
import logo from "../../assets/img/logo.svg";
import userIcon from "../../assets/img/iconUser.svg";
import imgSignin from "../../assets/img/imgSignin.svg";
import { useSearchParams } from "react-router-dom";
import {
  saveLoginUserAccessToken,
  saveLoginUserInfo,
} from "../../redux/action/login";
import { loginAPi } from "../../api/authApi/login";
import PageLoader from "../../components/pageLoader";
import { getBusinessSubdomain } from "../../shared/functions";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(AllContext);
  const [searchParams] = useSearchParams();
  let redirectionCode = null;
  let redirection = null;
  const [isLoading, setIsLoading] = useState(false);
  const [isRememberMeClicked, setIsRememberMeClicked] = useState(false)
  const rememberMeUser = JSON.parse(localStorage.getItem('rememberMeUser')) || {};
  const [storedUsername, setStoredUsername] = useState(rememberMeUser.userid || "");
  const [storedPassword, setStoredPassword] = useState(rememberMeUser.password || "");
  const { allApiUrl, toastMessage } = context;
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  useEffect(() => {
    localStorage.removeItem('rememberMeUser');
    if (rememberMeUser.userid && rememberMeUser.password) {
      // Autofill fields if 'Remember Me' data exists
      setStoredUsername(rememberMeUser.userid);
      setStoredPassword(rememberMeUser.password);
    }
  }, [])

  useEffect(() => {
    let loggedIn = localStorage.getItem("userLoggedIn");

    if (loggedIn) {
      if (userInfo.roles.type === "admin") {
        navigate("/dashboard");
      } else {
        navigate("/home");
      }
    }
  }, []);
  const LoginSchema = Yup.object({
    email: Yup.string().min(2).max(50).required("Please enter your name"),
    password: Yup.string().required("Please enter your password"),
  });
  const email_url = searchParams.get("email")
  const initialValues = {
    email: storedUsername ? storedUsername : '',
    password: storedPassword ? storedPassword : '',
  };
  const [passwordView, setPasswordView] = React.useState("password");
  const areFalse = (value) =>
    Object.values(value).every((value) => value === false);
  function handleFormSubmit(values) {
    setIsLoading(true);

    redirectionCode = searchParams.get("id");
    redirection = searchParams.get("redirection");
    const subDomain = getBusinessSubdomain();

    const data = {
      email: values.email,
      password: values.password,
      businessSubDomain: subDomain || '',
      rememberMe: isRememberMeClicked
    };
    loginAPi(allApiUrl.ADMIN_LOGIN_IN, data, {})
      .then((res) => {
        setIsLoading(false);

        if (res.status === 200) {
          const {
            clients,
            coupons,
            invoiceAndSubscriptionAccess,
            invoiceManagement,
            messaging,
            orderAccess,
            orderManagement,
            services,
            settingsAndTeam,
            ticketAccess,
            ticketManagement,
          } = res?.data?.roles;
          if (
            areFalse(clients) &&
            areFalse(coupons) &&
            areFalse(invoiceAndSubscriptionAccess) &&
            areFalse(invoiceManagement) &&
            areFalse(messaging) &&
            areFalse(orderAccess) &&
            areFalse(orderManagement) &&
            areFalse(services) &&
            areFalse(settingsAndTeam) &&
            areFalse(ticketAccess) &&
            areFalse(ticketManagement) &&
            res?.data.group === 1
          ) {
            navigate("/permissionDenied", {
              state: {
                noPermission: true,
              },
            });
          } else {
            toastMessage(
              "success",
              `Welcome ${res.data?.firstName
                ? res.data?.firstName.replace(
                  /(^\w{1})|(\s+\w{1})/g,
                  (letter) => letter.toUpperCase()
                ) +
                " " +
                res?.data?.lastName.replace(
                  /(^\w{1})|(\s+\w{1})/g,
                  (letter) => letter.toUpperCase()
                )
                : res.data.email
              }`
            );
            dispatch(saveLoginUserAccessToken(res.data.token));
            dispatch(saveLoginUserInfo(res.data));
            localStorage.setItem("userLoggedIn", "true");
            if (isRememberMeClicked) {
              // Save username and password to localStorage
              localStorage.setItem('rememberMeUser', JSON.stringify({ userid: values.email, password: values.password }));
            } else {
              // Clear 'Remember Me' data when not checked
              localStorage.removeItem('rememberMeUser');
            }
            if (res?.data?.group === 1) {
              const redirectPages = ["view-orders", "view-ticket"];
              if (
                (redirection !== null || redirectionCode !== null) &&
                redirectPages.includes(redirection)
              ) {
                let url = `/${redirection}${redirectionCode ? "/" + redirectionCode : ""
                  }`;
                navigate(url);
              } else {
                navigate("/dashboard");
              }
            } else {
              const redirectPages = [
                "view-purchase",
                "view-payment",
                "portal/view-ticket",
                "cart",
                "public-invoice",
              ];
              if (
                (redirection !== null || redirectionCode !== null) &&
                redirectPages.includes(redirection)
              ) {
                let url = `/${redirection}${redirectionCode ? "/" + redirectionCode : ""
                  }`;
                navigate(url);
              } else {
                navigate("/home");
              }
            }
          }
        }
      })

      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data?.message);
      });
  }

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  return (
    <main className="wrapper">
      <PageLoader isLoading={isLoading} />

      <div className="section-login">
        <div className="container-fluid">
          <div className="row h-100vh">
            <div className="col-lg-6 extralightGreyBg formOrder formGrid">
              <div className="loginForm">
                <a className="brandName" href="#">
                  <img src={logo} />
                </a>
                <form className="formLogin" onSubmit={handleSubmit}>
                  <h3>Sign In! Enter account details</h3>
                  <div className="formInner">
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Email"
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        required
                      />
                      <label htmlFor="floatingInput">Email</label>

                      <span className="formIcon">
                        <img src={userIcon} />
                      </span>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type={passwordView}
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                        required
                      />
                      <span
                        className="eyeIcon fa-solid fa-key"
                        onClick={() => {
                          if (passwordView == "password") {
                            setPasswordView("text");
                          } else {
                            setPasswordView("password");
                          }
                        }}
                      ></span>
                      <label htmlFor="floatingPassword">Password</label>
                    </div>
                    <div className="RememderSection">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={isRememberMeClicked}
                          onClick={(e) => {
                            setIsRememberMeClicked(e.target.checked)
                          }}
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Remember Me
                        </label>
                      </div>
                      <a className="forgotLink d-inline-block text-start">
                        <span onClick={() => navigate("/forgot-password")}>
                          {" "}
                          Forgot password?
                        </span>
                      </a>
                    </div>
                    <div className="input-group">
                      <button className="btn btn-primary btn-submit">
                        Login
                      </button>
                    </div>
                    <p className="signupText">
                      Don't have account?{" "}
                      <a href="#" onClick={() => navigate("/signup")}>
                        Sign Up
                      </a>
                    </p>
                  </div>
                </form>
              </div>
              <div className="formFooter">
                <p className="copyrightText">
                  © OPFLOW. All Rights Reserved.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6 lightGreyBg border formGrid">
              <div className="formContent">
                <h3>Welcome back!</h3>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
              </div>
              <div className="loginImg">
                <img src={imgSignin} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Login;
