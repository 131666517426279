import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/pageLoader";
import { formatMonthNameAndDate, getOrderStatus, pendingStatusTypeCheck } from "../../shared/functions";
import { useNavigate } from "react-router-dom";
import openIcon from "../../assets/img/icons/openSide.svg";
import { AllContext } from "../../context";

const Home = () => {
  const navigate = useNavigate();
  const context = useContext(AllContext);
  const {
    apiPostMethod,
    allApiUrl,
    toastMessage,
    redirectToLogin,
  } = context;
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);

  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [homePageData, setHomePageData] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);

  useEffect(() => {
    let loggedIn = localStorage.getItem("userLoggedIn");
    if (loggedIn) {
      if (userInfo.group == 1) {
        navigate("/dashboard");
      } else {
        navigate("/home");
      }
    } else {
      navigate("/login");
    }
    getHomePageData();
  }, []);

  function getHomePageData() {
    const header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.HOME_PAGE_VIEW_LIST_V2, {
      limit: 5
    }, header)
      .then((res) => {
        if (res.status === 200) {
          setInitialLoading(false);
          setHomePageData(res.data);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setInitialLoading(false);
      });
  }
  return (
    <div>
      <Sidebar
        activePage="home"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <PageLoader isLoading={initialLoading} />
      <Header handleSidebar={handleSidebar} />
      {!initialLoading && (
        <div className="commonSlidePl">
          <div className="dashboardSection commonPadding dashboardTop">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="heading position-relative">
                    <h2 className="fs-3 mb-0">Dashboard</h2>
                    <span className="openArrowIcon" onClick={() => { setToggleSidebar(!toggleSideBar) }}>
                      <img src={openIcon} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionHome">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xxl-12 mx-auto">
                  <div className="heading">
                    <h2>
                      Welcome,
                      {homePageData?.invoice[0]?.userDetails?.firstName
                        ? homePageData?.invoice[0]?.userDetails?.firstName :
                        userInfo.firstName.concat(' ', userInfo.lastName)
                      }!
                    </h2>
                  </div>

                  <div className="homeTiles rounded-1">
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-md-3">
                        <div className="tile">
                          <div className="row">
                            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-3">
                              <div className="tileImg">
                                {/* <img src={orderIcon} /> */}
                                <span>
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="60px"
                                    height="60px"
                                    viewBox="0 0 102.000000 128.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
                                      stroke="none"
                                    >
                                      <path
                                        d="M441 1269 c-93 -28 -150 -96 -175 -211 -6 -26 -10 -28 -62 -28 -71 0
                                  -117 -20 -132 -56 -18 -45 -73 -775 -62 -815 18 -61 58 -109 113 -135 52 -24
                                  54 -24 387 -24 333 0 335 0 387 24 57 27 99 79 113 142 10 45 -45 765 -62 808
                                  -15 36 -61 56 -132 56 -53 0 -56 2 -62 28 -28 133 -107 208 -227 217 -29 3
                                  -67 0 -86 -6z m149 -84 c49 -25 90 -87 90 -136 0 -18 -9 -19 -171 -19 l-172 0
                                  6 37 c18 109 145 169 247 118z m-330 -284 c0 -47 17 -81 40 -81 23 0 40 34 40
                                  81 l0 49 170 0 170 0 0 -49 c0 -47 17 -81 40 -81 23 0 40 34 40 81 l0 50 63
                                  -3 62 -3 27 -325 c36 -441 36 -450 -3 -494 -17 -20 -47 -40 -67 -46 -49 -13
                                  -615 -13 -664 0 -54 15 -98 70 -98 123 -1 51 50 717 55 735 3 8 25 12 65 12
                                  l60 0 0 -49z"
                                      />
                                      <path
                                        d="M548 563 l-88 -87 -39 38 c-41 40 -66 44 -85 14 -9 -15 -1 -28 49
                                  -78 33 -33 66 -60 75 -60 8 0 64 49 124 109 89 89 107 112 101 130 -13 41 -46
                                  25 -137 -66z"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-9">
                              <div className="tileText">
                                <p className="mb-0">Open Orders</p>
                                <h3 className="mb-0">
                                  {homePageData?.openOrders}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-md-3">
                        <div className="tile">
                          <div className="row">
                            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-3">
                              <div className="tileImg">
                                {/* <img src={activeOrderIcon} /> */}
                                <span>
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="60px"
                                    height="60px"
                                    viewBox="0 0 120.000000 120.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,120.000000) scale(0.100000,-0.100000)"
                                      fill="#000000"
                                      stroke="none"
                                    >
                                      <path
                                        d="M370 1187 c-71 -23 -130 -111 -130 -196 l0 -31 -120 0 -120 0 0 -416
                                  c0 -463 0 -460 68 -512 l35 -27 241 -3 240 -3 -45 40 -44 40 -183 1 c-181 0
                                  -183 0 -207 25 l-25 24 0 376 0 375 80 0 80 0 0 -40 0 -40 40 0 40 0 0 40 0
                                  40 120 0 120 0 0 -40 0 -40 40 0 40 0 0 40 0 40 80 0 80 0 0 -40 0 -40 40 0
                                  40 0 0 80 0 80 -120 0 -120 0 0 31 c0 144 -132 240 -270 196z m153 -105 c21
                                  -21 31 -42 34 -76 l6 -46 -123 0 -123 0 6 46 c5 47 33 88 72 105 37 16 96 3
                                  128 -29z"
                                      />
                                      <path
                                        d="M749 707 c-104 -30 -190 -104 -237 -206 -22 -46 -27 -71 -27 -141 0
                                  -76 3 -92 32 -151 39 -79 102 -140 181 -177 82 -38 202 -38 284 0 79 37 142
                                  98 181 177 28 58 32 75 32 151 0 76 -4 93 -32 151 -60 121 -170 196 -300 205
                                  -40 2 -87 -2 -114 -9z m187 -84 c66 -22 145 -101 167 -167 37 -108 13 -211
                                  -67 -292 -114 -113 -278 -113 -392 0 -113 114 -113 278 1 392 80 80 183 104
                                  291 67z"
                                      />
                                      <path
                                        d="M878 413 l-78 -77 -40 39 -40 39 -27 -27 -28 -27 68 -67 67 -68 107
                                  107 107 107 -24 26 c-13 14 -26 25 -29 25 -4 0 -41 -35 -83 -77z"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-9">
                              <div className="tileText">
                                <p className="mb-0">Completed Orders</p>
                                <h3 className="mb-0">
                                  {homePageData?.completeOrder}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-md-3">
                        <div className="tile">
                          <div className="row">
                            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-3">
                              <div className="tileImg">
                                {/* <img src={activeOrderIcon} /> */}
                                <span>
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="60px"
                                    height="60px"
                                    viewBox="0 0 120.000000 120.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,120.000000) scale(0.100000,-0.100000)"
                                      fill="#000000"
                                      stroke="none"
                                    >
                                      <path
                                        d="M370 1187 c-71 -23 -130 -111 -130 -196 l0 -31 -120 0 -120 0 0 -416
                                  c0 -463 0 -460 68 -512 l35 -27 241 -3 240 -3 -45 40 -44 40 -183 1 c-181 0
                                  -183 0 -207 25 l-25 24 0 376 0 375 80 0 80 0 0 -40 0 -40 40 0 40 0 0 40 0
                                  40 120 0 120 0 0 -40 0 -40 40 0 40 0 0 40 0 40 80 0 80 0 0 -40 0 -40 40 0
                                  40 0 0 80 0 80 -120 0 -120 0 0 31 c0 144 -132 240 -270 196z m153 -105 c21
                                  -21 31 -42 34 -76 l6 -46 -123 0 -123 0 6 46 c5 47 33 88 72 105 37 16 96 3
                                  128 -29z"
                                      />
                                      <path
                                        d="M749 707 c-104 -30 -190 -104 -237 -206 -22 -46 -27 -71 -27 -141 0
                                  -76 3 -92 32 -151 39 -79 102 -140 181 -177 82 -38 202 -38 284 0 79 37 142
                                  98 181 177 28 58 32 75 32 151 0 76 -4 93 -32 151 -60 121 -170 196 -300 205
                                  -40 2 -87 -2 -114 -9z m187 -84 c66 -22 145 -101 167 -167 37 -108 13 -211
                                  -67 -292 -114 -113 -278 -113 -392 0 -113 114 -113 278 1 392 80 80 183 104
                                  291 67z"
                                      />
                                      <path
                                        d="M878 413 l-78 -77 -40 39 -40 39 -27 -27 -28 -27 68 -67 67 -68 107
                                  107 107 107 -24 26 c-13 14 -26 25 -29 25 -4 0 -41 -35 -83 -77z"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-9">
                              <div className="tileText">
                                <p className="mb-0">Active Subscriptions</p>
                                <h3 className="mb-0">
                                  {
                                    homePageData?.activeSubscriptions
                                  }
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  !!homePageData?.invoice?.length && (
                    <div className="orders-section mt-4">
                      <div className="heading">
                        <h2 className="fs-5 mb-3">Unpaid Invoices</h2>
                      </div>
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Invoice</th>
                              <th>Date</th>
                              <th>Total ($)</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {
                              homePageData?.invoice.map((item, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>
                                      <a
                                        onClick={() =>
                                          navigate(
                                            `/view-payment/${item.orderId}`
                                          )
                                        }
                                      >
                                        {item.invoiceId}
                                      </a>
                                    </td>
                                    <td>
                                      {formatMonthNameAndDate(
                                        item.createdDate,
                                        companyData.timezone
                                      )}
                                    </td>
                                    <td>${item.totalPrice.toFixed(2)}</td>
                                    <td autoCapitalize="none">
                                      <span className="pill pill-grey fw-normal fs-12">
                                        {item.status}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                {!!homePageData?.tickets?.length && (
                  <div className="orders-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Open tickets</h2>
                    </div>
                    <div className="common-table invoiceTable rounded-1">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Subject</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {homePageData?.tickets.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <a
                                    onClick={() =>
                                      navigate(
                                        `/portal/view-ticket/${item._id}`
                                      )
                                    }
                                  >
                                    {item.subject}
                                  </a>
                                </td>
                                <td>
                                  {" "}
                                  {formatMonthNameAndDate(
                                    item.date,
                                    companyData.timezone
                                  )}
                                </td>
                                <td>
                                  <span className="pill pill-orange fw-normal fs-12">
                                    {item.status}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {!!homePageData.orders?.length && (
                  <div className="orders-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Recent orders</h2>
                    </div>
                    <div className="common-table invoiceTable rounded-1">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Order No.</th>
                            <th>Title</th>
                            <th>Paid Date</th>
                            <th>Completed Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {homePageData?.orders.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <a
                                    onClick={() =>
                                      navigate(
                                        `/view-purchase/${item?.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {item?.orderId}
                                  </a>
                                </td>
                                <td>{item?.title}</td>
                                <td>
                                  {formatMonthNameAndDate(
                                    item.createdDate,
                                    companyData.timezone
                                  )}
                                </td>
                                <td>{item?.completedDate ? item.completedDate : '--'}</td>
                                <td>
                                  {pendingStatusTypeCheck(item) &&
                                    item.inTakeForm ? (
                                    <span
                                      className="pill pill-grey fs-12 fw-normal"
                                      style={{
                                        cursor: "pointer",
                                        background: "#c5bff9",
                                        color: "#5a4cdc",
                                      }}
                                      onClick={() => {
                                        navigate(
                                          `/view-purchase/${item?.orderId}`,
                                          {
                                            state: {
                                              _id: item._id,
                                            },
                                          }
                                        )
                                      }
                                      }
                                    >
                                      Start Order
                                    </span>
                                  ) : (
                                    getOrderStatus(item)
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {!!homePageData.subscriptions?.length && (
                  <div className="orders-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Active Subscriptions</h2>
                    </div>
                    <div className="common-table invoiceTable rounded-1">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Service</th>
                            <th>Started</th>
                            <th>Next Payment</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {homePageData?.subscriptions.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <a>{item?.subscriptionId}</a>
                                </td>
                                <td>
                                  <a>
                                    {item?.product &&
                                      item?.product[0] &&
                                      item.product[0]?.name}
                                  </a>
                                </td>
                                <td>
                                  {formatMonthNameAndDate(
                                    item.startDate,
                                    companyData.timezone
                                  )}
                                </td>
                                <td>
                                  {formatMonthNameAndDate(
                                    item.nextDueDate,
                                    companyData.timezone
                                  )}
                                </td>
                                <td>
                                  <span className="pill pill-green fw-normal fs-12">
                                    Active
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Home;
