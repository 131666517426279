import React, { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import logo from "../../assets/img/logo.svg";
import forgotImg from "../../assets/img/Forgot-password.svg";
import { useFormik } from "formik";
import * as Yup from "yup";

import { AllContext } from "../../context";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../components/pageLoader";
import { getBusinessSubdomain } from "../../shared/functions";

const ForgotPassword = () => {
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const [isSendInvitationLoading, setIsSendInvitationLoading] = useState(false)
  const { toastMessage, allApiUrl, apiPostMethod } = context;
  const { REQUEST_RESET_EMAIL } = allApiUrl;
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });
  const initialValues = {
    email: "",
  };
  const [isResetPasswordAlertVisible, setIsResetPasswordAlertVisible] =
    useState(false);
  function handleFormSubmit(values) {
    const emailAddress = {
      email: values.email,
      businessSubDomain: getBusinessSubdomain()
    };
    setIsSendInvitationLoading(true);
    apiPostMethod(REQUEST_RESET_EMAIL, emailAddress, {})
      .then((res) => {
        if (res.status == 200) {
          toastMessage("success", res.data.message);
          setIsResetPasswordAlertVisible(true);
        }
        setIsSendInvitationLoading(false);
      })
      .catch((err) => {
        toastMessage("warn", "Email address not found.");
        console.log("error");
        setIsSendInvitationLoading(false);
      });
  }

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  return (
    <>
      <PageLoader isLoading={isSendInvitationLoading} />
      <main className="wrapper">
        <div className="section-login">
          <div className="container-fluid">
            <div className="row h-100vh">
              <div className="col-lg-6 extralightGreyBg formOrder formGrid">
                <div className="loginForm">
                  <a className="brandName">
                    <img src={logo} />
                  </a>
                  <form className="formLogin" onSubmit={handleSubmit}>
                    <h3>Account Recovery</h3>
                    <div className="formInner">
                      {isResetPasswordAlertVisible && (
                        <p>Reset successful, please check your email.</p>
                      )}
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Email"
                          onChange={handleChange}
                          value={values.email}
                          required
                        />
                        <label htmlFor="floatingInput">Email</label>
                      </div>
                      <div className="input-group">
                        <button type="submit" className="btn btn-primary btn-submit" disabled={isSendInvitationLoading}>
                          Send recovery link
                        </button>
                      </div>
                      <a onClick={() => navigate("/login")} className="forgotLink d-block mt-3 text-center">
                        Sign In ?
                      </a>
                    </div>
                  </form>
                </div>
                <div className="formFooter">
                  <p className="copyrightText">© OPFLOW. All Rights Reserved.{" "}</p>
                </div>
              </div>
              <div className="col-lg-6 lightGreyBg border formGrid">
                <div className="formContent">
                  <h3>Forgot Password!</h3>
                  <p>Securely reset your password and regain access to your account in just a few clicks.</p>
                </div>
                <div className="loginImg">
                  <img className="imgForgotpass" src={forgotImg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default ForgotPassword;
