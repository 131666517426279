import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";

import { AllContext } from "../../context";
import { types } from "../../shared/constants";
import openIcon from "../../assets/img/icons/openSide.svg";
const AddRole = () => {
  const [rolesName, setRolesName] = useState("");
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  //orderAccess
  const [assignedOrders, setAssignedOrders] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [allOrders, setAllOrders] = useState(false);
  //orderManagment
  const [assignToSelf, setAssignToSelf] = useState(false);
  const [assignToOthers, setAssignToOthers] = useState(false);
  const [editData, setEditData] = useState(false);
  const [_delete, set_Delete] = useState(false);
  //messaging
  const [messageTeam, setMessageTeam] = useState(false);
  const [messageClient, setMessageClient] = useState(false);
  //Ticket Access
  const [assignedTickets, setAssignedTickets] = useState(false);
  const [openTickets, setOpenTickets] = useState(false);
  const [allTickets, setAllTickets] = useState(false);
  //Ticket Management
  const [assignToSelf_TicketManagment, setAssignToSelf_TicketManagment] =
    useState(false);
  const [assignToOthers_TicketManagment, setAssignToOthers_TicketManagment] =
    useState(false);
  const [add_delete_Ticket_Management, setAdd_delete_Ticket_Managemenet] =
    useState(false);
  //Client
  const [view_Client, setView_Client] = useState(false);
  const [add_edit_login_Client, setAdd_edit_login_Client] = useState(false);
  const [_delete_Client, set_Delete_Client] = useState(false);
  //Invoice and subscription access
  const [ownInvoices, setOwnInvoices] = useState(false);
  const [allInvoices, setAllInvoices] = useState(false);
  //Invoice management
  const [add_edit, setAdd_edit] = useState(false);
  const [charge_delete, setCharge_delete] = useState(false);
  //coupons
  const [view_add_edit_delete_Coupons, setView_add_edit_delete_Coupons] =
    useState(false);
  //services
  const [view_add_edit_delete_Services, setView_add_edit_delete_Services] =
    useState(false);
  //settingsAndTeam
  const [view_add_edit_SettingsAndTeams, setView_add_edit_SettingsAndTeams] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const context = useContext(AllContext);
  const { allApiUrl, toastMessage, apiPostMethod, redirectToLogin } = context;
  const { ADD_ROLE } = allApiUrl;

  const addRoleHandler = (event) => {
    event.preventDefault();
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const data = {
      type: types.ADMIN,
      name: rolesName.trim(),
      orderAccess: {
        assignedOrders,
        openOrders,
        allOrders,
      },
      orderManagement: {
        assignToSelf,
        assignToOthers,
        editData,
        _delete,
      },
      messaging: {
        messageTeam,
        messageClient,
      },
      ticketAccess: {
        assignedTickets,
        openTickets,
        allTickets,
      },
      ticketManagement: {
        assignToSelf: assignToSelf_TicketManagment,
        assignToOthers: assignToOthers_TicketManagment,
        add_delete: add_delete_Ticket_Management,
      },
      clients: {
        view: view_Client,
        add_edit_login: add_edit_login_Client,
        _delete: _delete_Client,
      },
      invoiceAndSubscriptionAccess: {
        ownInvoices,
        allInvoices,
      },
      invoiceManagement: {
        add_edit,
        charge_delete,
      },
      coupons: {
        view_add_edit_delete: view_add_edit_delete_Coupons,
      },
      services: {
        view_add_edit_delete: view_add_edit_delete_Services,
      },
      settingsAndTeam: {
        view_add_edit: view_add_edit_SettingsAndTeams,
      },
    };
    setIsLoading(true);
    apiPostMethod(ADD_ROLE, data, header)
      .then((res) => {
        if (res.status === 201) {
          setIsLoading(false);
          navigate("/settings/team");
          toastMessage("success", res.data.message);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };
  return (
    <div>
      <Sidebar activePage="settings" />
      <Header isLoading={isLoading} handleSidebar={handleSidebar}  />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">
                    Team Setting
                  </h2>
                  <span className="openArrowIcon" onClick={() => { setToggleSidebar(!toggleSideBar) }}>
                    <img src={openIcon} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="setting-form-section pt-3 horizontalPadding">
          <div className="container-fluid pl-240">
            <form className="pt-4 pb-4" onSubmit={addRoleHandler}>
              <div className="row">
                  <div className="col-lg-9">
                    <div className="setting-form formOuter">
                        <div className="commonForm rounded-3 p-4">
                          <h3 className="fs-4 m-0 mb-4 p-3 pt-0">Add Role</h3>
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="e.g. Contractor"
                              required
                              value={rolesName}
                              onChange={(e) => setRolesName(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-check-label">Order access</label>
                            <div className="form-check">
                              <label className="checkbox-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    setAssignedOrders(!assignedOrders);
                                  }}
                                  checked={assignedOrders}
                                  readOnly
                                />{" "}
                                Assigned orders
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!openOrders) {
                                      if (!assignedOrders) {
                                        setAssignedOrders(true);
                                      }
                                    }
                                    setOpenOrders(!openOrders);
                                  }}
                                  checked={openOrders}
                                  readOnly
                                />{" "}
                                Open orders
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!allOrders) {
                                      if (!assignedOrders || !openOrders) {
                                        setAssignedOrders(true);
                                        setOpenOrders(true);
                                      }
                                    }
                                    setAllOrders(!allOrders);
                                  }}
                                  checked={allOrders}
                                  readOnly
                                />{" "}
                                All orders
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Order management</label>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => setAssignToSelf(!assignToSelf)}
                                  checked={assignToSelf}
                                  readOnly
                                />{" "}
                                Assign to self
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!assignToOthers) {
                                      if (!assignToSelf) {
                                        setAssignToSelf(true);
                                      }
                                    }
                                    setAssignToOthers(!assignToOthers);
                                  }}
                                  checked={assignToOthers}
                                  readOnly
                                />{" "}
                                Assign to others
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!editData) {
                                      if (!assignToSelf || !assignToOthers) {
                                        setAssignToSelf(true);
                                        setAssignToOthers(true);
                                      }
                                    }
                                    setEditData(!editData);
                                  }}
                                  checked={editData}
                                  readOnly
                                />{" "}
                                Edit data
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!_delete) {
                                      if (
                                        !assignToSelf ||
                                        !assignToOthers ||
                                        !editData
                                      ) {
                                        setAssignToSelf(true);
                                        setAssignToOthers(true);
                                        setEditData(true);
                                      }
                                    }
                                    set_Delete(!_delete);
                                  }}
                                  checked={_delete}
                                  readOnly
                                />{" "}
                                Delete
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Messaging</label>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => setMessageTeam(!messageTeam)}
                                  checked={messageTeam}
                                  readOnly
                                />{" "}
                                Message team
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-lanel">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!messageClient) {
                                      if (!messageTeam) {
                                        setMessageTeam(true);
                                      }
                                    }
                                    setMessageClient(!messageClient);
                                  }}
                                  checked={messageClient}
                                  readOnly
                                />{" "}
                                Message client
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Ticket access</label>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    setAssignedTickets(!assignedTickets);
                                  }}
                                  checked={assignedTickets}
                                  readOnly
                                />{" "}
                                Assigned tickets
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!openTickets) {
                                      if (!assignedTickets) {
                                        setAssignedTickets(true);
                                      }
                                    }
                                    setOpenTickets(!openTickets);
                                  }}
                                  checked={openTickets}
                                  readOnly
                                />{" "}
                                Open tickets
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!allTickets) {
                                      if (!openTickets || allTickets) {
                                        setAssignedTickets(true);
                                        setOpenTickets(true);
                                      }
                                    }
                                    setAllTickets(!allTickets);
                                  }}
                                  checked={allTickets}
                                  readOnly
                                />{" "}
                                All tickets
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Ticket management</label>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() =>
                                    setAssignToSelf_TicketManagment(
                                      !assignToSelf_TicketManagment
                                    )
                                  }
                                  checked={assignToSelf_TicketManagment}
                                  readOnly
                                />{" "}
                                Assign to self
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!assignToOthers_TicketManagment) {
                                      if (!assignToSelf_TicketManagment) {
                                        setAssignToSelf_TicketManagment(true);
                                      }
                                    }
                                    setAssignToOthers_TicketManagment(
                                      !assignToOthers_TicketManagment
                                    );
                                  }}
                                  checked={assignToOthers_TicketManagment}
                                  readOnly
                                />{" "}
                                Assign to others
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!add_delete_Ticket_Management) {
                                      if (
                                        !assignToSelf_TicketManagment ||
                                        !assignToOthers_TicketManagment
                                      ) {
                                        setAssignToSelf_TicketManagment(true);
                                        setAssignToOthers_TicketManagment(true);
                                      }
                                    }
                                    setAdd_delete_Ticket_Managemenet(
                                      !add_delete_Ticket_Management
                                    );
                                  }}
                                  checked={add_delete_Ticket_Management}
                                  readOnly
                                />{" "}
                                Add / Delete
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Clients</label>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => setView_Client(!view_Client)}
                                  checked={view_Client}
                                  readOnly
                                />{" "}
                                View
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!add_edit_login_Client) {
                                      if (!view_Client) {
                                        setView_Client(true);
                                      }
                                    }
                                    setAdd_edit_login_Client(!add_edit_login_Client);
                                  }}
                                  checked={add_edit_login_Client}
                                  readOnly
                                />{" "}
                                Add / Edit / Login
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!_delete_Client) {
                                      if (!view_Client || add_edit_login_Client) {
                                        setView_Client(true);
                                        setAdd_edit_login_Client(true);
                                      }
                                    }
                                    set_Delete_Client(!_delete_Client);
                                  }}
                                  checked={_delete_Client}
                                  readOnly
                                />{" "}
                                Delete
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Invoice and subscription access</label>
                            <div className="form-check">
                              <label className="checkbox-label form-check-check">
                                <input
                                  className="checkbox  form-check-input"
                                  type="checkbox"
                                  onClick={() => setOwnInvoices(!ownInvoices)}
                                  checked={ownInvoices}
                                  readOnly
                                />{" "}
                                Own invoices
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label  form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!allInvoices) {
                                      if (!ownInvoices) {
                                        setOwnInvoices(true);
                                      }
                                    }
                                    setAllInvoices(!allInvoices);
                                  }}
                                  checked={allInvoices}
                                  readOnly
                                />{" "}
                                All invoices
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Invoice management</label>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => setAdd_edit(!add_edit)}
                                  checked={add_edit}
                                  readOnly
                                />{" "}
                                Add / Edit
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() => {
                                    if (!charge_delete) {
                                      if (!add_edit) {
                                        setAdd_edit(true);
                                      }
                                    }
                                    setCharge_delete(!charge_delete);
                                  }}
                                  checked={charge_delete}
                                  readOnly
                                />{" "}
                                Charge / Delete
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Coupons</label>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() =>
                                    setView_add_edit_delete_Coupons(
                                      !view_add_edit_delete_Coupons
                                    )
                                  }
                                  checked={view_add_edit_delete_Coupons}
                                  readOnly
                                />{" "}
                                View / Add / Edit / Delete
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Services</label>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() =>
                                    setView_add_edit_delete_Services(
                                      !view_add_edit_delete_Services
                                    )
                                  }
                                  checked={view_add_edit_delete_Services}
                                  readOnly
                                />{" "}
                                View / Add / Edit / Delete
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Settings and team</label>
                            <div className="form-check">
                              <label className="checkbox-label form-check-label">
                                <input
                                  className="checkbox form-check-input"
                                  type="checkbox"
                                  onClick={() =>
                                    setView_add_edit_SettingsAndTeams(
                                      !view_add_edit_SettingsAndTeams
                                    )
                                  }
                                  checked={view_add_edit_SettingsAndTeams}
                                  readOnly
                                />{" "}
                                View / Add / Edit
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="btn-section text-end">
                          
                        </div>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="commonBtn-section text-end">
                      <button type="button" onClick={() => navigate('/settings/team')} className="btn btn-cancel">Cancel</button>
                      <button type="submit" className="btn btn-save">Save</button>
                    </div>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddRole;
