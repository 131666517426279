import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AllContext } from "../context";

const Modal = (props) => {
  useEffect(() => {
    setValue(props.data.name);
  }, [props.data.name, props.type]);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(AllContext);
  const { allApiUrl, apiPostMethod, apiPutMethod, redirectToLogin } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  useEffect(() => {
    setValue("");
  }, []);

  const { TAGS } = allApiUrl;
  const addTag = () => {
    setValue("");
    const data = {
      name: "",
    };
    if (value) {
      data.name = value;
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(TAGS, data, header)
      .then((res) => {
        if (res.status === 201) {
          props.refresh();
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        console.log("error", err);
      });
  };
  const editTag = (id) => {
    const data = {
      name: value,
    };

    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(TAGS, data, header, id)
      .then((res) => {
        if (res.status === 200) {
          props.refresh();
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        console.log("error", err);
      });
  };

  return (
    <div>
      <div
        className="modal fade tags-modal"
        id="tagsModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Tag Name</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form className="commonForm">
                <div className="form-group">
                  <label>Tag name</label>
                  <input
                    type="text"
                    placeholder="Tag Name"
                    className="form-control"
                    required
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    value={value}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setValue("")}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.event == "Add" ? addTag("") : editTag(props.data._id);
                }}
              >
                {props.event == "Add" ? "Add Tag" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
