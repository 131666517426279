import React, { useState, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { useLocation, useNavigate } from "react-router-dom";
import { handleOffSetDateTime } from "../../shared/functions";
import { useSelector } from "react-redux";

const ViewLogs = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [logData, setLogData] = useState(state);
  const { companyData } = useSelector((state) => state.sideMenuReducer);

  useEffect(() => {
    setLogData(state);
  }, []);

  return (
    <div>
      <Sidebar activePage="settings" />
      <Header />
      <div className="commonSlidePl">
      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-back" onClick={() => navigate("/logs")}>
                <a href="#">
                  <i className="fa-solid fa-chevron-left"></i> Logs{" "}
                </a>
              </div>

              <div className="col-lg-8 mx-auto">
                <div className="setting-form">
                  <h3 className="fs-4">{logData.message}</h3>
                  <div className="commonForm rounded-1">
                    <div className="row">
                      <div className="col-lg-12">
                        {logData?.dateAndTime && (
                          <div className="form-group">
                            <p className="logHead">Date & Time</p>
                            <p className="logsData">
                              {handleOffSetDateTime(logData.dateAndTime, companyData.timezone)}
                            </p>
                          </div>
                        )}
                        {logData?.accountType && (
                          <div className="form-group">
                            <p className="logHead">Account Type</p>
                            <p className="logsData">{logData.accountType}</p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        {logData?.firstName && (
                          <div className="form-group">
                            <p className="logHead">Account Name</p>
                            <p className="logsData">
                              {logData.firstName + " " + logData.lastName}
                            </p>
                          </div>
                        )}
                        {logData.resourceType && (
                          <div className="form-group">
                            <p className="logHead">Resource Type</p>
                            <p className="logsData">{logData.resourceType}</p>
                          </div>
                        )}
                      </div>

                      <div className="col-lg-12">
                        {logData?.resourceId && logData.resourceId !== "" && (
                          <div className="form-group">
                            <p className="logHead">Resource Id</p>
                            <p className="logsData">{logData.resourceId}</p>
                          </div>
                        )}
                        {logData?.message && (
                          <div className="form-group mb-0">
                            <p className="logHead">Message</p>
                            <p className="logsData mb-0">{logData.message}</p>
                          </div>
                        )}
                      </div>
                      {logData?.data && (
                        <div className="form-group">
                          <p className="logHead">Data</p>
                          <br />
                          <code>{"{"}</code>
                          <br />
                          <code>{`email : ${logData.data.email}`}</code>

                          {logData.data.firstName &&
                            logData.data.firstName !== "" && (
                              <>
                                <br />
                                <code>{`firstName : ${logData.data.firstName}`}</code>
                              </>
                            )}
                          {logData.data.lastName && logData.data.lastname !== "" && (
                            <>
                              <br />
                              <code>{`lastName : ${logData.data.lastName}`}</code>
                            </>
                          )}
                          {logData.data.role && logData.data.role !== "" && (
                            <>
                              <br />
                              <code>{`role : ${logData.data.role}`}</code>
                            </>
                          )}
                          <br />
                          <code>{"}"}</code>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default ViewLogs;
