import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const ClientRoute = ({ children }) => {
    const roleList = useSelector((state) => state?.loginReducer?.loginUserInfo);
    const {isSuperAdminLoggedIn} = useSelector((state) => state?.loginReducer);
    
    if(roleList?.group === 2) { 
      return children;
    } else {
      if(isSuperAdminLoggedIn) {
        return <Navigate to="/super-admin/dashboard" />
      } else {
        return <Navigate to="/dashboard" />
      }
    }
};

export default ClientRoute;